import axios from "axios";

// custom
import { sortGroupsParams } from "../types/request";
import { GroupsResponseType } from "../types/GetGroupsListResponseType";
import { CreateNewGroupResponseType } from "../types/CreateNewGroupResponseType";

// get all conatcttypes list
export function getProductTypesList() {
  return axios.get<GroupsResponseType>(`/products/types`);
}

// create new contact type
export function createNewProductType(name: string,unit?: string) {
  return axios.post<CreateNewGroupResponseType>(`/products/types`, {
    name: name,
    units: unit
  });
}

export function getProductUnits(){
  return axios.get<any>(`/products/units`)
}

// edit existing contact type
export function updateMasterProductType(id: number, name: string, unit?: string) {
  return axios.post<CreateNewGroupResponseType>(`/products/types/${id}`, {
    name: name,
    units: unit
  });
}

// delete existing contact type
export function deleteProductType(id: number) {
  return axios.delete<CreateNewGroupResponseType>(`/products/types/${id}`);
}

// mass delete existing contact types
export function massDeleteProductTypes(productTypes_id: Array<number>) {
  return axios.post<CreateNewGroupResponseType>(`/products/types/massDelete`, {
    productTypes_id,
  });
}

// sort existing contact types
export function sortProductTypes(product_types_sorting: sortGroupsParams) {
  return axios.post<CreateNewGroupResponseType>(`/products/types/sorting`, {
    product_types_sorting,
  });
}
