import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { getPipelineByID } from "../../pipelines/redux/PipelinesAPI";
import { PipelineType } from "../../pipelines/types/PipelinesResponseType";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";

interface Props {
  show: boolean;
  closeModal: () => void;
  pipeline_id: number;
}

const PipelineDetailsModal: FC<Props> = ({ show, closeModal, pipeline_id }) => {
  const intl = useIntl();
  const PIPELINE_TRANSITION_TYPE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "PIPELINE_TRANSITION_TYPE_OPTIONS" })
  ) as {
    id: number;
    name: string;
    title: string;
  }[];

  //   state
  const [loadng, setLoading] = useState(false);
  const [singlePipeline, setSinglePipeline] = useState<PipelineType>();

  useEffect(() => {
    if (pipeline_id) {
      setLoading(true);
      getPipelineByID(pipeline_id)
        .then(({ data: { pipeline } }) => {
          setSinglePipeline(pipeline);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [pipeline_id]);

  return (
    <Modal
      show={show}
      centered
      contentClassName={loadng ? "pe-none" : "AddNew"}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>{"Pipeline Details"}</div>
        </Modal.Title>
      </Modal.Header>
      <form className="form w-100 overflow-auto" noValidate id="kt_add_category_form">
        <Modal.Body>
          {loadng ? (
            <SimpleLoader fullLoader />
          ) : (
            <div className="pt-4">
              <div className="d-flex justify-content-center flex-column">
                <div className="flex-stack mb-4">
                  <label className="fw-bold fs-6">Pipeline name:</label>
                  <label className="text-muted fs-6 ms-3">
                    {singlePipeline?.name}
                  </label>
                </div>
                <div className="flex-stack">
                  <label className="fw-bold fs-6">Transition Type:</label>
                  <label className="text-muted fs-6 ms-3">
                    {PIPELINE_TRANSITION_TYPE_OPTIONS.find(
                      (TITLES_OPTION) =>
                        TITLES_OPTION.name === singlePipeline?.transition_type
                    )?.title || ""}
                  </label>
                </div>
              </div>
              <div className="mt-4 alert alert-warning d-flex align-items-start flex-column text-start">
                {intl
                  .formatMessage({
                    id: singlePipeline?.transition_type.includes("free")
                      ? "PIPELINE_MODAL_FREE_FLOW_INFO"
                      : "PIPELINE_MODAL_STEP_BY_STEP_INFO",
                  })
                  .split(".")
                  .map((line) => {
                    if (line && line.length > 0) return <li>{line}</li>;
                  })}
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PipelineDetailsModal;
