/*
 * Simple input text component that takes placeholder text as a prop
 */
// import clsx from 'clsx'
import React, { FC, useEffect, useRef, useState } from "react";

interface Props {
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const TextInput: FC<Props> = ({ value, onChange, disabled }) => {
  const textareaRef = useRef(null);
  const [textValue, setTextValue] = useState<string>(value);
  const [width, setWidth] = useState({});

  const handleResize = () => {
    setWidth({ width: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    //@ts-ignore
    textareaRef.current.style.height = "0px";
    //@ts-ignore
    const scrollHeight = textareaRef.current.scrollHeight;
    //@ts-ignore
    textareaRef.current.style.height = scrollHeight + 3 + "px";
  }, [textValue, width]);

  return (
    <textarea
      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
      autoComplete="off"
      placeholder=""
      style={{ resize: "none" }}
      ref={textareaRef}
      value={value}
      onChange={(e) => {
        setTextValue(e.target.value);
        onChange?.(e.target.value);
      }}
      disabled={disabled}
    ></textarea>
  );
};

export default TextInput;
