import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FieldType } from "../types/FieldsReponseTypes";
import Fields from "./Fields";

export interface FieldSelectModalProps {
  field_id: number;
  is_required: number;
}

interface Props {
  show: boolean;
  closeModal: () => void;
  onSelectedFields: (fields: FieldSelectModalProps[]) => void;
  isSelectionModal?: boolean;
  selectedFields?: FieldType[] | undefined | any;
}

const SelectFieldModal: FC<Props> = ({
  show,
  closeModal,
  onSelectedFields,
  isSelectionModal,
  selectedFields,
}) => {
  const intl = useIntl();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  return (
    <Modal
      show={show}
      centered
      dialogClassName="extra-large-size-modal"
      className={`${isFilterOpen ? "d-none" : "d-block"}`}
      backdrop="static"
      onHide={closeModal}
      contentClassName="overflow-auto"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "SELECT_FIELDS_MODAL_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="overflow-inherit"
        // className={`${isSelectionModal ? "selections_modal" : ""}`}
        // style={{ padding: "0px" }}
      >
        <Fields
          onSelectedFields={onSelectedFields}
          isSelectionModal={isSelectionModal}
          selectedFields={selectedFields}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SelectFieldModal;
