import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

// custom
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import FilemanagerIcon from "../../../../_metronic/assets/icons/filemanager.svg";
import { KTSVG, SVGICON } from "../../../../_metronic/helpers";
import Tabs from "../../../sharedComponents/tabs/Tabs";
import { CustomerType, UserType } from "../../auth/types/LoginResponseTypes";
import { actions, getCustomerDetails } from "../redux";
import CustomerLabelsSelect from "./CustomerLabelsSelect";
import CustomerNotice from "./CustomerNotice";
import Notes from "../../notes/Notes";
import CustomerActivity from "./CustomerActivity";
import ProgressCard from "./ProgressCard";
import CustomerProfile from "./CustomerProfileTab";
import { CustomerLabelsList } from "./CustomerLabelsSelect";
import { ScrollTop } from "../../../../_metronic/layout/components/ScrollTop";
// import BlockCustomer from "./BlockCustomer";
// import CustomerComments from "./CustomerComments";
// import CustomerDevices from "./CustomerDevices";
// import CustomerEmailChange from "./CustomerEmailChange";
// import CustomerHas2FA from "./CustomerHas2FA";
// import CustomerLogs from "./CustomerLogs";
// import CustomerPasswordChange from "./CustomerPasswordChange";

const EditUserDetails = () => {
  const dispatch = useDispatch();
  const { state = {} } = useLocation();
  const history = useHistory();
  const intl = useIntl();
  // @ts-ignore
  const personalInformation: UserType = state.userDetails || {};

  const [tabActiveIndex, setTabActiveIndex] = useState<number>(0);

  const onTabChange = (index: number) => {
    if (index !== tabActiveIndex) {
      setTabActiveIndex(index);
    }
  };

  useEffect(() => {
    getCustomerDetailsAPI();
  }, [personalInformation.id]);

  // state
  const [userDetails, setUserDetails] = useState<UserType>(personalInformation);

  const [customerDetails, setCustomerDetails] = useState<CustomerType>(
    // @ts-ignore
    state.customerDetails || {}
  );
  const [displayComments, setDisplayComments] = useState<string | null>("");

  const getCustomerDetailsAPI = (
    callback?: (customer: CustomerType) => void
  ) => {
    if (personalInformation?.id) {
      getCustomerDetails(personalInformation?.id).then(({ data }) => {
        const { personalInformation, comments } = data || {};
        // @ts-ignore
        setUserDetails(personalInformation);
        history.replace({ state: { userDetails: personalInformation } });
        // @ts-ignore
        setCustomerDetails(data);
        setDisplayComments(comments);
        // @ts-ignore
        callback?.(data);
        dispatch(actions.setCustomerDetails(data));
      });
    }
  };

  useEffect(() => {
    getCustomerDetailsAPI();
  }, []);

  return (
    <>
      <div className="card  bgi-position-y-center bgi-no-repeat mb-8 mt-7">
        {/* contact/partner information */}
        <div className="px-8 pt-8 d-flex flex-row align-items-center justify-content-between mb-2">
          <div className="d-flex flex-row align-items-center">
            <h1>
              {customerDetails?.personalInformation?.firstname}{" "}
              {customerDetails?.personalInformation?.lastname}
            </h1>
            <CustomerLabelsList
              customer={customerDetails?.personalInformation}
              customerLabels={personalInformation.labels}
              getCustomerDetailsAPI={getCustomerDetailsAPI}
            />
          </div>
        </div>
        {/* contact and partner information */}
        <div className="d-flex px-8 pb-8">
          <div className="d-flex flex-row d-grid gap-2">
            <div className="d-flex align-items-center me-5 mb-2">
              <KTSVG
                path="/media/icons/duotune/communication/com014.svg"
                className="svg-icon-1 mt-2"
              />
              <div className="text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8">
                {customerDetails?.personalInformation?.telephone}
              </div>
            </div>

            {customerDetails?.personalAddress?.address_1 && (
              <>
                <div className="me-5 mb-2 d-flex align-items-center fs-8">
                  <div className="symbol symbol-30px">
                    <KTSVG
                      path="media/icons/duotune/general/gen018.svg"
                      className="svg-icon-1 me-2 "
                    />
                  </div>
                  <div className="fw-semibold me-5">
                    {customerDetails?.personalAddress && (
                      <>
                        <div className="text-gray-500 fw-semibold fs-7 text-start maxWidth-100px ps-0 fs-8">
                          {[
                            customerDetails.personalAddress.address_1,
                            customerDetails.personalAddress.address_2,
                          ]
                            .filter(Boolean) // Filters out any falsy values (like empty strings)
                            .join(", ")}{" "}
                          {/* Joins the non-empty parts with a comma */}
                        </div>
                        <div className="text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8">
                          {[
                            customerDetails.personalAddress.city,
                            customerDetails.personalAddress.zip_code,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="d-flex align-items-center me-5 mb-2 ">
              <KTSVG
                path="/media/icons/duotune/communication/com002.svg"
                className="svg-icon-1 me-2 "
              />
              <a
                className="d-inline-block text-truncate text-gray-500 fw-semibold fs-7 text-start ps-0 fs-8"
                style={{ maxWidth: "300px" }}
              >
                {personalInformation.email}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row">
        <div
          className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10"
          style={{ flex: "0 0 300px" }}
        >
          {/* <BlockCustomer customer={userDetails} getCustomerDetailsAPI={getCustomerDetailsAPI} /> */}

          <CustomerNotice
            // @ts-ignore
            comment={displayComments}
            userId={userDetails.id}
            getCustomerDetailsAPI={getCustomerDetailsAPI}
          />
      {/* commenting for now */}
          {/* <ProgressCard className="" chartColor="primary" chartHeight="270" /> */}

          {/* <CustomerEmailChange
            // @ts-ignore
            userDetails={userDetails}
            // @ts-ignore
            getCustomerDetailsAPI={getCustomerDetailsAPI}
          /> */}
        </div>
        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
          <div className="card pb-0">
            <div
              className="card-header pb-0 pt-2"
              style={{ minHeight: "auto" }}
            >
              <Tabs
                tabs={[
                  intl.formatMessage({ id: "CONTACT_DETAILS_NOTES_TAB" }),
                  intl.formatMessage({ id: "CONTACT_DETAILS_EDIT_TAB" }),
                  intl.formatMessage({ id: "CONTACT_DETAILS_ACTIVITY_TAB" }),
                ]}
                activeIndex={tabActiveIndex}
                onActiveIndexChange={(index) => {
                  onTabChange(index);
                }}
              />
            </div>
            <div className="card-body">
              {tabActiveIndex === 0 && (
                <Notes users_id={personalInformation?.id} />
              )}
              {tabActiveIndex === 1 && (
                <CustomerProfile
                  userDetails={userDetails}
                  customerDetails={customerDetails}
                  getCustomerDetailsAPI={getCustomerDetailsAPI}
                />
              )}
              {tabActiveIndex === 2 && <CustomerActivity />}
            </div>
          </div>

          {/* <SignInMethod userDetails={userDetails} getCustomerDetailsAPI={getCustomerDetailsAPI} /> */}
          {/* <CustomerComments
              // @ts-ignore
              customerDetails={customerDetails}
              userId={personalInformation?.id}
              getCustomerDetailsAPI={getCustomerDetailsAPI}
              displayComments={displayComments}
            /> */}
          {/* <CustomerDevices
              customerDetails={customerDetails}
              getCustomerDetailsAPI={getCustomerDetailsAPI}
            /> */}
        </div>
      </div>
    </>
  );
};

export default EditUserDetails;
