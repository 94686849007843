import React, { FC, useState } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import clsx from "clsx";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { RootState } from "../../../../setup";
import { PipelineType } from "../../pipelines/types/PipelinesResponseType";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSubmitHandle: (piplineId: number | undefined) => void;
  id: number;
}

const EndPhaseTransitionModal: FC<Props> = ({
  show,
  closeModal,
  onSubmitHandle,
  id,
}) => {
  const intl = useIntl();

  // state
  const [loading, setLoading] = useState(false);

  const pipelineList = useSelector<RootState>(
    // @ts-ignore
    ({ pipelines }) => pipelines.pipelinesList
  ) as PipelineType[];

  const initialValues = {
    pipeline: undefined,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      pipeline: Yup.number()
        .required(
          intl.formatMessage({
            id: "DEAL_END_PHASE_TRANSITION_REQUIRED_VALIDATION_MESSAGE",
          })
        )
        .oneOf([...pipelineList?.map((item) => item?.id)]),
    }),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      if (values.pipeline) {
        onSubmitHandle(values.pipeline);
      }
      setLoading(false);
    },
  });

  return (
    <Modal
      show={show}
      centered
      contentClassName={"AddNew"}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "DEAL_END_PHASE_TRANSITION_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body>
          <div className="p-4">
            <div className="">
              <div className="alert alert-warning d-flex align-items-center flex-wrap">
                {intl.formatMessage({ id: "DEAL_END_PHASE_TRANSITION_INFO" })}
              </div>
            </div>
            <div>
              {/* Role */}
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="">
                  {intl.formatMessage({ id: "DEAL_PIPLINE_TITLE" })}
                </span>
              </label>
              <DropDown
                id="UserRoleTypeDropDown"
                items={
                  pipelineList.filter(
                    (data) => data.id !== id && data?.status === "publish"
                  ) || []
                }
                displayValue={
                  pipelineList.find(
                    (role) => role.id === formik.values.pipeline
                  )?.name || ""
                }
                selectedItem={pipelineList.find(
                  (role) => role.id === formik.values.pipeline
                )}
                onSelectItem={(item) => {
                  formik.setFieldValue("pipeline", item.id);
                }}
                displayFunc={(item) => {
                  const status = pipelineList.find(
                    (p) => p.id === item.id
                  )?.status;
                  const statusText =
                    status === "publish"
                      ? "Published"
                      : status === "draft"
                        ? "Draft"
                        : status === "deactivate"
                          ? "Deactivated"
                          : undefined;
                  const badgeType =
                    status === "publish"
                      ? "success"
                      : status === "draft"
                        ? "secondary"
                        : status === "deactivate"
                          ? "danger"
                          : undefined;
                  return (
                    <div className="d-flex justify-content-start align-items-center">
                      {item.name}{" "}
                      <span
                        className={clsx(
                          "badge ms-4 fs-9",
                          `badge-${badgeType}`
                        )}
                      >
                        {statusText}
                      </span>
                    </div>
                  );
                }}
              />
              {formik.touched.pipeline && formik.errors.pipeline && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.pipeline}</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            // disabled={!formik.values.pipeline}
          >
            {loading ? (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "MASTERDATA_TAGS_LOADING_MESSAGE" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              intl.formatMessage({ id: "SUBMIT_BUTTON" })
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EndPhaseTransitionModal;
