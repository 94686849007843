import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import CloseButton from "../closeButton/CloseButton";
import { PartnerType } from "../../modules/partnerManagement/types/getPartnersListResponseType";
import PartnersList from "../../modules/partnerManagement/components/PartnersList";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSelectPartner: (partners: PartnerType[]) => void;
  loading?: boolean;
  isSelectionModal?: boolean;
}

const SelectPartnersModal: FC<Props> = ({
  show,
  closeModal,
  onSelectPartner,
  loading,
  isSelectionModal,
}) => {
  const intl = useIntl();
  // state
  const [hideMoadal, setHideModal] = useState(false);
  return (
    <Modal
      show={show}
      centered
      dialogClassName="extra-large-size-modal"
      backdrop="static"
      className={hideMoadal ? "d-none" : "d-block"}
    >
      <Modal.Header>
        <div className="d-flex flex-row flex-fill align-items-center justify-content-between  ">
          <Modal.Title>
            {intl.formatMessage({
              id: "PARTNER_SELECT_MESSAGE",
            })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>
      <Modal.Body
        className={`${isSelectionModal ? "selections_modal" : ""}`}
        style={{ padding: "0px" }}
      >
        <PartnersList
          type={"select"}
          onSelectPartner={onSelectPartner}
          loading={loading}
          isSelectionModal={false}
          hideMainModal={(value) => {
            setHideModal(value);
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SelectPartnersModal;
