import axios from "axios";
import { CompanySettingsResponse } from "../types/CompanySettingsResponse";

export function getEvents(user_id: number, google_id: number) {
  return axios.get<CompanySettingsResponse>(
    `/googles/calendar/events/${google_id}/${user_id}`
  );
}

export function getCompanySettings() {
  return axios.get(`/partners/self`);
}

export function updateLogo(id: number, body: any) {
  return axios.post<any>(`/company/update/logo/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      // console.log('progressEventprogressEventprogressEvent', progressEvent)
    },
  });
}

export function updateCompanyGeneralDetails(
  id: number,
  name: string,
  street: null | string,
  street_extra: null | string,
  zipcode: null | number,
  city: null | string,
  country_id: null | number,
  email: string,
  website: string,
  telephone: string
) {
  return axios.post<CompanySettingsResponse>(`/company/general/${id}`, {
    name,
    street: street ? street : null,
    street_extra: street_extra ? street_extra : null,
    zipcode: zipcode ? zipcode : null,
    city: city ? city : null,
    country_id,
    email,
    website,
    telephone,
  });
}
