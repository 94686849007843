import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import { ProductType } from "../types/ProductsResponseType";
import { updateProductSellingPrice } from "../redux/ProductsAPI";
import { successToast } from "../../../sharedComponents/toasts/Toasts";
import { SVGICON } from "../../../../_metronic/helpers";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { useSelector } from "react-redux";

interface Props {
  product: ProductType;
  getProductDetailsAPI: () => void;
}

const ProductStock: FC<Props> = ({ product, getProductDetailsAPI }) => {
  const intl = useIntl();
  const [sellingValue, setSellingValue] = useState(product.selling_price);
  const [EditsellingPrice, setEditSellingPrice] = useState<Boolean>(false);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, "products"))

  const handleStockUpdate = () => {
    updateProductSellingPrice(product.id, sellingValue)
      .then((data) => {
        successToast(
          intl.formatMessage({ id: "PRODUCT_STOCK_UPDATE_SUCCESS_MESSAGE" })
        );
      })
      .catch((e) => {
        intl.formatMessage({ id: "PRODUCT_STOCK_UPDATE_FAILURE_MESSAGE" });
      })
      .finally(() => {
        getProductDetailsAPI();
      });
  };

  return (
    <div className={`card card-flush py-4 ${!crudPermission?.edit && "pe-none"}`}>
      <div className="card-header">
        <div className="card-title">
          <h2>{intl.formatMessage({ id: "PRODUCTS_STOCK_CARD_TITLE" })}</h2>
        </div>
      </div>

      <div className="card-body pt-0">
        {/* Groups */}
        <div className="row mb-8">
          <div className="row col-12 mb-3">
            <div className="col-6 fw-bold d-flex align-items-center">
              {intl.formatMessage({ id: "PRODUCTS_STOCK_TITLE" })}
            </div>
            <div className="col-6">
              <input
                type="string"
                className=" form-control form-control-lg form-control-solid mb-3 mb-lg-0 "
                // @ts-ignore
                value={product.stock}
                onChange={(e) => {}}
                disabled
              />
            </div>
          </div>
          <div className="col-12 mb-3 row">
            <div className="col-6 fw-bold d-flex align-items-center">
              {intl.formatMessage({ id: "PRODUCTS_AVG_BOUGHT_PRICE_TITLE" })}
            </div>
            <div className="col-6">
              <input
                type="string"
                className=" form-control form-control-lg form-control-solid mb-3 mb-lg-0 "
                // @ts-ignore
                value={product.avg_bought_price}
                onChange={(e) => {}}
                disabled
              />
            </div>
          </div>
          <div className="col-12 mb-3 row">
            <div className="col-6 fw-bold d-flex align-items-center">
              {intl.formatMessage({ id: "PRODUCTS_SELLING_PRICE_TITLE" })}
            </div>
            {!EditsellingPrice ? (
              <div className="d-flex col-6">
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <input
                    type="string"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 "
                    // @ts-ignore
                    value={sellingValue}
                    disabled
                  />
                </div>
                <div>
                  <button
                    className="btn btn-lg btn-icon btn-light btn-active-light-primary mx-1"
                    onClick={() => {
                      setEditSellingPrice(true);
                    }}
                  >
                    <SVGICON src={EditIcon} className="svg-icon-2" />
                  </button>
                </div>
              </div>
            ) : (
              <div className="col-6">
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 "
                  // @ts-ignore
                  value={sellingValue}
                  onChange={(e) => {
                    setSellingValue(e.target.value);
                    // var numberRegex =
                    //   /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/;
                    // if (numberRegex.test(e.target.value))
                    //   setSellingValue(e.target.value);
                  }}
                  autoFocus
                  onBlur={(e) => {
                    if (sellingValue) {
                      handleStockUpdate();
                    }
                    setEditSellingPrice(false);
                  }}
                />
              </div>
            )}
          </div>
          <div className="col-12 mb-3 row">
            <div className="col-6 fw-bold d-flex align-items-center">
              {intl.formatMessage({ id: "PRODUCTS_GROSS_PROFIT_TITLE" })}
            </div>
            <div className="col-6">
              <input
                type="string"
                className=" form-control form-control-lg form-control-solid mb-3 mb-lg-0 "
                value={product.gross_profit || "0.0"}
                onChange={(e) => {}}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductStock;
