import React from "react";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";

// custom
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import Calendar from "./components/Calendar";

const CalendarPage: React.FC = () => {
  const intl = useIntl();

  const calendarBreadCrumbs: Array<PageLink> = [
    // {
    //   title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
    {
      title: intl.formatMessage({ id: "MENU_CALENDAR" }),
      path: "/calendar",
      isSeparator: false,
      isActive: false,
    },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
  ];

  return (
    <div>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/calendar/overview">
          <PageTitle
            breadcrumbs={[
              ...calendarBreadCrumbs,
              // {
              //   title: intl.formatMessage({ id: "ROUTES_OVERVIEW" }),
              //   path: "/calendar/overview",
              //   isSeparator: false,
              //   isActive: true,
              // },
            ]}
          >
            {intl.formatMessage({ id: "MENU_CALENDAR" })}
          </PageTitle>
          <Calendar />
        </Route>

        <Redirect
          from="/calendar"
          exact={true}
          to="/calendar/overview"
        />
        <Redirect to="/calendar" />
      </Switch>
    </div>
  );
};

export default CalendarPage;
