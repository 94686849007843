import { Action } from "@reduxjs/toolkit";
import { OrderProductType } from "../types/OrdersTypes";
import { InvoiceFilterType } from "../types/getInvoicesByFiltersResponseType";
import { InvoiceType } from "../types/InvoicesTypes";
import {
  SalesContactsResponseType,
  SalesPaginationType,
} from "../../Offers/types/OfferTypes";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  SET_FILTER_DETAILS: "SET_FILTER_DETAILS",
  SET_ACTIVE_PAGE_DETAILS: "SET_ACTIVE_PAGE_DETAILS",
  SET_INVOICES_LIST: "SET_INVOICES_LIST",
  SET_INVOICES_DETAILS: "SET_INVOICES_DETAILS",
  SET_INVOICES_ACTIVE_PAGE_DETAILS: "SET_INVOICES_ACTIVE_PAGE_DETAILS",
  SET_INVOICES_PAGINATION: "SET_INVOICES_PAGINATION",
  SET_INVOICES_SEARCH_TEXT: "SET_INVOICES_SEARCH_TEXT",
  SET_INVOICES_TAB_ACTIVE_INDEX: "SET_INVOICES_TAB_ACTIVE_INDEX",
  SET_INVOICES_PARTNERS_AND_CONTACTS: "SET_INVOICES_PARTNERS_AND_CONTACTS",
  SET_INVOICES_PRODUCTS_AND_ITEMS: "SET_INVOICES_PRODUCTS_AND_ITEMS",
};

export interface EventsInitState {
  filterDetails: InvoiceFilterType;
  invoicesList: InvoiceType[];
  invoiceDetails: InvoiceType;
  activePageDetails: [];
  invoicesPagination: any[];
  invoicesSearchText: string;
  invoicesTabActiveIndex: number;
  invoicesPartnersAndContacts: SalesContactsResponseType;
  productsAndItems: OrderProductType[];
}

const initialState: EventsInitState = {
  filterDetails: {} as InvoiceFilterType,
  invoicesList: [],
  invoiceDetails: {} as InvoiceType,
  activePageDetails: [],
  invoicesPagination: [],
  invoicesSearchText: "",
  invoicesTabActiveIndex: 0,
  invoicesPartnersAndContacts: {} as SalesContactsResponseType,
  productsAndItems: [],
};

export const actions = {
  setFilterDetails: (filters: InvoiceFilterType) => ({
    type: actionTypes.SET_FILTER_DETAILS,
    payload: filters,
  }),
  setActivePageDetails: (events: number) => ({
    type: actionTypes.SET_ACTIVE_PAGE_DETAILS,
    payload: events,
  }),
  setInvoicesList: (invoices: InvoiceType[]) => ({
    type: actionTypes.SET_INVOICES_LIST,
    payload: invoices,
  }),
  setInvoicesPagination: (invoicesPagination: SalesPaginationType) => ({
    type: actionTypes.SET_INVOICES_PAGINATION,
    payload: invoicesPagination,
  }),
  setInvoicesSearchText: (invoicesSearchText: string) => ({
    type: actionTypes.SET_INVOICES_SEARCH_TEXT,
    payload: invoicesSearchText,
  }),
  setInvoiceDetails: (invoiceDetails: InvoiceType) => ({
    type: actionTypes.SET_INVOICES_DETAILS,
    payload: invoiceDetails,
  }),
  setInvoicesActivePageDetails: (page: number) => ({
    type: actionTypes.SET_INVOICES_ACTIVE_PAGE_DETAILS,
    payload: page,
  }),
  setInvoicesTabActiveIndex: (activePage: number) => ({
    type: actionTypes.SET_INVOICES_TAB_ACTIVE_INDEX,
    payload: activePage,
  }),
  setInvoicePartnersAndContacts: (
    invoicesPartnersAndContacts: SalesContactsResponseType
  ) => ({
    type: actionTypes.SET_INVOICES_PARTNERS_AND_CONTACTS,
    payload: invoicesPartnersAndContacts,
  }),
  setProductsAndItems: (productsAndItems: OrderProductType[]) => ({
    type: actionTypes.SET_INVOICES_PRODUCTS_AND_ITEMS,
    payload: productsAndItems,
  }),
};

export const InvoicesReducer = (
  state = initialState,
  action: ActionWithPayload<EventsInitState>
) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_DETAILS: {
      const filters = action.payload || {};
      return { ...state, filterDetails: filters };
    }
    case actionTypes.SET_ACTIVE_PAGE_DETAILS: {
      const events = action.payload || 1;
      return { ...state, activeEventPageDetails: events };
    }
    case actionTypes.SET_INVOICES_LIST: {
      const invoices = action.payload || [];
      return { ...state, invoicesList: invoices };
    }
    case actionTypes.SET_INVOICES_DETAILS: {
      const invoiceDetails = action.payload || [];
      return { ...state, invoiceDetails };
    }
    case actionTypes.SET_INVOICES_ACTIVE_PAGE_DETAILS: {
      const data = action.payload || 1;
      return { ...state, activePageDetails: data };
    }
    case actionTypes.SET_INVOICES_PAGINATION: {
      const page = action.payload || [];
      return { ...state, invoicesPagination: page };
    }
    case actionTypes.SET_INVOICES_SEARCH_TEXT: {
      const invoicesSearchText = action.payload || "";
      return { ...state, invoicesSearchText };
    }
    case actionTypes.SET_INVOICES_TAB_ACTIVE_INDEX: {
      const data = action.payload || 0;
      return { ...state, invoicesTabActiveIndex: data };
    }
    case actionTypes.SET_INVOICES_PARTNERS_AND_CONTACTS: {
      const data = action.payload || {};
      return { ...state, invoicesPartnersAndContacts: data };
    }
    case actionTypes.SET_INVOICES_PRODUCTS_AND_ITEMS: {
      const data = action.payload || {};
      return { ...state, productsAndItems: data };
    }

    default:
      return state;
  }
};
