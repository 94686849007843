const Constants = {
  defaultPaginationCount: 10,
  defaultItemsPerPageCount: 10,
  selectFileDefaultPaginationCount: 10,
  mimeTypes: {
    image: ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"],
    document: ["pdf", "PDF"],
    video: ["mp4", "mov", "avi", "MP4", "MOV", "AVI"],
    audio: ["aac", "mp3", "wav", "AAC", "MP3", "WAV"],
  },

  sectionCustomRuleConditions: [
    { id: 1, name: "Or", value: "or" },
    { id: 2, name: "And", value: "and" },
  ],
  SectionsIcons: {
    // Banner
    1: "bi bi-microsoft",
    // Carousel
    2: "bi bi-sliders",
    // List
    3: "bi bi-list-stars fs-2x",
    // NewsLetter
    4: "bi bi-newspaper",
    // Carousel
    5: "bi bi-sliders",
    //List
    6: "bi bi-list-stars fs-2x",
    //List
    7: "bi bi-list-stars fs-2x",
    //List
    8: "bi bi-list-stars fs-2x",
    //List
    9: "bi bi-list-stars fs-2x",
    // advertisement
    10: "bi bi-list-stars fs-2x",
    // related posts
    11: "bi bi-list-stars fs-2x",
    // related events
    12: "bi bi-list-stars fs-2x",
    // navigations
    13: "bi bi-box-arrow-up-right",
    // Scroller
    14: "bi bi-sliders",
    // Scroller
    15: "bi bi-sliders",
    16: "bi bi-list-stars fs-2x",
    17: "bi bi-list-stars fs-2x",
  },
  LangaugeTypes: [
    { id: 51, name: "German", code: "de" },
    { id: 40, name: "English", code: "en" },
  ],
  EmailTemplateTypes: [
    {
      id: 1,
      name: "forgot password - system",
      value: "forgot_password_system",
      url: "https://" + window.location.host + "/forgot-password/{{%URL%}}",
    },
    {
      id: 2,
      name: "forgot password - app",
      value: "forgot_password_app",
      url: "https://" + window.location.host + "/forgot-password/{{%URL%}}",
    },
    {
      id: 3,
      name: "user registration",
      value: "user_registration",
      url: process.env.REACT_APP_WEBAPP_URL + "/register/verify/{{%URL%}}",
    },
    {
      id: 4,
      name: "user double Opt-in",
      value: "user_double_opt_in",
      url: process.env.REACT_APP_WEBAPP_URL,
    },
  ],
  unauthorizedErrorpopupAllowedRoutes: [
    { route: "posts", apiUrl: "posts" },
    { route: "posts", apiUrl: "mediaManager" },
    { route: "events", apiUrl: "events" },
    { route: "file-manager", apiUrl: "mediaManager" },
    { route: "legal-texts", apiUrl: "legalTexts" },
    { route: "deals", apiUrl: "deals" },
    { route: "website-app", apiUrl: "pages" },
    { route: "crm/organisation", apiUrl: "partners" },
    { route: "twitter", apiUrl: "twitters" },
    { route: "google", apiUrl: "googles" },
    { route: "crm/contacts", apiUrl: "user" },
    { route: "crm/contacts", apiUrl: "contact" },
    { route: "user", apiUrl: "user" },
    { route: "roles-permissions", apiUrl: "roles" },
    { route: "roles-permissions", apiUrl: "resources" },
    { route: "master-data", apiUrl: "categories" },
    { route: "master-data", apiUrl: "contactDetails" },
    { route: "master-data", apiUrl: "contactsTitles" },
    { route: "master-data", apiUrl: "groups" },
    { route: "master-data", apiUrl: "tags" },
    { route: "master-data", apiUrl: "labels" },
    { route: "master-data", apiUrl: "sectors" },
    { route: "master-data", apiUrl: "fields" },
    { route: "master-data", apiUrl: "tabs" },
    { route: "master-data", apiUrl: "types" },
    { route: "products", apiUrl: "products" },
    { route: "pipelines", apiUrl: "pipelines" },
    { route: "teams", apiUrl: "teams" },
    
    { route: "system/settings", apiUrl: "system" },
    { route: "pipelines", apiUrl: "pipeline/phases" },
  ],
  pieChartColors: ["#009ef7", "#51cd89", "#ffc107", "#17a2b8"],
  sectionsSlugs: {
    relatedPosts: "related-post-list",
    relatedEvents: "related-event-list",
  },
  currencySymbols: {
    eur: "€",
    dollar: "$",
  },
  noSpecialCharectersRegex: new RegExp("^[a-zA-Z0-9 ]*$"),
};

export default Constants;
