import { Action } from "@reduxjs/toolkit";
import {
  OfferType,
  OfferProductType,
  SalesPaginationType,
  SalesContactsResponseType,
} from "../types/OfferTypes";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  SET_OFFERS_LIST: "SET_OFFERS_LIST",
  SET_OFFERS_DETAILS: "SET_OFFERS_DETAILS",
  SET_OFFERS_ACTIVE_PAGE_DETAILS: "SET_OFFERS_ACTIVE_PAGE_DETAILS",
  SET_OFFERS_PAGINATION: "SET_OFFERS_PAGINATION",
  SET_OFFERS_SEARCH_TEXT: "SET_OFFERS_SEARCH_TEXT",
  SET_OFFERS_TAB_ACTIVE_INDEX: "SET_OFFERS_TAB_ACTIVE_INDEX",
  SET_OFFERS_PARTNERS_AND_CONTACTS: "SET_OFFERS_PARTNERS_AND_CONTACTS",
  SET_OFFERS_PRODUCTS_AND_ITEMS: "SET_OFFERS_PRODUCTS_AND_ITEMS",
  SET_OFFERS_CHANNEL_FILTER: "SET_OFFERS_CHANNEL_FILTER",
};

const initialState: OffersInitState = {
  offersList: [],
  offerDetails: {} as OfferType,
  activePageDetails: [],
  offersPagination: {} as SalesPaginationType,
  offersSearchText: "",
  offersTabActiveIndex: 0,
  offersPartnersAndContacts: {} as SalesContactsResponseType,
  productsAndItems: [],
};

export interface OffersInitState {
  offersList: OfferType[];
  offerDetails: OfferType;
  activePageDetails: [];
  offersPagination: SalesPaginationType;
  offersSearchText: string;
  offersTabActiveIndex: number;
  offersPartnersAndContacts: SalesContactsResponseType;
  productsAndItems: OfferProductType[];
}

export const actions = {
  setOffersList: (offers: OfferType[]) => ({
    type: actionTypes.SET_OFFERS_LIST,
    payload: offers,
  }),
  setOffersPagination: (offersPagination: SalesPaginationType) => ({
    type: actionTypes.SET_OFFERS_PAGINATION,
    payload: offersPagination,
  }),
  setOffersSearchText: (offersSearchText: string) => ({
    type: actionTypes.SET_OFFERS_SEARCH_TEXT,
    payload: offersSearchText,
  }),
  setOfferDetails: (offerDetails: OfferType) => ({
    type: actionTypes.SET_OFFERS_DETAILS,
    payload: offerDetails,
  }),
  setOffersActivePageDetails: (page: number) => ({
    type: actionTypes.SET_OFFERS_ACTIVE_PAGE_DETAILS,
    payload: page,
  }),
  setOffersTabActiveIndex: (activePage: number) => ({
    type: actionTypes.SET_OFFERS_TAB_ACTIVE_INDEX,
    payload: activePage,
  }),
  setOfferPartnersAndContacts: (
    offersPartnersAndContacts: SalesContactsResponseType
  ) => ({
    type: actionTypes.SET_OFFERS_PARTNERS_AND_CONTACTS,
    payload: offersPartnersAndContacts,
  }),
  setProductsAndItems: (productsAndItems: OfferProductType[]) => ({
    type: actionTypes.SET_OFFERS_PRODUCTS_AND_ITEMS,
    payload: productsAndItems,
  }),
};

export const SalesOffersReducer = (
  state = initialState,
  action: ActionWithPayload<OffersInitState>
) => {
  switch (action.type) {
    case actionTypes.SET_OFFERS_LIST: {
      const offers = action.payload || [];
      return { ...state, offersList: offers };
    }
    case actionTypes.SET_OFFERS_DETAILS: {
      const offerDetails = action.payload || [];
      return { ...state, offerDetails };
    }
    case actionTypes.SET_OFFERS_ACTIVE_PAGE_DETAILS: {
      const data = action.payload || 1;
      return { ...state, activePageDetails: data };
    }
    case actionTypes.SET_OFFERS_PAGINATION: {
      const page = action.payload || [];
      return { ...state, offersPagination: page };
    }
    case actionTypes.SET_OFFERS_SEARCH_TEXT: {
      const offersSearchText = action.payload || "";
      return { ...state, offersSearchText: offersSearchText };
    }
    case actionTypes.SET_OFFERS_TAB_ACTIVE_INDEX: {
      const data = action.payload || 0;
      return { ...state, offersTabActiveIndex: data };
    }
    case actionTypes.SET_OFFERS_PARTNERS_AND_CONTACTS: {
      const data = action.payload || {};
      return { ...state, offersPartnersAndContacts: data };
    }
    case actionTypes.SET_OFFERS_PRODUCTS_AND_ITEMS: {
      const data = action.payload || {};
      return { ...state, productsAndItems: data };
    }

    default:
      return state;
  }
};
