import { Redirect, Route, Switch } from "react-router";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import CustomersList from "./components/CustomersList";
import EditCustomerDetails from "./components/EditCustomerDetails";

// styles
import { Helmet } from "react-helmet";
import config from "../../config";
import "./CustomerManagement.scss";
import { useIntl } from "react-intl";

const CustomerManagement = () => {
  const intl = useIntl();

  const customerManagementBreadCrumbs: Array<PageLink> = [
    // {
    //   title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
    {
      title: intl.formatMessage({ id: "CONTACTS_OVERVIEW" }),
      path: "/crm/contacts",
      isSeparator: false,
      isActive: false,
    },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {config.APP_NAME}-{intl.formatMessage({ id: "CONTACTS_OVERVIEW" })}
        </title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/crm/contacts/overview">
          <PageTitle
            breadcrumbs={[
              ...customerManagementBreadCrumbs,
              // {
              //   title: intl.formatMessage({ id: "CONTACTS_OVERVIEW_TITLE" }),
              //   path: "/crm/contacts/overview",
              //   isSeparator: false,
              //   isActive: true,
              // },
            ]}
          >
            {intl.formatMessage({ id: "CONTACTS_LIST_TITLE" })}
          </PageTitle>
          <CustomersList />
        </Route>
        <Route path="/crm/contacts/editcustomer">
          <PageTitle
            breadcrumbs={[
              ...customerManagementBreadCrumbs,
              {
                title: "Edit customer",
                path: "/crm/contacts/editcustomer",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({ id: "CONTACTS_LIST_TITLE" })}
          </PageTitle>
          <EditCustomerDetails />
        </Route>

        <Redirect
          from="/crm/contacts"
          exact={true}
          to="/crm/contacts/overview"
        />
        <Redirect to="/crm/contacts/overview" />
      </Switch>
    </div>
  );
};

export default CustomerManagement;
