import React, { FC, useEffect, useRef, useState } from "react";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import _ from "lodash";

import { useLang } from "../../../../../_metronic/i18n/Metronici18n";
import { SVGICON } from "../../../../../_metronic/helpers";
import DeleteIcon from "../../../../../_metronic/assets/icons/delete.svg";
import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";
import DynamicDropdown from "../../../../sharedComponents/DynamicDropdown/DynamicDropdown";

import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";

import { RootState } from "../../../../../setup";
import { OrderExternalItemType, OrderProductType } from "../types/OrdersTypes";
import { InvoiceType } from "../types/InvoicesTypes";
import { createInvoice, invoiceUpdate } from "../redux";
import {
  addSaleItems,
  deleteOrderItem,
  updateOrderItem,
} from "../../Offers/redux/SalesApi";

interface Props {
  order: InvoiceType;
  getOrderDetailsAPI: (_id?: number) => void;
  orderId?: number | undefined;
}

const AddOrderItems: FC<Props> = ({ order, getOrderDetailsAPI, orderId }) => {
  const intl = useIntl();
  const lang = useLang();
  const history = useHistory();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const [headerNotes, setHeaderNotes] = useState(
    order?.invoice_header_notes || ""
  );
  const [title, setTitle] = useState(order?.subject || "");
  const [footerNotes, setFooterNotes] = useState(
    order?.invoice_footer_notes || ""
  );
  const [taxedProducts, setTaxedProducts] = useState({}) as any;
  const [discountedProducts, setDiscountedProducts] = useState({}) as any;

  const productsAndItems = useSelector<RootState>(
    //@ts-ignore
    ({ invoices }) => invoices.productsAndItems
  ) as OrderProductType[];

  useEffect(() => {
    setHeaderNotes(order?.invoice_header_notes || "");
    setTitle(order?.subject || "");
    setFooterNotes(order?.invoice_footer_notes || "");
  }, [order]);

  const stripHtml = (html: string) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const handleAddInternalItems = (items: any) => {
    if (!orderId) {
      const default_payload = { to_type: "business" };
      createInvoice(default_payload)
        .then(({ data }) => {
          history.replace({ state: { order: data?.invoice[0] } });
          const invoice_id = data?.invoice[0]?.id;
          const sales_id = data?.invoice[0]?.sales_id;
          return { invoice_id, sales_id };
        })
        .then(({ invoice_id, sales_id }) => {
          addSaleItems(sales_id, items)
            .then(() => {
              successToast(
                intl.formatMessage({
                  id: "INVOICE_ITEM_INTERNAL_ADD_SUCCESS_MESSAGE",
                })
              );
            })
            .catch(() => {
              errorToast(
                intl.formatMessage({
                  id: "INVOICE_ITEM_EXTERNAL_ADD_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              getOrderDetailsAPI?.(invoice_id);
            });
        })
        .catch((error) => console.error(error?.response?.data?.message));
    } else {
      addSaleItems(order?.sales_id, items)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "INVOICE_ITEM_INTERNAL_ADD_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "INVOICE_ITEM_EXTERNAL_ADD_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getOrderDetailsAPI?.(orderId);
        });
    }
  };

  const validationSchema = Yup.object({
    items: Yup.array().of(
      Yup.object({
        title: Yup.string().required(
          intl.formatMessage({ id: "INVOICE_ITEM_REQUIRED" })
        ),
        price: Yup.number()
          .required(intl.formatMessage({ id: "INVOICE_UNIT_PRICE_REQUIRED" }))
          .min(0)
          .nullable(),
        tax_percentage: Yup.number()
          .required(intl.formatMessage({ id: "INVOICE_TAX_REQUIRED" }))
          .min(0)
          .nullable(),
      })
    ),
  });

  const getInitialValues = (internalItems: any) => {
    let arr: any[] = [];
    internalItems?.forEach((item: any) => {
      arr.push({
        id: item?.id,
        title: item?.product_title,
        description: stripHtml(item?.product_description) || "",
        quantity: parseFloat(item?.quantity) || 1,
        price: item?.unit_price
          ? (parseFloat(item?.unit_price) / 100).toFixed(2)
          : null,
        tax_percentage: parseFloat(item?.tax_percentage) || 0,
        discount_percentage: parseFloat(item?.discount_percentage) || 0,
      });
    });

    return arr;
  };

  const initialValues = {
    items: [...getInitialValues(order?.sales_details?.sale_items)]?.sort(
      (a, b) => a.id - b.id
    ),
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {},
  });

  const deleteSelectedItem = (id: number) => {
    setDeleteModalLoading(true);
    deleteOrderItem(id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "INVOICE_ITEM_DELETE_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "INVOICE_ITEM_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOrderDetailsAPI();
      });
  };

  const [updateLoading, setUpdateLoading] = useState(false);
  const handleUpdateItem = async (currItem: any) => {
    let { id, ...rest } = currItem;
    if (!id) {
      formik.handleSubmit();
    } else {
      let curr = [...getInitialValues(order?.sales_details?.sale_items)]?.find(
        (item) => item.id === currItem.id
      );

      if (!_.isEqual(curr, currItem)) {
        setUpdateLoading(true);
        updateOrderItem(id, {
          ...rest,
          quantity: currItem?.quantity
            ? currItem?.quantity
            : curr?.quantity
              ? curr?.quantity
              : 1,
          unit_price: currItem?.price
            ? currItem?.price * 100
            : curr?.price
              ? curr?.price
              : null,
        })
          .then(() => {
            successToast(
              intl.formatMessage({ id: "INVOICE_ITEM_UPDATE_SUCCESS_MESSAGE" })
            );
          })
          .catch(() => {
            errorToast(
              intl.formatMessage({ id: "INVOICE_ITEM_UPDATE_FAILURE_MESSAGE" })
            );
          })
          .finally(() => {
            getOrderDetailsAPI();
            setUpdateLoading(false);
          });
      }
    }
  };

  const handleUpdateNotes = (
    type: "footer_notes" | "header_notes" | "subject"
  ) => {
    if (
      type === "footer_notes"
        ? order?.invoice_footer_notes !== footerNotes
        : type === "subject"
          ? order?.subject !== title
          : order?.invoice_header_notes !== headerNotes
    ) {
      let notes =
        type === "header_notes"
          ? headerNotes
          : type === "subject"
            ? title
            : footerNotes;
      if (notes) {
        if (!orderId) {
          const default_payload = { to_type: "business" };
          createInvoice(default_payload)
            .then(({ data }) => {
              history.replace({ state: { order: data?.invoice[0] } });
              const invoice_id = data?.invoice[0]?.id;
              return invoice_id;
            })
            .then((invoice_id) => {
              invoiceUpdate(invoice_id, type, notes)
                .then(() => {
                  successToast(
                    intl.formatMessage({
                      id:
                        type === "footer_notes"
                          ? "INVOICE_FOOTER_NOTES_UPDATE_SUCCESS_MESSAGE"
                          : type === "subject"
                            ? "INVOICE_SUBJECT_UPDATE_SUCCESS_MESSAGE"
                            : "INVOICE_HEADER_NOTES_UPDATE_SUCCESS_MESSAGE",
                    })
                  );
                })
                .catch(() => {
                  errorToast(
                    intl.formatMessage({
                      id:
                        type === "footer_notes"
                          ? "INVOICE_FOOTER_NOTES_UPDATE_SUCCESS_MESSAGE"
                          : type === "subject"
                            ? "INVOICE_SUBJECT_UPDATE_SUCCESS_MESSAGE"
                            : "INVOICE_HEADER_NOTES_UPDATE_SUCCESS_MESSAGE",
                    })
                  );
                })
                .finally(() => {
                  getOrderDetailsAPI(invoice_id);
                });
            })
            .catch((error) => console.error(error?.response?.data?.message));
        } else {
          invoiceUpdate(orderId, type, notes)
            .then(() => {
              successToast(
                intl.formatMessage({
                  id:
                    type === "footer_notes"
                      ? "INVOICE_FOOTER_NOTES_UPDATE_SUCCESS_MESSAGE"
                      : type === "subject"
                        ? "INVOICE_SUBJECT_UPDATE_SUCCESS_MESSAGE"
                        : "INVOICE_HEADER_NOTES_UPDATE_SUCCESS_MESSAGE",
                })
              );
            })
            .catch(() => {
              errorToast(
                intl.formatMessage({
                  id:
                    type === "footer_notes"
                      ? "INVOICE_FOOTER_NOTES_UPDATE_SUCCESS_MESSAGE"
                      : type === "subject"
                        ? "INVOICE_SUBJECT_UPDATE_SUCCESS_MESSAGE"
                        : "INVOICE_HEADER_NOTES_UPDATE_SUCCESS_MESSAGE",
                })
              );
            })
            .finally(() => {
              getOrderDetailsAPI();
            });
        }
      }
    }
  };

  const getProductPrice = (item: OrderExternalItemType) => {
    if (!item.price) {
      return 0;
    }

    const tax_behaviour = "inclusive";

    let initialPrice =
      Number(item.price) * Number(item.quantity ? item.quantity : 1);
    let discountAmount = item.discount_percentage
      ? initialPrice * (Number(item.discount_percentage) / 100)
      : 0;
    let discountedPrice = initialPrice - discountAmount;

    let taxAmount = item.tax_percentage
      ? discountedPrice * (Number(item.tax_percentage) / 100)
      : 0;

    return discountedPrice + (tax_behaviour === "inclusive" ? 0 : taxAmount);
  };

  const getNetProductPrice = (item: OrderExternalItemType) => {
    if (!item.price) {
      return 0;
    }

    let initialPrice =
      Number(item.price) * Number(item.quantity ? item.quantity : 1);

    let taxPercentage = Number(item.tax_percentage) / 100;
    let netPrice = initialPrice / (1 + taxPercentage);

    // let taxAmount = item.tax_percentage
    //   ? initialPrice * (Number(item.tax_percentage) / 100)
    //   : 0;
    // let netPrice = initialPrice - taxAmount;

    return Number(netPrice.toFixed(2));
  };

  const getTotalAmount = () => {
    let result = 0;
    formik?.values?.items?.forEach((item) => {
      result += getProductPrice(item);
    });
    return Number(result.toFixed(2));
  };

  const getNetTotalAmount = () => {
    let result = 0;

    formik?.values?.items?.forEach((item) => {
      result += getNetProductPrice(item);
    });
    return Number(result.toFixed(2));
  };

  const getTotalTaxedAmount = (items: OrderExternalItemType[]) => {
    let result = 0;
    items.forEach((item) => {
      if (!item.price) {
        return 0;
      }

      let initialPrice = getNetProductPrice(item);
      // let initialPrice =
      //   Number(item.price) * Number(item.quantity ? item.quantity : 1);

      let taxAmount = item.tax_percentage
        ? initialPrice * (Number(item.tax_percentage) / 100)
        : 0;
      result = result + taxAmount;
    });

    return Number(result.toFixed(2));
  };

  const getTotalDiscountAmount = (items: OrderExternalItemType[]) => {
    let result = 0;
    items.forEach((item) => {
      if (!item.price) {
        return 0;
      }

      let initialPrice =
        Number(item.price) * Number(item.quantity ? item.quantity : 1);
      let discountAmount = item.discount_percentage
        ? initialPrice * (Number(item.discount_percentage) / 100)
        : 0;
      result = result + discountAmount;
    });

    return Number(result.toFixed(2));
  };

  const calculateDiscounts = () => {
    const discounts: any = {};
    formik.values.items?.forEach((item) => {
      if (Boolean(item.discount_percentage)) {
        if (!discounts[item.discount_percentage]) {
          discounts[item.discount_percentage] = [];
        }
        discounts[item.discount_percentage].push(item);
      }
    });
    setDiscountedProducts(discounts);
  };

  const calculateTaxes = () => {
    const taxes: any = {};
    formik.values.items?.forEach((item) => {
      if (Boolean(item.tax_percentage)) {
        if (!taxes[item.tax_percentage]) {
          taxes[item.tax_percentage] = [];
        }
        taxes[item.tax_percentage].push(item);
      }
    });
    setTaxedProducts(taxes);
  };

  const [triggerUpdate, setTriggerUpdate] = useState();

  const timeoutRef: any = useRef(null);
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      if (triggerUpdate) {
        handleUpdateItem(triggerUpdate);
      }
    }, 800);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [triggerUpdate]);

  useEffect(() => {
    let descriptions = document.querySelectorAll(".item_description_container");
    descriptions?.forEach((item: any) => {
      item.style.height = "auto";
      item.style.height = `${item.scrollHeight + 8}px`;
    });
    calculateTaxes();
    calculateDiscounts();
  }, [formik.values]);

  return (
    <div className="mb-5">
      <div className="w-100 my-2 mb-4">
        <label className="form-label">
          {intl.formatMessage({
            id: "EVENTS_INVITATIONS_SELECT_TEMPLATE_FIELD_SUBJECT",
          })}
        </label>
        <input
          className="form-control form-control-solid"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          onBlur={() => {
            handleUpdateNotes("subject");
          }}
          disabled={
            order?.status &&
            order?.status !== "draft" &&
            order?.status !== "open"
          }
        />
      </div>
      <div className="w-100 my-2">
        <label className="form-label">
          {intl.formatMessage({
            id: "ORDER_EDIT_HEADER_NOTES_LABEL",
          })}
        </label>
        <textarea
          className="form-control form-control-solid resize-none"
          rows={3}
          value={headerNotes}
          onChange={(e) => {
            setHeaderNotes(e.target.value);
          }}
          onBlur={() => {
            handleUpdateNotes("header_notes");
          }}
          disabled={
            order?.status &&
            order?.status !== "draft" &&
            order?.status !== "open"
          }
        ></textarea>
      </div>

      <div className="table-responsive w-100 my-2 mb-6">
        <table
          className="table g-5 gs-0 mb-0 fw-bold text-gray-700"
          data-kt-element="items"
        >
          <thead>
            <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
              <th className="min-w-300px w-475px required">
                {intl.formatMessage({ id: "ORDERS_ITEM_LABEL" })}
              </th>
              <th className="min-w-75px w-100px">
                {intl.formatMessage({
                  id: "ORDER_PRODUCTS_QUANTITY_PLACEHOLDER",
                })}
              </th>
              <th className="min-w-125px w-150px required">
                {intl.formatMessage({
                  id: "ORDER_PRODUCTS_UNIT_PRICE_PLACEHOLDER",
                })}
              </th>
              <th className="min-w-75px w-150px required">
                {intl.formatMessage({
                  id: "ORDER_PRODUCTS_TAX_PERCENT_PLACEHOLDER",
                })}
              </th>
              <th className="min-w-125px w-150px">
                {intl.formatMessage({
                  id: "ORDER_PRODUCTS_DISCOUNT_PERCENT_PLACEHOLDER",
                })}
              </th>
              <th className="min-w-100px w-150px text-end">
                {intl.formatMessage({
                  id: "ORDER_PRODUCTS_TOTAL_COLUMN_LABEL",
                })}
              </th>
              {order?.status === "draft" && (
                <th className="min-w-75px w-75px text-end">
                  {intl.formatMessage({
                    id: "ORDER_PRODUCTS_ACTION_COLUMN_LABEL",
                  })}
                </th>
              )}
            </tr>
          </thead>

          <FormikProvider value={formik}>
            <FieldArray name="items">
              {({ push, remove }) => (
                <>
                  {formik.values.items.length > 0 &&
                    formik.values.items.map((item, index) => (
                      <tbody
                        key={`invoice-item-${index}`}
                        style={updateLoading ? { pointerEvents: "none" } : {}}
                      >
                        {/* <tr>
                          <td>
                            <pre style={{ maxWidth: "400px" }}>
                              {JSON.stringify(
                                formik.values.items[index],
                                null,
                                2
                              )}
                            </pre>
                          </td>
                        </tr> */}
                        <tr>
                          {/* Item Name */}
                          <td className="pe-7">
                            <DynamicDropdown
                              id="products_dropdown"
                              isProducts
                              handleProductSelect={(product) => {
                                formik.handleSubmit();
                                handleAddInternalItems([
                                  {
                                    id: product.id,
                                  },
                                ]);
                              }}
                              selectedItem={item.title}
                              disabled={
                                (order?.status && order?.status !== "draft") ||
                                item?.title
                              }
                              orderInteralItems={
                                order?.sales_details?.sale_items
                              }
                              productsAndItems={productsAndItems}
                            />
                            {formik.errors.items &&
                              //@ts-ignore
                              formik.errors.items[index]?.title && (
                                <span className="text-danger fs-8">
                                  {/* @ts-ignore */}
                                  {formik.errors.items[index]?.title}
                                </span>
                              )}
                          </td>

                          {/* Quantity */}
                          <td className="ps-0">
                            <Field
                              className="form-control form-control-lg fs-6 form-control-solid p-2 px-4"
                              name={`items.${index}.quantity`}
                              onKeyUp={() => {
                                if (
                                  item?.quantity >= 0 &&
                                  initialValues.items?.[index]?.quantity !==
                                    item?.quantity
                                ) {
                                  setTriggerUpdate(item);
                                }
                              }}
                              type="number"
                              min="1"
                              disabled={
                                (order?.status && order?.status !== "draft") ||
                                !item?.title
                              }
                            />
                          </td>

                          {/* Unit Price */}
                          <td className="ps-0">
                            <Field
                              className="form-control form-control-lg fs-6 form-control-solid p-2 px-4"
                              name={`items.${index}.price`}
                              onKeyUp={() => {
                                if (
                                  item?.price >= 0 &&
                                  initialValues.items?.[index]?.price !==
                                    item?.price
                                ) {
                                  setTriggerUpdate(item);
                                }
                              }}
                              type="number"
                              disabled={
                                (order?.status && order?.status !== "draft") ||
                                !item?.title
                              }
                              min="0"
                              onWheel={(e: any) => e.target.blur()}
                            />
                            {formik.errors.items &&
                              //@ts-ignore
                              formik.errors.items[index]?.price && (
                                <span className="text-danger fs-8">
                                  {/* @ts-ignore */}
                                  {formik.errors.items[index]?.price}
                                </span>
                              )}
                          </td>

                          {/* Tax % */}
                          <td className="ps-0" style={{ minWidth: "90px" }}>
                            <Field
                              className="form-control form-control-lg fs-6 form-control-solid p-2 px-4"
                              name={`items.${index}.tax_percentage`}
                              onKeyUp={() => {
                                if (
                                  item?.tax_percentage >= 0 &&
                                  initialValues.items?.[index]
                                    ?.tax_percentage !== item?.tax_percentage
                                ) {
                                  setTriggerUpdate(item);
                                }
                              }}
                              type="number"
                              disabled={
                                (order?.status &&
                                  order?.status !== "draft" &&
                                  order?.status !== "open") ||
                                !item?.title
                              }
                              min="0"
                              onWheel={(e: any) => e.target.blur()}
                            />
                            {formik.errors.items &&
                              //@ts-ignore
                              formik.errors.items[index]?.tax_percentage && (
                                <span className="text-danger fs-8">
                                  {/* @ts-ignore */}
                                  {formik.errors.items[index]?.tax_percentage}
                                </span>
                              )}
                          </td>

                          {/* Discount % */}
                          <td className="ps-0 pe-0">
                            <Field
                              className="form-control form-control-lg fs-6 form-control-solid p-2 px-4"
                              name={`items.${index}.discount_percentage`}
                              onKeyUp={() => {
                                if (
                                  item?.discount_percentage >= 0 &&
                                  initialValues.items?.[index]
                                    ?.discount_percentage !==
                                    item?.discount_percentage
                                ) {
                                  setTriggerUpdate(item);
                                }
                              }}
                              type="number"
                              disabled={
                                (order?.status &&
                                  order?.status !== "draft" &&
                                  order?.status !== "open") ||
                                !item?.title
                              }
                              min="0"
                              onWheel={(e: any) => e.target.blur()}
                            />
                          </td>

                          {/* Total */}
                          <td className="ps-0 pb-0 pt-2">
                            <div className="d-flex justify-content-end text-bold fs-6 pt-3">
                              <div className="d-flex flex-column align-items-center gap-2">
                                <div>
                                  {getProductPrice(item).toLocaleString(lang, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}{" "}
                                  €
                                </div>
                              </div>
                            </div>
                          </td>

                          {/* Item Delete Button */}
                          {order?.status === "draft" && (
                            <td className="text-end">
                              <button
                                type="button"
                                className="btn btn-icon btn-light btn-active-light-danger btn-sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (item?.id) {
                                    showDeleteConfirmModal(
                                      intl.formatMessage({
                                        id: "INVOICE_ITEM_DELETE_CONFIRM_MESSAGE",
                                      }),
                                      () => {
                                        deleteSelectedItem(item?.id);
                                      },
                                      intl.formatMessage({
                                        id: "COMMON_YES_DELETE",
                                      })
                                    );
                                  } else {
                                    remove(index);
                                  }
                                  // }
                                }}
                                disabled={
                                  order?.status && order?.status !== "draft"
                                }
                              >
                                <SVGICON
                                  src={DeleteIcon}
                                  className="svg-icon-3"
                                />
                              </button>
                            </td>
                          )}
                        </tr>
                        {/* Product Deccription */}
                        <tr
                          className={` ${
                            formik.values.items.length - 1 !== index &&
                            "border-bottom border-bottom-dashed"
                          }`}
                        >
                          <td colSpan={5} className="pt-0">
                            <Field
                              className="form-control form-control-lg fs-6 resize-none form-control-solid mt-4 item_description_container"
                              name={`items.${index}.description`}
                              placeholder={intl.formatMessage({
                                id: "ORDER_PRODUCTS_DESCRIPTION_PLACEHOLDER",
                              })}
                              as="textarea"
                              disabled={
                                order?.sales_details?.is_editable === 0 ||
                                order?.sales_details?.sale_items?.some(
                                  (intItem) => intItem?.id === item?.id
                                ) ||
                                (order?.status && order?.status !== "draft") ||
                                item?.title
                              }
                              onBlur={(e: any) => {
                                item?.description &&
                                  e.target.value !== item?.description &&
                                  setTriggerUpdate(item);
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  {/* Add Sale Item Button */}
                  <button
                    className="btn btn-link py-1"
                    onClick={() => {
                      if (order?.sales_details?.is_editable !== 0) {
                        push({
                          title: "",
                          description: "",
                          quantity: "",
                          price: "",
                          tax_percentage: "",
                          discount_percentage: "",
                        });
                      }
                    }}
                    disabled={order?.status && order?.status !== "draft"}
                  >
                    {intl.formatMessage({ id: "ORDER_ADD_ITEM_LABEL" })}
                  </button>
                </>
              )}
            </FieldArray>
          </FormikProvider>

          <tfoot>
            {/* Subtotal (Net Amount) */}
            <tr className="border-top border-top-dashed align-top fs-6 fw-bold text-gray-700">
              <th className="text-primary"></th>
              <th
                colSpan={3}
                className="border-bottom border-bottom-dashed ps-0"
              >
                <div className="d-flex flex-column align-items-start">
                  <div className="fs-5">
                    {intl.formatMessage({
                      id: "ORDER_PRODUCTS_SUBTOTAL_COLUMN_LABEL",
                    })}
                  </div>
                </div>
              </th>
              <th
                colSpan={2}
                className="border-bottom border-bottom-dashed text-end"
              >
                <span>
                  {getNetTotalAmount()?.toLocaleString(lang, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  €
                </span>
              </th>
            </tr>
            {/* Taxes */}
            {Object.keys(taxedProducts)?.map((taxItem, index) => (
              <tr
                key={`invoices-tax-${index}`}
                className="border-top border-top-dashed align-top fs-6 fw-bold text-gray-700"
              >
                <th className="text-primary"></th>
                <th
                  colSpan={3}
                  className="border-bottom border-bottom-dashed ps-0"
                >
                  <div className="d-flex flex-column align-items-start">
                    <div className="fs-5">Tax ({taxItem}%)</div>
                  </div>
                </th>
                <th
                  colSpan={2}
                  className="border-bottom border-bottom-dashed text-end"
                >
                  <span>
                    +
                    {getTotalTaxedAmount(
                      taxedProducts[taxItem]
                    )?.toLocaleString(lang, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €
                  </span>
                </th>
              </tr>
            ))}
            {/* Discounts */}
            {Object.keys(discountedProducts)?.map((discountItem, index) => (
              <tr
                key={`invoices-discount-${index}`}
                className="border-top border-top-dashed align-top fs-6 fw-bold text-gray-700"
              >
                <th className="text-primary"></th>
                <th
                  colSpan={3}
                  className="border-bottom border-bottom-dashed ps-0"
                >
                  <div className="d-flex flex-column align-items-start">
                    <div className="fs-5">Discount ({discountItem}%)</div>
                  </div>
                </th>
                <th
                  colSpan={2}
                  className="border-bottom border-bottom-dashed text-end"
                >
                  <span>
                    -
                    {getTotalDiscountAmount(
                      discountedProducts[discountItem]
                    )?.toLocaleString(lang, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €
                  </span>
                </th>
              </tr>
            ))}
            {/* Total (Gross Amount) */}
            <tr className="align-top fw-bold text-gray-700">
              <th></th>
              <th colSpan={2} className="fs-4 ps-0">
                {intl.formatMessage({
                  id: "ORDER_PRODUCTS_TOTAL_COLUMN_LABEL",
                })}
              </th>
              <th colSpan={3} className="text-end fs-4 text-nowrap">
                <span>
                  {getTotalAmount()?.toLocaleString(lang, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  €
                </span>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="w-100 my-2 mb-6">
        <label className="form-label">
          {intl.formatMessage({
            id: "ORDER_EDIT_FOOTER_NOTES_LABEL",
          })}
        </label>
        <textarea
          className="form-control form-control-solid resize-none"
          rows={3}
          value={footerNotes}
          onChange={(e) => {
            setFooterNotes(e.target.value);
          }}
          onBlur={() => {
            handleUpdateNotes("footer_notes");
          }}
          disabled={
            order?.status &&
            order?.status !== "draft" &&
            order?.status !== "open"
          }
        ></textarea>
      </div>
    </div>
  );
};

export default AddOrderItems;
