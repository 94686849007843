import React, { FC, useState } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import clsx from "clsx";
import * as Yup from "yup";

import { createNote } from "../redux";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

interface Props {
  users_id?: number | null;
  deals_id?: number | null;
  getNotesListAPI: () => void;
}

const AddNotesForm: FC<Props> = ({ users_id, deals_id, getNotesListAPI }) => {
  const intl = useIntl();

  // state
  const [loading, setLoading] = useState(false);
  const initialValues = {
    users_id: users_id,
    deals_id: deals_id,
    note: "",
  };

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;

  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "contacts"
  );
  const crudPermissionForDeals: any = enablePermissionForCrud(
    userRoleDetails,
    "deals"
  );

  const EditCustomerProfileSchema = Yup.object()
    .shape({
      users_id: Yup.number().nullable(),
      deals_id: Yup.number().nullable(),
      note: Yup.string().required(
        intl.formatMessage({
          id: "CUSTOMER_MANAGEMENT_PROFILE_NOTE_VALIDATION_MESSAGE",
        })
      ),
    })
    .test(
      "at-least-one-id",
      intl.formatMessage({
        id: "CUSTOMER_MANAGEMENT_PROFILE_NOTES_ID_VALIDATION_MESSAGE",
      }),
      function (value) {
        // Ensure that at least one of users_id or deals_id is not null
        return value.users_id !== null || value.deals_id !== null;
      }
    );

  const handleCancel = () => {
    formik.setTouched({}, false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: EditCustomerProfileSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      createNote(values.note, values.users_id, values.deals_id)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "CUSTOMER_MANAGEMENT_PROFILE_ADD_NOTE_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "CUSTOMER_MANAGEMENT_PROFILE_ADD_NOTE_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          setLoading(false);
          getNotesListAPI();
          handleCancel();
        });
    },
  });

  return (
    <div className={clsx("mb-8")}>
      {/* comment */}
      <div className="mb-8">
        {/* <label className="form-label required">
            {intl.formatMessage({
              id: "CUSTOMER_MANAGEMENT_PROFILE_ADD_NEW_NOTE",
            })}
          </label> */}
        <textarea
          className="form-control form-control-lg form-control-solid customer-note-textarea mb-3 mb-lg-0"
          rows={5}
          placeholder={intl.formatMessage({
            id: "CUSTOMER_MANAGEMENT_PROFILE_ADD_NEW_NOTE",
          })}
          {...formik.getFieldProps("note")}
        />
        {formik.touched.note && formik.errors.note && (
          <div className="text-danger">
            <span role="alert">{formik.errors.note}</span>
          </div>
        )}
      </div>

      {/* submit button */}
      <div className="d-flex gap-2 mt-4">
        {((crudPermission?.edit && users_id) ||
          (crudPermissionForDeals?.edit && deals_id)) && (
          <button
            type="button"
            className="btn btn-primary min-w-100px"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {!loading &&
              intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_SAVE_BTN",
              })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_PROFILE_LOADING_MESSAGE",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        )}
        <button
          className="btn btn-light min-w-100px text-muted"
          onClick={handleCancel}
        >
          {intl.formatMessage({
            id: "CUSTOMER_MANAGEMENT_PROFILE_CANCEL_NEW_NOTE_BTN",
          })}
        </button>
      </div>
    </div>
  );
};

export default AddNotesForm;
