import React, { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import clsx from "clsx";

import { addNotes, updateNotes } from "../redux/SalesNotesAPI";
import { Button, Modal } from "react-bootstrap";
import { successToast } from "../../../../sharedComponents/toasts/Toasts";

interface ModalProps {
  show: boolean;
  type?: string;
  notes?: any;
}
interface Props {
  modalProps: ModalProps;
  closeModal: () => void;
  getNotesListAPI: () => void;
}
const AddEditSalesNotesModal: FC<Props> = ({
  modalProps,
  closeModal,
  getNotesListAPI,
}) => {
  const { show, type, notes } = modalProps;

  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const [currPage, setCurrPage] = useState<any | undefined>(notes);

  const AddNewNotesSchema = Yup.object().shape({
    name: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(
        intl.formatMessage({
          id: "MASTERDATA_SALES_NOTES_NEW_LABEL_VALIDATION",
        })
      ),
  });

  const initialValues = {
    name: type === "edit" ? currPage?.name : "",
    subject: type === "edit" ? currPage?.subject : "",
    header_notes: type === "edit" ? currPage?.header_notes : "",
    footer_notes: type === "edit" ? currPage?.footer_notes : "",
  };

  useEffect(() => {
    setCurrPage(notes);
  }, [notes]);

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewNotesSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus }) => {
      if (type === "new") {
        if (values.name) {
          setLoading(true);
          addNotes(values.name)
            .then(() => {
              closeModal();
              successToast(
                intl.formatMessage({
                  id: "MASTERDATA_SALES_NOTES_CREATE_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "MASTERDATA_SALES_NOTES_CREATE_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              getNotesListAPI();
              setLoading(false);
            });
        }
      } else {
        if (values.name && notes) {
          setLoading(true);

          updateNotes(notes.id, {
            name: values.name,
            subject: values.subject,
            header_notes: values.header_notes,
            footer_notes: values.footer_notes,
          })
            .then(() => {
              closeModal();
              successToast(
                intl.formatMessage({
                  id: "MASTERDATA_SALES_NOTES_UPDATE_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "MASTERDATA_SALES_NOTES_UPDATE_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              getNotesListAPI();
              setLoading(false);
            });
        }
      }
    },
  });

  return (
    <Modal
      show={show}
      centered
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "MASTERDATA_SALES_NOTES_EDIT_TITLE" })
            : intl.formatMessage({ id: "MASTERDATA_SALES_NOTES_NEW_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_group_form"
      >
        <Modal.Body className="py-2 overflow-inherit">
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_GRPUPS_GROUP_NAME_FIELD_NAME",
                  })}
                </span>
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: "MASTERDATA_SALES_NOTES_NEW_LABEL_PLACEHOLDER",
                })}
                {...formik.getFieldProps("name")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                name="name"
                autoComplete="off"
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-danger">
                  {/* @ts-ignore */}
                  <span role="alert">{formik?.errors?.name}</span>
                </div>
              )}
            </div>
            {type === "edit" && (
              <>
                <div className="mb-8">
                  <label className="fw-bold fs-6 mb-2">
                    {intl.formatMessage({
                      id: "MASTERDATA_SALES_NOTES_SUBJECT_LABEL",
                    })}
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    {...formik.getFieldProps("subject")}
                    className={clsx(
                      "form-control form-control-lg form-control-solid"
                    )}
                  />
                </div>
                <div className="mb-8">
                  <label className="fw-bold fs-6 mb-2">
                    {intl.formatMessage({
                      id: "MASTERDATA_SALES_NOTES_HEADER_LABEL",
                    })}
                  </label>
                  <textarea
                    className="form-control form-control-solid"
                    rows={4}
                    {...formik.getFieldProps("header_notes")}
                  ></textarea>
                </div>
                <div className="mb-8">
                  <label className="fw-bold fs-6 mb-2">
                    {intl.formatMessage({
                      id: "MASTERDATA_SALES_NOTES_FOOTER_LABEL",
                    })}
                  </label>
                  <textarea
                    className="form-control form-control-solid"
                    rows={4}
                    {...formik.getFieldProps("footer_notes")}
                  ></textarea>
                </div>
              </>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_NOTES_LOADING_MESSAGE",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddEditSalesNotesModal;
