import clsx from "clsx";
import { useFormik } from "formik";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
// @ts-ignore
import DragSortableList from "react-drag-sortable";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import "./styles.scss";

// images
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import Constants from "../../../config/Constants";
// import Pagination from '../../../sharedComponents/pagination/Pagination'
import SelectFileModal from "../../../sharedComponents/selectFileModal/selectFileModal";
import { FileType } from "../../filemanager/types/getFilemanagerDataResponseType";
import { actions } from "../redux";
import {
  createNewGroup,
  deleteGroup,
  getGroupsList,
  massDeleteGroups,
  sortGroups,
  updateGroup,
} from "../redux/GroupsAPI";
import { GroupType } from "../types/GetGroupsListResponseType";
import { sortGroupsParams } from "../types/request";
import { group } from "console";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
interface ModelComponentProps {
  ModalProps: GroupModalProps;
  closeModal: () => void;
  getGroupsListAPI: () => void;
}

interface GroupModalProps {
  show: boolean;
  type?: string;
  group?: GroupType;
}

interface SortedObject {
  group: GroupType;
}

const GroupsModal: FC<ModelComponentProps> = ({
  ModalProps,
  closeModal,
  getGroupsListAPI,
}) => {
  const { show, type, group } = ModalProps;
  // state
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [seoPictureFile, setSeoPictureFile] = useState<FileType | null>();
  const [showFileSelectModal, setShowFileSelectModal] =
    useState<boolean>(false);
  //@ts-ignore
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "groups"
  );
  const [currPage, setCurrPage] = useState<GroupType | undefined>(group);
  const AddNewGroupSchema = Yup.object().shape({
    groupName: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(
        intl.formatMessage({ id: "MASTERDATA_GROUPS_GROUP_NAME_REQUIRED" })
      ),
  });
  const initialValues = {
    groupName: type === "edit" ? group?.name : "",
    // seo_title: type === "edit" ? group?.seo_title : "",
    // seo_description: type === "edit" ? group?.seo_description : "",
    // is_visible: type === "edit" ? group?.is_visibility : "",
  };

  const closeMainFileModal = () => {
    setShowFileSelectModal(false);
  };

  const onFileSelect = (file: any) => {
    closeMainFileModal();
    setSeoPictureFile(file);
  };

  useEffect(() => {
    if (type === "new") {
      setSeoPictureFile(null);
    }
  }, [type]);

  useEffect(() => {
    setCurrPage(group);
  }, [group]);
  const formik = useFormik({
    initialValues,
    validationSchema: AddNewGroupSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (type === "new") {
        if (values.groupName) {
          setLoading(true);
          const updatedPictureId = seoPictureFile?.id
            ? seoPictureFile?.id
            : currPage?.seo_picture_id
              ? currPage?.seo_picture_id
              : null;
          createNewGroup(
            values.groupName //@ts-ignore
            // values.seo_title,
            // values.seo_description,
            // updatedPictureId,
            // values.is_visible ? 1 : 0
          )
            .then(() => {
              closeModal();
              successToast(
                intl.formatMessage({
                  id: "MASTERDATA_GROUPS_CREATE_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "MASTERDATA_GROUPS_CREATE_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get groups list API
              getGroupsListAPI();
              setSeoPictureFile(null);
              setLoading(false);
            });
        }
      } else {
        if (values.groupName && group) {
          setLoading(true);
          const updatedPictureId = seoPictureFile?.id
            ? seoPictureFile?.id
            : currPage?.seo_picture_id
              ? currPage?.seo_picture_id
              : null;
          updateGroup(
            group.id,
            values.groupName //@ts-ignore
            // values.seo_title,
            // values.seo_description,
            // updatedPictureId,
            // values.is_visible ? 1 : 0
          )
            .then(() => {
              closeModal();
              successToast(
                intl.formatMessage({
                  id: "MASTERDATA_GROUPS_EDIT_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "MASTERDATA_GROUPS_EDIT_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get groups list API
              getGroupsListAPI();
              setSeoPictureFile(null);
              setLoading(false);
            });
        }
      }
    },
  });

  return (
    <Modal
      show={show}
      centered
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "MASTERDATA_GROUPS_EDIT_TITLE" })
            : intl.formatMessage({ id: "MASTERDATA_GROUPS_EDIT_NEW_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      {showFileSelectModal && (
        <SelectFileModal
          show={showFileSelectModal}
          closeModal={closeMainFileModal}
          onSelectFile={onFileSelect}
          allowedMimetypes={Constants.mimeTypes.image}
          isSelectionModal={true}
        />
      )}
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_group_form"
      >
        <Modal.Body
          className={`overflow-inherit ${
            !crudPermission?.edit && type === "edit" && "pe-none"
          }`}
        >
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_GRPUPS_GROUP_NAME_FIELD_NAME",
                  })}
                </span>
              </label>
              <input
                placeholder={intl.formatMessage({
                  id: "MASTERDATA_GROUPS_GROUP_NAME_PLACEHOLDER",
                })}
                {...formik.getFieldProps("groupName")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                name="groupName"
                autoComplete="off"
              />
              {formik.touched.groupName && formik.errors.groupName && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.groupName}</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              setSeoPictureFile(null);
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          {((crudPermission?.edit && type === "edit") ||
            (crudPermission?.create && type === "new")) && (
            <Button
              variant="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "MASTERDATA_GROUPS_LOADING_MESSAGE",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const Groups: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const groupsList = useSelector<RootState>(
    ({ masterdata }) => masterdata.groupsList
  ) as GroupType[];
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  // state
  const [displayGroupsList, setDisplayGroupsList] = useState(groupsList);
  const [showGroupsModal, setShowGroupsModal] = useState<GroupModalProps>({
    show: false,
  });
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "groups"
  );
  const [checkedGroups, setCheckedGroups] = useState<GroupType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllGroups, setCheckAllGroups] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );

  const getGroupsListAPI = () => {
    getGroupsList()
      .then(({ data: { data } }) => {
        dispatch(actions.setGroupsList(data));
      })
      .catch((e) => {
        // console.log(`ERROR: getGroupsListAPI`, e)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateDisplayGroupsList = () => {
    const updatedGroupsList = groupsList.filter((group) => {
      if (
        (group.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayGroupsList(updatedGroupsList);
  };

  useEffect(() => {
    setIsLoading(true);
    // get groups API call
    getGroupsListAPI();
  }, []);

  useEffect(() => {
    updateDisplayGroupsList();

    return () => {
      setDisplayGroupsList(groupsList);
    };
  }, [groupsList, searchText]);

  useEffect(() => {
    if (checkAllGroups && checkedGroups.length !== groupsList.length) {
      setCheckAllGroups(false);
    }
    if (
      checkedGroups.length > 0 &&
      checkedGroups.length === groupsList.length
    ) {
      setCheckAllGroups(true);
    }
  }, [checkedGroups]);

  const closeGroupsModal = () => {
    setShowGroupsModal({
      show: false,
      type: "",
      group: undefined,
    });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };
  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const deleteExistingGroup = (id: number) => {
    setDeleteModalLoading(true);
    deleteGroup(id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "MASTERDATA_GROUPS_DELETE_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "MASTERDATA_GROUPS_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        closeGroupsModal();
        setCheckAllGroups(false);
        setCheckedGroups([]);
        // recall  get groups list API
        getGroupsListAPI();
      });
  };

  const onGroupsSortChange = (sortedList: Array<SortedObject>) => {
    const newSortedGroupsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedGroupsArr.push({
        id: sortedListItem.group.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing groups API Call
    sortGroups(newSortedGroupsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getGroupsListAPI();
      });
  };

  //
  const onGroupCheckChange = (group: GroupType) => {
    let checkedGroupsCopy = _.clone(checkedGroups);
    // check if already exists in the checked list
    const index = checkedGroupsCopy.findIndex(
      (checkedGroup) => checkedGroup.id === group.id
    );
    // if exists remove
    if (index > -1) {
      checkedGroupsCopy.splice(index, 1);
    }
    // if doesnt exist push to checked groups
    else {
      checkedGroupsCopy.push(group);
    }
    setCheckedGroups(checkedGroupsCopy);
  };

  const isGroupChecked = (group: GroupType) => {
    const index = checkedGroups.findIndex(
      (checkedGroup) => checkedGroup.id === group.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteMultipleGroups = () => {
    setDeleteModalLoading(true);
    const ids = checkedGroups.map((checkedGroup) => {
      return checkedGroup.id;
    });
    massDeleteGroups(ids)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_GROUPS_MASS_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_GROUPS_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getGroupsListAPI();
        setCheckedGroups([]);
      });
  };

  const onAllGroupCheckChange = () => {
    const updatedCheckAllGroups = !checkAllGroups;
    setCheckAllGroups(updatedCheckAllGroups);
    if (updatedCheckAllGroups) {
      setCheckedGroups(groupsList);
    } else {
      setCheckedGroups([]);
    }
  };

  const GroupItem = (group: GroupType, index: number) => {
    const {
      showDeleteConfirmModal,
      hideDeleteConfirmModal,
      setDeleteModalLoading,
    } = useGlobalModalContext();
    return (
      <div
        className="card mt-5 p-4 d-flex flex-row align-items-center justify-content-between"
        key={index}
      >
        <div className="d-flex flex-row align-items-center">
          {crudPermission?.delete && (
            <div className="form-check form-check-sm form-check-custom form-check-solid ">
              <input
                onChange={(e) => {
                  onGroupCheckChange(group);
                }}
                className="form-check-input widget-9-check"
                type="checkbox"
                checked={isGroupChecked(group)}
              />
            </div>
          )}
          {crudPermission?.edit && (
            <div className="mx-5">
              <SVGICON
                src={DragIcon}
                className="svg-icon-2 svg-hover-primary"
              />
            </div>
          )}

          <div
            className={`fw-bold  ${!crudPermission?.edit && "no-drag"}`}
            role="button"
            onClick={() => {
              setShowGroupsModal({
                show: true,
                type: "edit",
                group: group,
              });
            }}
          >
            {group.name}
          </div>
        </div>

        <div>
          {crudPermission?.edit && (
            <button
              className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
              onClick={() => {
                setShowGroupsModal({
                  show: true,
                  type: "edit",
                  group: group,
                });
              }}
            >
              <SVGICON src={EditIcon} className="svg-icon-3" />
            </button>
          )}
          {crudPermission?.delete && (
            <button
              className="btn btn-icon btn-light btn-active-light-primary btn-sm"
              onClick={() => {
                showDeleteConfirmModal(
                  intl.formatMessage({
                    id: "MASTERDATA_GROUPS_DELETE_CONFIRM_MESSAGE",
                  }),
                  () => {
                    deleteExistingGroup(group.id);
                  }
                );
              }}
            >
              <SVGICON src={DeleteIcon} className="svg-icon-3" />
            </button>
          )}
        </div>
      </div>
    );
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedGroups =
    displayGroupsList && displayGroupsList.length > 10
      ? displayGroupsList.slice(startIndex, startIndex + itemsPerPage)
      : displayGroupsList;

  useEffect(() => {
    if (paginatedGroups.length === 0 && displayGroupsList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedGroups]);

  useEffect(() => {
    ScrollToTop();
  }, [activePage]);

  // draggable groups list
  const list = paginatedGroups
    ? paginatedGroups.map((group, i) => {
        return {
          content: (
            <div
              className={searchText || !crudPermission?.edit ? "no-drag" : ""}
            >
              {GroupItem(group, i)}
            </div>
          ),
          group: group,
        };
      })
    : [];

  return (
    <div>
      {/* @ts-ignore */}
      {showGroupsModal.show && (
        <GroupsModal
          ModalProps={showGroupsModal}
          closeModal={closeGroupsModal}
          getGroupsListAPI={getGroupsListAPI}
        />
      )}

      {/* search groups */}
      <div className="card p-4 d-flex flex-row justify-content-between mt-7">
        <div className="d-flex flex-row">
          {crudPermission?.delete && (
            <div className="form-check form-check-sm form-check-custom form-check-solid ">
              <input
                onChange={onAllGroupCheckChange}
                className="form-check-input widget-9-check"
                type="checkbox"
                checked={checkAllGroups}
              />
            </div>
          )}
          <div className="d-flex align-items-center">
            <SVGICON
              src={SearchIcon}
              className="svg-icon svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              className="form-control form-control-solid w-250px ps-15 mx-4"
              placeholder={intl.formatMessage({
                id: "MASTERDATA_GROUPS_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>

        {/* add new group button */}
        <div className="d-flex align-items-center">
          {checkedGroups.length > 0 ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedGroups.length} {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn btn-danger d-flex align-items-center"
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "MASTERDATA_GROUPS_SELECTED_DELETE_CONFIRM_MESSAGE",
                    }),
                    () => {
                      deleteMultipleGroups();
                    }
                  );
                }}
              >
                <i className={"bi bi-trash3-fill fs-6"}></i>
                {intl.formatMessage({ id: "DELETE_BUTTON" })}
              </button>
            </>
          ) : (
            <>
              {crudPermission?.create && (
                <button
                  className="btn btn-primary "
                  onClick={() => {
                    setShowGroupsModal({
                      show: true,
                      type: "new",
                    });
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  {intl.formatMessage({
                    id: "MASTERDATA_GROUPS_CREATE_NEW_BUTTON",
                  })}
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <DragSortableList
        items={list}
        // @ts-ignore
        onSort={(sortedList, dropEvent) => {
          onGroupsSortChange(sortedList);
        }}
        type="vertical"
      />
      {/* no data */}
      {!isLoading && displayGroupsList.length === 0 && (
        <table className="d-flex justify-content-center align-items-center mt-2">
          <tbody>
            <NoItemsFound languageKey="NO_ITEMS_FOUND" />
          </tbody>
        </table>
      )}
      {displayGroupsList.length > 0 && (
        <div className="card mt-5 pe-3">
          <Pagination
            totalPages={Math.ceil(displayGroupsList.length / itemsPerPage)}
            activePage={
              Math.ceil(displayGroupsList.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Groups;
