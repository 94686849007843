//@ts-ignore
import RobotoBold from "../../_metronic/assets/fonts/roboto-bold.ttf";
//@ts-ignore
import RobotoRegular from "../../_metronic/assets/fonts/roboto-regular.ttf";
//@ts-ignore
import HahmletBold from "../../_metronic/assets/fonts/Hahmlet-Bold.ttf";
//@ts-ignore
import HahmletRegular from "../../_metronic/assets/fonts/Hahmlet-Regular.ttf";
//@ts-ignore
import SoraBold from "../../_metronic/assets/fonts/Sora-Bold.ttf";
//@ts-ignore
import SoraRegular from "../../_metronic/assets/fonts/Sora-Regular.ttf";
//@ts-ignore
import AndaraProBold from "../../_metronic/assets/fonts/AndadaPro-Bold.ttf";
//@ts-ignore
import AndaraProRegular from "../../_metronic/assets/fonts/AndadaPro-Regular.ttf";
//@ts-ignore
import EpilogueBold from "../../_metronic/assets/fonts/Epilogue-Bold.ttf";
//@ts-ignore
import EpilogueRegular from "../../_metronic/assets/fonts/Epilogue-Regular.ttf";
//@ts-ignore
import InterBold from "../../_metronic/assets/fonts/Inter-Bold.ttf";
//@ts-ignore
import InterRegular from "../../_metronic/assets/fonts/Inter-Regular.ttf";
//@ts-ignore
import EncodeSansBold from "../../_metronic/assets/fonts/EncodeSans-Bold.ttf";
//@ts-ignore
import EncodeSansRegular from "../../_metronic/assets/fonts/EncodeSans-Regular.ttf";
//@ts-ignore
import ManropeBold from "../../_metronic/assets/fonts/Manrope-Bold.ttf";
//@ts-ignore
import ManropeRegular from "../../_metronic/assets/fonts/Manrope-Regular.ttf";
//@ts-ignore
import LoraBold from "../../_metronic/assets/fonts/Lora-Bold.ttf";
//@ts-ignore
import LoraRegular from "../../_metronic/assets/fonts/Lora-Regular.ttf";
//@ts-ignore
import BioRhymeBold from "../../_metronic/assets/fonts/BioRhyme-Bold.ttf";
//@ts-ignore
import BioRhymeRegular from "../../_metronic/assets/fonts/BioRhyme-Regular.ttf";
//@ts-ignore
import PlayfairDisplayBold from "../../_metronic/assets/fonts/PlayfairDisplay-Bold.ttf";
//@ts-ignore
import PlayfairDisplayRegular from "../../_metronic/assets/fonts/PlayfairDisplay-Regular.ttf";
//@ts-ignore
import ArchivoBold from "../../_metronic/assets/fonts/Archivo-Bold.ttf";
//@ts-ignore
import ArchivoRegular from "../../_metronic/assets/fonts/Archivo-Regular.ttf";
//@ts-ignore
import CormorantBold from "../../_metronic/assets/fonts/Cormorant-Bold.ttf";
//@ts-ignore
import CormorantRegular from "../../_metronic/assets/fonts/Cormorant-Regular.ttf";
//@ts-ignore
import SpectralBold from "../../_metronic/assets/fonts/Spectral-Bold.ttf";
//@ts-ignore
import SpectralRegular from "../../_metronic/assets/fonts/Spectral-Regular.ttf";
//@ts-ignore
import RalewayBold from "../../_metronic/assets/fonts/Raleway-Bold.ttf";
//@ts-ignore
import RalewayRegular from "../../_metronic/assets/fonts/Raleway-Regular.ttf";
//@ts-ignore
import WorkSansBold from "../../_metronic/assets/fonts/WorkSans-Bold.ttf";
//@ts-ignore
import WorkSansRegular from "../../_metronic/assets/fonts/WorkSans-Regular.ttf";
//@ts-ignore
import LatoBold from "../../_metronic/assets/fonts/Lato-Bold.ttf";
//@ts-ignore
import LatoRegular from "../../_metronic/assets/fonts/Lato-Regular.ttf";
//@ts-ignore
import AntonRegular from "../../_metronic/assets/fonts/Anton-Regular.ttf";
//@ts-ignore
import OldStandardTTBold from "../../_metronic/assets/fonts/OldStandardTT-Bold.ttf";
//@ts-ignore
import OldStandardTTRegular from "../../_metronic/assets/fonts/OldStandardTT-Regular.ttf";
//@ts-ignore
import OswaldBold from "../../_metronic/assets/fonts/Oswald-Bold.ttf";
//@ts-ignore
import OswaldRegular from "../../_metronic/assets/fonts/Oswald-Regular.ttf";
//@ts-ignore
import MontserratBold from "../../_metronic/assets/fonts/Montserrat-Bold.ttf";
//@ts-ignore
import MontserratRegular from "../../_metronic/assets/fonts/Montserrat-Regular.ttf";
//@ts-ignore
import PoppinsBold from "../../_metronic/assets/fonts/Poppins-Bold.ttf";
//@ts-ignore
import PoppinsRegular from "../../_metronic/assets/fonts/Poppins-Regular.ttf";
//@ts-ignore
import RubikBold from "../../_metronic/assets/fonts/Rubik-Bold.ttf";
//@ts-ignore
import RubikRegular from "../../_metronic/assets/fonts/Rubik-Regular.ttf";
//@ts-ignore
import NunitoBold from "../../_metronic/assets/fonts/Nunito-Bold.ttf";
//@ts-ignore
import NunitoRegular from "../../_metronic/assets/fonts/Nunito-Regular.ttf";
//@ts-ignore
import SourceSans3Bold from "../../_metronic/assets/fonts/SourceSans3-Bold.ttf";
//@ts-ignore
import SourceSans3Regular from "../../_metronic/assets/fonts/SourceSans3-Regular.ttf";
//@ts-ignore
import OxygenBold from "../../_metronic/assets/fonts/Oxygen-Bold.ttf";
//@ts-ignore
import OxygenRegular from "../../_metronic/assets/fonts/Oxygen-Regular.ttf";
//@ts-ignore
import OpenSansBold from "../../_metronic/assets/fonts/OpenSans-Bold.ttf";
//@ts-ignore
import OpenSansRegular from "../../_metronic/assets/fonts/OpenSans-Regular.ttf";

import { Font } from "@react-pdf/renderer";

interface FontConfig {
  family: string;
  fonts: Array<{
    src: string;
    fontWeight?: "normal" | "bold" | undefined;
    fontStyle?: "normal" | "italic" | undefined;
  }>;
}

const fontConfig: FontConfig[] = [
  {
    family: "Roboto",
    fonts: [{ src: RobotoBold, fontWeight: "bold" }, { src: RobotoRegular }],
  },
  {
    family: "Hahmlet",
    fonts: [{ src: HahmletBold, fontWeight: "bold" }, { src: HahmletRegular }],
  },
  {
    family: "Sora",
    fonts: [{ src: SoraBold, fontWeight: "bold" }, { src: SoraRegular }],
  },
  {
    family: "Andada Pro",
    fonts: [
      { src: AndaraProBold, fontWeight: "bold" },
      { src: AndaraProRegular },
    ],
  },
  {
    family: "Epilogue",
    fonts: [
      { src: EpilogueBold, fontWeight: "bold" },
      { src: EpilogueRegular },
    ],
  },
  {
    family: "Inter",
    fonts: [{ src: InterBold, fontWeight: "bold" }, { src: InterRegular }],
  },
  {
    family: "Encode Sans",
    fonts: [
      { src: EncodeSansBold, fontWeight: "bold" },
      { src: EncodeSansRegular },
    ],
  },
  {
    family: "Manrope",
    fonts: [{ src: ManropeBold, fontWeight: "bold" }, { src: ManropeRegular }],
  },
  {
    family: "Lora",
    fonts: [{ src: LoraBold, fontWeight: "bold" }, { src: LoraRegular }],
  },
  {
    family: "BioRhyme",
    fonts: [
      { src: BioRhymeBold, fontWeight: "bold" },
      { src: BioRhymeRegular },
    ],
  },
  {
    family: "Playfair Display",
    fonts: [
      { src: PlayfairDisplayBold, fontWeight: "bold" },
      { src: PlayfairDisplayRegular },
    ],
  },
  {
    family: "Archivo",
    fonts: [{ src: ArchivoBold, fontWeight: "bold" }, { src: ArchivoRegular }],
  },
  {
    family: "Cormorant",
    fonts: [
      { src: CormorantBold, fontWeight: "bold" },
      { src: CormorantRegular },
    ],
  },
  {
    family: "Spectral",
    fonts: [
      { src: SpectralBold, fontWeight: "bold" },
      { src: SpectralRegular },
    ],
  },
  {
    family: "Raleway",
    fonts: [{ src: RalewayBold, fontWeight: "bold" }, { src: RalewayRegular }],
  },
  {
    family: "Work Sans",
    fonts: [
      { src: WorkSansBold, fontWeight: "bold" },
      { src: WorkSansRegular },
    ],
  },
  {
    family: "Lato",
    fonts: [{ src: LatoBold, fontWeight: "bold" }, { src: LatoRegular }],
  },
  {
    family: "Old Standard TT",
    fonts: [
      { src: OldStandardTTBold, fontWeight: "bold" },
      { src: OldStandardTTRegular },
    ],
  },
  {
    family: "Anton",
    fonts: [{ src: AntonRegular }],
  },
  {
    family: "Oswald",
    fonts: [{ src: OswaldBold, fontWeight: "bold" }, { src: OswaldRegular }],
  },
  {
    family: "Montserrat",
    fonts: [
      { src: MontserratBold, fontWeight: "bold" },
      { src: MontserratRegular },
    ],
  },
  {
    family: "Poppins",
    fonts: [{ src: PoppinsBold, fontWeight: "bold" }, { src: PoppinsRegular }],
  },
  {
    family: "Nunito",
    fonts: [{ src: NunitoBold, fontWeight: "bold" }, { src: NunitoRegular }],
  },
  {
    family: "Rubik",
    fonts: [{ src: RubikBold, fontWeight: "bold" }, { src: RubikRegular }],
  },
  {
    family: "Source Sans Pro",
    fonts: [
      { src: SourceSans3Bold, fontWeight: "bold" },
      { src: SourceSans3Regular },
    ],
  },
  {
    family: "Oxygen",
    fonts: [{ src: OxygenBold, fontWeight: "bold" }, { src: OxygenRegular }],
  },
  {
    family: "Open Sans",
    fonts: [
      { src: OpenSansBold, fontWeight: "bold" },
      { src: OpenSansRegular },
    ],
  },
];

export const registerFonts = () => {
  fontConfig.forEach(({ family, fonts }) => {
    Font.register({
      family,
      fonts,
    });
  });
};
