import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../../../setup";
import { ProductsFilterType } from "../types/ProductsResponseType";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import { Button, CloseButton, Modal } from "react-bootstrap";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { actions } from "../redux/ProductsRedux";

interface Props {
  show: boolean;
  getProductDetailsAPI: () => void;
  closeModal: () => void;
  loading?: boolean;
}
const ProductsFilterModal: FC<Props> = ({
  show,
  getProductDetailsAPI,
  closeModal,
  loading,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // selectors
  const filters = useSelector<RootState>(
    ({ products }) => products.filters
  ) as ProductsFilterType;
  const categoriesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.categoriesList
  ) as GroupType[];
  const productTypesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.productTypesList
  ) as GroupType[];

  //   state
  const [selectedCategory, setSelectedCategory] = useState<GroupType>();
  const [selectedProductType, setSelectedProductType] = useState<GroupType[]>(
    []
  );

  useEffect(() => {
    if (
      (filters.Category && categoriesList?.length > 0) ||
      (filters.productTypes !== null &&
        filters.productTypes?.length > 0 &&
        productTypesList?.length > 0)
    ) {
      const data = categoriesList.filter((res) => res.id === filters.Category);
      if (data && data.length > 0) {
        setSelectedCategory(data[0]);
      }
      const typeData = productTypesList?.filter((res) => {
        return filters?.productTypes?.filter((data: number) => res.id === data);
      });
      if (typeData && typeData.length > 0) {
        setSelectedProductType(typeData);
      }
    }
  }, [filters]);

  const handleOnSelectItem = (item: GroupType, type: "label") => {
    const index =
      selectedProductType &&
      selectedProductType.findIndex(
        (selectedItem) => selectedItem.id === item.id
      );

    // if doesnt exist push item
    if (index === -1) {
      type === "label" &&
        setSelectedProductType(() => [...selectedProductType, item]);
    }
    // if already exists remove item
    else {
      type === "label" &&
        setSelectedProductType((prevState) => {
          prevState.splice(index, 1);
          return [...prevState];
        });
    }
  };

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{intl.formatMessage({ id: "FILTER_BUTTON" })}</Modal.Title>
        <div className="d-flex justify-content-end align-items-center">
          <button
            className="btn btn-sm btn-primary me-2"
            onClick={() => {
              dispatch(
                actions.setFilters({
                  Category: null,
                  Location: null,
                  Article_no: null,
                  productTypes: null,
                  EAN: null,
                  Instructions: null,
                })
              );
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "RESET_BUTTON" })}
          </button>
          <CloseButton onClick={closeModal} />
        </div>
      </Modal.Header>
      <div className="overflow-visible">
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {/* city  & zipcode*/}
            <div className="row">
              {/* city */}
              {/* <div className="fv-row mb-8 col-lg-6">
              <label className="fw-bold fs-6">
                {intl.formatMessage({
                  id: "FORM_VALIDATION_FIELD_LABEL_CITY",
                })}
              </label>
              <input
                placeholder={""}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                onChange={(e) => {}}
                autoComplete="off"
                value={""}
              />
            </div> */}

              {/* zip code */}
              {/* <div className="fv-row mb-8 col-lg-6">
              <label className="fw-bold fs-6">
                {intl.formatMessage({
                  id: "FORM_VALIDATION_FIELD_LABEL_ZIPCODE",
                })}
              </label>
              <input
                placeholder={""}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                onChange={(e) => {}}
                autoComplete="off"
                value={""}
              />
            </div> */}
            </div>

            {/* country */}
            <div className="mb-8">
              <label className="form-label">
                {intl.formatMessage({
                  id: "PRODUCT_GENERAL_CATEGORY_TITLE",
                })}
              </label>
              <DropDown
                id="countriesDropDown"
                items={categoriesList || []}
                selectedItem={selectedCategory}
                // @ts-ignore
                onSelectItem={(item: GroupType) => {
                  setSelectedCategory(item);
                }}
                displayValue={selectedCategory?.name}
                // @ts-ignore
                displayFunc={(item: GroupType) => {
                  return item.name;
                }}
              />
            </div>
            <div className="mb-8">
              <label className="form-label">
                {intl.formatMessage({
                  id: "PRODUCTS_PRODUCTTYPES_NAME",
                })}
              </label>
              <DropDown
                id="countriesDropDown"
                items={productTypesList || []}
                multiSelect
                selectedItems={selectedProductType}
                // @ts-ignore
                onSelectItem={(item: GroupType) => {
                  handleOnSelectItem(item, "label");
                }}
                // @ts-ignore
                displayFunc={(item: GroupType) => {
                  return item.name;
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
        <Button
          className="btn btn-primary"
          onClick={() => {
            const ids = selectedProductType.map((data) => data.id);
            dispatch(
              actions.setFilters({
                Category: selectedCategory ? selectedCategory?.id : null,
                Location: null,
                Article_no: null,
                productTypes: ids.length > 0 ? ids : null,
                EAN: null,
                Instructions: null,
              })
            );
            closeModal();
          }}
        >
          <span className="indicator-label">
            {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductsFilterModal;
