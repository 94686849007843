import clsx from "clsx";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

// custom
// import {useDispatch} from 'react-redux'
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { createProduct } from "../redux/ProductsAPI";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import {
  createNewCategory,
  getCategoriesList,
} from "../../masterdata/redux/CategoriesAPI";
import { useDispatch } from "react-redux";
import { actions } from "../../masterdata/redux/MasterdataRedux";
import { successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  show: boolean;
  closeModal?: () => void;
  getProductsListAPI?: () => void;
}

const AddProductsModal: FC<Props> = ({
  show,
  closeModal,
  getProductsListAPI,
}) => {
  const categoriesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.categoriesList
  ) as GroupType[];

  // const dispatch = useDispatch()
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState();

  const formik = useFormik({
    initialValues: {
      name: "",
      category_id: undefined,
    },
    validate: (values) => {
      let errors = {};
      if (!values.name) {
        //@ts-ignore
        errors.name = intl.formatMessage({
          id: "ADD_PRODUCTS_NAME_REQUIRED_MESSAGAE",
        });
      }
      if (!values.category_id) {
        //@ts-ignore
        errors.category_id = intl.formatMessage({
          id: "ADD_PRODUCTS_CATEGORY_REQUIRED_MESSAGE",
        });
      }
      return errors;
    },
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      createProduct(values.category_id, values.name)
        .then(({ data: { products_details } }) => {
          closeModal?.();
          successToast(
            intl.formatMessage({ id: "ADD_PRODUCTS_ADD_SUCCESS_MESSAGE" })
          );
          // redirect

          // dispatch(actions.setProductsList(stripeProductDetails))
        })
        .catch((err) => {
          err.response?.data?.errors?.email
            ? setStatus(err.response.data?.errors?.email)
            : setStatus(
                intl.formatMessage({ id: "ADD_PRODUCTS_ERROR_MESSAGE" })
              );
        })
        .finally(() => {
          getProductsListAPI?.();
          setLoading(false);
        });
    },
  });

  const createCustomCategory = (name: string) => {
    createNewCategory(name)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getCategoriesList().then(({ data: { data } }) => {
          dispatch(actions.setCategoriesList(data));
          const selectedItem = data.filter((cate) => cate.name === name)[0];
          //@ts-ignore
          setSelectedCategory(selectedItem);
          formik.setFieldValue("category_id", selectedItem.id);
        });
      });
  };

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      contentClassName={loading ? "pe-none" : "AddNew"}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "PRODUCTS_ADD_NEW_MODAL_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
        <Modal.Body>
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="row fv-row  align-items-center mb-8">
              <div>
                <label className="required fw-bold fs-6">
                  {intl.formatMessage({
                    id: "PRODUCTS_ADD_NEW_MODAL_NAME_FIELD",
                  })}
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  {...formik.getFieldProps("name")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.name}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2 ">
                <span className="required">
                  {intl.formatMessage({
                    id: "PRODUCTS_ADD_NEW_MODAL_CATEGORY_FIELD",
                  })}
                </span>
              </label>
              <DropDown
                id="pages-parent-dropdown"
                items={categoriesList || []}
                // @ts-ignore
                displayFunc={(item) => {
                  return item.name;
                }}
                displayValue={
                  categoriesList?.find(
                    (item) => item.id === formik.values.category_id
                  )?.name || ""
                }
                onSelectItem={(item) => {
                  //@ts-ignore
                  formik.setFieldValue("category_id", item.id);
                  //@ts-ignore
                  setSelectedCategory(item);
                }}
                // @ts-ignore
                selectedItem={selectedCategory}
                customMessage={intl.formatMessage({
                  id: "ADD_PRODUCT_CATEGORY_CREATE_CUSTOM_MESSAGE",
                })}
                onEnterCreateElement={(newCategory) => {
                  createCustomCategory(newCategory);
                }}
              />
              {formik.touched.category_id && formik.errors.category_id && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.category_id}</span>
                </div>
              )}
            </div>

            {/* begin::Form group Firstname */}

            {/* end::Form group */}

            {/* end::Form group */}
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            type="submit"
            id="kt_sign_in_submit"
            className="btn  btn-primary"
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_LOADING_MESSAGE",
                })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddProductsModal;
