import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { SVGICON } from "../../../../_metronic/helpers";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import { sortGroupsParams } from "../types/request";
// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import InfoIcon from "../../../../_metronic/assets/icons/info.svg";
import SVG from "react-inlinesvg";

import {
  deletePanels,
  deleteSection,
  getTabByID,
  sortFieldSections,
  sortPanels,
  storeSectionFields,
  storeTabPanels,
  updateIsRequired,
  updateTab,
} from "../../projectMasterData/redux/TabsAPI";
import { useIntl } from "react-intl";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { TwoButtonConfirmAlert } from "../../../sharedComponents/Alert";

import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";

import "../../projectMasterData/ContentMasterPage.scss";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import clsx from "clsx";
import {
  deleteSelectedField,
  getFieldsList,
  updatePanelHeading,
} from "../../projectMasterData/redux/FieldsAPI";

import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";

import { useDispatch } from "react-redux";

import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import withScrolling from "react-dnd-scrolling";
import SelectFieldModal, {
  FieldSelectModalProps,
} from "../../projectMasterData/components/SelectFieldsModal";
import { Tooltip } from "react-tooltip";
import { FieldType } from "../../projectMasterData/types/FieldsReponseTypes";
import {
  panels,
  SectionType,
  TabType,
} from "../../projectMasterData/types/TabsResponseType";
import {
  FieldsModal,
  FieldsModalProps,
} from "../../projectMasterData/components/Fields";
import { actions } from "../../projectMasterData/redux";
import TabPreview from "../../projectMasterData/components/TabPreview";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

interface selectFieldModalProps {
  show: boolean;
  section: number | undefined;
  panel: number | undefined;
}

interface Props {
  selectedTab: TabType;
  onChangeTabName: () => void;
}

const PipelineTabsAndFields: FC<Props> = ({ selectedTab, onChangeTabName }) => {
  const { state = {} } = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const ScrollingComponent = withScrolling("div");

  const MASTER_DATA_PANELS_TYPE = JSON.parse(
    intl.formatMessage({ id: "MASTER_DATA_PANELS_TYPE" })
  ) as any[];

  const fieldsList = useSelector<RootState>(
    // @ts-ignore
    ({ contentMaster }) => contentMaster.fieldsList
  ) as FieldType[];
  const singleTab = useSelector<RootState>(
    // @ts-ignore
    ({ contentMaster }) => contentMaster.tabDetails
  ) as TabType;

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "pipelines"
  );

  // state
  const [tabDetails, setTabDetails] = useState<TabType>(selectedTab);
  const [searchText, setSearchText] = useState<string>("");
  const [fieldData, setFieldData] = useState<FieldType[]>(fieldsList);
  const [showPreview, setPreview] = useState(false);
  const [tabHeading, setTabHeading] = useState(tabDetails?.name || "");
  const [loading, setLoding] = useState(false);
  const [tabHeight, setTabHeight] = useState<any>();
  const [openAddFieldModal, setOpenAddFieldModal] = useState<FieldsModalProps>({
    show: false,
    type: "new",
  });

  const [selectFieldModal, setSelectFieldModal] =
    useState<selectFieldModalProps>({
      show: false,
      section: undefined,
      panel: undefined,
    });

  const getTabDetailsByID = () => {
    if (selectedTab && selectedTab?.id) {
      getTabByID(selectedTab?.id)
        .then(({ data }) => {
          setTabDetails(data.tab);
          setTabHeading(data.tab.name);
        })
        .finally(() => {
          setLoding(false);
          // setSearchText("");
        });
    }
  };

  useEffect(() => {
    setLoding(true);
    setTabDetails(selectedTab);
    getTabDetailsByID();
  }, [selectedTab]);

  const onPanelsSortChange = (sortedList: panels[]) => {
    const newSortedTabsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTabsArr.push({
        id: sortedListItem.tab_panel_id,
        newposition: i + 1,
      });
    });
    // sort existing tags API Call
    sortPanels(selectedTab?.id, newSortedTabsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getTabDetailsByID();
      });
  };

  // draggable tags list
  const list =
    tabDetails && tabDetails?.panels && tabDetails?.panels.length > 0
      ? tabDetails?.panels.map((panel, i) => {
          return {
            content: <div className="mt-4" key={i}></div>,
            panel: panel,
          };
        })
      : [];

  const handleDrop = useCallback(
    (
      dropZone: { path: any; childrenCount: any; panel?: any; section?: any },
      item: { id: any; type: any; isNew: boolean; path?: any }
    ) => {
      // console.log("dropZone", dropZone);
      // console.log("item", item);

      if (item.type === "panel") {
        const isSortingPanel = item.isNew ? false : true;
        if (!isSortingPanel) {
          storeTabPanels(tabDetails?.id, item.id)
            .then((data) => {
              successToast(
                intl.formatMessage({
                  id: "MASTERDATA_TAB_PANEL_ADD_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((e) => {
              errorToast(
                intl.formatMessage({
                  id: "MASTERDATA_TAB_PANEL_ADD_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              getTabDetailsByID();
            });
        }
      }
      if (item.type === "field" && item.isNew) {
        const isSortingField = item.isNew ? false : true;
        if (!isSortingField) {
          TwoButtonConfirmAlert(
            intl.formatMessage({ id: "FIELD_REQUIRED_FIELD_CONFIRM_MESSAGE" }),
            (selected: boolean) => {
              const isRequiredField = selected;
              const newSectionField: {
                field_id: number;
                is_required: number;
              }[] = [];
              newSectionField.push({
                field_id: item.id,
                is_required: isRequiredField ? 1 : 0,
              });
              storeSectionFields(
                dropZone.panel,
                dropZone.section,
                newSectionField
              )
                .then((data) => {
                  successToast(
                    intl.formatMessage({
                      id: "MASTERDATA_TAB_PANEL_FIELD_ADD_SUCCESS_MESSAGE",
                    })
                  );
                })
                .catch((e) => {
                  errorToast(
                    intl.formatMessage({
                      id: "MASTERDATA_TAB_PANEL_FIELD_ADD_FAILURE_MESSAGE",
                    })
                  );
                })
                .finally(() => {
                  getTabDetailsByID();
                });
            }
          );
        }
      }
    },
    []
  );
  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const updateDisplayTabsList = () => {
    const updatedTabsList = fieldsList.filter((tab) => {
      if (
        (tab?.name || "")
          .toLowerCase()
          .includes((searchText || "").toLowerCase())
      ) {
        return true;
      }
      return false;
    });

    // save updatedlist to display
    setFieldData(updatedTabsList);
  };

  useEffect(() => {
    updateDisplayTabsList();

    if (searchText.length == 0) {
      setFieldData(fieldsList);
    }

    return () => {
      setFieldData(fieldsList);
    };
  }, [fieldsList, searchText]);

  const renderSection = (panel: panels, currentPath: any) => {
    return (
      <Section
        panel={panel}
        components={tabDetails}
        handleDrop={handleDrop}
        path={currentPath}
        getTabDetailsByID={getTabDetailsByID}
        onSelectModal={(data: selectFieldModalProps) => {
          setSelectFieldModal(data);
        }}
      />
    );
  };

  const dragPanel = useRef<number>(0);
  const draggedOverPanel = useRef<number>(0);
  // console.log(fieldData);

  const handleTabHeading = () => {
    updateTab(tabDetails?.id, tabHeading)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_TABS_EDIT_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((err) => {})
      .finally(() => {
        getTabDetailsByID();
        onChangeTabName();
      });
  };

  useEffect(() => {
    // @ts-ignore
    // if (document.getElementById("mainTabContent").clientHeight) {
    //   const value =
    //     // @ts-ignore
    //     document.getElementById("mainTabContent").clientHeight;
    //   setTabHeight(value);
    // }
  }, []);

  const getFieldsListAPI = () => {
    // getGroupsList()
    getFieldsList().then(({ data: { fields } }) => {
      dispatch(actions.setFieldssList(fields));
    });
  };

  // console.log("isMobile", isMobile);

  const hasNative = document && document.elementsFromPoint;

  function getDropTargetElementsAtPoint(x: any, y: any, dropTargets: any) {
    return dropTargets.filter((t: any) => {
      const rect = t.getBoundingClientRect();
      return (
        x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
      );
    });
  }

  // use custom function only if elementsFromPoint is not supported
  const backendOptions = {
    getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
  };

  //   adding panels
  const hanldePanles = (id: number) => {
    storeTabPanels(tabDetails?.id, id)
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_TAB_PANEL_ADD_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_TAB_PANEL_ADD_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getTabDetailsByID();
      });
  };

  return (
    <>
      {openAddFieldModal.show && (
        <FieldsModal
          ModalProps={openAddFieldModal}
          closeModal={() => {
            setOpenAddFieldModal({
              show: false,
            });
          }}
          getFieldsListAPI={getFieldsListAPI}
        />
      )}
      {selectFieldModal.show && (
        <SelectFieldModal
          show={selectFieldModal.show}
          closeModal={() => {
            setSelectFieldModal({
              show: false,
              section: undefined,
              panel: undefined,
            });
          }}
          onSelectedFields={(fields: FieldSelectModalProps[]) => {
            if (selectFieldModal.section && selectFieldModal.panel)
              storeSectionFields(
                selectFieldModal?.panel,
                selectFieldModal?.section,
                fields
              )
                .then((data) => {
                  setSelectFieldModal({
                    show: false,
                    section: undefined,
                    panel: undefined,
                  });
                  successToast(
                    intl.formatMessage({
                      id: "MASTERDATA_TAB_PANEL_FIELD_ADD_SUCCESS_MESSAGE",
                    })
                  );
                })
                .catch((e) => {
                  errorToast(
                    intl.formatMessage({
                      id: "MASTERDATA_TAB_PANEL_FIELD_ADD_FAILURE_MESSAGE",
                    })
                  );
                })
                .finally(() => {
                  getTabDetailsByID();
                });
          }}
          isSelectionModal={true}
          selectedFields={
            selectFieldModal.section
              ? tabDetails.panels
                  ?.filter(
                    (panel) => panel.tab_panel_id === selectFieldModal.panel
                  )?.[0]
                  ?.sections?.filter(
                    (section) => section.id === selectFieldModal.section
                  )?.[0].fields_data
              : []
          }
        />
      )}
      <DndProvider
        backend={isMobile ? TouchBackend : HTML5Backend}
        options={backendOptions}
      >
        <div className="">
          <div
            className={`card py-4 d-flex flex-row align-items-center justify-content-between`}
            id="panel-card"
          >
            <div
              className="d-flex flex-row align-items-center ms-0"
              style={{
                flex: 1,
              }}
            >
              <input
                type="text"
                value={tabHeading}
                onChange={(e) => {
                  setTabHeading(e.target.value);
                }}
                style={{
                  flex: 1,
                  border: "none",
                  outline: "none",
                }}
                className={`panelHeading ms-2 me-5 fw-bold py-4 ${!crudPermission?.edit && "pe-none"}`}
                onBlur={() => {
                  if (tabHeading.length === 0) {
                    setTabHeading(tabDetails?.name || "");
                  } else if (tabDetails?.name !== tabHeading) {
                    handleTabHeading();
                  }
                }}
              />
            </div>
            <div>
              {showPreview ? (
                <button
                  className="btn  btn-light btn-primary btn-sm"
                  onClick={() => {
                    setPreview(false);
                  }}
                >
                  {intl.formatMessage({ id: "GO_BACK_BUTTON" })}
                </button>
              ) : (
                <button
                  className="btn  btn-light btn-primary btn-sm"
                  onClick={() => {
                    setPreview(true);
                  }}
                >
                  {intl.formatMessage({ id: "PREVIEW_BUTTON" })}
                </button>
              )}
              {/* <button
              className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
              onClick={() => {}}
            >
              <SVGICON src={EditIcon} className="svg-icon-3" />
            </button> */}
            </div>
          </div>
          {!showPreview ? (
            <div className="row">
              {loading ? (
                <SimpleLoader />
              ) : (
                <div className="d-flex flex-column  col-lg-12">
                  {/* main content */}
                  <ScrollingComponent
                    className=" d-flex flex-column flex-row-fluid  col-md--9 "
                    id="mainTabContent"
                  >
                    {list && list.length > 0 ? (
                      <ScrollingComponent className="card card-body my-4 p-3 ps-4">
                        {tabDetails &&
                        tabDetails?.panels &&
                        tabDetails?.panels.length > 0
                          ? tabDetails?.panels.map((panel, index) => {
                              const currentPath = `${index}`;
                              return (
                                <div
                                  className="draggable"
                                  onDragStart={() =>
                                    (dragPanel.current = index)
                                  }
                                  onDragEnter={() =>
                                    (draggedOverPanel.current = index)
                                  }
                                  key={index}
                                  onDragEnd={() => {
                                    if (
                                      dragPanel.current !==
                                      draggedOverPanel.current
                                    ) {
                                      const clonePanels = [
                                        ...tabDetails?.panels,
                                      ];
                                      const deletedSlice = clonePanels.splice(
                                        dragPanel.current,
                                        1
                                      )[0];
                                      clonePanels.splice(
                                        draggedOverPanel.current,
                                        0,
                                        deletedSlice
                                      );
                                      // const temp = clonePanels[dragPanel.current];
                                      // clonePanels[dragPanel.current] =
                                      //   clonePanels[draggedOverPanel.current];
                                      // clonePanels[draggedOverPanel.current] =
                                      //   temp;
                                      onPanelsSortChange(clonePanels);
                                    }
                                  }}
                                  onDragOver={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <React.Fragment key={panel.tab_panel_id}>
                                    {renderSection(panel, currentPath)}
                                  </React.Fragment>
                                </div>
                              );
                            })
                          : []}
                      </ScrollingComponent>
                    ) : (
                      <div className="w-100 h-100 card card-body d-flex justify-content-center align-items-center mt-4">
                        <div className="w-100 h-100 firstPanel">
                          <span className="text-muted h5">
                            {intl.formatMessage({
                              id: "TABS_EMPTY_MESSAGE",
                            })}
                          </span>
                          <React.Fragment>
                            <div className="w-100 h-100 flex-column d-flex justify-content-center align-items-center">
                              <button
                                type="button"
                                className="btn btn-sm btn-icon btn-primary me-4"
                                onClick={() => {}}
                              >
                                <SVGICON
                                  src={PlusIcon}
                                  className="svg-icon-2"
                                />
                                {/* {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CONTACT_TITLE'})} */}
                              </button>
                              <div className="  text-muted fs-6">
                                {intl.formatMessage({
                                  id: "MASTERDATA_TABS_PANELS_DRAG_INFO",
                                })}
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </ScrollingComponent>
                  {/* panels section */}
                  <div
                    className={`d-flex gap-7 ${!crudPermission?.edit && "pe-none"}`}
                    style={{
                      height: "150px",
                    }}
                  >
                    <div
                      className="btn flex-column btn-active-light-primary border-primary border border-dashed p-7 d-flex align-items-center justify-content-center"
                      style={{
                        flex: 1,
                      }}
                      role="button"
                      onClick={() => {
                        // id 1 for single panel
                        hanldePanles(1);
                      }}
                    >
                      <button className="btn btn-icon btn-primary btn-sm mb-2 ">
                        <SVGICON
                          src={PlusIcon}
                          className="svg-icon-2  svg-icon-white"
                        />
                      </button>

                      {MASTER_DATA_PANELS_TYPE[0].name}
                    </div>
                    <div
                      className="btn flex-column  btn-active-light-primary border-primary border border-dashed p-7 d-flex align-items-center justify-content-center"
                      style={{
                        flex: 1,
                      }}
                      role="button"
                      onClick={() => {
                        // id 2 for single panel
                        hanldePanles(2);
                      }}
                    >
                      <button className="btn btn-icon btn-primary btn-sm mb-2 ">
                        <SVGICON
                          src={PlusIcon}
                          className="svg-icon-2  svg-icon-white"
                        />
                      </button>
                      {MASTER_DATA_PANELS_TYPE[1].name}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <TabPreview dealTab={tabDetails} type="new" currPhase={undefined} />
          )}
        </div>
      </DndProvider>
    </>
  );
};

export default PipelineTabsAndFields;

export const Section: FC<{
  panel: panels;
  components: any;
  handleDrop: any;
  path: any;
  getTabDetailsByID: () => void;
  onSelectModal: (data: selectFieldModalProps) => void;
}> = ({
  panel,
  components,
  handleDrop,
  path,
  getTabDetailsByID,
  onSelectModal,
}) => {
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const ref = useRef(null);
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "pipelines"
  );
  // state
  const [panelHeading, setPanelHeading] = useState(
    panel.heading || panel.name || ""
  );

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "panel",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      type: "panel",
      id: panel.tab_panel_id,
    },
  }));

  const opacity = isDragging ? 0 : 1;
  drag(ref);
  const style = {
    // border: "1px solid red",
  };

  const handleDeletePanel = (id: number) => {
    setDeleteModalLoading(true);
    deletePanels([id])
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_TAB_PANEL_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_TAB_PANEL_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getTabDetailsByID();
      });
  };

  const renderSection = (section: SectionType, currentPath: any) => {
    return (
      <SectionFields
        section={section}
        components={section}
        handleDrop={handleDrop}
        path={currentPath}
        panel={panel}
        getTabDetailsByID={getTabDetailsByID}
        onSelectModal={onSelectModal}
      />
    );
  };

  const handlePanelHeading = () => {
    updatePanelHeading(panel.tab_panel_id, panelHeading)
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_TABS_PANEL_HEADING_UPDATE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_TABS_PANEL_HEADING_UPDATE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getTabDetailsByID();
      });
  };

  return (
    <>
      <div
        ref={ref}
        style={{ ...style, opacity }}
        className="base draggable row"
      >
        <div className="card cardBorder card-flush mb-4">
          <div
            className={`card-header d-flex ${!crudPermission?.edit && "pe-none"}`}
          >
            <div
              className="card-title"
              style={{
                flex: 1,
              }}
            >
              <div className="me-5">
                {crudPermission?.edit && (
                  <SVGICON
                    src={DragIcon}
                    className="svg-icon-2 svg-hover-primary"
                  />
                )}
              </div>
              <input
                style={{
                  flex: 1,
                  outline: "none",
                  border: "none",
                }}
                type="text"
                value={panelHeading}
                className="panelHeading py-4"
                onChange={(e) => {
                  e.preventDefault();
                  setPanelHeading(e.target.value);
                }}
                onBlur={() => {
                  if (panelHeading.length === 0) {
                    setPanelHeading(panel.heading || panel.name || "");
                  } else if (panel.heading !== panelHeading) {
                    handlePanelHeading();
                  }
                }}
              />
            </div>
            <div className="card-toolbar">
              <div className="d-flex align-items-center">
                {crudPermission?.edit && (
                  <div
                    className="position-absolute bg-light btn btn-icon btn-active-light-danger d-flex justify-content-center align-items-center"
                    style={{
                      right: -15,
                      top: -10,
                      zIndex: 99,
                      borderRadius: "50px",
                      height: "25px",
                      width: "25px",
                    }}
                    role={"button"}
                    onClick={() => {
                      showDeleteConfirmModal(
                        intl.formatMessage({
                          id: "TAB_SECTION_DEELTE_CONFORM_MESSAGE",
                        }),
                        () => {
                          handleDeletePanel(panel.tab_panel_id);
                        }
                      );
                    }}
                  >
                    <SVGICON
                      src={CloseIcon}
                      className="svg-icon svg-icon-1 svg-icon-grey-800"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="d-flex flex-xl-row flex-lg-column gap-12">
              {panel.sections.map((section, index) => {
                const currentPath = `${path}-${index}`;

                return (
                  <div
                    style={{
                      flex: 1,
                    }}
                    key={index}
                  >
                    <React.Fragment key={index}>
                      {renderSection(section, currentPath)}
                    </React.Fragment>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const SectionFields: FC<{
  section: SectionType;
  components: any;
  handleDrop: () => void;
  path: any;
  panel: panels;
  getTabDetailsByID: () => void;
  onSelectModal: (data: selectFieldModalProps) => void;
}> = ({
  section,
  components,
  handleDrop,
  path,
  panel,
  getTabDetailsByID,
  onSelectModal,
}) => {
  const ref = useRef(null);
  const [cardFields, setCardFields] = useState<FieldType[]>(
    section.fields_data
  );

  useEffect(() => {
    setCardFields(section.fields_data);
  }, [section]);
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const intl = useIntl();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "field",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      id: section.id,
      type: "section",
    },
  }));

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "pipelines"
  );
  const crudPermissionForFields: any = enablePermissionForCrud(
    userRoleDetails,
    "fields"
  );

  const opacity = isDragging ? 0 : 1;
  drag(ref);
  const style = {
    // border: "1px solid blue",
  };

  const sortPanelFields = (sortedList: FieldType[]) => {
    const newSortedTabsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTabsArr.push({
        id: sortedListItem.id,
        newposition: i + 1,
      });
    });
    sortFieldSections(panel.tab_panel_id, section.id, newSortedTabsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getTabDetailsByID();
      });
  };

  const renderSection = (field: FieldType, currentPath: any, index: number) => {
    return (
      <SidebarField
        field={field}
        components={index}
        path={currentPath}
        getTabDetailsByID={getTabDetailsByID}
        panel={panel}
        section={section}
        // moveField={moveField}
      />
    );
  };

  const handleSectionDelete = () => {
    setDeleteModalLoading(true);
    deleteSection(panel.tab_panel_id, section.id)
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_TAB_PANEL_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch((e) => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_TAB_PANEL_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getTabDetailsByID();
      });
  };

  const dragField = useRef<number>(0);
  const draggedOverField = useRef<number>(0);

  const draggable = document.getElementById("draggable");

  let isDraggingElement = false;

  draggable?.addEventListener("touchstart", (event) => {
    isDraggingElement = true;
  });

  document.addEventListener("touchmove", (event) => {
    if (isDraggingElement) {
      const touch = event.touches[0];
    }
  });

  document.addEventListener("touchend", () => {
    isDraggingElement = false;
  });

  return (
    <>
      <div
        // ref={ref}
        style={{ ...style, opacity }}
        className={clsx(
          "base cardBorder rounded-3 draggable row position-relative"
        )}
      >
        {section.fields_data.length > 0 && (
          <>
            {crudPermission?.edit && (
              <div
                className="position-absolute bg-light btn btn-icon btn-active-light-danger d-flex justify-content-center align-items-center"
                style={{
                  right: -15,
                  top: -10,
                  zIndex: 99,
                  borderRadius: "50px",
                  height: "25px",
                  width: "25px",
                }}
                role={"button"}
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "TAB_SECTION_FIELDS_DELETE_CONFIRM_MESSAGE",
                    }),
                    () => {
                      handleSectionDelete();
                    }
                  );
                }}
              >
                <SVGICON
                  src={CloseIcon}
                  className="svg-icon svg-icon-1 svg-icon-grey-800"
                />
              </div>
            )}
          </>
        )}
        <div className="card  card-flush mb-4">
          <div className="d-flex card-toolbar justify-content-end align-items-center my-3">
            {crudPermissionForFields?.read && (
              <div
                role="button"
                className="text-primary me-4"
                onClick={(e) => {
                  onSelectModal({
                    show: true,
                    section: section.id,
                    panel: panel.tab_panel_id,
                  });
                }}
              >
                {intl.formatMessage({ id: "TAB_DETAILS_ADD_FIELD_TEXT" })}
              </div>
            )}
          </div>
          <div className="card-body py-0">
            <div>
              {cardFields.length > 0
                ? cardFields?.map((field, index) => {
                    const currentPath = `${path}-${index}`;

                    return (
                      <div
                        className="draggable"
                        onDragOver={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        id="draggable"
                        key={index}
                        onDragStart={() => (dragField.current = index)}
                        onDragEnter={() => (draggedOverField.current = index)}
                        onDragEnd={() => {
                          // console.log(dragField.current, draggedOverField.current);
                          if (dragField.current !== draggedOverField.current) {
                            const cloneFields = [...cardFields];
                            const deletedSlice = cloneFields.splice(
                              dragField.current,
                              1
                            )[0];
                            cloneFields.splice(
                              draggedOverField.current,
                              0,
                              deletedSlice
                            );
                            sortPanelFields(cloneFields);
                          }
                        }}
                      >
                        <React.Fragment key={index}>
                          {renderSection(field, currentPath, index)}
                        </React.Fragment>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const SidebarField: FC<{
  field: FieldType;
  components: any;
  path: any;
  getTabDetailsByID: () => void;
  panel: panels;
  section: SectionType;
  // moveField: (dragI: any, hoverI: any, item: any) => void;
}> = ({
  field,
  components,
  path,
  getTabDetailsByID,
  panel,
  section,
  // moveField,
}) => {
  const style = {
    // border: "1px dashed black",
    padding: "0.5rem 1rem",
    backgroundColor: "white",
    cursor: "move",
    marginBottom: ".5rem",
  };

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "pipelines"
  );

  // context
  const {
    showDeleteConfirmModal,
    setDeleteModalLoading,
    hideDeleteConfirmModal,
  } = useGlobalModalContext();

  const ref = useRef(null);
  const intl = useIntl();

  const [, drop] = useDrop({
    accept: ["field"],
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "field",
    end(draggedItem, monitor) {
      const res = monitor.getDropResult();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      id: field?.id,
      type: "field",
      path: path,
      fieldType: field?.type,
      name: field?.name,
      index: components,
      isFieldSorting: true,
    },
  }));

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleFieldDelete = () => {
    setDeleteModalLoading(true);
    deleteSelectedField([field.tab_panels_fields_id])
      .then((data) => {})
      .catch((e) => {})
      .finally(() => {
        hideDeleteConfirmModal();
        setDeleteModalLoading(false);
        getTabDetailsByID();
      });
  };

  return (
    <div
      ref={ref}
      style={{ opacity }}
      className="position-relative"
      key={field?.id}
    >
      <div className="d-flex">
        {crudPermission?.edit && (
          <div className="me-5 mt-4">
            <SVGICON src={DragIcon} className="svg-icon-2 svg-hover-primary" />
          </div>
        )}
        <input
          type="text"
          className="form-control form-control form-control-solid mb-3 "
          value={field?.placeholder ? field.placeholder : field?.name}
          disabled
        />
        {field?.tooltip && (
          <>
            {crudPermission?.edit && (
              <div
                style={{
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <button className="btn btn-icon btn-light btn-md ms-1 ">
                  <span
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={field.tooltip}
                    data-tooltip-place="top"
                  >
                    <SVG src={InfoIcon} className="svg-icon mx-2" />
                  </span>
                </button>

                <Tooltip
                  id="my-tooltip"
                  place="top"
                  style={{
                    color: "black",
                    backgroundColor: "#e9ecef",
                    zIndex: 999,
                    whiteSpace: "normal",
                    maxWidth: "250px",
                    wordWrap: "break-word",
                  }}
                  className="btn text-dark"
                  border="1px solid #6c757d"
                />
              </div>
            )}
          </>
        )}

        {crudPermission?.edit && (
          <button
            className="btn btn-icon btn-light btn-active-light-danger btn-md ms-1 "
            onClick={() => {
              showDeleteConfirmModal(
                intl.formatMessage({ id: "TAB_FIELD_DEELTE_CONFIRM_MESSAGE" }),
                () => {
                  handleFieldDelete();
                }
              );
            }}
          >
            <SVGICON src={DeleteIcon} className="svg-icon-3" />
          </button>
        )}
      </div>
      {crudPermission?.edit && (
        <div
          className="form-check form-switch form-switch-sm form-check-custom form-check-solid position-absolute"
          style={{ top: "11.5px", right: "95px" }}
        >
          <input
            className={clsx(
              "form-check-input",
              field?.tooltip ? "position-relative" : "position-absolute"
            )}
            type="checkbox"
            name="notifications"
            value=""
            checked={field?.is_required === 1 ? true : false}
            style={{ width: "45px", height: "22px" }}
            onChange={(e) => {
              updateIsRequired(
                panel?.tab_panel_id,
                section?.id,
                field?.id,
                e.target.checked ? 1 : 0
              ).finally(() => {
                getTabDetailsByID();
              });
            }}
          />
        </div>
      )}
    </div>
  );
};
