import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LayoutListType } from "../types/LayoutResponsetypes";
import { getLayoutDetails } from "../redux/LayoutApi";
import UploadLayoutLogo from "./UploadLayoutLogo";
import Footertext from "./Footertext";
import LayoutBackgroundColor from "./LayoutBackgroundColor";
import FooterColors from "./FooterColors";
import LayoutPagesShow from "./LayoutPagesShow";
import ShowCreatedAt from "./ShowCreatedAt";

const EditLayout = () => {
  const location = useLocation();
  const { state = {} } = location;
  const history = useHistory();
  // @ts-ignore
  const sharedLayout: any = state.headerState || {};

  const [layout, setLayout] = useState<LayoutListType>(sharedLayout);
  const [loading, setLoading] = useState<boolean>();
  const [prevValues, setPrevValues] = useState<any[]>([]);

  const getLayoutDetailsAPI = () => {
    if (sharedLayout.id) {
      getLayoutDetails(sharedLayout.id)
        .then(({ data: { data } }) => {
          setLayout(data);
          setPrevValues([
            data?.footers_text_1 || "",
            data?.footers_text_2 || "",
            data?.footers_text_3 || "",
            data?.footers_text_4 || "",
          ]);
          history.replace({ state: { headerState: data } });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getLayoutDetailsAPI();
  }, [sharedLayout.id]);

  return (
    <div>
      <div className="d-flex flex-column ">
        <div className="d-flex flex-column flex-lg-row   mt-10">
          <div
            className="d-flex flex-column gap-7 gap-lg-10 w-100  mb-7 me-lg-10"
            style={{ flex: "0 0 400px" }}
          >
            <UploadLayoutLogo
              layout={layout}
              getLayoutDetailsAPI={getLayoutDetailsAPI}
            />
            <LayoutBackgroundColor
              layout={layout}
              getLayoutDetailsAPI={getLayoutDetailsAPI}
            />
            <div className="card">
              <FooterColors
                layout={layout}
                getLayoutDetailsAPI={getLayoutDetailsAPI}
              />
              <LayoutPagesShow
                layout={layout}
                getLayoutDetailsAPI={getLayoutDetailsAPI}
              />
              <ShowCreatedAt
                layout={layout}
                getLayoutDetailsAPI={getLayoutDetailsAPI}
              />
            </div>
          </div>

          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            <Footertext
              layout={layout}
              getLayoutDetailsAPI={getLayoutDetailsAPI}
              prevValues={prevValues}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLayout;
