import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

// custom
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import config from "../../../config";

import InvoiceOverVeiw from "./components/InvoiceOverVeiw";
import EditInvoice from "./components/EditInvoice";

const InvoicesPage: React.FC = () => {
  const intl = useIntl();

  const postsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "INVOICE_PAGE_TITLE" }),
      path: "/sales/invoices",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {config.APP_NAME}-{intl.formatMessage({ id: "INVOICE_PAGE_TITLE" })}
        </title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/sales/invoices/overview">
          <PageTitle>
            {intl.formatMessage({ id: "INVOICE_PAGE_TITLE" })}
          </PageTitle>
          <InvoiceOverVeiw />
        </Route>
        <Route path="/sales/invoices/edit">
          <PageTitle
            breadcrumbs={[
              ...postsBreadCrumbs,
              {
                title: intl.formatMessage({
                  id: "INVOICE_DETAILS_ROUTE",
                }),
                path: "/sales/invoices/edit",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({ id: "INVOICE_PAGE_TITLE" })}
          </PageTitle>
          <EditInvoice />
        </Route>
        <Redirect
          from="/sales/invoices"
          exact={true}
          to="/sales/invoices/overview"
        />
        <Redirect to="/sales/invoices/overview" />
      </Switch>
    </div>
  );
};

export default InvoicesPage;
