import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";

import { SVGICON } from "../../../../../_metronic/helpers";
import { useLang } from "../../../../../_metronic/i18n/Metronici18n";

import CloseIcon from "../../../../../_metronic/assets/icons/close.svg";
import SearchIcon from "../../../../../_metronic/assets/icons/search.svg";
import PlusIcon from "../../../../../_metronic/assets/icons/plus.svg";
import NoItemsFound from "../../../../sharedComponents/NoItemsFound/NoItemsFound";

import Tabs from "../../../../sharedComponents/tabs/Tabs";
import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";
import Checkbox from "../../../../sharedComponents/Checkbox/Checkbox";
import SimpleLoader from "../../../../sharedComponents/Loader/SimpleLoader";
import Pagination from "../../../../sharedComponents/pagination/Pagination";
import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import SalesOfferListDropdown from "./OfferListDropdown";

import { RootState } from "../../../../../setup";
import { actions } from "../redux/SalesOffersredux";

import {
  massDeletesalesoffers,
  getSalesOffersStatus,
  getSalesOffersTrashList,
  massForceDeleteOffers,
  restoresalesOffers,
  massRestoresalesOffers,
} from "../redux/OffersApi";
import { getSalesSettings } from "../../../salesMasterData/SalesSettings/redux/SalesSettingsApi";

import {
  OfferType,
  SalesPaginationType,
  OfferExternalItemType,
  SaleItem,
} from "../types/OfferTypes";

const SalesOfferOverview = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const local = useLang();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const offers = useSelector<RootState>(
    // @ts-ignore
    ({ salesOffers }) => salesOffers.offersList
  ) as OfferType[];

  const offersSearchText = useSelector<RootState>(
    // @ts-ignore
    ({ salesOffers }) => salesOffers.offersSearchText
  ) as string;

  const activePageData = useSelector<RootState>(
    // @ts-ignore
    ({ salesOffers }) => salesOffers.activePageDetails as number
  );

  const offersPaginationData = useSelector<RootState>(
    // @ts-ignore
    ({ salesOffers }) => salesOffers.offersPagination
  ) as SalesPaginationType;

  const offersTabActiveIndex = useSelector<RootState>(
    // @ts-ignore
    ({ salesOffers }) => salesOffers.offersTabActiveIndex as number
  );

  const [tabActiveIndex, setTabActiveIndex] = useState<number>(
    //@ts-ignore
    offersTabActiveIndex || 0
  );

  const offerSearchText = tabActiveIndex === 5 ? "" : offersSearchText;
  const [offersList, setOffersList] = useState<OfferType[]>([]);
  const [offersPagination, setOffersPagination] =
    useState<SalesPaginationType>(offersPaginationData);
  const [searchText, setSearchText] = useState<string>(offerSearchText);
  const [checkedSalesOffers, setCheckedSalesOffers] = useState<OfferType[]>([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(
    //@ts-ignore
    Array.isArray(activePageData) ? 1 : activePageData || 1
  );

  const [status, setStatus] = useState<string>(
    offersTabActiveIndex === 1
      ? "draft"
      : offersTabActiveIndex === 2
        ? "open"
        : offersTabActiveIndex === 3
          ? "accept"
          : offersTabActiveIndex === 4
            ? "reject"
            : "all"
  );

  // Custom Pagination Items
  const customPaginationItems = JSON.parse(
    intl.formatMessage({ id: "TASKS_NUMBER_ITEMS_PER_PAGE_OPTIONS" })
  ) as any[];

  const [itemsPerPage, setItemsPerPage] = useState(
    customPaginationItems[0].value
  );
  const [headerOfferID, setOfferHeaderID] = useState<any>();
  const [headerInvoiceID, setHeaderInvoiceID] = useState<any>();
  const [getOffersListAPICompleted, setGetOffersListAPICompleted] =
    useState<boolean>(false);

  const getOffersListAPI = (
    statusParam?: any,
    offersSearchTextParam?: string,
    limit?: number,
    page?: number
  ) => {
    if (page) {
      dispatch(actions.setOffersActivePageDetails(page));
    }
    setGetOffersListAPICompleted(false);
    setOffersList([]);
    getSalesOffersStatus(
      statusParam,
      offersSearchTextParam,
      limit ? limit : 50,
      page
    )
      .then(({ data: { salesOfferDetails, paginationDetails } }) => {
        setActivePage(page ? page : activePage);
        setOffersPagination(paginationDetails);
        setOffersList(salesOfferDetails);
        dispatch(
          actions.setOffersActivePageDetails(paginationDetails.current_page)
        );

        dispatch(actions.setOffersList(salesOfferDetails));
        dispatch(actions.setOffersPagination(paginationDetails));
      })
      .catch((error) => console.error(error?.response?.data?.message))
      .finally(() => {
        setGetOffersListAPICompleted(true);
      });
  };

  const getOffersTrashListAPI = () => {
    setGetOffersListAPICompleted(false);
    getSalesOffersTrashList()
      .then(({ data: { salesOfferDetails = [] } }) => {
        dispatch(actions.setOffersList(salesOfferDetails));

        setOffersList(salesOfferDetails);
      })
      .catch((error) => console.error(error?.response?.data?.message))
      .finally(() => {
        setGetOffersListAPICompleted(true);
      });
  };

  const updateTrashOffersList = () => {
    const updateTrashOffers = offers?.filter((item) =>
      (
        (item?.sales_details?.to_name || "") +
        (item?.sales_details?.from_name || "")
      )
        .toLocaleLowerCase()
        .includes((searchText || "").toLocaleLowerCase())
    );
    setOffersList(updateTrashOffers);
  };

  useEffect(() => {
    checkedSalesOffers.length === 0 && setCheckAllItems(false);
    checkedSalesOffers.length !== 0 &&
      checkedSalesOffers.length === offersList.length &&
      setCheckAllItems(true);
  }, [checkedSalesOffers]);

  useEffect(() => {
    offersList.length === 0 && setCheckedSalesOffers([]);

    return () => {
      setCheckedSalesOffers([]);
    };
  }, [offersList]);

  const onPageClick = (page: number) => {
    setActivePage(page);
    dispatch(actions.setOffersActivePageDetails(page));
  };

  useEffect(() => {
    if (tabActiveIndex === 5) {
      setGetOffersListAPICompleted(false);
      getOffersTrashListAPI();
    } else {
      getOffersListAPI(status, searchText, itemsPerPage, activePage);
    }
  }, [itemsPerPage, activePage]);

  const onSearchTextChange = (text: string) => {
    setSearchText(text || "");
  };

  const handleGetOrderSettings = () => {
    getSalesSettings()
      .then(
        ({
          data: {
            settingsDetails: { salesSettingsDetails },
          },
        }) => {
          let headerFooterId = salesSettingsDetails?.find(
            (item) => item.id === 1
          )?.answer;

          setOfferHeaderID(headerFooterId);

          let headerInvoiceFooterId = salesSettingsDetails?.find(
            (item) => item.id === 3
          )?.answer;
          setHeaderInvoiceID(headerInvoiceFooterId);
        }
      )
      .catch((error) => console.error(error?.response?.data?.message));
  };

  useEffect(() => {
    handleGetOrderSettings();
  }, []);

  const onTabChange = (index: number) => {
    if (index !== tabActiveIndex) {
      dispatch(actions.setOffersTabActiveIndex(index));
      setSearchText("");
      dispatch(actions.setOffersSearchText(""));
      setActivePage(1);
      setTabActiveIndex(index);
      setOffersList([]);
      if (index === 1) {
        setStatus("draft");
        getOffersListAPI("draft", "", itemsPerPage, 1);
        return;
      } else if (index === 2) {
        setStatus("open");
        getOffersListAPI("open", "", itemsPerPage, 1);
        return;
      } else if (index === 3) {
        setStatus("accept");
        getOffersListAPI("accept", "", itemsPerPage, 1);
        return;
      } else if (index === 4) {
        setStatus("reject");
        getOffersListAPI("reject", "", itemsPerPage, 1);
        return;
      } else if (index === 5) {
        getOffersTrashListAPI();
        return;
      } else {
        setStatus("all");
        getOffersListAPI("all", "", itemsPerPage, 1);
      }
    }
  };

  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedItems =
    offersList && offersList.length > itemsPerPage
      ? offersList.slice(startIndex, startIndex + itemsPerPage)
      : offersList;

  const onAllItemsCheckChange = () => {
    const updatedCheckAllItems = !checkAllItems;
    setCheckAllItems(updatedCheckAllItems);
    if (updatedCheckAllItems) {
      setCheckedSalesOffers(tabActiveIndex !== 5 ? offersList : paginatedItems);
    } else {
      setCheckedSalesOffers([]);
    }
  };

  const deleteSelectedItems = () => {
    const offerIds = checkedSalesOffers.map((salesoffers) => {
      return salesoffers.id;
    });
    setDeleteModalLoading(true);
    massDeletesalesoffers(offerIds)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "SALES_OFFER_DELETE_SUCCESS_MESSAGE" })
        );
        setCheckedSalesOffers([]);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "SALES_OFFER_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOffersListAPI(
          status,
          searchText,
          itemsPerPage,
          paginatedItems?.length === offerIds?.length && activePage > 1
            ? activePage - 1
            : 1
        );
        dispatch(actions.setOffersActivePageDetails(activePage));
      });
  };

  const forceDeleteSelectedItems = () => {
    const ids = checkedSalesOffers.map((item) => {
      return item.id;
    });
    setDeleteModalLoading(true);
    massForceDeleteOffers(ids)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "SALES_OFFER_FORCE_MASS_DELETE_SUCCESS_MESSAGE",
          })
        );
        setCheckedSalesOffers([]);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "SALES_OFFER_FORCE_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOffersTrashListAPI();
        setCheckAllItems(false);
      });
  };

  const onCheckedChange = (offer: OfferType) => {
    let offersCopy = _.clone(checkedSalesOffers);

    const index = offersCopy.findIndex((item) => item.id === offer.id);
    if (index > -1) {
      offersCopy.splice(index, 1);
      setCheckAllItems(false);
    } else {
      offersCopy.push(offer);
    }
    setCheckedSalesOffers(offersCopy);
  };

  const isChecked = (offer: OfferType) => {
    const index = checkedSalesOffers.findIndex((item) => item.id === offer.id);
    if (index > -1) {
      return true;
    }
    return false;
  };

  const handleSearch = () => {
    dispatch(actions.setOffersSearchText(""));
    setSearchText("");
    getOffersListAPI(status, "", itemsPerPage, 1);
  };

  const handleRestoreOffer = (salesOffer: OfferType) => {
    restoresalesOffers(salesOffer.id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "SALES_OFFER_RESTORE_SUCCESS_MESSAGE" })
        );
        setCheckedSalesOffers([]);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "SALES_OFFER_RESTORE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getOffersTrashListAPI();
        setCheckAllItems(false);
      });
  };

  const restoreSelectedItems = () => {
    const ids = checkedSalesOffers.map((item) => {
      return item.id;
    });
    setDeleteModalLoading(true);
    massRestoresalesOffers(ids)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "SALES_OFFER_MASS_RESTORE_SUCCESS_MESSAGE" })
        );
        setCheckedSalesOffers([]);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "SALES_OFFER_MASS_RESTORE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOffersTrashListAPI();
        setCheckAllItems(false);
      });
  };

  useEffect(() => {
    if (
      tabActiveIndex !== 5 &&
      offerSearchText?.length > 2 &&
      searchText.length === 0
    ) {
      onSearchTextChange("");
      dispatch(actions.setOffersSearchText(""));
    }

    if (tabActiveIndex === 5 && searchText.length === 0) {
      setSearchText("");
      setOffersList(offers);
    }
  }, [searchText, offerSearchText]);

  const getClassName = (status: string) => {
    if (status === "draft") {
      return "draft";
    } else if (status === "open") {
      return "open";
    } else if (status === "accept") {
      return "text-bg-success";
    } else if (status === "reject") {
      return "text-bg-danger text-white";
    }
  };

  const getInternalInitialValues = (internalItems: any) => {
    let arr: any[] = [];
    internalItems?.forEach((item: any) => {
      arr.push({
        id: item?.id,
        title: item?.name,
        description: item?.product_description || "",
        quantity: item?.quantity || 1,
        price: item?.unit_price ? (item?.unit_price / 100).toFixed(2) : null,
        tax_percentage: item?.tax_percentage || null,
        discount_percentage: item?.discount_percentage,
      });
    });

    return arr;
  };

  const getProductPrice = (item: OfferExternalItemType) => {
    if (!item.price) {
      return 0;
    }

    const tax_behaviour = "inclusive";

    let initialPrice =
      Number(item.price) * Number(item.quantity ? item.quantity : 1);
    let discountAmount = item.discount_percentage
      ? initialPrice * (Number(item.discount_percentage) / 100)
      : 0;
    let discountedPrice = initialPrice - discountAmount;

    let taxAmount = item.tax_percentage
      ? discountedPrice * (Number(item.tax_percentage) / 100)
      : 0;

    return discountedPrice + (tax_behaviour === "inclusive" ? 0 : taxAmount);
  };

  const getTotalAmount = (sale_items: SaleItem[]) => {
    let result = 0;
    [...getInternalInitialValues(sale_items)]?.forEach((item) => {
      result += getProductPrice(item);
    });

    return Number(result.toFixed(2));
  };

  return (
    <div className="card mt-7">
      <div className="card-header border-0 pt-8 d-flex flex-row justify-content-between">
        <div className="d-flex align-items-center">
          <div className="position-relative">
            {(tabActiveIndex !== 5
              ? offerSearchText && offerSearchText.length >= 3
              : searchText && searchText.length >= 3) && (
              <div
                className="position-absolute"
                style={{
                  right: 8,
                  top: 10,
                  zIndex: 99,
                }}
                role={"button"}
              >
                <span
                  onClick={() => {
                    if (tabActiveIndex === 6) {
                      setSearchText("");
                      setOffersList(offers);
                    } else {
                      handleSearch();
                    }
                  }}
                >
                  <SVGICON src={CloseIcon} className="svg-icon svg-icon-1 " />
                </span>
              </div>
            )}
            <input
              type="text"
              className="form-control form-control-solid w-250px position-relative"
              placeholder={intl.formatMessage({
                id: "INVOICES_SEARCH_PLACEHOLDER",
              })}
              value={searchText}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
                if (offerSearchText.length > 0 && e.target.value.length === 0) {
                  handleSearch();
                }
              }}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                paddingRight: "30px",
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (searchText && searchText.length >= 3) {
                    if (tabActiveIndex === 5) {
                      setSearchText(searchText);
                      updateTrashOffersList();
                    } else {
                      dispatch(actions.setOffersActivePageDetails(1));
                      dispatch(actions.setOffersSearchText(searchText));
                      setSearchText(searchText);
                      getOffersListAPI(status, searchText, itemsPerPage, 1);
                    }
                  }
                }
              }}
            />
          </div>

          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              if (searchText && searchText.length >= 3) {
                if (tabActiveIndex === 5) {
                  setSearchText(searchText);
                  updateTrashOffersList();
                } else {
                  dispatch(actions.setOffersActivePageDetails(1));
                  dispatch(actions.setOffersSearchText(searchText));
                  setSearchText(searchText);
                  getOffersListAPI(status, searchText, itemsPerPage, 1);
                }
              }
            }}
            style={{
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              padding: "11px",
            }}
          >
            <SVGICON src={SearchIcon} className="svg-icon svg-icon-2" />
          </button>
        </div>

        <div className="d-flex justify-content-end align-items-center">
          {offerSearchText && offerSearchText.length > 0 ? (
            <button
              id="filter"
              className="btn btn-light-primary btn-active-light-primary  symbol  btn-sm text-primary mx-2 d-none d-sm-block d-sm-none d-md-block"
              style={{ borderRadius: "20px" }}
              onClick={() => {
                dispatch(actions.setOffersSearchText(""));
                setSearchText("");
                getOffersListAPI(status, "", itemsPerPage, 1);
                setActivePage(1);
              }}
            >
              <SVGICON src={CloseIcon} className="svg-icon-3" />
              {offerSearchText ? offerSearchText : ""}
            </button>
          ) : null}

          {checkedSalesOffers.length > 0 &&
          (tabActiveIndex === 1 || tabActiveIndex === 5) ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4 d-none d-sm-block d-sm-none d-md-block">
                {checkedSalesOffers.length}{" "}
                {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn btn-danger me-2 d-flex align-items-center"
                onClick={() => {
                  if (tabActiveIndex === 1) {
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "SALES_OFFER_DELETE_CONFIRM_MESSAGE",
                      }),
                      deleteSelectedItems,
                      intl.formatMessage({ id: "COMMON_YES_DELETE" })
                    );
                  }
                  if (tabActiveIndex === 5) {
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "SALES_OFFER_DELETE_PERMANENT_CONFIRM_MESSAGE",
                      }),
                      forceDeleteSelectedItems,
                      intl.formatMessage({ id: "COMMON_YES_DELETE" })
                    );
                  }
                }}
              >
                <i className="bi bi-trash3-fill fs-6"></i>
                {intl.formatMessage({ id: "COMMON_DELETE" })}
              </button>
              {tabActiveIndex === 5 && (
                <button
                  className={clsx("btn", "btn-success ms-2")}
                  onClick={() => {
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "SALES_OFFER_RESTORE_CONFIRM_MESSAGE",
                      }),
                      restoreSelectedItems,
                      intl.formatMessage({
                        id: "COMMON_YES_RESTORE",
                      })
                    );
                  }}
                >
                  {intl.formatMessage({ id: "COMMON_RESTORE_SELECTED" })}
                </button>
              )}
            </>
          ) : (
            tabActiveIndex === 0 && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  history.push(`/sales/offers/edit`, {
                    offer: {
                      id: null,
                      sales_id: null,
                      headerOfferID: headerOfferID,
                      headerInvoiceID: headerInvoiceID,
                    },
                  });
                }}
              >
                <SVGICON src={PlusIcon} className="svg-icon-2" />
                <span className="align-middle">
                  {intl.formatMessage({
                    id: "SALES_OFFER_CREATE_NEW_SALES_OFFER",
                  })}
                </span>
              </button>
            )
          )}
        </div>
      </div>
      {searchText && searchText.length > 0 && searchText.length < 3 && (
        <div className="text-danger " style={{ padding: "0 2.25rem" }}>
          <span role="alert">
            {intl.formatMessage({
              id: "COURSES_LIST_SEARCH_VALIDATION_MESSAGE",
            })}
          </span>
        </div>
      )}

      <div className="card-body py-3">
        <div className="mt-2 mb-6">
          <Tabs
            tabs={[
              intl.formatMessage({ id: "INVOICES_PAYMENT_ALL_TAB_TITLE" }),
              intl.formatMessage({ id: "INVOICES_PAYMENT_DRAFT_TAB_TITLE" }),
              intl.formatMessage({ id: "INVOICES_PAYMENT_OPEN_TAB_TITLE" }),
              intl.formatMessage({ id: "ORDERS_ACCEPT_TAB_TITLE" }),
              intl.formatMessage({ id: "ORDERS_REJECT_TAB_TITLE" }),
              intl.formatMessage({ id: "ORDERS_TRASH_TAB_TITLE" }),
            ]}
            activeIndex={tabActiveIndex}
            onActiveIndexChange={(index) => {
              setCheckedSalesOffers([]);
              onTabChange(index);
            }}
          />
        </div>
        <div className="table-responsive table_actions_overflow">
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                {(tabActiveIndex === 1 || tabActiveIndex === 5) && (
                  <th className="w-25px">
                    <Checkbox
                      onChange={onAllItemsCheckChange}
                      checked={checkAllItems}
                    />
                  </th>
                )}
                {tabActiveIndex !== 5 && (
                  <th className="min-w-125px text-start">
                    {intl.formatMessage({
                      id: "INVOICES_STATUS_TITLE",
                    })}
                  </th>
                )}
                <th className="min-w-125px text-center">
                  {intl.formatMessage({
                    id: "INVOICES_DATE_TITLE",
                  })}
                </th>
                <th className="min-w-150px" style={{ textAlign: "center" }}>
                  {intl.formatMessage({
                    id: "INVOICES_TO_DETAILS",
                  })}
                </th>
                <th className="min-w-125px text-center">
                  {intl.formatMessage({
                    id:
                      tabActiveIndex !== 1 && tabActiveIndex !== 5
                        ? "OFFERS_OFFER_NUNBER"
                        : "INVOICES_FINAL_AMOUNT",
                  })}
                </th>
                {tabActiveIndex !== 1 && tabActiveIndex !== 5 && (
                  <th className="min-w-125px text-center">
                    {intl.formatMessage({
                      id: "INVOICES_FINAL_AMOUNT",
                    })}
                  </th>
                )}
                {tabActiveIndex === 1 || (tabActiveIndex === 5 && <th></th>)}
              </tr>
            </thead>
            <tbody>
              {paginatedItems
                ?.sort(
                  (a, b) => Number(b.offers_number) - Number(a.offers_number)
                )
                .map((offer, i) => {
                  return (
                    <tr
                      key={i}
                      className={`${
                        tabActiveIndex !== 5 &&
                        "cursor-pointer bg-hover-lighten"
                      }`}
                    >
                      {(tabActiveIndex === 1 || tabActiveIndex === 5) && (
                        <td>
                          <div
                            className="form-check form-check-sm form-check-custom form-check-solid me-2"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Checkbox
                              onChange={() => {
                                onCheckedChange(offer);
                              }}
                              checked={isChecked(offer)}
                            />
                          </div>
                        </td>
                      )}

                      {tabActiveIndex !== 5 && (
                        <td
                          onClick={() => {
                            if (tabActiveIndex !== 5) {
                              history.push(`/sales/offers/edit`, {
                                offer,
                              });
                            }
                          }}
                        >
                          <span
                            className={`${getClassName(
                              offer?.status
                            )} badge text-uppercase`}
                          >
                            {offer?.status}
                          </span>
                        </td>
                      )}
                      <td
                        onClick={() => {
                          if (tabActiveIndex !== 5) {
                            history.push(`/sales/offers/edit`, {
                              offer,
                            });
                          }
                        }}
                      >
                        {offer?.offer_date ? (
                          <div className="d-flex  flex-column flex-wrap align-items-center">
                            <div className="mb-2 text-gray-600 fw-bold">
                              {local === "de"
                                ? moment(offer?.offer_date).format("DD.MM.yyyy")
                                : moment(offer?.offer_date).format(
                                    "YYYY-MM-DD"
                                  )}
                            </div>
                          </div>
                        ) : null}
                      </td>
                      <td
                        onClick={() => {
                          if (tabActiveIndex !== 5) {
                            history.push(`/sales/offers/edit`, {
                              offer,
                            });
                          }
                        }}
                        className="text-center"
                      >
                        <div className="d-flex justify-content-start flex-column fw-bold text-gray-600 fs-7">
                          <span
                            className="text-gray-800 fw-bold fs-6 mb-1"
                            style={{ wordBreak: "break-word" }}
                          >
                            {offer?.sales_details?.to_firstname &&
                            offer?.sales_details.to_lastname ? (
                              <>
                                {offer?.sales_details?.to_firstname}{" "}
                                {offer?.sales_details.to_lastname}
                              </>
                            ) : (
                              "-"
                            )}
                          </span>
                          <span className="">
                            {offer?.sales_details?.to_company_name
                              ? offer?.sales_details?.to_company_name
                              : "-"}
                          </span>
                          <span className="fw-bold text-gray-600 fs-7">
                            {offer?.sales_details?.to_address
                              ? offer?.sales_details?.to_address +
                                (offer?.sales_details?.to_city ? "," : ".")
                              : ""}
                          </span>
                          <span className="fw-bold text-gray-600 fs-7">
                            {offer?.sales_details?.to_city
                              ? offer?.sales_details?.to_city +
                                (offer?.sales_details?.to_zipcode ? "," : ".")
                              : ""}
                            {offer?.sales_details?.to_zipcode
                              ? offer?.sales_details?.to_zipcode + "."
                              : ""}
                          </span>
                        </div>
                      </td>
                      <td className="text-center">
                        {tabActiveIndex !== 1 && tabActiveIndex !== 5 ? (
                          <div className="d-flex flex-column gap-2 align-items-center">
                            <span
                              className="text-gray-600 fw-bold text-center"
                              onClick={() => {
                                navigator.clipboard
                                  // @ts-ignore
                                  ?.writeText(offer?.final_offer_number)
                                  .then(() => {
                                    successToast(
                                      intl.formatMessage({
                                        id: "FILEMANAGER_FILE_LINK_COPY_SUCCESS_MESSAGE",
                                      })
                                    );
                                  })
                                  .catch((error) => console.error(error));
                              }}
                            >
                              {offer?.final_offer_number
                                ? offer?.final_offer_number
                                : "-"}
                            </span>
                          </div>
                        ) : (
                          <span className="text-gray-600 fw-bold ">
                            {getTotalAmount(
                              offer?.sales_details?.sale_items
                            )?.toLocaleString(local, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{" "}
                            <span className="ms-2">€</span>
                          </span>
                        )}
                      </td>
                      {tabActiveIndex !== 1 && tabActiveIndex !== 5 && (
                        <td
                          onClick={() => {
                            if (tabActiveIndex !== 5) {
                              history.push(`/sales/offers/edit`, {
                                offer,
                              });
                            }
                          }}
                          className="text-center"
                        >
                          <span className="text-gray-600 fw-bold ">
                            {getTotalAmount(
                              offer?.sales_details?.sale_items
                            )?.toLocaleString(local, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{" "}
                            <span className="ms-2">€</span>
                          </span>
                        </td>
                      )}
                      <td>
                        {tabActiveIndex !== 4 ? (
                          <SalesOfferListDropdown
                            offer={offer}
                            getOffersListAPI={getOffersListAPI}
                            tabActiveIndex={tabActiveIndex}
                            status={status}
                            itemsPerPage={itemsPerPage}
                            activePage={activePage}
                            offerSearchText={offerSearchText}
                            getOffersTrashListAPI={getOffersTrashListAPI}
                            handleRestoreOffer={handleRestoreOffer}
                            paginatedItems={paginatedItems}
                          />
                        ) : null}
                      </td>
                    </tr>
                  );
                })}

              {/* loader */}
              {!getOffersListAPICompleted && paginatedItems.length === 0 && (
                <tr>
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <SimpleLoader />
                  </td>
                </tr>
              )}

              {/* no data */}
              {getOffersListAPICompleted && paginatedItems.length === 0 && (
                <NoItemsFound languageKey="NO_ITEMS_FOUND" />
              )}
            </tbody>
          </table>

          {(tabActiveIndex !== 5
            ? offersPagination?.number_of_pages
            : offersList.length > 0) && (
            <Pagination
              totalPages={
                tabActiveIndex !== 5
                  ? offersPagination?.number_of_pages || 1
                  : Math.ceil(Number(offersList.length) / itemsPerPage)
              }
              itemsPerPage={itemsPerPage}
              showItemsPerPage
              setItemsPerPage={(count) => setItemsPerPage(count)}
              activePage={activePage}
              onPageClick={onPageClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesOfferOverview;
