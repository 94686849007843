import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Modal } from "react-bootstrap";
import TextInput from "../../../../sharedComponents/TextInput/TextInput";

interface Props {
  show: boolean;
  closeModal: () => void;
  initialValue: string;
  formik: any;
}

const CreateCustomerModal: FC<Props> = ({
  show,
  closeModal,
  initialValue,
  formik,
}) => {
  const intl = useIntl();

  const nameInitialValues = {
    firstName: initialValue?.split(" ")?.[0] || "",
    lastName: initialValue?.split(" ")?.slice(1)?.join(" ") || "",
    email: "",
  };

  const nameSchema = Yup.object().shape({
    firstName: Yup.string().required(
      intl.formatMessage({ id: "USERS_FIRSTNAME_REQUIRED" })
    ),
    lastName: Yup.string().required(
      intl.formatMessage({ id: "USERS_LASTNAME_REQUIRED" })
    ),
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: "AUTH_FORGOT_PASSWORD_EMAIL_VALIDATION_MESSAGE",
        })
      )
      .nullable()
      .required(
        intl.formatMessage({ id: "PARTNERS_ACTIVITIES_EMAIL_IS_REQUIRED" })
      ),
  });
  const formik2 = useFormik({
    initialValues: nameInitialValues,
    validationSchema: nameSchema,
    onSubmit: async (values) => {
      await formik.setFieldValue(
        "to_name",
        values.firstName + " " + values.lastName
      );
      await formik.setFieldValue("to_email", values.email);
      formik.handleSubmit();
    },
  });

  return (
    <Modal
      show={show}
      centered
      dialogClassName="large-size-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({
            id: "PARTNERS_CONTACTTYPES_ADD_CONTACT_BUTTON",
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 m-0">
        <div className="px-10 py-6">
          <div className="d-flex flex-column gap-6">
            <div className="w-100">
              <div className="mb-5">
                <label className="form-label">
                  {intl.formatMessage({
                    id: "USERS_FIRSNAME_FIELD",
                  })}
                </label>
                <TextInput
                  value={formik2.values.firstName || ""}
                  onChange={(value) => {
                    formik2.setFieldValue("firstName", value);
                  }}
                />
                {formik2.errors.firstName && (
                  <span className="text-danger">
                    {formik2.errors.firstName}
                  </span>
                )}
              </div>
              <div className="mb-5">
                <label className="form-label">
                  {intl.formatMessage({
                    id: "USERS_LASTNAME_FIELD",
                  })}
                </label>
                <TextInput
                  value={formik2.values.lastName || ""}
                  onChange={(value) => {
                    formik2.setFieldValue("lastName", value);
                  }}
                />
                {formik2.errors.lastName && (
                  <span className="text-danger">{formik2.errors.lastName}</span>
                )}
              </div>
              <div className="mb-5">
                <label className="form-label">
                  {intl.formatMessage({
                    id: "USERS_EMAIL_FIELD",
                  })}
                </label>
                <TextInput
                  value={formik2.values.email || ""}
                  onChange={(value) => {
                    formik2.setFieldValue("email", value);
                  }}
                />
                {formik2.errors.email && (
                  <span className="text-danger">{formik2.errors.email}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-center p-2">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            formik2.handleSubmit();
          }}
        >
          {intl.formatMessage({
            id: "SUBMIT_BUTTON",
          })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCustomerModal;
