import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../../sharedComponents/closeButton/CloseButton";
import "./style.scss";

interface Props {
  show: boolean;
  closeModal: () => void;
  pdfURL: string;
}

const OfferPreviewModal: FC<Props> = ({ show, closeModal, pdfURL }) => {
  const intl = useIntl();
  return (
    <Modal size="xl" show={show} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({
            id: "ORDER_EDIT_PREVIEW_BUTTON_TITLE",
          })}
        </Modal.Title>
        <CloseButton onClose={closeModal} />
      </Modal.Header>
      <>
        <Modal.Body className="full_modal_height">
          <div
            className="d-flex flex-row justify-content-center"
            style={{ height: "100%" }}
          >
            <iframe src={pdfURL} height="100%" width="100%" title={""}></iframe>
          </div>
        </Modal.Body>
      </>
    </Modal>
  );
};

export default OfferPreviewModal;
