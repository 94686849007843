import clsx from "clsx";
import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSelectPayment: (option: string) => void;
}

const SelectPaymentModal: FC<Props> = ({
  show,
  closeModal,
  onSelectPayment,
}) => {
  const intl = useIntl();

  const [selectedTemplateType, setSelectedTemplateType] = useState<
    "single_payment" | "partial_payment"
  >();
  return (
    <Modal
      show={show}
      centered
      dialogClassName={"medium-size-modal"}
      // contentClassName={loading ? 'pe-none' : ''}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({
            id: "PAYMENT_METHOD_SELECTION",
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <button onClick={() => onSelectPayment('single_payment')}>full payment</button>
        <button onClick={() => onSelectPayment('partial_payment')}>PARTIALpayment</button> */}

        <div className="p-4">
          <div className="row">
            <div
              className=" d-flex mt-8 gap-4"
              style={{ borderColor: "#e4e6ef" }}
            >
              {/* offers */}
              <div
                className={clsx(
                  "form-check p-4 py-8 form-check-custom form-check-solid flex-1 notice d-flex  rounded cursor-pointer bg-hover-darken  border border-dashed",
                  selectedTemplateType === "single_payment"
                    ? "bg-light-primary border-primary"
                    : ""
                )}
                role={"button"}
                onClick={() => {
                  //   setSelectedTemplateType('product')
                  //   selectedTemplateType !== 'product'
                  onSelectPayment("single_payment");
                }}
              >
                {/* <input
                  className='form-check-input me-3'
                  name='user_role'
                  type='radio'
                  checked={selectedTemplateType === 'single_payment'}
                  onChange={() => {}}
                /> */}
                <label className="form-check-label">
                  <div className="fs-1 fw-bolder">
                    <span className="me-1">
                      {intl.formatMessage({
                        id: "SINGLE_PAYMENT_TITLE",
                      })}
                    </span>
                  </div>
                  {/* <div className='fs-7' role={'button'}>
                    {intl.formatMessage({
                      id: 'EVENT_PHASE_PRODUCT_FLOW_MESSAGE',
                    })}
                  </div> */}
                </label>
              </div>
              {/* custom */}
              <div
                className={clsx(
                  "form-check p-4 py-8 form-check-custom form-check-solid flex-1 notice d-flex  rounded  cursor-pointer bg-hover-darken border border-dashed",
                  selectedTemplateType === "partial_payment"
                    ? "bg-light-primary border-primary"
                    : ""
                )}
                role={"button"}
                onClick={() => {
                  //   setSelectedTemplateType('offers')
                  //   selectedTemplateType !== 'offers'

                  onSelectPayment("partial_payment");
                }}
              >
                {/* <input
                  className='form-check-input me-3'
                  name='user_role'
                  type='radio'
                  checked={selectedTemplateType === 'partial_payment'}
                  onChange={() => {}}
                /> */}
                <label className="form-check-label">
                  <div className="fs-1 fw-bolder">
                    {intl.formatMessage({
                      id: "PARTIAL_PAYMENT_TITLE",
                    })}
                  </div>
                  {/* <div className='fs-7'>
                    {intl.formatMessage({
                      id: 'PAYMENT_EVENT_PHASE_OFFERS_FLOW_MESSAGE',
                    })}
                  </div> */}
                </label>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({
            id: "CLOSE_BUTTON",
          })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectPaymentModal;
