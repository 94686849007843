import clsx from "clsx";
import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import notFound from "../../../../_metronic/assets/icons/notFound.png";

import _ from "lodash";
// @ts-ignore
import DragSortableList from "react-drag-sortable";

// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import Constants from "../../../config/Constants";
import SelectFileModal from "../../../sharedComponents/selectFileModal/selectFileModal";
import { FileType } from "../../filemanager/types/getFilemanagerDataResponseType";
import { actions } from "../redux";
import {
  createNewCategory,
  deleteCategory,
  getCategoriesList,
  massDeleteCategories,
  sortCategories,
  updateCategory,
} from "../redux/CategoriesAPI";
import { GroupType } from "../types/GetGroupsListResponseType";
import { sortGroupsParams } from "../types/request";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

interface ModelComponentProps {
  ModalProps: CategoryModalProps;
  closeModal: () => void;
  getCategoriesListAPI: () => void;
}

interface CategoryModalProps {
  show: boolean;
  type?: string;
  category?: GroupType;
}

// interface FormValuesType {
//   categoryName?: string | undefined
// }

interface SortedObject {
  category: GroupType;
}

interface Props {
  type?: string;
  onSelectCategory?: (category: GroupType[]) => void;
  loading?: boolean;
  isSelectionModal?: boolean;
}

const CategoriesModal: FC<ModelComponentProps> = ({
  ModalProps,
  closeModal,
  getCategoriesListAPI,
}) => {
  const { show, type, category } = ModalProps;
  const [seoPictureFile, setSeoPictureFile] = useState<FileType>();
  const [showFileSelectModal, setShowFileSelectModal] =
    useState<boolean>(false);
  //@ts-ignore
  const [currPage, setCurrPage] = useState<GroupType>(category);
  const intl = useIntl();
  const AddNewCategorySchema = Yup.object().shape({
    categoryName: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(
        intl.formatMessage({ id: "MASTERDATA_CATEGORY_CATEGORY_NAME_REQUIRED" })
      ),
  });
  // state
  const [loading, setLoading] = useState(false);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "categories"
  );

  const initialValues = {
    categoryName: type === "edit" ? category?.name : "",
    // seo_title: type === "edit" ? category?.seo_title : "",
    // seo_description: type === "edit" ? category?.seo_description : "",
    // is_visible: type === "edit" ? category?.is_visibility : "",
  };

  const closeMainFileModal = () => {
    setShowFileSelectModal(false);
  };

  const onFileSelect = (file: any) => {
    closeMainFileModal();
    setSeoPictureFile(file);
  };

  useEffect(() => {
    // @ts-ignore
    setCurrPage(category);

    return () => {
      setSeoPictureFile(undefined);
    };
  }, [category]);

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewCategorySchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (type === "new") {
        if (values.categoryName) {
          setLoading(true);
          const updatedPictureId = seoPictureFile?.id
            ? seoPictureFile?.id
            : currPage?.seo_picture_id
              ? currPage?.seo_picture_id
              : null;
          createNewCategory(
            values.categoryName
            // //@ts-ignore
            // values.seo_title,
            // values.seo_description,
            // updatedPictureId,
            // values.is_visible ? 1 : 0
          )
            .then(() => {
              closeModal();
              successToast(
                intl.formatMessage({
                  id: "MASTERDATA_CATEGORIES_CREATE_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "MASTERDATA_CATEGORIES_CREATE_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get categories list API
              getCategoriesListAPI();
              setLoading(false);
            });
        }
      } else {
        if (values.categoryName && category) {
          setLoading(true);
          const updatedPictureId = seoPictureFile?.id
            ? seoPictureFile?.id
            : currPage?.seo_picture_id
              ? currPage?.seo_picture_id
              : null;
          updateCategory(
            category.id,
            values.categoryName //@ts-ignore
            // values.seo_title,
            // values.seo_description,
            // updatedPictureId,
            // values.is_visible ? 1 : 0
          )
            .then(() => {
              closeModal();
              successToast(
                intl.formatMessage({
                  id: "MASTERDATA_CATEGORIES_EDIT_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "MASTERDATA_CATEGORIES_EDIT_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get categories list API
              getCategoriesListAPI();
              setLoading(false);
            });
        }
      }
    },
  });

  return (
    <Modal
      show={show}
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      centered
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "MASTERDATA_EDIT_CATEGORY_TITLE" })
            : intl.formatMessage({
                id: "MASTERDATA_CREATE_NEW_CATEGORY_TITLE",
              })}
        </Modal.Title>
      </Modal.Header>
      {showFileSelectModal && (
        <SelectFileModal
          show={showFileSelectModal}
          closeModal={closeMainFileModal}
          onSelectFile={onFileSelect}
          allowedMimetypes={Constants.mimeTypes.image}
        />
      )}
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_CATEGORY_NAME_FIELD_NAME",
                  })}
                </span>
              </label>
              <input
                {...formik.getFieldProps("categoryName")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                name="categoryName"
                autoComplete="off"
              />
              {formik.touched.categoryName && formik.errors.categoryName && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.categoryName}</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          {((crudPermission?.edit && type === "edit") ||
            (crudPermission?.create && type === "new")) && (
            <Button
              variant="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "MASTERDATA_CATEGORY_LOADING_MESSAGE",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const Categories: React.FC<Props> = ({
  type,
  onSelectCategory,
  loading,
  isSelectionModal,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const categoriesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.categoriesList
  ) as GroupType[];
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  // state
  const [displayCategoriesList, setDisplayCategoriesList] =
    useState(categoriesList);
  const [showCategoriesModal, setShowCategoriesModal] =
    useState<CategoryModalProps>({ show: false });
  const [checkedCategories, setCheckedCategories] = useState<GroupType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllCategories, setCheckAllCategories] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "categories"
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );

  const isSelectCategory = () => {
    return type === "select";
  };

  const getCategoriesListAPI = () => {
    // getGroupsList()
    getCategoriesList()
      .then(({ data: { data } }) => {
        dispatch(actions.setCategoriesList(data));
      })
      .catch((e) => {
        // console.log(`ERROR: getCategoriesListAPI`, e)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateDisplayCategoriesList = () => {
    const updatedCategoriesList = categoriesList.filter((category) => {
      if (
        (category.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayCategoriesList(updatedCategoriesList);
  };

  useEffect(() => {
    setIsLoading(true);
    // get categories API call
    getCategoriesListAPI();
  }, []);

  useEffect(() => {
    updateDisplayCategoriesList();

    return () => {
      setDisplayCategoriesList(categoriesList);
    };
  }, [categoriesList, searchText]);

  useEffect(() => {
    if (
      checkAllCategories &&
      checkedCategories.length !== categoriesList.length
    ) {
      setCheckAllCategories(false);
    }
    if (
      checkedCategories.length > 0 &&
      checkedCategories.length === categoriesList.length
    ) {
      setCheckAllCategories(true);
    }
  }, [checkedCategories]);

  const closeCategoriesModal = () => {
    setShowCategoriesModal({
      show: false,
      type: "new",
      category: undefined,
    });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
    // setActivePage(1);
  };
  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const deleteExistingCategory = (id: number) => {
    setDeleteModalLoading(true);
    deleteCategory(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_CATEGORY_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_CATEGORY_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        closeCategoriesModal();
        setCheckAllCategories(false);
        setCheckedCategories([]);
        // recall  get categories list API
        getCategoriesListAPI();
      });
  };

  const onCategoriesSortChange = (sortedList: Array<SortedObject>) => {
    const newSortedCategoriesArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedCategoriesArr.push({
        id: sortedListItem.category.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing categories API Call
    sortCategories(newSortedCategoriesArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getCategoriesListAPI();
      });
  };

  //
  const onCategoryCheckChange = (category: GroupType) => {
    if (isSelectCategory()) {
      setCheckedCategories([category]);
    } else {
      let checkedCategoriesCopy = _.clone(checkedCategories);
      // check if already exists in the checked list
      const index = checkedCategoriesCopy.findIndex(
        (checkedCategory) => checkedCategory.id === category.id
      );
      // if exists remove
      if (index > -1) {
        checkedCategoriesCopy.splice(index, 1);
      }
      // if doesnt exist push to checked categories
      else {
        checkedCategoriesCopy.push(category);
      }
      setCheckedCategories(checkedCategoriesCopy);
    }
  };

  const isCategoryChecked = (category: GroupType) => {
    const index = checkedCategories.findIndex(
      (checkedCategory) => checkedCategory.id === category.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteMultipleCategories = () => {
    setDeleteModalLoading(true);
    const ids = checkedCategories.map((checkedCategory) => {
      return checkedCategory.id;
    });
    massDeleteCategories(ids)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_CATEGORY_MASS_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_CATEGORY_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getCategoriesListAPI();
        setCheckedCategories([]);
      });
  };

  const onAllCategoriesCheckChange = () => {
    const updatedCheckAllCategories = !checkAllCategories;
    setCheckAllCategories(updatedCheckAllCategories);
    if (updatedCheckAllCategories) {
      setCheckedCategories(categoriesList);
    } else {
      setCheckedCategories([]);
    }
  };

  const CategoryItem = (category: GroupType, index: number) => {
    const {
      showDeleteConfirmModal,
      hideDeleteConfirmModal,
      setDeleteModalLoading,
    } = useGlobalModalContext();
    return (
      <div
        className="card mt-5 p-4 d-flex flex-row align-items-center justify-content-between"
        key={index}
      >
        <div className="d-flex flex-row align-items-center">
          {crudPermission?.delete && (
            <div className="form-check form-check-sm form-check-custom form-check-solid ">
              <input
                onChange={(e) => {
                  onCategoryCheckChange(category);
                }}
                className="form-check-input widget-9-check"
                type="checkbox"
                checked={isCategoryChecked(category)}
              />
            </div>
          )}
          {crudPermission?.edit && (
            <div className="mx-5">
              <SVGICON
                src={DragIcon}
                className="svg-icon-2 svg-icon-hover-primary"
              />
            </div>
          )}

          <div
            className={`fw-bold mx-5 ${!crudPermission?.edit && "no-drag"}`}
            role="button"
            onClick={() => {
              setShowCategoriesModal({
                show: true,
                type: "edit",
                category: category,
              });
            }}
          >
            {category.name}
          </div>
        </div>

        {/* @ts-ignore */}
        {isSelectCategory() ? null : (
          <>
            {(crudPermission?.edit || crudPermission?.delete) && (
              <div>
                {crudPermission?.edit && (
                  <button
                    className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
                    onClick={() => {
                      setShowCategoriesModal({
                        show: true,
                        type: "edit",
                        category: category,
                      });
                    }}
                  >
                    <SVGICON src={EditIcon} className="svg-icon-3" />
                  </button>
                )}
                {crudPermission?.delete && (
                  <button
                    className="btn btn-icon btn-light btn-active-light-primary btn-sm"
                    onClick={() => {
                      showDeleteConfirmModal(
                        intl.formatMessage({
                          id: "MASERDATA_CATEGORY_DELETE_CONFIRM_MESSAGE",
                        }),
                        () => {
                          deleteExistingCategory(category.id);
                        }
                      );
                    }}
                  >
                    <SVGICON src={DeleteIcon} className="svg-icon-3" />
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedCategories =
    displayCategoriesList && displayCategoriesList.length > 10
      ? displayCategoriesList.slice(startIndex, startIndex + itemsPerPage)
      : displayCategoriesList;

  useEffect(() => {
    if (paginatedCategories.length === 0 && displayCategoriesList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedCategories]);

  useEffect(() => {
    ScrollToTop();
  }, [activePage]);

  // draggable categories list
  const list = paginatedCategories
    ? paginatedCategories.map((category, i) => {
        return {
          content: (
            <div
              className={searchText || !crudPermission?.edit ? "no-drag" : ""}
            >
              {CategoryItem(category, i)}
            </div>
          ),
          category: category,
        };
      })
    : [];

  return (
    <>
      <div>
        {/* @ts-ignore */}
        {showCategoriesModal.show && (
          <CategoriesModal
            ModalProps={showCategoriesModal}
            closeModal={closeCategoriesModal}
            getCategoriesListAPI={getCategoriesListAPI}
          />
        )}

        {/* search categories */}
        <div className="card p-4 d-flex flex-row justify-content-between mt-7">
          <div className="d-flex flex-row search-container">
            {isSelectCategory() ? null : (
              <>
                {crudPermission?.delete && (
                  <div className="form-check form-check-sm form-check-custom form-check-solid ">
                    <input
                      onChange={onAllCategoriesCheckChange}
                      className="form-check-input widget-9-check"
                      type="checkbox"
                      checked={checkAllCategories}
                    />
                  </div>
                )}
              </>
            )}
            <div className="d-flex align-items-center">
              <SVGICON
                src={SearchIcon}
                className="svg-icon svg-icon-1 position-absolute ms-6"
              />
              <input
                type="text"
                className="form-control form-control-solid w-250px ps-15 mx-4"
                placeholder={intl.formatMessage({
                  id: "MASTERDATA_CATEGORY_SEARCH_PLACEHOLDER",
                })}
                onChange={(e) => {
                  onSearchTextChange(e.target.value);
                }}
              />
            </div>
          </div>

          {/* add new category button */}
          <div className="d-flex align-items-center">
            {checkedCategories.length > 0 ? (
              <>
                {isSelectCategory() ? (
                  <>
                    <button
                      className="btn  btn-primary"
                      onClick={() => {
                        onSelectCategory?.(checkedCategories);
                      }}
                    >
                      {!loading && (
                        <span className="indicator-label">
                          {intl.formatMessage({
                            id: "APPLY_BUTTON",
                          })}
                        </span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {intl.formatMessage({
                            id: "EVENTS_FOLLOW_UP_ADD_LOADING",
                          })}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </>
                ) : (
                  <>
                    <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                      {checkedCategories.length}{" "}
                      {intl.formatMessage({ id: "SELECTED" })}
                    </span>
                    <button
                      className="btn btn-danger d-flex align-items-center"
                      onClick={() => {
                        showDeleteConfirmModal(
                          intl.formatMessage({
                            id: "MASTERDATA_CATEGORY_SELECTED_DELETE_CONFIRM_MESSAGE",
                          }),
                          () => {
                            deleteMultipleCategories();
                          }
                        );
                      }}
                    >
                      <i className={"bi bi-trash3-fill fs-6"}></i>
                      {intl.formatMessage({ id: "DELETE_BUTTON" })}
                    </button>
                  </>
                )}
              </>
            ) : isSelectCategory() ? null : (
              <>
                {crudPermission?.create && (
                  <button
                    className="btn  btn-primary"
                    onClick={() => {
                      setShowCategoriesModal({
                        show: true,
                        type: "new",
                      });
                    }}
                  >
                    <SVGICON src={PlusIcon} className="svg-icon-2" />
                    {intl.formatMessage({
                      id: "MASTERDATA_CREATE_NEW_CATEOGRY_BUTTON",
                    })}
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        <DragSortableList
          items={list}
          // @ts-ignore
          onSort={(sortedList, dropEvent) => {
            onCategoriesSortChange(sortedList);
          }}
          type="vertical"
        />
      </div>
      {/* no data */}
      {!isLoading && displayCategoriesList.length === 0 && (
        <table className="d-flex justify-content-center align-items-center mt-2">
          <tbody>
            <NoItemsFound languageKey="NO_ITEMS_FOUND" />
          </tbody>
        </table>
      )}
      {displayCategoriesList.length > 0 && (
        <div className="card mt-5 pe-3">
          <Pagination
            totalPages={Math.ceil(displayCategoriesList.length / itemsPerPage)}
            activePage={
              Math.ceil(displayCategoriesList.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1);
            }}
          />
        </div>
      )}
      {/* {displayCategoriesList.length === 0 && (
        <div className='d-flex flex-column flex-center w-100 bg-body'>
          <img src={notFound} className='mh-400px' alt='' style={{color: 'inherit'}} />
          <div className='fs-1 fw-bolder text-dark mb-4'>
            {intl.formatMessage({id: 'NO_ITEMS_FOUND'})}
          </div>
        </div>
      )} */}
    </>
  );
};

export default Categories;
