import { FC, useEffect, useState } from "react";
import {
  salesNumbersDetails,
  salesSettingsDetails,
} from "../types/SalesSettingstype";
import { useIntl } from "react-intl";
import {
  updateNumberSettingsDetails,
  updateSalesSettingsDetails,
} from "../redux/SalesSettingsApi";
import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import InfoIcon from "../../../../../_metronic/assets/icons/info.svg";
import SVG from "react-inlinesvg";
import SimpleLoader from "../../../../sharedComponents/Loader/SimpleLoader";
import DropDown from "../../../../sharedComponents/dropdown/Dropdown";
import LayoutListModal from "./LayoutListModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "../../../../sharedComponents/TextInput/TextInput";
// import { getSalesOffersStatus } from "../../SalesOffers/redux/SalesOffersApi";
// import { salesOfferDetails } from "../../SalesOffers/types/SalesOffertype";
import SalesNotesListModal from "./SalesNotesListModal";

interface Props {
  offers?: salesSettingsDetails;
  numberoffers?: salesNumbersDetails;
  getSalesSettingsApi: () => void;
  fromSalesOfferEdit?: boolean;
  setShowSettingModal?: () => void;
  setModalOpen?: (modalinv: boolean) => void;
  mainCloseModal?: () => void;
  error?: any;
  setError?: () => void;
}

const computeNextNumber = (values: any) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = (new Date().getMonth() + 1)?.toString().padStart(2, "0");
  if (!values?.prefix) return;

  let nextNumber = values.prefix;
  if (values.enable_year) {
    nextNumber += currentYear;
  }

  if (values.enable_month) {
    nextNumber += currentMonth;
  }

  const numberOfDigits = parseInt(values.no_of_digits);
  const startNumber = values.next_number
    ?.toString()
    ?.padStart(numberOfDigits, "0");

  nextNumber += startNumber;

  return nextNumber;
};

const LayoutOffers: FC<Props> = ({
  offers,
  numberoffers,
  getSalesSettingsApi,
  fromSalesOfferEdit,
  setModalOpen,
  mainCloseModal,
  error,
  setError,
}) => {
  const intl = useIntl();
  const Offers_Interval = JSON.parse(
    intl.formatMessage({ id: "MASTERDATA_SALES_PRICE_INTERVALS_NAMES" })
  ) as any[];
  const [salesoffers, setSalesOffers] = useState<salesSettingsDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<number | string>("");
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [showListModal, setShowListModal] = useState<boolean>(false);
  const [salesNumber, setSalesNumber] = useState<salesNumbersDetails>();
  const [nextNumber, setNextNumber] = useState(computeNextNumber(numberoffers));
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  /**
   * TODO: add correct type for incoices list in useState
   * use this -> const [offersList, setOffersList] = useState<salesOfferDetails[]>([]);
   */
  const [offersList, setOffersList] = useState<any[]>([]);
  const [offerListApiCompleted, setOfferListApiCompleted] =
    useState<boolean>(true);
  const Digit_Options = JSON.parse(
    intl.formatMessage({ id: "MASTERDATA_SALES_NO_OF_DIGITS_VALUES" })
  );

  useEffect(() => {
    // @ts-ignore
    setSalesOffers(offers);
    // @ts-ignore
    setSalesNumber(numberoffers?.[0]);
    // @ts-ignore
    setInputValue(offers?.[3]?.answer || "");

    if (offers) {
      const selectedOffer = Offers_Interval.find(
        // @ts-ignore
        (item) => item.value === offers[2]?.answer
      );
      setSelectedItem(selectedOffer);
    }
  }, [offers, numberoffers]);

  const handleGeneralSettingsDetails = (id: number, answer: any) => {
    setLoading(true);
    updateSalesSettingsDetails(id, answer)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_UPDATE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_UPDATE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getSalesSettingsApi();
        setLoading(false);
      });
  };

  const handleSelectItem = (item: any) => {
    setSelectedItem(item);
    handleGeneralSettingsDetails(salesoffers[2]?.id, item.value);
  };

  const closeModal = () => {
    setShowListModal(false);
    setModalOpen?.(false);
    setShowNotesModal(false);
  };

  const onHandleApply = (answer: number) => {
    updateSalesSettingsDetails(salesoffers[0]?.id, answer)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_UPDATE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_UPDATE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getSalesSettingsApi();
        setLoading(false);
        closeModal();
        setModalOpen?.(false);
        setError?.();
        // mainCloseModal?.()
      });
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      prefix: salesNumber?.prefix,
      enable_year: salesNumber?.enable_year,
      enable_month: salesNumber?.enable_month,
      starting_number: salesNumber?.starting_number,
      no_of_digits: salesNumber?.no_of_digits,
      can_edit: salesNumber?.can_edit,
    });
    setNextNumber(computeNextNumber(salesNumber));
  }, [salesNumber]);

  const initialValues = {
    module: "offers",
    prefix: salesNumber?.prefix,
    enable_year: salesNumber?.enable_year,
    enable_month: salesNumber?.enable_month,
    starting_number: salesNumber?.starting_number,
    no_of_digits: salesNumber?.no_of_digits,
    can_edit: salesNumber?.can_edit,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      prefix: Yup.string()
        .required(
          intl.formatMessage({
            id: "AUTH_NEW_PASSWORD_REQUIRED_VALIDATION_MESSAGE",
          })
        )
        .max(
          3,
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_PREFIX_ERROR_VALIDATION",
          })
        ),
      // .matches(/^[A-Za-z]{3}$/, intl.formatMessage({id: 'ERROR_PREFIX_LETTERS_ONLY'})),
      starting_number: Yup.number()
        .typeError(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_STARTING_NUMBER_ERROR_VALIDATION",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH_NEW_PASSWORD_REQUIRED_VALIDATION_MESSAGE",
          })
        )
        .max(
          99999,
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_STARTING_NUMBER_ERROR_VALIDATION_1",
          })
        ),
      no_of_digits: Yup.number()
        .oneOf(
          [3, 4, 5, 6],
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_NUMBER_OF_DIGIT_ERROR_VALIDATION",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH_NEW_PASSWORD_REQUIRED_VALIDATION_MESSAGE",
          })
        ),
      next_number: Yup.number().max(99999),
    }),
    onSubmit: (values) => {
      setLoading(true);
      updateNumberSettingsDetails(formik.values)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_SETTINGS_UPDATE_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_SETTINGS_UPDATE_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getSalesSettingsApi();
          setLoading(false);
        });
    },
  });

  // const getOffersListAPI = (
  //   offersSearchTextParam?: string,
  //   limit?: number,
  //   page?: number
  // ) => {
  //   Promise.all([
  //     getSalesOffersStatus("draft", offersSearchTextParam, limit || 50, page),
  //     getSalesOffersStatus("all", offersSearchTextParam, limit || 50, page),
  //   ])
  //     .then(([draftResponse, allResponse]) => {
  //       const mergedOffers = [
  //         ...draftResponse.data.salesOfferDetails,
  //         ...allResponse.data.salesOfferDetails,
  //       ];

  //       setOffersList(mergedOffers);
  //     })
  //     .catch((error) => console.error(error?.response?.data?.message))
  //     .finally(() => {
  //       setOfferListApiCompleted(false);
  //     });
  // };

  const getOffersListAPI = (
    offersSearchTextParam?: string,
    limit?: number,
    page?: number
  ) => {
    /**
     * TODO: add correct request function and their responses after backend done
     */
    setOffersList([]);
    setOfferListApiCompleted(false);
  };

  useEffect(() => {
    getOffersListAPI();
  }, []);

  const disabledValue = salesNumber?.can_edit === 1;

  const onHandleNotesApply = (answer: number) => {
    updateSalesSettingsDetails(salesoffers[1]?.id, answer)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_UPDATE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_UPDATE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getSalesSettingsApi();
        setLoading(false);
        closeModal();
        setModalOpen?.(false);
        setError?.();
      });
  };
  return (
    <>
      {/* Offers Card */}
      <div className={`card  ${!fromSalesOfferEdit ? "mt-10" : "mt-0"} `}>
        {showListModal && (
          <LayoutListModal
            show={showListModal}
            closeModal={closeModal}
            isSelectionModal={true}
            onHandleApply={onHandleApply}
            selectedfooter={{
              id: +salesoffers?.[0]?.answer,
              value: salesoffers?.[0]?.value,
            }}
          />
        )}

        {showNotesModal && (
          <SalesNotesListModal
            show={showNotesModal}
            closeModal={closeModal}
            isSelectionModal={true}
            onHandleApply={onHandleNotesApply}
            selectedNotes={{
              id: +salesoffers?.[1]?.answer,
              value: salesoffers?.[1]?.value,
            }}
          />
        )}

        {!fromSalesOfferEdit && (
          <div className="card-header">
            <div className="card-title">
              <h2>
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_SETTINGS_OFFERS_PAGE_TITLE",
                })}
              </h2>
            </div>
          </div>
        )}

        {(fromSalesOfferEdit && offersList.length === 0) ||
          (!fromSalesOfferEdit && (
            <div className="overflow-auto">
              <div
                className="card-body pt-4"
                style={{ overflow: "inherit!important", minHeight: "205px" }}
              >
                {offerListApiCompleted && <SimpleLoader fullLoader />}

                {fromSalesOfferEdit &&
                  offersList.length === 0 &&
                  !offerListApiCompleted && (
                    <div>
                      {" "}
                      <SVG src={InfoIcon} className="svg-icon mx-2" />{" "}
                      {intl.formatMessage({
                        id: "MASTERDATA_SALES_SETTINGS_OFFER_INFO_MESSAGE",
                      })}{" "}
                    </div>
                  )}

                {((fromSalesOfferEdit && offersList.length === 0) ||
                  !fromSalesOfferEdit) &&
                  !offerListApiCompleted && (
                    <div className="table-responsive  table_actions_overflow">
                      {/* begin::Table */}
                      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        {/* begin::Table head */}
                        <thead>
                          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th className="min-w-150px">
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_1",
                              })}
                            </th>
                            <th
                              className="min-w-125px"
                              style={{ textAlign: "center" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_2",
                              })}
                            </th>
                            <th
                              className="min-w-125px mw-150px"
                              style={{ textAlign: "center" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_3",
                              })}
                            </th>
                            <th
                              className="min-w-125px mw-150px"
                              style={{ textAlign: "center" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_4",
                              })}
                            </th>
                            <th
                              className="min-w-125px mw-150px"
                              style={{ textAlign: "center" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_5",
                              })}
                            </th>
                            <th
                              className="min-w-125px mw-150px"
                              style={{ textAlign: "center" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_6",
                              })}
                            </th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody className="">
                          <tr className="align-top">
                            <td className="mw-100px">
                              <div
                                className="form-input form-input-control"
                                onBlur={() => {
                                  if (
                                    salesNumber?.prefix !== formik.values.prefix
                                  ) {
                                    formik.handleSubmit();
                                  }
                                }}
                              >
                                <input
                                  type="text"
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  value={formik.values.prefix || ""}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "prefix",
                                      e.target.value
                                    )
                                  }
                                  maxLength={3}
                                  disabled={!disabledValue}
                                />
                                {formik.touched.prefix &&
                                  formik.errors.prefix && (
                                    <div className="text-danger">
                                      <span
                                        role="alert"
                                        style={{ fontSize: "smaller" }}
                                      >
                                        {formik.errors.prefix}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </td>

                            {/* Title */}
                            <td className="mw-100px">
                              <div className="px-2 d-flex justify-content-center mt-3  align-items-center form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  // @ts-ignore
                                  checked={formik.values.enable_year === 1}
                                  {...formik.getFieldProps("enable_year")}
                                  // onBlur={handleBlurAndSave}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "enable_year",
                                      e.target.checked ? 1 : 0
                                    );
                                    formik.handleSubmit();
                                  }}
                                  disabled={!disabledValue}
                                  style={{ width: "50px", height: "25px" }}
                                />
                              </div>
                            </td>

                            {/* Date */}
                            <td className="mw-100px">
                              <div className="px-2 d-flex justify-content-center mt-3  align-items-center form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  // @ts-ignore
                                  checked={formik.values.enable_month === 1}
                                  {...formik.getFieldProps("enable_month")}
                                  // onBlur={handleBlurAndSave}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "enable_month",
                                      e.target.checked ? 1 : 0
                                    );
                                    formik.handleSubmit();
                                  }}
                                  disabled={!disabledValue}
                                  style={{ width: "50px", height: "25px" }}
                                />
                              </div>
                            </td>

                            <td className="mw-150px">
                              <div
                                className=" px-2 form-input form-input-control"
                                onBlur={() => {
                                  if (
                                    salesNumber?.starting_number !==
                                    formik.values?.starting_number
                                  ) {
                                    formik.handleSubmit();
                                  }
                                }}
                              >
                                <TextInput
                                  value={formik.values?.starting_number || ""}
                                  onChange={(value) => {
                                    formik.setFieldValue(
                                      "starting_number",
                                      value
                                    );
                                  }}
                                  disabled={!disabledValue}
                                />
                                {formik.touched.starting_number &&
                                  formik.errors.starting_number && (
                                    <div className="text-danger">
                                      <span
                                        role="alert"
                                        style={{ fontSize: "smaller" }}
                                      >
                                        {formik.errors.starting_number}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </td>

                            {/* Categories */}
                            <td className="mw-150px">
                              {/* <div
                              className=" px-2  form-input form-input-control"
                              onBlur={() => {
                                if (
                                  salesNumber?.no_of_digits !==
                                  formik.values?.no_of_digits
                                ) {
                                  formik.handleSubmit();
                                }
                              }}
                            >
                              <TextInput
                                value={formik.values?.no_of_digits || ""}
                                onChange={(value) => {
                                  formik.setFieldValue("no_of_digits", value);
                                }}
                              />
                              {formik.errors.no_of_digits && (
                                <div className="text-danger">
                                  <span
                                    role="alert"
                                    style={{ fontSize: "smaller" }}
                                  >
                                    {formik.errors.no_of_digits}
                                  </span>
                                </div>
                              )}
                            </div> */}
                              <div className="">
                                <DropDown
                                  disabled={!disabledValue}
                                  id="DigitsDropDown"
                                  hideSearch
                                  items={Digit_Options || []}
                                  displayFunc={(item) => {
                                    return item.name;
                                  }}
                                  displayValue={
                                    Digit_Options.find((postion: any) => {
                                      // @ts-ignore
                                      return (
                                        postion.value ===
                                        formik.values?.no_of_digits
                                      );
                                    })?.name || ""
                                  }
                                  // @ts-ignore
                                  onSelectItem={(item) => {
                                    formik.setFieldValue(
                                      "no_of_digits",
                                      // @ts-ignore
                                      item?.value
                                    );
                                    // @ts-ignore
                                    formik.handleSubmit();
                                  }}
                                  // @ts-ignore
                                  selectedItem={Digit_Options.find(
                                    (postion: any) => {
                                      return (
                                        postion.value ===
                                        formik.values?.no_of_digits
                                      );
                                    }
                                  )}
                                  // selectedItem={
                                  //   ALIGN_OPTIONS.find((position: any) => {
                                  //     return position.value === option
                                  //   })?.name || ''
                                  // }
                                />
                              </div>
                            </td>

                            {/* Tags */}
                            <td className="mw-150px">
                              <div className=" px-2  form-input form-input-control">
                                <TextInput
                                  // @ts-ignore
                                  value={nextNumber || ""}
                                  // onChange={(value) => {
                                  //   formik.setFieldValue('next_number', value)
                                  // }}
                                  disabled
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        {/* end::Table body */}
                      </table>

                      {/* end::Table */}
                    </div>
                  )}
              </div>
            </div>
          ))}
      </div>

      {/* Settings Card */}
      <div
        className={`${
          (fromSalesOfferEdit && offersList.length === 0) || !fromSalesOfferEdit
            ? "mt-10 card"
            : "mt-0 card"
        }`}
      >
        {!fromSalesOfferEdit && (
          <div className="card-header">
            <div className="card-title">
              <h2>
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_SETTINGS_SETTINGS_CARD_TITLE",
                })}
              </h2>
            </div>
          </div>
        )}
        <div className="overflow-auto">
          <div
            className="card-body p-4 d-flex flex-column gap-6 my-4"
            style={{ overflow: "inherit!important" }}
          >
            {/* Offers Expiration Interval */}
            {!fromSalesOfferEdit && (
              <div>
                <div className="d-flex justify-content-start  align-items-center">
                  <div className="fw-bold mx-5" style={{ width: "50%" }}>
                    {/* {generalDetails && generalDetails.length > 0 && generalDetails[0].question} */}
                    {intl.formatMessage({
                      id: "MASTERDATA_SALES_SETTINGS_GENERAL_TAB_QUESTION_4",
                    })}
                  </div>
                  <div className="ms-2" style={{ width: "25%" }}>
                    <DropDown
                      id="generalSettingsDropdown"
                      items={Offers_Interval}
                      displayFunc={(item) => {
                        // @ts-ignore
                        return item.plural;
                      }}
                      selectedItem={selectedItem}
                      onSelectItem={handleSelectItem}
                      displayValue={selectedItem?.plural || ""}
                      hideSearch
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Offers Expiration Count */}
            {!fromSalesOfferEdit && (
              <div className="d-flex justify-content-start  align-items-center">
                <div className="fw-bold mx-5" style={{ width: "50%" }}>
                  {/* {generalDetails && generalDetails.length > 0 && generalDetails[1].question} */}
                  {intl.formatMessage({
                    id: "MASTERDATA_SALES_SETTINGS_GENERAL_TAB_QUESTION_5",
                  })}
                </div>
                <div style={{ width: "26%" }}>
                  <div className=" px-2  form-input form-input-control">
                    <input
                      // disabled
                      style={{ backgroundColor: "#eef3f7" }}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      type="number"
                      max={5}
                      //   defaultValue={formik.values.devices}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onBlur={() => {
                        handleGeneralSettingsDetails(
                          salesoffers[3]?.id,
                          inputValue
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Offer layout setting */}
            <div className="d-flex justify-content-start  align-items-center">
              <div
                className={`fw-bold mx-5 ${fromSalesOfferEdit && "required"} `}
                style={{ width: "50%" }}
              >
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_SETTINGS_GENERAL_TAB_QUESTION_6",
                })}
              </div>
              <div style={{ width: fromSalesOfferEdit ? "50%" : "26%" }}>
                <div
                  className=" px-2  form-input form-input-control "
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowListModal(true);
                    setModalOpen?.(true);
                  }}
                >
                  <span
                    style={{ backgroundColor: "#eef3f7" }}
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                  >
                    {salesoffers[0]?.value}
                  </span>
                </div>
                {fromSalesOfferEdit && error && !salesoffers[0]?.value && (
                  <span
                    className="text-danger d-flex justify-content-center alig-items-center "
                    style={{ marginLeft: "154px" }}
                  >
                    {error}
                  </span>
                )}
              </div>
            </div>

            {/* Offer notes setting */}
            <div className="d-flex justify-content-start  align-items-center">
              <div
                className={`fw-bold mx-5 ${fromSalesOfferEdit && "required"} `}
                style={{ width: "50%" }}
              >
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_SETTINGS_GENERAL_OFFER_TAB_NOTES_QUESTION",
                })}
              </div>
              <div style={{ width: fromSalesOfferEdit ? "50%" : "26%" }}>
                <div
                  className=" px-2  form-input form-input-control "
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowNotesModal(true);
                    setModalOpen?.(true);
                  }}
                >
                  <span
                    style={{ backgroundColor: "#eef3f7" }}
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                  >
                    {salesoffers[1]?.value}
                  </span>
                </div>
                {fromSalesOfferEdit && error && !salesoffers[1]?.value && (
                  <span
                    className="text-danger d-flex justify-content-center alig-items-center "
                    style={{ marginLeft: "154px" }}
                  >
                    {error}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutOffers;
