import React from "react";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";

// custom
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import GoogleList from "./components/GoogleList";

const GooglePage: React.FC = () => {
  const intl = useIntl();

  const googleBreadCrumbs: Array<PageLink> = [
    // {
    //   title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
    {
      title: intl.formatMessage({ id: "MENU_GOOGLE" }),
      path: "/interfaces/google",
      isSeparator: false,
      isActive: false,
    },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
  ];

  return (
    <div>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/interfaces/google/overview">
          <PageTitle
            breadcrumbs={[
              ...googleBreadCrumbs,
              // {
              //   title: intl.formatMessage({ id: "ROUTES_OVERVIEW" }),
              //   path: "/interfaces/google/overview",
              //   isSeparator: false,
              //   isActive: true,
              // },
            ]}
          >
            {intl.formatMessage({ id: "MENU_GOOGLE" })}
          </PageTitle>
          <GoogleList />
        </Route>

        <Redirect
          from="/interfaces/google"
          exact={true}
          to="/interfaces/google/overview"
        />
        <Redirect to="/interfaces/google" />
      </Switch>
    </div>
  );
};

export default GooglePage;
