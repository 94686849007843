import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Tabs from "../../../../sharedComponents/tabs/Tabs";
import LayoutInvoices from "./LayoutInvoices";
import LayoutOffers from "./LayoutOffers";
import { getSalesSettings } from "../redux/SalesSettingsApi";
import {
  salesNumbersDetails,
  settingsDetailsType,
} from "../types/SalesSettingstype";

const SalesSetting = () => {
  const intl = useIntl();
  const { state = {} } = useLocation();

  const [tabActiveIndex, setTabActiveIndex] = useState<number>(
    //@ts-ignore
    state?.activeTab ? state?.activeTab : 0
  );

  const [invoices, setInvoices] = useState<settingsDetailsType[]>([]);
  const [offers, setOffers] = useState<settingsDetailsType[]>([]);
  const [numberCancelledInvoices, setNumberCancelledInvoices] = useState<
    settingsDetailsType[]
  >([]);
  const [numberInvoices, setnumberInvoices] = useState<salesNumbersDetails>();
  const [numberoffers, setNumberOffers] = useState<salesNumbersDetails>();

  const onTabChange = (index: number) => {
    if (index !== tabActiveIndex) {
      setTabActiveIndex(index);
    }
  };

  const getSalesSettingsApi = () => {
    getSalesSettings()
      .then(({ data: { settingsDetails = [] as settingsDetailsType[] } }) => {
        // @ts-ignore
        const organisedData = settingsDetails?.salesSettingsDetails?.reduce(
          // @ts-ignore
          (acc, item) => {
            const module = item.module;
            if (!acc[module]) {
              acc[module] = [];
            }
            acc[module].push(item);
            return acc;
          },
          {} as Record<string, settingsDetailsType[]>
        );

        setInvoices(organisedData["invoice"] || []);
        setOffers(organisedData["offers"] || []);

        const organisedaNumberData = // @ts-ignore
          settingsDetails?.salesNumbersDetails?.reduce(
            // @ts-ignore
            (acc, item) => {
              const module = item.module;
              if (!acc[module]) {
                acc[module] = [];
              }
              acc[module].push(item);
              return acc;
            },
            {} as Record<string, settingsDetailsType[]>
          );
        setnumberInvoices(organisedaNumberData["invoices"] || []);
        setNumberOffers(organisedaNumberData["offers"] || []);
        setNumberCancelledInvoices(
          organisedaNumberData["cancelled_invoices"] || []
        );
      })
      .catch(() => {})
      .finally(() => {});
  };

  useEffect(() => {
    getSalesSettingsApi();
  }, []);

  return (
    <>
      <div className="card mt-7">
        <div className="px-10 mt-2 ml-10">
          <Tabs
            tabs={[
              intl.formatMessage({
                id: "MASTERDATA_SALES_SETTINGS_TAB_1",
              }),
              intl.formatMessage({ id: "MASTERDATA_SALES_SETTINGS_TAB_2" }),
            ]}
            activeIndex={tabActiveIndex}
            onActiveIndexChange={(index) => {
              onTabChange(index);
            }}
          />
        </div>
      </div>
      {tabActiveIndex === 0 && (
        <LayoutInvoices
          // @ts-ignore
          invoices={invoices}
          numberInvoices={numberInvoices}
          getSalesSettingsApi={getSalesSettingsApi}
          // @ts-ignore
          numberCancelledInvoices={numberCancelledInvoices}
        />
      )}

      {tabActiveIndex === 1 && (
        <LayoutOffers
          // @ts-ignore
          offers={offers}
          numberoffers={numberoffers}
          getSalesSettingsApi={getSalesSettingsApi}
        />
      )}
    </>
  );
};

export default SalesSetting;
