import React, { useEffect, useState } from "react";
// import * as Yup from 'yup'
// import {useFormik} from 'formik'

// custom
// import {ProfileDetails} from '../../accounts/components/settings/cards/ProfileDetails'

import UserProfileDetails from "./UserProfileDetails";
import { useHistory, useLocation } from "react-router";
import { UserType } from "../types/getUsersListResponseType";
import SignInMethod from "./SignInMethod";

// import ConnectUserRole from "./ConnectUserRole";
import { getUserDetails } from "../redux";
import ConnectUserRole from "./ConnectUserRole";
import UserTeamDetails from "./UserTeamDetails";
import { ConnectedAccounts } from "../../accounts/components/settings/cards/ConnectedAccounts";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";

const EditUserDetails = () => {
  const { state = {} } = useLocation();
  const history = useHistory();

  // @ts-ignore
  const userDetails: UserType = state.userDetails || {};

  // state
  const [user, setUser] = useState<UserType>(userDetails);

  const getUserDetailsAPI = () => {
    userDetails.id &&
      getUserDetails(userDetails.id).then(({ data: { user } }) => {
        setUser(user);
        history.replace({ state: { userDetails: user } });
      });
  };

  useEffect(()=>{
    ScrollToTop()
  },[])

  useEffect(() => {
    getUserDetailsAPI();
  }, []);

  // console.log(user);

  return (
    <div>
      {user?.id && (
        <UserProfileDetails
          userDetails={user}
          getUserDetailsAPI={getUserDetailsAPI}
        />
      )}

      {user?.id && (
        <SignInMethod
          userDetails={user}
          getUserDetailsAPI={getUserDetailsAPI}
        />
      )}

      {user?.id && (
        <ConnectUserRole
          // @ts-ignore
          userDetails={user}
          getUserDetailsAPI={getUserDetailsAPI}
        />
      )}
      {user?.id && (
        <UserTeamDetails
          // @ts-ignore
          userDetails={user}
          getUserDetailsAPI={getUserDetailsAPI}
        />
      )}
    </div>
  );
};

export default EditUserDetails;
