import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

// images
import MenuIcon from "../../../../_metronic/assets/icons/menu.svg";

// custom
import { SVGICON } from "../../../../_metronic/helpers";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { deleteProduct } from "../redux/ProductsAPI";
import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import { ProductType } from "../types/ProductsResponseType";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  productDetails: ProductType;
  getProductsListAPI?: () => void;
}

const ProductsListActionsDropdown: FC<Props> = ({
  productDetails,
  getProductsListAPI,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "products"
  );

  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const onEditProductClick = () => {
    history.push(`/sales/products/edit`, {
      productDetails: productDetails,
    });
  };

  const deleteExistingPost = (product_id: number) => {
    setDeleteModalLoading(true);
    deleteProduct(product_id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "PRODUCTS_DELETE_POST_MESSAGE" }),
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "PRODUCTS_DELETE_POST_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        // recall  get  list API
        getProductsListAPI?.();
      });
  };

  const dropdownOptions = [
    ...(crudPermission?.edit
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_EDIT" }),
            onClick: onEditProductClick,
          },
        ]
      : []),
    ...(crudPermission?.delete
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_DELETE" }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "PRODUCTS_DELETE_POST_CONFIRM_MESSAGE",
                }),
                () => {
                  deleteExistingPost(productDetails?.id);
                }
              );
            },
            className: "text-danger",
          },
        ]
      : []),
  ];

  if (dropdownOptions.length > 0) {
    return (
      <div className="dropdown d-flex flex-row justify-content-end">
        <ActionsDropdown options={dropdownOptions} />
      </div>
    );
  } else {
    return null;
  }
};

export default ProductsListActionsDropdown;
