import { useFormik } from "formik";
import { FC, useState } from "react";
import { useIntl } from "react-intl";

import * as Yup from "yup";
import { createPipelinePhase } from "../redux/PipelinesAPI";
import { PipelineType } from "../types/PipelinesResponseType";
import { successToast } from "../../../sharedComponents/toasts/Toasts";
import { Button, Modal } from "react-bootstrap";
import clsx from "clsx";

interface Props {
  show: boolean;
  closeModal: () => void;
  getPipelineDetailsById: () => void;
  pipeline: PipelineType;
}

const AddPipelinePhaseMoadl: FC<Props> = ({
  show,
  closeModal,
  getPipelineDetailsById,
  pipeline,
}) => {
  const intl = useIntl();

  //   state
  const [loadng, setLoading] = useState(false);

  const initialValues = {
    name: "",
    is_required: 0,
    is_endPhase: 0,
  };

  const AddNewPipelinePhaseSchema = Yup.object().shape({
    name: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(intl.formatMessage({ id: "PROJECTS_PIPELINE_NAME_REQUIRED" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewPipelinePhaseSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (values.name) {
        setLoading(true);
        createPipelinePhase(
          pipeline.id,
          values.name,
          values.is_required,
          values.is_endPhase
        )
          .then(() => {
            closeModal();
            successToast(
              intl.formatMessage({
                id: "PROJECTS_PIPELINE_PHASES_ADD_SUCCESS_MESSAGE",
              })
            );
          })
          .catch((err) => {
            err.response?.data?.errors?.name
              ? setStatus(err.response.data?.errors?.name)
              : setStatus(
                  intl.formatMessage({
                    id: "PROJECTS_PIPELINE_PHASES_ADD_FAILURE_MESSAGE",
                  })
                );
          })
          .finally(() => {
            // recall  get tags list API
            getPipelineDetailsById();
            setLoading(false);
          });
      }
    },
  });

  return (
    <Modal
      show={show}
      centered
      contentClassName={loadng ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "PROJECTS_PHASE_ADD_MODAL_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "PROJECTS_PIPELINE_PHASE_NAME_FIELD",
                  })}
                </span>
              </label>

              <input
                placeholder={intl.formatMessage({
                  id: "PROJECTS_PIPELINE_PHASE_NAME_FIELD_PLACEHOLDER",
                })}
                {...formik.getFieldProps("name")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                name="name"
                autoComplete="off"
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.name}</span>
                </div>
              )}
            </div>

            <div className="mb-8 d-flex  align-items-center gap-20">
              <div className="">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="">
                    {intl.formatMessage({
                      id: "PROJECTS_PIPELINE_PHASE_IS_REQUIRED_FIELD",
                    })}
                  </span>
                </label>

                <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="notifications"
                    value=""
                    checked={formik.values.is_required === 1 ? true : false}
                    style={{ width: "45px", height: "22px" }}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "is_required",
                        e.target.checked ? 1 : 0
                      );
                    }}
                  />
                </div>
              </div>
              <div className="">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="">
                    {intl.formatMessage({
                      id: "PROJECTS_PIPELINE_PHASE_IS_ENDPHASE_FIELD",
                    })}
                  </span>
                </label>

                <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="notifications"
                    value=""
                    checked={formik.values.is_endPhase === 1 ? true : false}
                    style={{ width: "45px", height: "22px" }}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "is_endPhase",
                        e.target.checked ? 1 : 0
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!loadng && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loadng && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "MASTERDATA_TAGS_LOADING_MESSAGE" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddPipelinePhaseMoadl;
