import React from "react";

import "./TeamsPage.scss";
import { useIntl } from "react-intl";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch } from "react-router-dom";
import Config from "../../config";
import TeamsList from "./components/TeamsList";

const TeamsPage = () => {
  const intl = useIntl();

  const teamsBreadCrumbs: Array<PageLink> = [
    // {
    //   title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
    {
      title: intl.formatMessage({ id: "MENU_TEAMS" }),
      path: "/system/teams",
      isSeparator: false,
      isActive: false,
    },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
  ];
  return (
    <div>
      <Helmet>
        <title>{Config.APP_NAME}-Teams</title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/system/teams/overview">
          <PageTitle
            breadcrumbs={[
              ...teamsBreadCrumbs,
              // {
              //   title: intl.formatMessage({ id: "ROUTES_OVERVIEW" }),
              //   path: "/system/teams/overview",
              //   isSeparator: false,
              //   isActive: true,
              // },
            ]}
          >
            {intl.formatMessage({ id: "TEAMS_TITLE" })}
          </PageTitle>
          <TeamsList />
        </Route>

        <Redirect
          from="/system/teams"
          exact={true}
          to="/system/teams/overview"
        />
        <Redirect to="/system/teams/overview" />
      </Switch>
    </div>
  );
};

export default TeamsPage;
