import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import clsx from "clsx";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSelectMethod: (method: string) => void;
}

const SelectResponsibles: FC<Props> = ({
  show,
  closeModal,
  onSelectMethod,
}) => {
  const [selectMethod, setSeletMethod] = useState("single");

  const intl = useIntl();
  return (
    <div className="cam-modal-container">
      <Modal
        show={show}
        centered
        dialogClassName="medium-size-modal"
        backdrop="static"
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({ id: "DEAL_SELECT_RESPONSIBLE_TITLE" })}
          </Modal.Title>
        </Modal.Header>
        <div className="overflow-auto">
          <Modal.Body className="pt-10 pb-10 px-lg-17 overflow-inherit">
            <p className="text-muted fs-5 fw-bold mb-10">
              {intl.formatMessage({ id: "DEAL_SELECT_RESPONSIBLE_INFO" })}
            </p>
            <div className="d-flex flex-row gap-5">
              <label
                className={clsx(
                  "btn  btn-active-light-primary border-primary border border-dashed p-7 d-flex align-items-center",
                  selectMethod === "single" ? "bg-light-primary" : ""
                )}
                htmlFor="kt_modal_two_factor_authentication_option_1"
                onClick={() => {
                  setSeletMethod("single");
                }}
              >
                <span className="d-block fw-bold text-start">
                  <span className="text-dark fw-bolder d-block fs-3">
                    {intl.formatMessage({
                      id: "DEAL_SELECT_RESPONSIBLE_SINGLE_USER_TITLE",
                    })}
                  </span>
                  <span className="text-muted fw-bold fs-6">
                    {intl.formatMessage({
                      id: "DEAL_SELECT_RESPONSIBLE_SINGLE_USER_INFO",
                    })}
                  </span>
                </span>
              </label>
              <label
                className={clsx(
                  "btn  btn-active-light-primary border-primary border border-dashed p-7 d-flex align-items-center",
                  selectMethod === "team" ? "bg-light-primary" : ""
                )}
                htmlFor="kt_modal_two_factor_authentication_option_1"
                onClick={() => {
                  setSeletMethod("team");
                }}
              >
                <span className="d-block fw-bold text-start">
                  <span className="text-dark fw-bolder d-block fs-3">
                    {intl.formatMessage({
                      id: "DEAL_SELECT_RESPONSIBLE_TEAM_USER_TITLE",
                    })}
                  </span>
                  <span className="text-muted fw-bold fs-6">
                    {intl.formatMessage({
                      id: "DEAL_SELECT_RESPONSIBLE_TEAM_USER_INFO",
                    })}
                  </span>
                </span>
              </label>
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer className="justify-content-center px-lg-17">
          <button
            className="btn btn-light-primary w-100"
            data-kt-element="options-select"
            onClick={() => {
              onSelectMethod(selectMethod);
            }}
          >
            {intl.formatMessage({ id: "CONTINUE_BUTTON" })}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SelectResponsibles;
