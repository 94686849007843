import React, { FC, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap-v5";
import { useIntl } from "react-intl";
import CloseButton from "../../../sharedComponents/closeButton/CloseButton";

interface Props {
  show: boolean;
  previewFileURL: string | undefined;
  previewFileExtension: string | undefined;
  closeModal?: () => void;
  showCloseButton?: boolean;
}

const ProductFilePreviewModal: FC<Props> = ({
  show,
  previewFileURL,
  closeModal,
  previewFileExtension,
  showCloseButton,
}) => {
  const intl = useIntl();
  const [previewURL, setPreviewURL] = useState<string | undefined>(
    previewFileURL
  );
  const [previewExtension, setPreviewExtension] = useState<string | undefined>(
    previewFileExtension
  );
  const isImageFile = (ext: string | undefined) =>
    ext === "png" ||
    ext === "jpg" ||
    ext === "jpeg" ||
    ext === "svg" ||
    ext === "bmp" ||
    ext === "ico" ||
    ext === "gif" ||
    ext === "webp";

  const isDocFile = (ext: string | undefined) =>
    ext === "doc" || ext === "docx" || ext === "xlsx" || ext === "ppt" || ext === "pptx" || ext === "odp" || ext === "ods";

  const isOdtFile = (ext: string | undefined) => ext === "odt" || ext === "ods";

  const isCsvFile = (ext: string | undefined) => ext === "csv" || ext === "rtf";

  const renderFilePreview = () => {
    if (previewURL) {
      if (isImageFile(previewExtension)) {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={previewURL}
              alt=""
              style={{
                objectFit: "contain",
                width: "500px",
                height: "auto !important",
              }}
            />
          </div>
        );
      } else if (isDocFile(previewExtension)) {
        // Using Google Docs Viewer for .doc and .docx files
        return (
          <iframe
            title={previewFileURL}
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${previewURL}`}
            // src={`https://docs.google.com/viewerng/viewer?url=${previewURL}&embedded=true`}
            width="100%"
            height="500"
          ></iframe>
        );
      } else if (isCsvFile(previewExtension)) {
        // Using Google Docs Viewer for .doc and .docx files
        return (
          <iframe
            title={previewFileURL}
            src={`https://docs.google.com/viewerng/viewer?url=${previewURL}&embedded=true`}
            width="100%"
            height="500"
          ></iframe>
        );
      } else if (isOdtFile(previewExtension)) {
        // Handling .odt files
        // Attempt to use the Microsoft Office Viewer for .odt files
        return (
          <iframe
            title={previewFileURL}
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${previewURL}`}
            width="100%"
            height="500"
            frameBorder="0"
            onError={() => window.open(previewURL, "_blank")}
          ></iframe>
        );
      } else {
        return (
          <iframe
            title={previewFileURL}
            src={`${previewURL}`}
            width={"100%"}
            height={"500"}
          ></iframe>
        );
      }
    }
    return null;
  };

  useEffect(() => {
    setPreviewURL(previewFileURL);
    setPreviewExtension(previewFileExtension);
  }, [previewFileURL]);
  return (
    <div>
      <Modal
        show={show}
        centered
        dialogClassName="medium-size-modal"
        // contentClassName={`${loading ? "pe-none" : ""}`}
        backdrop="static"
      >
        <Modal.Header>
          <div className="d-flex flex-row flex-fill align-items-center justify-content-between">
            <Modal.Title className="p-0 m-0">
              {intl.formatMessage({
                id: "PRODUCT_FILE_PREVIEW_TITLE",
              })}
            </Modal.Title>
            <CloseButton
              onClose={() => {
                // setShowEditTaskModal(true);
                closeModal?.();
              }}
            />
          </div>
        </Modal.Header>
        <div className="overflow-auto">
          <Modal.Body className="overflow-inherit">
            {previewURL && <>{renderFilePreview()}</>}
          </Modal.Body>
        </div>
        {showCloseButton && (
          <Modal.Footer className="justify-content-center">
            <Button variant="secondary" onClick={closeModal}>
              {intl.formatMessage({
                id: "CLOSE_BUTTON",
              })}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default ProductFilePreviewModal;
