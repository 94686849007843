import {ResolutionType} from '../modules/filemanager/types/getFilemanagerDataResponseType'

export const getResolutionFileUrl = (
  resolution: string = '',
  default_media_url: string = '',
  resolutions: ResolutionType[] = []
) => {
  let url = default_media_url
  resolutions.forEach((item) => {
    if (item.resolution.includes(resolution)) {
      url = item.file_url
    }
  })
// console.log(url)
  return url
}
