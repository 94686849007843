import { FC, useEffect, useState } from "react";
import {
  salesNumbersDetails,
  salesSettingsDetails,
} from "../types/SalesSettingstype";
import SimpleLoader from "../../../../sharedComponents/Loader/SimpleLoader";
import {
  updateNumberSettingsDetails,
  updateSalesSettingsDetails,
} from "../redux/SalesSettingsApi";
import { useIntl } from "react-intl";
import LayoutListModal from "./LayoutListModal";
import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import InfoIcon from "../../../../../_metronic/assets/icons/info.svg";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "../../../../sharedComponents/TextInput/TextInput";
import DropDown from "../../../../sharedComponents/dropdown/Dropdown";
import LayoutCancelInvoice from "./LayoutCancelInvoice";
// import { OrderType } from "../../orders/types/OrdersTypes";
// import { getOrdersByStatus } from "../../orders/redux/OrdersAPI";
import SalesNotesListModal from "./SalesNotesListModal";

interface Props {
  invoices?: salesSettingsDetails;
  numberInvoices?: salesNumbersDetails;
  getSalesSettingsApi: () => void;
  numberCancelledInvoices?: salesNumbersDetails;
  fromInvoiceEdit?: boolean;
  setLayoutInvoiceModal?: () => void;
  setModalOpen?: (modalinv: boolean) => void;
  mainCloseModal?: () => void;
  error?: any;
  setError?: () => void;
}

const computeNextNumber = (values: any) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = (new Date().getMonth() + 1)?.toString().padStart(2, "0");
  if (!values?.prefix) return;

  let nextNumber = values.prefix;
  if (values.enable_year) {
    nextNumber += currentYear;
  }

  if (values.enable_month) {
    nextNumber += currentMonth;
  }
  const numberOfDigits = parseInt(values.no_of_digits);
  const startNumber = values.next_number
    ?.toString()
    ?.padStart(numberOfDigits, "0");

  nextNumber += startNumber;

  return nextNumber;
};

const LayoutInvoices: FC<Props> = ({
  invoices,
  numberInvoices,
  getSalesSettingsApi,
  numberCancelledInvoices,
  fromInvoiceEdit,
  setModalOpen,
  mainCloseModal,
  error,
  setError,
}) => {
  const intl = useIntl();

  const [loading, setLoading] = useState<boolean>(false);
  const [generalInvoices, setGeneralInvoices] = useState<
    salesSettingsDetails[]
  >([]);
  const [invoicesNumberData, setInvoicesNumberData] =
    useState<salesNumbersDetails>();
  const [showlistModal, setShowListModal] = useState<boolean>(false);
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const Digit_Options = JSON.parse(
    intl.formatMessage({ id: "MASTERDATA_SALES_NO_OF_DIGITS_VALUES" })
  );
  const [nextNumber, setNextNumber] = useState(
    computeNextNumber(invoicesNumberData)
  );

  /**
   * TODO: add correct type for invoices list in useState
   * use this -> const [invoicesList, setInvoiceList] = useState<OrderType[]>([]);
   */
  const [invoicesList, setInvoiceList] = useState<any[]>([]);
  const [invoiceListApiCompleted, setInvoiceListApiCompleted] =
    useState<boolean>(true);

  useEffect(() => {
    // @ts-ignore
    setGeneralInvoices(invoices);
  }, [invoices]);

  useEffect(() => {
    // @ts-ignore
    setInvoicesNumberData(numberInvoices?.[0]);
  }, [numberInvoices]);

  // const getInvoicesListAPI = (
  //   ordersSearchTextParam?: string,
  //   limit?: number,
  //   page?: number
  // ) => {
  //   Promise.all([
  //     getOrdersByStatus(
  //       "draft",
  //       ordersSearchTextParam,
  //       limit || 10,
  //       page,
  //       "invoices"
  //     ),
  //     getOrdersByStatus(
  //       "all",
  //       ordersSearchTextParam,
  //       limit || 10,
  //       page,
  //       "invoices"
  //     ),
  //   ])
  //     .then(([draftResponse, allResponse]) => {
  //       const draftList = draftResponse?.data?.salesOrderDetails || [];
  //       const allList = allResponse?.data?.salesOrderDetails || [];
  //       const mergedList = [...draftList, ...allList];

  //       setInvoiceList(mergedList);
  //     })
  //     .catch((error) => console.error(error?.response?.data?.message))
  //     .finally(() => {
  //       setInvoiceListApiCompleted(false);
  //     });
  // };

  const getInvoicesListAPI = () => {
    setInvoiceList([]);
    setInvoiceListApiCompleted(false);
  };

  useEffect(() => {
    getInvoicesListAPI();
  }, [fromInvoiceEdit]);

  const closeModal = () => {
    setShowListModal(false);
    setModalOpen?.(false);
    setShowNotesModal(false);
  };

  const onHandleApply = (answer: number) => {
    updateSalesSettingsDetails(generalInvoices[0]?.id, answer)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_UPDATE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_UPDATE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getSalesSettingsApi();
        setLoading(false);
        closeModal();
        setModalOpen?.(false);
        setError?.();
        // mainCloseModal?.()
      });
  };

  const onHandleNotesApply = (answer: number) => {
    updateSalesSettingsDetails(generalInvoices[1]?.id, answer)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_UPDATE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_UPDATE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getSalesSettingsApi();
        setLoading(false);
        closeModal();
        setModalOpen?.(false);
        setError?.();
        // mainCloseModal?.()
      });
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      prefix: invoicesNumberData?.prefix,
      enable_year: invoicesNumberData?.enable_year,
      enable_month: invoicesNumberData?.enable_month,
      starting_number: invoicesNumberData?.starting_number,
      no_of_digits: invoicesNumberData?.no_of_digits,
      can_edit: invoicesNumberData?.can_edit,
    });
    setNextNumber(computeNextNumber(invoicesNumberData));
  }, [invoicesNumberData]);

  const initialValues = {
    module: "invoices",
    prefix: invoicesNumberData?.prefix,
    enable_year: invoicesNumberData?.enable_year,
    enable_month: invoicesNumberData?.enable_month,
    starting_number: invoicesNumberData?.starting_number,
    no_of_digits: invoicesNumberData?.no_of_digits,
    can_edit: invoicesNumberData?.can_edit,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      prefix: Yup.string()
        .required(
          intl.formatMessage({
            id: "AUTH_NEW_PASSWORD_REQUIRED_VALIDATION_MESSAGE",
          })
        )
        .max(
          3,
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_PREFIX_ERROR_VALIDATION",
          })
        ),
      // .matches(/^[A-Za-z]{3}$/, intl.formatMessage({id: 'ERROR_PREFIX_LETTERS_ONLY'})),
      starting_number: Yup.number()
        .typeError(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_STARTING_NUMBER_ERROR_VALIDATION",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH_NEW_PASSWORD_REQUIRED_VALIDATION_MESSAGE",
          })
        )
        .max(
          99999,
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_STARTING_NUMBER_ERROR_VALIDATION_1",
          })
        ),
      no_of_digits: Yup.number()
        .oneOf(
          [3, 4, 5, 6],
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_NUMBER_OF_DIGIT_ERROR_VALIDATION",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH_NEW_PASSWORD_REQUIRED_VALIDATION_MESSAGE",
          })
        ),
      // next_number: Yup.number().max(99999),
    }),
    onSubmit: (values) => {
      setLoading(true);
      updateNumberSettingsDetails(formik.values)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_SETTINGS_UPDATE_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_SETTINGS_UPDATE_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getSalesSettingsApi();
          setLoading(false);
        });
    },
  });

  const disabledValue = invoicesNumberData?.can_edit === 1;

  return (
    <>
      <div className={`card  ${!fromInvoiceEdit ? "mt-10" : "mt-0"}  `}>
        {showlistModal && (
          <LayoutListModal
            show={showlistModal}
            closeModal={closeModal}
            isSelectionModal={true}
            onHandleApply={onHandleApply}
            selectedfooter={{
              id: +generalInvoices?.[0]?.answer,
              value: generalInvoices?.[0]?.value,
            }}
          />
        )}
        {showNotesModal && (
          <SalesNotesListModal
            show={showNotesModal}
            closeModal={closeModal}
            isSelectionModal={true}
            onHandleApply={onHandleNotesApply}
            selectedNotes={{
              id: +generalInvoices?.[1]?.answer,
              value: generalInvoices?.[1]?.value,
            }}
          />
        )}

        {!fromInvoiceEdit && (
          <div className="card-header">
            <div className="card-title">
              <h2>
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_SETTINGS_INVOICE_PAGE_TITLE",
                })}
              </h2>
            </div>
          </div>
        )}

        {/* Invoices Card */}
        {(fromInvoiceEdit && invoicesList.length === 0) ||
          (!fromInvoiceEdit && (
            <div className="overflow-auto">
              <div
                className="card-body pt-4"
                style={{ minHeight: "200px", overflow: "inherit!important" }}
              >
                {invoiceListApiCompleted && <SimpleLoader fullLoader />}

                {fromInvoiceEdit &&
                  invoicesList.length === 0 &&
                  !invoiceListApiCompleted && (
                    <div>
                      {" "}
                      <SVG src={InfoIcon} className="svg-icon mx-2" />{" "}
                      {intl.formatMessage({
                        id: "MASTERDATA_SALES_SETTINGS_INVOICE_INFO_MESSAGE",
                      })}{" "}
                    </div>
                  )}

                {(fromInvoiceEdit ||
                  // && invoicesList.length === 0
                  !fromInvoiceEdit) &&
                  !invoiceListApiCompleted && (
                    <div
                      className={`table-responsive   table_actions_overflow`}
                    >
                      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th className="min-w-150px ms-4">
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_1",
                              })}
                            </th>
                            <th
                              className="min-w-125px"
                              style={{ textAlign: "center" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_2",
                              })}
                            </th>
                            <th
                              className="min-w-125px mw-150px"
                              style={{ textAlign: "center" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_3",
                              })}
                            </th>
                            <th
                              className="min-w-125px mw-150px"
                              style={{ textAlign: "center" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_4",
                              })}
                            </th>
                            <th
                              className="min-w-125px mw-150px"
                              style={{ textAlign: "center" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_5",
                              })}
                            </th>
                            <th
                              className="min-w-125px mw-150px"
                              style={{ textAlign: "center" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_6",
                              })}
                            </th>
                          </tr>
                        </thead>

                        <tbody className="">
                          <tr className="align-top">
                            <td className="mw-100px">
                              <div
                                className="form-input form-input-control"
                                onBlur={() => {
                                  if (
                                    invoicesNumberData?.prefix !==
                                    formik.values.prefix
                                  ) {
                                    formik.handleSubmit();
                                  }
                                }}
                              >
                                <input
                                  type="text"
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  value={formik.values.prefix || ""}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "prefix",
                                      e.target.value
                                    )
                                  }
                                  maxLength={3}
                                  disabled={!disabledValue}
                                />
                                {formik.touched.prefix &&
                                  formik.errors.prefix && (
                                    <div className="text-danger">
                                      <span
                                        role="alert"
                                        style={{ fontSize: "smaller" }}
                                      >
                                        {formik.errors.prefix}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </td>

                            <td className="mw-100px">
                              <div className="px-2 d-flex justify-content-center mt-3  align-items-center form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  // @ts-ignore
                                  checked={formik.values.enable_year === 1}
                                  {...formik.getFieldProps("enable_year")}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "enable_year",
                                      e.target.checked ? 1 : 0
                                    );
                                    formik.handleSubmit();
                                  }}
                                  style={{ width: "50px", height: "25px" }}
                                  disabled={!disabledValue}
                                />
                              </div>
                            </td>

                            <td className="mw-100px">
                              <div className="px-2 d-flex justify-content-center mt-3  align-items-center form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  // @ts-ignore
                                  checked={formik.values.enable_month === 1}
                                  // {...formik.getFieldProps('enable_month')}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "enable_month",
                                      e.target.checked ? 1 : 0
                                    );
                                    formik.handleSubmit();
                                  }}
                                  style={{ width: "50px", height: "25px" }}
                                  disabled={!disabledValue}
                                />
                              </div>
                            </td>

                            <td className="mw-150px">
                              <div
                                className=" px-2  form-input form-input-control"
                                onBlur={() => {
                                  if (
                                    invoicesNumberData?.starting_number !==
                                    formik.values?.starting_number
                                  ) {
                                    formik.handleSubmit();
                                  }
                                }}
                              >
                                <TextInput
                                  value={formik.values?.starting_number || ""}
                                  onChange={(value) => {
                                    formik.setFieldValue(
                                      "starting_number",
                                      value
                                    );
                                  }}
                                  disabled={!disabledValue}
                                />
                                {formik.touched.starting_number &&
                                  formik.errors.starting_number && (
                                    <div className="text-danger">
                                      <span
                                        role="alert"
                                        style={{ fontSize: "smaller" }}
                                      >
                                        {formik.errors.starting_number}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </td>

                            <td className="mw-150px">
                              <div className="">
                                <DropDown
                                  disabled={!disabledValue}
                                  id="DigitsDropDown"
                                  hideSearch
                                  items={Digit_Options || []}
                                  displayFunc={(item) => {
                                    return item.name;
                                  }}
                                  displayValue={
                                    Digit_Options.find((postion: any) => {
                                      // @ts-ignore
                                      return (
                                        postion.value ===
                                        formik.values?.no_of_digits
                                      );
                                    })?.name || ""
                                  }
                                  // @ts-ignore
                                  onSelectItem={(item) => {
                                    formik.setFieldValue(
                                      "no_of_digits",
                                      // @ts-ignore
                                      item?.value
                                    );
                                    // @ts-ignore
                                    formik.handleSubmit();
                                  }}
                                  // @ts-ignore
                                  selectedItem={Digit_Options.find(
                                    (postion: any) => {
                                      return (
                                        postion.value ===
                                        formik.values?.no_of_digits
                                      );
                                    }
                                  )}
                                />
                              </div>
                            </td>

                            <td className="mw-150px">
                              <div className=" px-2  form-input form-input-control">
                                <TextInput
                                  // @ts-ignore
                                  value={nextNumber || ""}
                                  disabled
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
            </div>
          ))}
      </div>

      {/* Settings Card */}
      <div
        className={`${
          (fromInvoiceEdit && invoicesList.length === 0) || !fromInvoiceEdit
            ? "card mt-10"
            : "card mt-0"
        }`}
      >
        {!fromInvoiceEdit && (
          <div className="card-header">
            <div className="card-title">
              <h2>
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_SETTINGS_SETTINGS_CARD_TITLE",
                })}
              </h2>
            </div>
          </div>
        )}
        <div className="overflow-auto">
          <div
            className="card-body p-4 d-flex flex-column gap-6 my-4"
            style={{ overflow: "inherit!important" }}
          >
            <div className="d-flex justify-content-start  align-items-center">
              <div
                className={`fw-bold mx-5 ${fromInvoiceEdit && "required"}`}
                style={{ width: "50%" }}
              >
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_SETTINGS_GENERAL_TAB_QUESTION_3",
                })}
              </div>
              <div style={{ width: "50%" }}>
                <div
                  className="px-2 form-input form-input-control "
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowListModal(true);
                    setModalOpen?.(true);
                  }}
                >
                  <span
                    style={{ backgroundColor: "#eef3f7" }}
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                  >
                    {generalInvoices[0]?.value}
                  </span>
                </div>
                {error && fromInvoiceEdit && !generalInvoices[0]?.value && (
                  <span
                    className="text-danger d-flex justify-content-center alig-items-center "
                    style={{ marginLeft: "154px" }}
                  >
                    {error}
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-start  align-items-center">
              <div className="fw-bold mx-5" style={{ width: "50%" }}>
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_SETTINGS_GENERAL_INVOICE_TAB_NOTES_QUESTION",
                })}
              </div>
              <div style={{ width: "50%" }}>
                <div
                  className="px-2 form-input form-input-control "
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowNotesModal(true);
                    setModalOpen?.(true);
                  }}
                >
                  <span
                    style={{ backgroundColor: "#eef3f7" }}
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                  >
                    {generalInvoices[1]?.value}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!fromInvoiceEdit && (
        <LayoutCancelInvoice
          numberCancelledInvoices={numberCancelledInvoices}
          getSalesSettingsApi={getSalesSettingsApi}
        />
      )}
    </>
  );
};

export default LayoutInvoices;
