import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FieldType } from "../types/FieldsReponseTypes";
import Fields from "./Fields";
import ProjectTabs from "./ProjectTabs";
import { TabType } from "../types/TabsResponseType";

export interface FieldSelectModalProps {
  field_id: number;
  is_required: number;
}

interface Props {
  show: boolean;
  closeModal: () => void;
  onSelectedTabs: (tabs: TabType[]) => void;
  isSelectionModal?: boolean;
  selectedTabs?: TabType[] | undefined | any;
}

const SelectTabsModal: FC<Props> = ({
  show,
  closeModal,
  onSelectedTabs,
  isSelectionModal,
  selectedTabs,
}) => {
  const intl = useIntl();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  return (
    <Modal
      show={show}
      centered
      dialogClassName="extra-large-size-modal"
      className={`${isFilterOpen ? "d-none" : "d-block"}`}
      backdrop="static"
      onHide={closeModal}
      contentClassName="overflow-auto"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "MASTERDATA_TABS_EDIT_NEW_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="overflow-inherit"
        // className={`${isSelectionModal ? "selections_modal" : ""}`}
        // style={{ padding: "0px" }}
      >
        <ProjectTabs
          onSelectedTabs={onSelectedTabs}
          isSelectionModal={isSelectionModal}
          selectedTabs={selectedTabs}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SelectTabsModal;
