import React, { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

// custom
import { actions, registerUser } from "../redux";
import { useDispatch } from "react-redux";
// import PasswordMeter from '../../../sharedComponents/passwordMeter/passwordMeter'
import { useIntl } from "react-intl";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { successToast } from "../../../sharedComponents/toasts/Toasts";
// import {RootState} from '../../../../setup'
// import {RoleType} from '../../RolesAndPermissions/types/getRolesResponseType'

interface Props {
  show: boolean;
  closeModal?: () => void;
  getUsersListAPI?: () => void;
}

const initialValues = {
  salutation: 1,
  title: 0,
  firstname: "",
  lastname: "",
  email: "",
  username: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
  role_id: undefined,
};

const AddUserModal: FC<Props> = ({ show, closeModal, getUsersListAPI }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const SALUATION_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_SALUATION_OPTIONS" })
  ) as any[];
  const TITLES_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_TITLES_OPTIONS" })
  ) as any[];

  // selectors
  const allRoles = useSelector<RootState>(
    // @ts-ignore
    ({ rolesAndPermissionsManagement }) => rolesAndPermissionsManagement?.roles
  ) as RoleType[];

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const AddUserSchema = Yup.object().shape({
    salutation: Yup.string().required(
      intl.formatMessage({ id: "USERS_SALUATION_REQUIRED" })
    ),
    firstname: Yup.string()
      .max(60, intl.formatMessage({ id: "USERS_60SYMBOLS_REQUIRED" }))
      .required(intl.formatMessage({ id: "USERS_FIRSTNAME_REQUIRED" })),
    lastname: Yup.string()
      .max(60, intl.formatMessage({ id: "USERS_60SYMBOLS_REQUIRED" }))
      .required(intl.formatMessage({ id: "USERS_LASTNAME_REQUIRED" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "USERS_WRONG_EMAIL_FORMAT" }))
      .max(60, intl.formatMessage({ id: "USERS_60SYMBOLS_REQUIRED" }))
      .required(intl.formatMessage({ id: "USERS_EMAIL_REQUIRED" })),
    username: Yup.string().matches(
      /^[a-zA-Z0-9]*$/,
      intl.formatMessage({ id: "USERS_USERNAME_FIELD_ERROR" })
    ),
    password: Yup.string()
      .required(intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" }))
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        intl.formatMessage({ id: "USERS_PASSWORD_MATCHES_INFO" })
      ),
    changepassword: Yup.string()
      .required(intl.formatMessage({ id: "USERS_CONFIRM_PASSWORD_REQUIRED" }))
      .when("password", {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({ id: "USERS_PASSWORD_MISMATCH" })
        ),
      }),
    role_id: Yup.number().required(
      intl.formatMessage({ id: "USERS_ADD_ROLE_FIELD_ERROR" })
    ),
    // acceptTerms: Yup.bool().equals([true], 'You must accept the terms and conditions'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: AddUserSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      registerUser(
        values.salutation,
        values.title,
        values.firstname,
        values.lastname,
        values.email,
        values.username,
        values.password,
        values.changepassword,
        // @ts-ignore
        values.role_id
      )
        .then(({ data: { userDetails } }) => {
          closeModal?.();
          // redirect
          history.push(`/system/user/edituser`, { userDetails });
          successToast(
            intl.formatMessage({ id: "USERS_ADD_SUCCESS_MESSAGE" })
          );
          dispatch(actions.setUserDetails(userDetails));
        })
        .catch((err) => {
          err.response?.data?.error?.email
            ? setStatus(err.response.data?.error?.email[0])
            : setStatus(
                intl.formatMessage({ id: "USERS_ADD_FAILURE_MESSAGE" })
              );
        })
        .finally(() => {
          getUsersListAPI?.();
          setLoading(false);
        });
    },
  });
  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "USERS_ADD_NEW_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form" className="overflow-auto">
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="mb-4">
              <label className="required fw-bold fs-6 mb-2 mb-2">
                {intl.formatMessage({ id: "USERS_SALUATION_TITLE" })}
              </label>
              <DropDown
                hideSearch
                id="users-salutaions-dropdown"
                items={SALUATION_OPTIONS}
                displayFunc={(item) => {
                  return item.name;
                }}
                displayValue={
                  SALUATION_OPTIONS.find(
                    (SALUATION_OPTION) =>
                      SALUATION_OPTION.id === formik.values.salutation
                  )?.name || ""
                }
                onSelectItem={(item) => {
                  //@ts-ignore
                  formik.setFieldValue("salutation", item.id);
                }}
                selectedItem={SALUATION_OPTIONS.find(
                  (SALUATION_OPTION) =>
                    SALUATION_OPTION.id === formik.values.salutation
                )}
              />
            </div>

            {/* Titles */}
            <div className="mb-4">
              <label className="fw-bold fs-6 mb-2">
                {intl.formatMessage({ id: "USERS_TITLES_NAME" })}
              </label>
              <DropDown
                hideSearch
                id="users-titles-dropdown"
                items={TITLES_OPTIONS}
                displayFunc={(item) => {
                  return item.name;
                }}
                displayValue={
                  TITLES_OPTIONS.find(
                    (TITLES_OPTION) => TITLES_OPTION.id === formik.values.title
                  )?.name || ""
                }
                onSelectItem={(item) => {
                  //@ts-ignore
                  formik.setFieldValue("title", item.id);
                }}
                selectedItem={TITLES_OPTIONS.find(
                  (TITLES_OPTION) => TITLES_OPTION.id === formik.values.title
                )}
              />
            </div>

            {/* begin::Form group Firstname */}
            <div className="row fv-row">
              <div className="col-lg-6 mb-4">
                <label className="required fw-bold fs-6 mb-2">
                  {intl.formatMessage({ id: "USERS_FIRSNAME_FIELD" })}
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  {...formik.getFieldProps("firstname")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.firstname}</span>
                  </div>
                )}
              </div>
              <div className="col-lg-6 mb-4">
                {/* begin::Form group Lastname */}
                <div className="fv-row">
                  <label className="required fw-bold fs-6 mb-2">
                    {intl.formatMessage({ id: "USERS_LASTNAME_FIELD" })}
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    {...formik.getFieldProps("lastname")}
                    className={clsx(
                      "form-control form-control-lg form-control-solid"
                    )}
                  />
                  {formik.touched.lastname && formik.errors.lastname && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.lastname}</span>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Email */}
            <div className="fv-row mb-4">
              <label className="required fw-bold fs-6 mb-2">
                {intl.formatMessage({ id: "USERS_EMAIL_FIELD" })}
              </label>
              <input
                type="email"
                autoComplete="new-email"
                {...formik.getFieldProps("email")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Username */}
            {/* <div className='fv-row mb-4'>
              <label className=' fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CUSTOMER_USERNAME_FIELD'})}
              </label>
              <input
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('username')}
                className={clsx('form-control form-control-lg form-control-solid')}
              />
              {formik.touched.username && formik.errors.username && (
                <div className='text-danger'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              )}
            </div> */}

            {/* begin::Form group Password */}
            <div className="mb-4 fv-row" data-kt-password-meter="true">
              <div>
                <label className="required fw-bold fs-6 mb-2">
                  {intl.formatMessage({ id: "USERS_PASSWORD_FIELD" })}
                </label>
                <div className="position-relative mb-3">
                  <div className="position-relative">
                  <input
                     type={showNewPassword ? "text" : "password"}
                    autoComplete="new-password"
                    {...formik.getFieldProps("password")}
                    className={clsx(
                      "form-control form-control-lg form-control-solid"
                    )}
                  />
                   <div
              className="position-absolute"
              role={"button"}
              style={{ top: 14, right: 18 }}
              onClick={() => {
                setShowNewPassword(!showNewPassword);
              }}
            >
              <i
                className={clsx(
                  "bi fs-3 fw-bolder text-gray-500",
                  showNewPassword ? "bi-eye-slash-fill" : "bi-eye-fill"
                )}
              />
            </div>
                  </div>

                  {formik.touched.password && formik.errors.password && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  )}
                </div>
                {/* begin::Meter */}
                {/* <PasswordMeter /> */}
                {/* end::Meter */}
              </div>
              <div className="text-muted">
                {intl.formatMessage({ id: "USERS_PASSWORD_INFO" })}
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className="fv-row mb-4">
              <label className="required fw-bold fs-6 mb-2">
                {intl.formatMessage({ id: "USERS_CONFIRM_PASSWORD_FIELD" })}
              </label>
              <div className="position-relative">
              <input
                 type={showConfirmPassword ? "text" : "password"}
                autoComplete="off"
                {...formik.getFieldProps("changepassword")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
              />
               <div
              className="position-absolute"
              role={"button"}
              style={{ top: 14, right: 18 }}
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            >
              <i
                className={clsx(
                  "bi fs-3 fw-bolder text-gray-500",
                  showConfirmPassword ? "bi-eye-slash-fill" : "bi-eye-fill"
                )}
              />
            </div>
            </div>
              {formik.touched.changepassword &&
                formik.errors.changepassword && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.changepassword}</span>
                  </div>
                )}

            </div>

            {/* role */}
            <div className="mb-8">
              <label className="required fw-bold fs-6 mb-2">
                {intl.formatMessage({ id: "USERS_ADD_ROLE_FIELD_TITLE" })}
              </label>
              <DropDown
                hideSearch
                id="users-salutaions-dropdown"
                items={allRoles}
                displayFunc={(item) => {
                  return item.name;
                }}
                displayValue={
                  allRoles.find((role) => role.id === formik.values.role_id)
                    ?.name || ""
                }
                onSelectItem={(item) => {
                  //@ts-ignore
                  formik.setFieldValue("role_id", item.id);
                }}
                selectedItem={allRoles.find(
                  (role) => role.id === formik.values.role_id
                )}
              />
              {formik.touched.role_id && formik.errors.role_id && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.role_id}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          {/* <Button type='submit' id='kt_sign_in_submit' className='btn btn-light-primary'> */}
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-light-primary"
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>

          {/* </Button> */}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddUserModal;
