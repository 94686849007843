import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import _ from "lodash";

// svg
import { SVGICON } from "../../../../_metronic/helpers";
import CallIcon from "../../../../_metronic/assets/icons/telephone.svg";
import EmailIcon from "../../../../_metronic/assets/icons/envelope.svg";
import OthersIcon from "../../../../_metronic/assets/icons/clipboard.svg";
import AddUserIcon from "../../../../_metronic/assets/icons/add-user.svg";

// Components
import { Button, Modal, CloseButton } from "react-bootstrap";

// Custom Components
import { UserSymbol, AddUserSymbol } from "./UserSymbol";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import SelectedUsersModal from "../../teams/components/SelectUsersModal";

import { RootState } from "../../../../setup";

// Types
import { FieldOptionType, TaskFilterModalProps } from "../types/TasksPropsType";
import { UserType } from "../../userManagement/types/getUsersListResponseType";

interface Props {
  modalProps: TaskFilterModalProps;
  filters: any;
  setFilters: (newFilters: any) => void;
  closeModal: () => void;
}

const TasksFilterModal: FC<Props> = ({
  modalProps,
  filters,
  setFilters,
  closeModal,
}) => {
  const intl = useIntl();
  const usersList = useSelector<RootState>(
    // @ts-ignore
    ({ userManagement }) => userManagement.users
  ) as UserType[];

  const { show, isPageMenuPoint, usersDeals } = modalProps;

  // state
  const [tempFilters, setTempFilters] = useState<any>({ ...filters });
  const [showUsersModal, setShowUsersModal] = useState<boolean>(false);

  // Field Options
  const PRIORITY_TYPES = JSON.parse(
    intl.formatMessage({ id: "TASKS_ADD_TASK_PRIORITY_TYPES" })
  ) as FieldOptionType[];
  const TYPE_TYPES = JSON.parse(
    intl.formatMessage({ id: "TASKS_ADD_TASK_TYPE_TYPES" })
  ) as FieldOptionType[];

  // Handlers
  const addUserHandle = (users: UserType[]) => {
    const finalIds = Array.from(
      new Set([
        ...users.map((user) => user.id),
        ...(tempFilters?.responsibles || []),
      ])
    );
    setTempFilters((prevFilters: any) => ({
      ...prevFilters,
      responsibles: finalIds,
    }));
  };

  const removeUserHandle = (userId: number) => {
    const remainingUsers =
      tempFilters?.responsibles?.filter((id: number) => id !== userId) || [];
    setTempFilters(
      remainingUsers.length
        ? { ...tempFilters, responsibles: remainingUsers }
        : _.omit(tempFilters, "responsibles")
    );
  };

  const handleSelectChange = (key: string, value: any) => {
    if (key !== "deals") {
      setTempFilters((prevFilters: any) => ({ ...prevFilters, [key]: value }));
    } else {
      let selectedDeals = tempFilters?.deals || [];
      const doesSelectedDealsExists = selectedDeals
        .map((item: any) => item.id)
        .includes(value.id);
      if (doesSelectedDealsExists) {
        selectedDeals = selectedDeals.filter(
          (item: any) => item.id !== value.id
        );
      } else {
        selectedDeals.push({
          id: value.id,
          name: value.name,
          pipeline: value.pipeline_name,
        });
      }

      setTempFilters((prevFilters: any) => ({
        ...prevFilters,
        [key]: selectedDeals,
      }));
    }
  };

  const handleSubmit = () => {
    setFilters(tempFilters);
    closeModal();
  };

  const responsiblePersons = usersList.filter((user) =>
    tempFilters?.responsibles?.includes(user.id)
  );

  return (
    <>
      {showUsersModal && (
        <SelectedUsersModal
          show={showUsersModal}
          closeModal={() => setShowUsersModal(false)}
          onSelectUsers={(customers) => {
            setShowUsersModal(false);
            addUserHandle(customers);
          }}
          isSelectionModal={true}
          selectedContacts={usersList.filter((user) =>
            tempFilters?.responsibles?.includes(user.id)
          )}
        />
      )}
      <Modal
        show={show}
        centered
        dialogClassName="medium-size-modal"
        contentClassName={showUsersModal ? "d-none" : "d-block"}
        backdrop="static"
        className="overflow-visible"
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>
            {intl.formatMessage({ id: "FILTER_BUTTON" })}
          </Modal.Title>
          <div className=" d-flex align-items-center">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setFilters({});
                closeModal();
              }}
            >
              {intl.formatMessage({ id: "RESET_BUTTON" })}
            </button>
            <CloseButton className="ms-2" onClick={closeModal} />
          </div>
        </Modal.Header>
        <div className="">
          <Modal.Body className="overflow-inherit">
            <div className="p-4">
              {/* Task Type Filter */}
              <div className="mb-8">
                <label className="form-label">
                  {intl.formatMessage({
                    id: "TASKS_ADD_TASK_TYPE_FIELD_NAME",
                  })}
                </label>
                <DropDown
                  id="task_type"
                  items={TYPE_TYPES || []}
                  selectedItem={tempFilters?.type}
                  onSelectItem={(item) => handleSelectChange("type", item.name)}
                  displayValue={
                    TYPE_TYPES.filter(
                      (item) => item.name === tempFilters?.type
                    )[0]?.title
                  }
                  displayFunc={(item) => {
                    return (
                      <span className="d-flex align-items-center">
                        <SVGICON
                          src={
                            item.name === "call"
                              ? CallIcon
                              : item.name === "email"
                                ? EmailIcon
                                : item.name === "other"
                                  ? OthersIcon
                                  : null
                          }
                          className="svg-icon-3 me-4"
                        />
                        {/* @ts-ignore */}
                        {item?.title}
                      </span>
                    );
                  }}
                  hideSearch
                />
              </div>
              {/* Task Priority Filter */}
              <div className="mb-8">
                <label className="form-label">
                  {intl.formatMessage({
                    id: "TASKS_ADD_TASK_PRIORITY_FIELD_PLACEHOLDER",
                  })}
                </label>
                <DropDown
                  id="task_priority"
                  items={PRIORITY_TYPES || []}
                  selectedItem={tempFilters?.priority}
                  onSelectItem={(item) =>
                    handleSelectChange("priority", item.name)
                  }
                  displayValue={
                    PRIORITY_TYPES.filter(
                      (item) => item.name === tempFilters?.priority
                    )[0]?.title
                  }
                  displayFunc={(item) => {
                    return (
                      <span className="d-flex align-items-center">
                        {/* @ts-ignore */}
                        {item?.title}
                      </span>
                    );
                  }}
                  hideSearch
                />
              </div>
              {/* Deals Filter */}
              {isPageMenuPoint && (
                <div className="mb-8">
                  <label className="form-label">
                    {intl.formatMessage({
                      id: "TASKS_LIST_FILTER_DEALS_FIELD_NAME",
                    })}
                  </label>
                  <DropDown
                    id="task_deal"
                    items={
                      usersDeals.filter((item) => {
                        return !tempFilters?.deals?.some(
                          (item2: any) => item2.id === item.id
                        );
                      }) || []
                    }
                    selectedItems={tempFilters?.deals || []}
                    onSelectItem={(item) => {
                      handleSelectChange("deals", item);
                    }}
                    multiSelect
                    hideSelectedItems
                    displayValue={
                      usersDeals?.filter(
                        (item: any) => item?.name === tempFilters?.deal
                      )[0]?.name
                    }
                    enableHoverBackground
                    displayFunc={(item) =>
                      // @ts-ignore
                      item?.name + " - " + item?.pipeline_name
                    }
                    searchPlaceholder={intl.formatMessage({
                      id: "TASKS_ADD_TASK_DEAL_FIELD_SEARCH_PLACEHOLDER",
                    })}
                  />
                </div>
              )}
              {/* Task Responsible Persons Filter */}
              <div className="row mb-8">
                <div className="col-12">
                  <label className="form-label">
                    {intl.formatMessage({
                      id: "TASKS_FILTER_RESPONSIBLE_PERSONS_FIELD_NAME",
                    })}
                  </label>
                </div>
                <div className="col-1 py-2">
                  <SVGICON src={AddUserIcon} className="svg-icon-1 me-0" />
                </div>
                <div className="col-11 ps-0">
                  <div className="d-flex justify-content-start">
                    <div className="symbol-group symbol-hover flex-nowrap">
                      {responsiblePersons.map((data, i) => (
                        <UserSymbol
                          key={i}
                          data={data}
                          handleRemove={removeUserHandle}
                        />
                      ))}
                      <AddUserSymbol
                        tooltipText="Find Responsible Person"
                        onClick={() => {
                          setShowUsersModal(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button className="btn btn-primary" onClick={handleSubmit}>
            {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TasksFilterModal;
