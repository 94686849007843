import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// images
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
// custom
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import FilterIcon from "../../../../_metronic/assets/icons/filter.svg";
import Arrow from "../../../../_metronic/assets/icons/arrow.svg";
import DownArrow from "../../../../_metronic/assets/icons/downArrow.svg";
import Constants from "../../../config/Constants";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { getDateInFormat, getDiffFromNow } from "../../../utils/DateFormatter";
import { useFullName } from "../../../utils/getFullName";
import { actions } from "../redux";
import { getUsers } from "../redux/UserManagementAPI";
import { UserType } from "../types/getUsersListResponseType";
import AddUserModal from "./AddUserModal";
import UsersListActionsDropdown from "./UsersListActionsDropdown";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import _ from "lodash";
import { TeamUserType } from "../../teams/types/TeamsResponseType";
import UsersFilterModal from "./UsersFilterModal";
import { FilterTag } from "../../tasks/components/FilterTag";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";

interface Props {
  type?: string;
  onSelectUser?: (user: UserType) => void;
  onSelectUsers?: (users: UserType[]) => void;
  isSelectionModal?: boolean;
  selectedContacts?: TeamUserType[];
  setIsFilterOpen?: any;
  selectMultiple?: boolean;
}

interface SortType {
  name: string;
  type: "asc" | "desc" | "";
}

const UsersList: FC<Props> = ({
  type,
  onSelectUser,
  onSelectUsers,
  isSelectionModal,
  selectedContacts,
  setIsFilterOpen,
  selectMultiple,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const getFullName = useFullName();

  const users = useSelector<RootState>(
    // @ts-ignore
    ({ userManagement }) => userManagement.users
  ) as UserType[];
  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  //@ts-ignore
  const usersPageDetails = useSelector<RootState>(
    // @ts-ignore
    ({ userManagement }) => userManagement.usersPageDetails
  );
  // state
  const [displayUsers, setDisplayUsers] = useState<UserType[]>(users);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [checkedCustomers, setCheckedCustomers] = useState<UserType[]>([]);
  //@ts-ignore
  const [activePage, setActivePage] = useState<number>(
    // @ts-ignore
    usersPageDetails && usersPageDetails.activePage
      ? // @ts-ignore
        usersPageDetails.activePage
      : 1
  );
  const [searchText, setSearchText] = useState<string>("");
  const [getUsersListAPICompleted, setGetUsersListAPICompleted] =
    useState<boolean>(false);
  //@ts-ignore
  const [itemsPerPage, setItemsPerPage] = useState(
    // @ts-ignore
    usersPageDetails && usersPageDetails.itemsPerPage
      ? // @ts-ignore
        usersPageDetails.itemsPerPage
      : Constants.defaultItemsPerPageCount
  );
  const [filters, setFilters] = useState<any>({});
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [sortView, setSortView] = useState<SortType>({
    name: "",
    type: "",
  });

  const isSelectCustomerType = () => {
    return type === "select";
  };

  const closeModal = () => {
    setShowAddUserModal(false);
  };

  const getUsersListAPI = () => {
    getUsers()
      .then(({ data: { users } }) => {
        dispatch(actions.setUsersList(users));
      })
      .catch(() => {})
      .finally(() => {
        setGetUsersListAPICompleted(true);
      });
  };

  useEffect(() => {
    getUsersListAPI();
  }, []);

  const onPageClick = (page: number) => {
    setActivePage(page);
    dispatch(
      actions.setUsersPageDetails({
        activePage: page,
        itemsPerPage: itemsPerPage,
      })
    );
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
    setActivePage(1);
    dispatch(
      actions.setUsersPageDetails({
        activePage: 1,
        itemsPerPage: itemsPerPage,
      })
    );
  };

  const isChecked = (customer: UserType) => {
    const index = checkedCustomers.findIndex(
      (checkedCustomer) => checkedCustomer.id === customer.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const onCheckedChange = (customer: UserType) => {
    if (!selectMultiple) {
      setCheckedCustomers([customer]);
    } else {
      let checkedCustomersCopy = _.clone(checkedCustomers);
      const index = checkedCustomersCopy.findIndex(
        (checkedCustomer) => checkedCustomer.id === customer.id
      );
      if (index > -1) {
        checkedCustomersCopy.splice(index, 1);
      } else {
        checkedCustomersCopy.push(customer);
      }
      setCheckedCustomers(checkedCustomersCopy);
    }
  };

  const handleSort = (columnName: string) => {
    if (sortView.type === "asc") {
      setSortView({ name: columnName, type: "desc" });
    } else if (sortView.type === "desc") {
      setSortView({ name: columnName, type: "" });
    } else {
      setSortView({ name: columnName, type: "asc" });
    }
  };

  const handleSubmitFilter = () => {
    let filteredUsers = [...users];

    // Filter by user roles
    if (filters?.role) {
      filteredUsers = filteredUsers.filter(
        (item) => item?.role?.name === filters?.role
      );
    }

    // Filter by Search
    if (searchText) {
      const searchTextLower = searchText.toLowerCase();
      filteredUsers = filteredUsers.filter((item) => {
        const firstname = item?.firstname?.toLowerCase();
        const lastname = item?.lastname?.toLowerCase();
        const email = item?.email?.toLowerCase();
        const username = item?.username?.toLowerCase();
        return (
          firstname?.includes(searchTextLower) ||
          lastname?.includes(searchTextLower) ||
          email?.includes(searchTextLower) ||
          username?.includes(searchTextLower)
        );
      });
    }

    // Sorting
    const sortField = sortView.name;
    const sortOrder = sortView.type;

    if (sortOrder !== "") {
      // Sort by user full name
      if (sortField === "user_name") {
        // @ts-ignore
        filteredUsers?.sort((a, b) => {
          const afullName =
            a.firstname.toLowerCase() + a.lastname.toLowerCase();
          const bfullName =
            b.firstname.toLowerCase() + b.lastname.toLowerCase();
          const result = afullName.localeCompare(bfullName);
          return sortOrder === "asc" ? result : -result;
        });
      }
      // Sort by last login datetime
      if (sortField === "last_login") {
        // @ts-ignore
        filteredUsers?.sort((a, b) => {
          // @ts-ignore
          const dateA = new Date(a.last_login).getTime();
          // @ts-ignore
          const dateB = new Date(b.last_login).getTime();
          return sortOrder === "asc" ? dateB - dateA : dateA - dateB;
        });
      }
      //Sort by created_at datetime
      if (sortField === "created_at") {
        // @ts-ignore
        filteredUsers?.sort((a, b) => {
          // @ts-ignore
          const dateA = new Date(a.created_at).getTime();
          // @ts-ignore
          const dateB = new Date(b.created_at).getTime();
          return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        });
      }
    }

    setDisplayUsers(filteredUsers);
  };

  const handleRemoveFilter = (key: string) => {
    const filtersCopy = _.omit(filters, key);
    setFilters(filtersCopy);
  };

  // Memoized pagination logic
  const paginatedUsers = useMemo(() => {
    const startIndex = itemsPerPage * (activePage - 1);
    return displayUsers.slice(startIndex, startIndex + itemsPerPage);
  }, [displayUsers, activePage, itemsPerPage]);

  useEffect(() => {
    if (paginatedUsers.length === 0 && displayUsers.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedUsers]);

  useEffect(() => {
    handleSubmitFilter();
  }, [users, filters, searchText, sortView]);

  useEffect(()=>{
    ScrollToTop()
  },[activePage])

  return (
    <div className="card borderNone mt-7">
      {showAddUserModal && (
        <AddUserModal
          show={showAddUserModal}
          closeModal={closeModal}
          getUsersListAPI={getUsersListAPI}
        />
      )}
      {showFilterModal && (
        <UsersFilterModal
          show={showFilterModal}
          filters={filters}
          setFilters={setFilters}
          closeModal={() => {
            setShowFilterModal(false);
          }}
        />
      )}

      {/* begin::Header */}
      <div className="card-header border-0 pt-5 d-flex flex-row justify-content-between">
        <div className="d-flex align-items-center pt-4">
          <SVGICON
            src={SearchIcon}
            className="svg-icon svg-icon-1 position-absolute ms-6"
          />
          <input
            type="text"
            className="form-control form-control-solid w-250px ps-15"
            placeholder={intl.formatMessage({ id: "USERS_SEARCH_PLACEHOLDER" })}
            onChange={(e) => {
              onSearchTextChange(e.target.value);
            }}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center pt-4">
          {/* Filters Badges */}
          <div className="row mx-0 g-2">
            {!showFilterModal &&
              Object.keys(filters).map((key, i) => {
                if (filters[key] !== null) {
                  return (
                    <FilterTag
                      key={i}
                      keyName={key}
                      value={filters[key]}
                      onRemove={handleRemoveFilter}
                      tooltipMessage={
                        key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()
                      }
                    />
                  );
                } else {
                  return null;
                }
              })}
          </div>
          {checkedCustomers.length > 0 && isSelectCustomerType() && (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedCustomers.length}{" "}
                {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn  btn-primary"
                onClick={() => {
                  onSelectUsers?.(checkedCustomers);
                }}
              >
                <span className="indicator-label">
                  {intl.formatMessage({ id: "APPLY_BUTTON" })}
                </span>
              </button>
            </>
          )}
          {!isSelectCustomerType() && (
            <>
              {/* Filter Button */}
              <button
                type="button"
                className="btn btn-primary me-4"
                onClick={() => setShowFilterModal(true)}
              >
                <SVGICON src={FilterIcon} className="svg-icon-2" />
                {intl.formatMessage({ id: "FILTER_BUTTON" })}
              </button>

              {/* Add New User Button */}
              {userData?.role?.id === 1 && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setShowAddUserModal(true);
                  }}
                >
                  <SVGICON
                    src={PlusIcon}
                    className="svg-icon-2 addPlusSvgWhite"
                  />
                  {intl.formatMessage({ id: "USERS_ADD_NEW_USER_BUTTON" })}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="overflow-auto">
        <div
          className="card-body py-3"
          style={{ overflow: "inherit !important" }}
        >
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
              {/* begin::Table head */}
              <thead>
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  {isSelectCustomerType() && (
                    <th className="w-25px">
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                          disabled
                        />
                      </div>
                    </th>
                  )}
                  <th
                    className="min-w-125px"
                    role="button"
                    onClick={() => handleSort("user_name")}
                  >
                    {intl.formatMessage({ id: "USERS_TABLE_COLUMN1" })}
                    {sortView?.name === "user_name" &&
                      (sortView.type === "asc" || sortView.type === "desc") && (
                        <span>
                          <SVGICON
                            src={sortView.type === "asc" ? Arrow : DownArrow}
                            className="svg-icon-2"
                          />
                        </span>
                      )}
                  </th>
                  <th className="min-w-125px">
                    {intl.formatMessage({ id: "USERS_TABLE_COLUMN2" })}
                  </th>
                  <th
                    className="min-w-125px"
                    role="button"
                    onClick={() => handleSort("last_login")}
                  >
                    {intl.formatMessage({ id: "USERS_TABLE_COLUMN3" })}
                    {sortView?.name === "last_login" &&
                      (sortView.type === "asc" || sortView.type === "desc") && (
                        <span>
                          <SVGICON
                            src={sortView.type === "asc" ? Arrow : DownArrow}
                            className="svg-icon-2"
                          />
                        </span>
                      )}
                  </th>
                  <th className="min-w-125px">
                    {intl.formatMessage({ id: "USERS_TABLE_COLUMN4" })}
                  </th>
                  <th
                    className="min-w-125px"
                    role="button"
                    onClick={() => handleSort("created_at")}
                  >
                    {intl.formatMessage({ id: "USERS_TABLE_COLUMN5" })}
                    {sortView?.name === "created_at" &&
                      (sortView.type === "asc" || sortView.type === "desc") && (
                        <span>
                          <SVGICON
                            src={sortView.type === "asc" ? Arrow : DownArrow}
                            className="svg-icon-2"
                          />
                        </span>
                      )}
                  </th>
                  <th className="min-w-100px"></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {paginatedUsers &&
                  paginatedUsers?.length > 0 &&
                  paginatedUsers?.map((user, i) => {
                    return (
                      <tr key={i}>
                        {/* checkbox*/}
                        {isSelectCustomerType() && (
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
                              <Checkbox
                                onChange={() => {
                                  onCheckedChange(user);
                                }}
                                checked={isChecked(user)}
                                disabled={selectedContacts
                                  ?.map(
                                    (item) =>
                                      item?.id ||
                                      // @ts-ignore
                                      item?.user_id ||
                                      // @ts-ignore
                                      item?.users_id
                                  )
                                  .includes(user?.id)}
                              />
                            </div>
                          </td>
                        )}

                        {/* user name,email,pic */}
                        <td>
                          <div className="d-flex align-items-center">
                            {/* <div className='symbol symbol-45px me-5'>
                            <img src={toAbsoluteUrl('/media/avatars/150-11.jpg')} alt='' />
                          </div> */}
                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                              <div className="symbol-label">
                                {user.profile_photo_url ? (
                                  <img
                                    placeholder={BlankAvatar}
                                    src={user.profile_photo_url}
                                    alt={user.firstname}
                                    style={{
                                      // minHeight: "100%",
                                      minWidth: "100%",
                                      objectFit: "cover",
                                    }}
                                    className="w-100"
                                  />
                                ) : (
                                  <div className="symbol-label fs-3 bgBrandLightPink text-uppercase brandPink">
                                    {(user.firstname || " ")[0]}
                                    {(user.lastname || " ")[0]}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="d-flex justify-content-start flex-column">
                              <span
                                className={`text-gray-800 fw-bold text-hover-brandPink text-capitalize mb-1 ${userData?.role?.id !== 1 && "pe-none"}`}
                                role="button"
                                onClick={() => {
                                  history.push(`/system/user/edituser`, {
                                    userDetails: user,
                                  });
                                }}
                              >
                                {user.firstname + " " + user.lastname}
                              </span>
                              <span className="text-gray-600 fw-bold">
                                {user.email}
                              </span>
                            </div>
                          </div>
                        </td>

                        {/* role */}
                        <td>
                          <span className="text-gray-600 fw-bold">
                            {user.role.name || "-"}
                          </span>
                        </td>

                        {/* last login */}
                        <td>
                          {user.last_login ? (
                            <div className="badge badge-light fw-bolder">
                              {getDiffFromNow(user.last_login)}
                            </div>
                          ) : (
                            <span></span>
                          )}
                        </td>

                        {/* 2-step verification */}
                        <td>
                          {user.has2FA ? (
                            <div className="badge badge-light-success fw-bolder">
                              {intl.formatMessage({
                                id: "USERS_VERIFICATION_ENABLED_TEXT",
                              })}
                            </div>
                          ) : (
                            <div className="badge badge-light-danger fw-bolder">
                              {intl.formatMessage({
                                id: "USERS_VERIFICATION_DISABLED_TEXT",
                              })}
                            </div>
                          )}
                        </td>

                        {/* joined date */}
                        <td>
                          {user.created_at ? (
                            <span className="text-gray-600 fw-bold">
                              {getDateInFormat(
                                user.created_at,
                                "DD MMM YYYY, HH:mm"
                              )}
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </td>

                        {/* actions */}
                        {isSelectCustomerType() ? (
                          ""
                        ) : (
                          <td>
                            <UsersListActionsDropdown
                              userDetails={user}
                              getUsersListAPI={getUsersListAPI}
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })}

                {!getUsersListAPICompleted && paginatedUsers?.length === 0 && (
                  <tr>
                    <td valign="top" colSpan={7} className="dataTables_empty">
                      <SimpleLoader />
                    </td>
                  </tr>
                )}

                {/* no data */}
                {getUsersListAPICompleted && paginatedUsers?.length === 0 && (
                  <NoItemsFound languageKey="NO_ITEMS_FOUND"/>
                )}
              </tbody>
              {/* end::Table body */}
            </table>

            {/* end::Table */}
            {users.length > 0 && (
              <Pagination
                totalPages={Math.ceil(displayUsers.length / itemsPerPage)}
                activePage={
                  Math.ceil(displayUsers.length / itemsPerPage) === 1
                    ? 1
                    : activePage
                }
                onPageClick={onPageClick}
                itemsPerPage={itemsPerPage}
                showItemsPerPage
                setItemsPerPage={(count) => {
                  setItemsPerPage(count);
                  setActivePage(1);
                  dispatch(
                    actions.setUsersPageDetails({
                      activePage: 1,
                      itemsPerPage: count,
                    })
                  );
                }}
              />
            )}
          </div>
          {/* end::Table container */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

export default UsersList;
