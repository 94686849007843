import React, { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

// custom
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { CountryType } from "../../masterdata/types/GetCountriesListResponseTypes";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import { updateCompanyGeneralDetails } from "../redux/CompanySettingsAPi";

interface Props {
  partner: any;
}

const CompanyGeneralDetails: FC<Props> = ({ partner }) => {
  const countries = useSelector<RootState>(
    ({ masterdata }) => masterdata.countriesList
  ) as CountryType[];

  const intl = useIntl();
  const textareaRef = useRef(null);

  // state

  const [loading, setLoading] = useState<boolean>(false);
  const [titleLength, setTitleLength] = useState<number>(1);
  const [selectedCountry, setSelectedCountry] = useState<CountryType>();
  const [width, setWidth] = useState({});

  const PostGeneralDetailsSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({
        id: "GENERAL_DETAILS_FIELD_COMPANYNAME_VALIDATION_ERROR",
      })
    ),
  });

  const initialValues = {
    name: partner?.name || "",
    address_1: partner?.street || "",
    address_2: partner?.street_extra || "",
    zip_code: partner?.zipcode || undefined,
    city: partner?.city || "",
    country_id: partner?.country_id || undefined,
    email: partner?.email || "",
    website_url: partner?.website || "",
    telephone: partner?.telephone || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: PostGeneralDetailsSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      if (partner?.id && values.name) {
        setLoading(true);
        setStatus(null);
        updateCompanyGeneralDetails(
          partner.id,
          values.name,
          values.address_1,
          values.address_2,
          values.zip_code || null,
          values.city,
          values.country_id || null,
          values.email,
          values.website_url,
          values.telephone
        )
          .then(() => {
            successToast(
              intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_UPDATE_SUCCESS",
              })
            );
          })
          .catch((e) => {
            if (e.response?.status === 422) {
              setStatus(
                intl.formatMessage({
                  id: "NAME_ALREADY_EXISTS",
                })
              );
              errorToast(
                intl.formatMessage({
                  id: "PARTNERS_GENERAL_DETAILS_UPDATE_FAILURE",
                })
              );
            } else {
              errorToast(
                intl.formatMessage({
                  id: "PARTNERS_GENERAL_DETAILS_UPDATE_FAILURE",
                })
              );
            }
          })
          .finally(() => {
            setLoading(false);
            //getPartnerDetailsAPI();
          });
      }
    },
  });

  useEffect(() => {
    handleKeyUp(partner?.name || "");
    countries.forEach((country) => {
      if (country.id === Number(partner?.country_id)) {
        formik.setFieldValue("country_id", country.id);
        setSelectedCountry(country);
      }
    });
  }, [partner?.country_id]);

  const handleKeyUp = (value: string) => {
    const res = Math.ceil(value.length / 90);
    if (res <= 1) {
      setTitleLength(1);
    } else {
      setTitleLength(res);
    }
  };

  const handleResize = () => {
    setWidth({ width: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    //@ts-ignore
    textareaRef.current.style.height = "0px";
    //@ts-ignore
    const scrollHeight = textareaRef.current.scrollHeight;
    //@ts-ignore
    textareaRef.current.style.height = scrollHeight + 3 + "px";
  }, [handleKeyUp, width]);

  return (
    <div className={`card card-flush`}>
      <div className="card-header">
        <div className="card-title">
          <h2>
            {intl.formatMessage({ id: "PARTNERS_GENERAL_DETAILS_TITLE" })}
          </h2>
        </div>
      </div>
      <div className={clsx("card-body pt-0", loading ? "pe-none" : "")}>
        {/* Title */}
        <div className="fv-row mb-8">
          <label className="required fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "GENERAL_DETAILS_FIELD_COMPANY_NAME",
            })}
          </label>
          <input
            ref={textareaRef}
            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            autoComplete="off"
            placeholder=""
            {...formik.getFieldProps("name")}
            style={{ resize: "none" }}
            onKeyUp={() => handleKeyUp(formik.values.name)}
          />
          {formik.touched.name && formik.errors.name && (
            <div className="text-danger">
              <span role="alert">{formik.errors.name}</span>
            </div>
          )}
          {formik.status && (
            <div className="text-danger">
              <span role="alert">{formik.status}</span>
            </div>
          )}
        </div>

        {/* Address 1 */}
        <div className="fv-row mb-8">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_ADDRESS1",
            })}
          </label>
          <input
            placeholder={""}
            className={clsx("form-control form-control-lg form-control-solid")}
            {...formik.getFieldProps("address_1")}
            autoComplete="off"
          />
        </div>

        {/* Address 2 */}
        <div className="fv-row mb-8">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_ADDRESS2",
            })}
          </label>
          <input
            placeholder={""}
            className={clsx("form-control form-control-lg form-control-solid")}
            {...formik.getFieldProps("address_2")}
            autoComplete="off"
          />
        </div>

        <div className="row">
          {/* city */}
          <div className="fv-row mb-8 col-lg-6">
            <label className="fw-bold fs-6 mb-2">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_CITY",
              })}
            </label>
            <input
              placeholder={""}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
              {...formik.getFieldProps("city")}
              autoComplete="off"
            />
          </div>

          {/* zip code */}
          <div className="fv-row mb-8 col-lg-6">
            <label className="fw-bold fs-6 mb-2">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_ZIPCODE",
              })}
            </label>
            <input
              placeholder={""}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
              {...formik.getFieldProps("zip_code")}
              autoComplete="off"
            />
          </div>
        </div>

        {/* Country */}
        <div className="fv-row mb-8">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_COUNTRY",
            })}
          </label>
          <DropDown
            id="countriesDropDown"
            items={countries}
            selectedItem={selectedCountry}
            // @ts-ignore
            onSelectItem={(item: CountryType) => {
              formik.setFieldValue("country_id", item?.id);
              setSelectedCountry(item);
            }}
            displayValue={selectedCountry?.name || ""}
            // @ts-ignore
            displayFunc={(item: CountryType) => {
              return item?.emoji + " " + item?.name;
            }}
          />
        </div>

        <div className="fv-row mb-8">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_EMAIL",
            })}
          </label>
          <input
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="fv-row mb-8">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_WEBSITE_URL",
            })}
          </label>
          <input
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("website_url")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        <div className="fv-row mb-8">
          <label className="fw-bold fs-6 mb-2">
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_DETAILS_FIELD_TELEPHONE",
            })}
          </label>
          <input
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("telephone")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />
        </div>
        {/* submit button */}
        <div className="d-flex justify-content-end ">
          {
            <button
              className="btn btn-primary min-w-100px"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {!loading &&
                intl.formatMessage({
                  id: "PARTNERS_GENERAL_DETAILS_SAVE_BUTTON",
                })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "PARTNERS_GENERAL_DETAILS_SAVE_LOADING",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default CompanyGeneralDetails;
