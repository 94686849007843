import clsx from "clsx";
import { useFormik } from "formik";
import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import notFound from "../../../../_metronic/assets/icons/notFound.png";

import _ from "lodash";
// @ts-ignore
import DragSortableList from "react-drag-sortable";

// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import Constants from "../../../config/Constants";
import { sortGroupsParams } from "../types/request";
import { actions } from "../redux/ContentMasterRedux";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { TabType } from "../types/TabsResponseType";
import {
  createTab,
  deleteTab,
  getTabsList,
  massDeleteTabs,
  sortTab,
} from "../redux/TabsAPI";
import { useHistory } from "react-router-dom";
import { getFieldsList } from "../redux/FieldsAPI";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

export interface TabsModalProps {
  show: boolean;
  type?: string;
  tab?: TabType;
}
interface ModelComponentProps {
  ModalProps: TabsModalProps;
  closeModal: () => void;
  getTabsListAPI: () => void;
}

export const TabsModal: FC<ModelComponentProps> = ({
  ModalProps,
  closeModal,
  getTabsListAPI,
}) => {
  const { show, type, tab } = ModalProps;
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [currPage, setCurrPage] = useState<TabType | undefined>(tab);

  const initialValues = {
    tabName: type === "edit" ? currPage?.name : "",
  };

  const AddNewTabSchema = Yup.object().shape({
    tabName: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(
        intl.formatMessage({ id: "MASTERDATA_TABS_TAG_NAME_REQUIRED" })
      ),
  });

  useEffect(() => {
    if (type === "new") {
      setCurrPage(undefined);
    }
  }, [type, tab]);

  useEffect(() => {
    setCurrPage(tab);
  }, [tab]);

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewTabSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (type === "new") {
        if (values.tabName) {
          setLoading(true);
          createTab(values.tabName)
            .then(() => {
              closeModal();
              successToast(
                intl.formatMessage({
                  id: "MASTERDATA_TABS_CREATE_SUCCESS_MESSAGE",
                })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "MASTERDATA_TABS_CREATE_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get tags list API
              getTabsListAPI();
              setLoading(false);
            });
        }
      }
    },
  });

  return (
    <Modal
      show={show}
      centered
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "MASTERDATA_TABS_EDIT_TITLE" })
            : intl.formatMessage({ id: "MASTERDATA_TABS_EDIT_NEW_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_TABS_TAB_NAME_FIELD_NAME",
                  })}
                </span>
              </label>

              <input
                placeholder={intl.formatMessage({
                  id: "MASTERDATA_TABS_TAB_NAME_PLACEHOLDER",
                })}
                {...formik.getFieldProps("tabName")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                name="tabName"
                autoComplete="off"
              />
              {formik.touched.tabName && formik.errors.tabName && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.tabName}</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "MASTERDATA_TAGS_LOADING_MESSAGE" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

interface Props {
  onSelectedTabs?: (tabs: TabType[]) => void;
  isSelectionModal?: boolean;
  selectedTabs?: any[];
}

const ProjectTabs: FC<Props> = ({
  onSelectedTabs,
  isSelectionModal,
  selectedTabs,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const tabsList = useSelector<RootState>(
    // @ts-ignore
    ({ contentMaster }) => contentMaster.tabsList
  ) as TabType[];
  // state
  const [displayTabsList, setDisplayTabsList] = useState(tabsList);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(userRoleDetails, "tabs");
  const crudPermissionForPipeline: any = enablePermissionForCrud(
    userRoleDetails,
    "pipelines"
  );
  const [showTabsModal, setShowTabsModal] = useState<TabsModalProps>({
    show: false,
  });
  const [checkedTabs, setCheckedTabs] = useState<TabType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllTabs, setCheckAllTabs] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [activePage, setActivePage] = useState<number>(1);
  const history = useHistory();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const getTabsListAPI = () => {
    getTabsList()
      .then(({ data: { data } }) => {
        dispatch(actions.setTabsList(data));
      })
      .catch((e) => {
        // console.log(`ERROR: getCategoriesListAPI`, e)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateDisplayTabsList = () => {
    const updatedTabsList = tabsList.filter((tab) => {
      if (
        (tab.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayTabsList(updatedTabsList);
  };

  useEffect(() => {
    setIsLoading(true);
    // get tags API call
    getTabsListAPI();
  }, []);

  useEffect(() => {
    updateDisplayTabsList();

    return () => {
      setDisplayTabsList(tabsList);
    };
  }, [tabsList, searchText]);

  useEffect(() => {
    if (checkAllTabs && checkedTabs.length !== tabsList.length) {
      setCheckAllTabs(false);
    }
    if (checkedTabs.length > 0 && checkedTabs.length === tabsList.length) {
      setCheckAllTabs(true);
    }
  }, [checkedTabs]);

  const closeTabsModal = () => {
    setShowTabsModal({
      show: false,
      type: "new",
      tab: undefined,
    });
  };

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const deleteExistingTab = (id: number) => {
    setDeleteModalLoading(true);
    deleteTab(id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "MASTERDATA_TABS_DELETE_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "MASTERDATA_TABS_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        // recall  get tags list API
        setCheckAllTabs(false);
        setCheckedTabs([]);
        getTabsListAPI();
      });
  };

  const onTabsSortChange = (sortedList: TabType[]) => {
    const newSortedTabsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTabsArr.push({
        id: sortedListItem.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing tags API Call
    sortTab(newSortedTabsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getTabsListAPI();
      });
  };

  //
  const onTabCheckChange = (tab: TabType) => {
    let checkedTabsCopy = _.clone(checkedTabs);
    // check if already exists in the checked list
    const index = checkedTabsCopy.findIndex(
      (checkedTab) => checkedTab.id === tab.id
    );
    // if exists remove
    if (index > -1) {
      checkedTabsCopy.splice(index, 1);
    }
    // if doesnt exist push to checked tabs
    else {
      checkedTabsCopy.push(tab);
    }
    setCheckedTabs(checkedTabsCopy);
  };

  const isTabChecked = (tab: TabType) => {
    const index = checkedTabs.findIndex(
      (checkedTab) => checkedTab.id === tab.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const isTabSelectModal = () => {
    return isSelectionModal === undefined ? false : isSelectionModal;
  };

  useEffect(() => {
    isTabSelectModal();
  }, [isSelectionModal]);

  const deleteMultipleTabs = () => {
    setDeleteModalLoading(true);
    const ids = checkedTabs.map((checkedTab) => {
      return checkedTab.id;
    });
    massDeleteTabs(ids)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_TABS_MASS_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_TABS_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getTabsListAPI();
        setCheckedTabs([]);
      });
  };

  const onAllTabsCheckChange = () => {
    const updatedCheckAllTabs = !checkAllTabs;
    setCheckAllTabs(updatedCheckAllTabs);
    if (updatedCheckAllTabs) {
      setCheckedTabs(tabsList);
    } else {
      setCheckedTabs([]);
    }
  };

  useEffect(() => {
    ScrollToTop();
  }, [activePage]);

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedTabs =
    displayTabsList && displayTabsList.length > 10
      ? displayTabsList.slice(startIndex, startIndex + itemsPerPage)
      : displayTabsList;

  useEffect(() => {
    if (paginatedTabs.length === 0 && displayTabsList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedTabs]);

  const dragTab = useRef<number>(0);
  const dragOverTab = useRef<number>(0);

  return (
    <div>
      {showTabsModal.show && (
        <TabsModal
          ModalProps={showTabsModal}
          closeModal={closeTabsModal}
          getTabsListAPI={getTabsListAPI}
        />
      )}

      {/* search tabs */}
      <div className="card p-4 d-flex flex-row justify-content-between mt-7">
        <div className="d-flex flex-row">
          {crudPermission?.delete && (
            <div className="form-check form-check-sm form-check-custom form-check-solid ">
              <input
                onChange={onAllTabsCheckChange}
                className="form-check-input widget-9-check"
                type="checkbox"
                checked={checkAllTabs}
              />
            </div>
          )}
          <div className="d-flex align-items-center">
            <SVGICON
              src={SearchIcon}
              className="svg-icon svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              className="form-control form-control-solid w-250px ps-15 mx-4"
              placeholder={intl.formatMessage({
                id: "MASTERDATA_TABS_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>

        {/* add new tag button */}
        <div className="d-flex align-items-center">
          {checkedTabs.length > 0 ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedTabs.length} {intl.formatMessage({ id: "SELECTED" })}
              </span>
              {!isTabSelectModal() ? (
                <button
                  className="btn btn-danger d-flex align-items-center"
                  onClick={() => {
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "MASTERDATA_TABS_SELECTED_DELETE_CONFIRM_MESSAGE",
                      }),
                      () => {
                        deleteMultipleTabs();
                      }
                    );
                  }}
                >
                  <i className={"bi bi-trash3-fill fs-6"}></i>
                  {intl.formatMessage({ id: "DELETE_BUTTON" })}
                </button>
              ) : (
                <button
                  className="btn btn-primary d-flex align-items-center"
                  onClick={() => {
                    onSelectedTabs?.(checkedTabs);
                  }}
                >
                  {intl.formatMessage({ id: "APPLY_BUTTON" })}
                </button>
              )}
            </>
          ) : (
            <>
              {crudPermission?.create && (
                <button
                  className="btn  btn-primary"
                  onClick={() => {
                    setShowTabsModal({
                      show: true,
                      type: "new",
                    });
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  {intl.formatMessage({
                    id: "MASTERDATA_TABS_CREATE_NEW_BUTTON",
                  })}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        {paginatedTabs &&
          paginatedTabs.length > 0 &&
          paginatedTabs.map((tab, index) => {
            return (
              <div
                className="card mt-5 p-4 d-flex flex-row align-items-center justify-content-between"
                key={index}
                // as discussed with ravi, commenting drag and drop for now
                // draggable
                // onDragStart={() => (dragTab.current = index)}
                // onDragEnter={() => (dragOverTab.current = index)}
                // onDragEnd={() => {
                //   if (dragTab.current !== dragOverTab.current) {
                //     const clonedPipelineList = [...paginatedTabs];
                //     const deletedSlice = clonedPipelineList.splice(
                //       dragTab.current,
                //       1
                //     )[0];
                //     clonedPipelineList.splice(
                //       dragOverTab.current,
                //       0,
                //       deletedSlice
                //     );
                //     if(crudPermission?.edit){
                //       onTabsSortChange(clonedPipelineList);

                //     }
                //   }
                // }}
                // onDragOver={(e) => {
                //   e.preventDefault();
                // }}
              >
                <div className="d-flex flex-row align-items-center">
                  {(crudPermission?.delete ||
                    (isTabSelectModal() &&
                      crudPermissionForPipeline?.edit)) && (
                    <div className="form-check form-check-sm form-check-custom form-check-solid ">
                      <input
                        onChange={(e) => {
                          onTabCheckChange(tab);
                        }}
                        className="form-check-input widget-9-check"
                        type="checkbox"
                        checked={isTabChecked(tab)}
                        disabled={
                          isTabSelectModal() &&
                          selectedTabs &&
                          selectedTabs?.filter((data) => data.id === tab.id)
                            .length > 0
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}
                  {/* {crudPermission?.edit&& <div className="mx-5">
                    <SVGICON
                      src={DragIcon}
                      className="svg-icon-2 svg-hover-primary"
                    />
                  </div>} */}

                  <div
                    className={`fw-bold mx-5 no-drag`}
                    role="button"
                    onClick={() => {
                      history.push(`/projects/master-data/tabs/editTab`, {
                        tabDetails: tab,
                      });
                      dispatch(actions.setTabDetails(tab));
                    }}
                  >
                    {tab.name}
                  </div>
                </div>

                <div>
                  {!isTabSelectModal()
                    ? crudPermission?.edit && (
                        <button
                          className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
                          onClick={() => {
                            history.push(`/projects/master-data/tabs/editTab`, {
                              tabDetails: tab,
                            });
                            dispatch(actions.setTabDetails(tab));
                          }}
                        >
                          <SVGICON src={EditIcon} className="svg-icon-3" />
                        </button>
                      )
                    : null}
                  {!isTabSelectModal()
                    ? crudPermission?.delete && (
                        <button
                          className="btn btn-icon btn-light btn-active-light-primary btn-sm"
                          onClick={() => {
                            showDeleteConfirmModal(
                              intl.formatMessage({
                                id: "MASTERDATA_TABS_DELETE_CONFIRM_MESSAGE",
                              }),
                              () => {
                                deleteExistingTab(tab.id);
                              }
                            );
                          }}
                        >
                          <SVGICON src={DeleteIcon} className="svg-icon-3" />
                        </button>
                      )
                    : null}
                </div>
              </div>
            );
          })}
      </div>
      {/* no data */}
      {!isLoading && displayTabsList.length === 0 && (
        <table className="d-flex justify-content-center align-items-center mt-2">
          <tbody>
            <NoItemsFound languageKey="NO_ITEMS_FOUND" />
          </tbody>
        </table>
      )}
      {displayTabsList.length > 0 && (
        <div className="card mt-5 pe-3">
          <Pagination
            totalPages={Math.ceil(displayTabsList.length / itemsPerPage)}
            activePage={
              Math.ceil(displayTabsList.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectTabs;
