/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { SVGICON } from "../../../../_metronic/helpers";

// custom
import FilemanagerIcon from "../../../../_metronic/assets/icons/filemanager.svg";
import { bytesToSize } from "../../../utils/BytesToSize";
import { useIntl } from "react-intl";

interface Props {
  total_size: number;
  total_items: number;
}

const ProfileHeader: React.FC<Props> = ({ total_size = 0, total_items }) => {
  const intl = useIntl();
  return (
    <div className="card p-8 sababab d-flex flex-row align-items-center bgi-position-y-center bgi-no-repeat mt-7">
      <div className="me-5">
        <SVGICON
          src={FilemanagerIcon}
          className="svg-icon-primary svg-icon-3x"
        />
      </div>

      <div className="me-5">
        <span className="text-gray-800 text-hover-primary fs-2 fw-bolder ">
          {intl.formatMessage({ id: "FILEMANAGER_TITLE" })}
        </span>
        <div className="text-muted fw-bolder mt-1">
          <span>{bytesToSize(total_size)}</span>
          <span className="mx-3">|</span>
          <span>
            {total_items} {intl.formatMessage({ id: "ITEMS" })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
