/*
 * Sale related APIs used in Offers and Invoices
 */

import axios from "axios";
import {
  SalesContactsResponseType,
  OfferResponseType,
} from "../types/OfferTypes";
import { UsersListResponseType } from "../../../userManagement/types/getUsersListResponseType";

// get contacts & partners contacts
export function getSalesContacts() {
  return axios.get<SalesContactsResponseType>("/client/partners/users");
}

// get users
export function getSalesUsers() {
  return axios.get<UsersListResponseType>("/users");
}

//get users
export function getOrderUsers() {
  return axios.get<UsersListResponseType>("/users");
}

// get sale items list
export function getProductsAndItems() {
  return axios.get<any>("/client/sales/items");
}

// create new sales
export function createSale(data?: any) {
  return axios.post<any>("/sales", data);
}

// update sale currency
export function updateSaleCurrency(sales_id: number, currency: string) {
  return axios.post(`/sales/currency/${sales_id}`, {
    currency,
  });
}

// update sale details
export function updateSaleDetails(sales_id: number, obj: any) {
  return axios.post<OfferResponseType>(`/sales/general/${sales_id}`, {
    ...obj,
  });
}

// add sale items
export function addSaleItems(sales_id: number, items: any) {
  return axios.post<any>(`/sales/items/add/${sales_id}`, {
    items: items,
  });
}

// update sale items
export function updateOrderItem(item_id: number, data: any) {
  return axios.post<any>(`/sales/items/update/${item_id}`, {
    ...data,
  });
}

// delete sale item
export function deleteOrderItem(item_id: number) {
  return axios.post<any>(`/sales/items/delete/${item_id}`);
}
