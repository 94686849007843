import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";

// custom
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import config from "../../config";
import CompanyGeneralSettings from "./components/CompanyGeneralSettings";
// import EditPartner from '../partner/components/EditPartner'
// import PagesList from '../websiteAndApp/Components/PagesList'
// import SelfPartnerDetails from "./components/settings/SelfPartnerDetails";
// import SystemGeneralSettings from "./components/settings/SystemGeneralSettings";

const CompanySettings: React.FC = () => {
  const intl = useIntl();

  const CompanySettingsBreadCrumbs: Array<PageLink> = [
    // {
    //   title: intl.formatMessage({ id: "MENU.DASHBOARD" }),
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
    // {
    //   title: intl.formatMessage({ id: "MENU.SYSTEM" }),
    //   path: "/settings/company",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {config.APP_NAME}-
          {intl.formatMessage({ id: "COMPANY_SETTINGS_TITLE" })}
        </title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/settings/company">
          <PageTitle
            breadcrumbs={[
              ...CompanySettingsBreadCrumbs,
              {
                title: intl.formatMessage({ id: "COMPANY_SETTINGS" }),
                path: "/settings/company",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({ id: "COMPANY_SETTINGS" })}
          </PageTitle>
          <CompanyGeneralSettings />
        </Route>
        {/* deafault */}
        <Redirect
          from="/settings/company"
          exact={true}
          to="/settings/company/overview"
        />
        <Redirect to="settings/company" />
      </Switch>
    </div>
  );
};

export default CompanySettings;
