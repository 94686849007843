import {
  GoogleAuthorizeURLResponseType,
  GoogleConnectedResponseType,
  LinkedInsAuthorizeURLResponseType,
  LinkedInsConnectResponseType,
  SharedTwitterResponseType,
  TwitterAuthorizeURLResponseType,
  TwitterConnectedResponseType,
} from "./../types/getUsersListResponseType";
import axios from "axios";
import { GenericResponseType } from "../../../types/GenericReponse";
import { AddUserResponseType } from "../types/addUserResponseType";
import { PermissionsResponseType } from "../types/getPermissionsResponseType";
import { UsersListResponseType } from "../types/getUsersListResponseType";
import { UserDetailsResponseType } from "../types/getUserDetailsResponseType";

// get all groups list
export function getUsers() {
  return axios.get<UsersListResponseType>("/users");
}

// Server should return AuthModel
export function registerUser(
  salutation_id: number,
  title_id: number,
  first_name: string,
  last_name: string,
  email: string,
  username: string,
  password: string,
  password_confirmation: string,
  role_id: number
) {
  return axios.post<AddUserResponseType>("/user/register", {
    salutation_id,
    title_id: title_id ? title_id : null,
    first_name,
    last_name,
    email,
    username: username.length > 0 ? username : null,
    password,
    password_confirmation,
    role_id,
  });
}

// get user details
export function getUserDetails(id: number) {
  return axios.get<UserDetailsResponseType>(`/user/${id}`);
}

// update user email
// export function updateUserEmail(id: number, email: string, confirm_password: string) {
export function updateUserEmail(
  id: number,
  email: string,
  confirm_password: string
) {
  return axios.post<UserDetailsResponseType>(`/user/updateEmail/${id}`, {
    email,
    confirm_password,
  });
}

// update user password
export function updateUserPassword(
  id: number,
  current_password: string,
  password: string,
  password_confirmation: string
) {
  return axios.post<UserDetailsResponseType>(`/user/updatePassword/${id}`, {
    current_password,
    password,
    password_confirmation,
  });
}

export function updateUserPersonalDetails(id: number, formData: any) {
  return axios.post<AddUserResponseType>(`/user/personal/${id}/users`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function deleteUser(id: number) {
  return axios.delete<AddUserResponseType>(`/user/${id}`);
}

// get permissions
export function getPermissions() {
  return axios.get<PermissionsResponseType>(`/permissions`);
}
export function linkedInAuthorizeURL(linkedInID: number) {
  return axios.get<LinkedInsAuthorizeURLResponseType>(
    `/linkedIns/authorizeURL/${linkedInID}`
  );
}
export function linkedInAuthorizeUser(
  linkedInID: number,
  userid: number,
  code: string | null
) {
  return axios.post<LinkedInsConnectResponseType>(
    `/linkedIns/users/${linkedInID}/${userid}`,
    {
      code,
    }
  );
}
export function linkedInDisconnectAccount(linkedInID: number, userId: number) {
  return axios.delete<LinkedInsAuthorizeURLResponseType>(
    `/linkedIns/users/disconnect/${linkedInID}/${userId}`
  );
}
export function linkedInAccountRefreshToken(
  linkedInID: number,
  userId: number
) {
  return axios.post<LinkedInsAuthorizeURLResponseType>(
    `/linkedIns/users/refreshToken/${linkedInID}/${userId}`
  );
}

export function twitterAuthorizeURL(twitterid: number) {
  return axios.get<TwitterAuthorizeURLResponseType>(
    `/twitters/authorizeURL/${twitterid}`
  );
}

export function twitterAuthorizeUser(
  twitterid: number,
  userid: number,
  code: string | null,
  auth_type: string
) {
  return axios.post<TwitterConnectedResponseType>(
    `/twitters/users/${twitterid}/${userid}`,
    {
      code,
      auth_type,
    }
  );
}

export function twitterDisconnectAccount(twitter_detail_id: number) {
  return axios.delete<TwitterAuthorizeURLResponseType>(
    `/twitters/users/disconnect/${twitter_detail_id}`
  );
}

export function twitterAccountRefreshToken(twitter_detail_id: number) {
  return axios.post<TwitterAuthorizeURLResponseType>(
    `/twitters/refreshToken/${twitter_detail_id}`
  );
}

export function googleAuthorizeURL(googleid: number) {
  return axios.get<GoogleAuthorizeURLResponseType>(
    `/googles/authorizeURL/${googleid}`
  );
}

export function googleAuthorizeUser(
  googleid: number,
  userid: number,
  code: string | null
) {
  return axios.post<GoogleConnectedResponseType>(
    `/googles/users/${googleid}/${userid}`,
    {
      code,
    }
  );
}

export function googleDisconnectAccount(googleid: number, userid: number) {
  return axios.delete<GoogleAuthorizeURLResponseType>(
    `/googles/users/disconnect/${googleid}/${userid}`
  );
}

export function googleAccountRefreshToken(googleid: number, userid: number) {
  return axios.post<GoogleAuthorizeURLResponseType>(
    `/googles/refreshToken/${googleid}/${userid}`
  );
}

export function updateUserRole(user_id: number, user_role_id: number) {
  return axios.post<GenericResponseType>(`/user/role/${user_id}`, {
    user_role_id,
  });
}

export function removeUserRole(user_id: number, user_role_id: number) {
  return axios.post<GenericResponseType>(`/user/role/delete/${user_id}`, {
    user_role_id,
  });
}
export function ShareablePassword(
  twitter_detail_id: number,
  shareable_password: string
) {
  return axios.post<GoogleAuthorizeURLResponseType>(
    `/twitters/users/password/${twitter_detail_id}`,
    {
      shareable_password,
    }
  );
}

export function ShowSharedTwitterAccounts() {
  return axios.get<SharedTwitterResponseType>(`twitters/users/shared/accounts`);
}

export function AuthorizeExistingUser(
  twitterid: number,
  userid: number,
  shareable_password: string,
  auth_id: number,
  twitter_id: number,
  auth_type: string
) {
  return axios.post<TwitterConnectedResponseType>(
    `twitters/users/existing/${twitterid}/${userid}`,
    {
      shareable_password,
      auth_id,
      twitter_id,
      auth_type,
    }
  );
}
