const OrderPreviewLang = {
  company_name: {
    en: "Company Name",
    de: "Unternehmen",
  },
  name: {
    en: "Name",
    de: "Name",
  },
  email: {
    en: "Email",
    de: "E-Mail",
  },
  address: {
    en: "Address",
    de: "Adresse",
  },
  city: {
    en: "City",
    de: "Stadt",
  },
  zip_code: {
    en: "Zip Code",
    de: "PLZ",
  },
  country: {
    en: "Country",
    de: "Land",
  },
  phone: {
    en: "Phone",
    de: "Telefon",
  },
  date: {
    en: "Date",
    de: "Datum",
  },
  item: {
    en: "Item",
    de: "Item",
  },
  quantity: {
    en: "Quantity",
    de: "Menge",
  },
  unit_price: {
    en: "Unit Price",
    de: "Einzelpreis",
  },
  tax_percent: {
    en: "Tax %",
    de: "Steuer %",
  },
  discount_percent: {
    en: "Discount %",
    de: "Rabatt %",
  },
  total: {
    en: "Total",
    de: "Gesamt",
  },
  no_items: {
    en: "No Items",
    de: "keine Items",
  },
  sub_total: {
    en: "Subtotal",
    de: "Zwischensumme",
  },
  offer_date: {
    en: "offer Date",
    de: "",
  },
  expiration_date: {
    en: "Expiration Date",
    de: "",
  },
  currency: {
    en: "Currency",
    de: "",
  },
  from_contact_person: {
    en: "Reference",
    de: "",
  },
  offer_number: {
    en: "Offer Number",
    de: "",
  },
  invoice_number: {
    en: "Invoice Number",
    de: "",
  },
  sales_pdf_page: {
    en: "Page",
    de: "Seite",
  },
  sales_pdf_page_of: {
    en: "of",
    de: "von",
  },
};
export default OrderPreviewLang;
