import React from 'react';

// ScrollToTop Component
const ScrollToTop = () => {
    const scrollableElement = document.getElementsByClassName('bodyScollContent')[0];
    if (scrollableElement) {
      scrollableElement.scrollTop = 0; // Scroll to top
    }
 

  return null; 
};

export default ScrollToTop;
