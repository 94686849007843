import React from "react";
import { useIntl } from "react-intl";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Helmet } from "react-helmet";
import Config from "../../config";
import { Redirect, Route, Switch } from "react-router-dom";
import ArchiveList from "./components/ArchiveList";

const ArchivedPage = () => {
  const intl = useIntl();
  const archivedBreadCrumbs: Array<PageLink> = [
    // {
    //   title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
    {
      title: intl.formatMessage({ id: "MENU_DEALS_ARCHIVED" }),
      path: "/deals/archived",
      isSeparator: false,
      isActive: false,
    },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
  ];
  return (
    <div>
      <Helmet>
        <title>{Config.APP_NAME}-Products</title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/deals/archived/overview">
          <PageTitle
            breadcrumbs={[
              ...archivedBreadCrumbs,
              // {
              //   title: intl.formatMessage({ id: "ROUTES_OVERVIEW" }),
              //   path: "/deals/archived/overview",
              //   isSeparator: false,
              //   isActive: true,
              // },
            ]}
          >
            {intl.formatMessage({ id: "DEALS_ARCHIVED_TITLE" })}
          </PageTitle>
          <ArchiveList />
        </Route>

        <Redirect
          from="/deals/archived"
          exact={true}
          to="/deals/archived/overview"
        />
        <Redirect to="/deals/archived/overview" />
      </Switch>
    </div>
  );
};

export default ArchivedPage;
