import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";

// Permissions related imports
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

// custom
import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import {
  NoteDetailsType,
  NoteModalProps,
} from "../types/getNotesResponseTypes";
import { deleteNote } from "../redux";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  noteDetails: NoteDetailsType;
  getNotesListAPI: () => void;
  onEdit: (editObject: NoteModalProps) => void;
  setShowNoteLogsModal?: () => void;
}

const NotesListActionDropdown: FC<Props> = ({
  noteDetails,
  getNotesListAPI,
  onEdit,
  setShowNoteLogsModal,
}) => {
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "contacts"
  );
  const crudPermissionForDeals: any = enablePermissionForCrud(
    userRoleDetails,
    "deals"
  );

  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const onEdituserClick = () => {
    onEdit({
      show: true,
      note: noteDetails,
    });
  };

  const onViewNoteLogsClick = () => {
    setShowNoteLogsModal?.();
  };

  const deleteSelectedNote = () => {
    setDeleteModalLoading(true);
    deleteNote(
      noteDetails?.notes_id,
      noteDetails?.users_id,
      noteDetails?.deals_id
    )
      .then(() => {
        successToast(
          intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_DELETE_NOTE_SUCCESS" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_DELETE_NOTE_FAILED" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getNotesListAPI?.();
      });
  };

  const dropdownOptions = [
    // User can edit note if user has edit permission for contacts
    ...((crudPermission?.edit && noteDetails?.users_id) ||
    (crudPermissionForDeals?.edit && noteDetails?.deals_id)
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_EDIT" }),
            onClick: onEdituserClick,
          },
        ]
      : []),

    // User can delete note if user has delete permission for contacts
    ...((crudPermission?.delete && noteDetails?.users_id) ||
    (crudPermissionForDeals?.delete && noteDetails?.deals_id)
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_DELETE" }),
            onClick: () =>
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_NOTE_ACTION_DELETE_MESSAGE",
                }),
                deleteSelectedNote
              ),
            className: "text-danger",
          },
        ]
      : []),

    // User can view logs if user has read permission for contacts
    ...((crudPermission?.read && noteDetails?.users_id) ||
    (crudPermissionForDeals?.read && noteDetails?.deals_id)
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_VIEW_LOGS" }),
            onClick: onViewNoteLogsClick,
          },
        ]
      : []),
  ];

  if (dropdownOptions.length > 0) {
    return (
      <div className="dropdown d-flex flex-row justify-content-end">
        <ActionsDropdown options={dropdownOptions} />
      </div>
    );
  } else {
    return null;
  }
};

export default NotesListActionDropdown;
