import { FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import SunEditorComponent from "../../../../sharedComponents/textEditor/SunEditor";
import { LayoutListType } from "../types/LayoutResponsetypes";
import {
  downloadImageFooterLayout,
  updateFooterText,
  updateLayout,
} from "../redux/LayoutApi";
import DropDown from "../../../../sharedComponents/dropdown/Dropdown";
import LayoutPdf from "./LayoutPdf";
import { pdf } from "@react-pdf/renderer";
import LayoutPreviewModal from "./LayoutPreviewModal";
import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import { useLang } from "../../../../../_metronic/i18n/Metronici18n";

interface Props {
  layout: LayoutListType;
  getLayoutDetailsAPI: () => void;
  prevValues: any;
}

const editorOptions = [
  { id: "1", value: 1 },
  { id: "2", value: 2 },
  { id: "3", value: 3 },
  { id: "4", value: 4 },
];

const Footertext: FC<Props> = ({ layout, getLayoutDetailsAPI, prevValues }) => {
  // state
  const [currfooter, setcurrfooter] = useState<LayoutListType>(layout);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPreveiwModal, setShowPreveiwModal] = useState<boolean>(false);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [values, setValues] = useState<(string | null)[]>([
    layout?.footers_text_1 || "",
    layout?.footers_text_2 || "",
    layout?.footers_text_3 || "",
    layout?.footers_text_4 || "",
  ]);

  const initRef = useRef([]);
  const [editorCount, setEditorCount] = useState<number>(
    layout?.footers_count || 1
  );
  const intl = useIntl();
  const lang = useLang();

  useEffect(() => {
    setcurrfooter(layout);
    const tempInitialValues = [
      layout?.footers_text_1 || "",
      layout?.footers_text_2 || "",
      layout?.footers_text_3 || "",
      layout?.footers_text_4 || "",
    ];
    setValues(tempInitialValues);
    // @ts-ignore
    initRef.current = tempInitialValues;
    setEditorCount(tempInitialValues.filter((value) => value).length || 1);
  }, [layout]);

  const cleanHtmlContent = (html: string): string => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const elementsWithStyles = tempDiv.querySelectorAll<HTMLElement>("*");

    elementsWithStyles.forEach((element) => {
      element.style.fontSize = "";

      if (element.tagName.toLowerCase() === "p") {
        const div = document.createElement("div");
        div.innerHTML = element.innerHTML;
        element.replaceWith(div);
      }

      if (
        element.tagName.toLowerCase() === "ul" ||
        element.tagName.toLowerCase() === "ol"
      ) {
        const div = document.createElement("div");
        div.innerHTML = element.innerHTML;
        element.replaceWith(div);

        const listItems = div.querySelectorAll("li");
        listItems.forEach((li) => {
          const innerDiv = document.createElement("div");
          innerDiv.innerHTML = li.innerHTML;
          li.replaceWith(innerDiv);
        });
      }
    });

    return tempDiv.innerHTML;
  };

  const handleUpdateTexts = (index: number, value: string) => {
    setLoading(true);
    const key = `footers_text_${index + 1}`;
    if (index < editorCount) {
      const cleanedValue = cleanHtmlContent(value);

      updateFooterText(currfooter?.id, key, cleanedValue)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_FOOTER_TEXT_EDITOR_UPDATE_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_FOOTER_TEXT_EDITOR_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          setLoading(false);
          getLayoutDetailsAPI();
        });
    }
  };
  const handleEditorCountChange = (item: { name: string; value: number }) => {
    setEditorCount(item.value);
    const data = [...values];
    const max = Math.max(editorCount, item.value);

    if (item.value < values.length) {
      const newValues = values.map((value, index) =>
        index < item.value ? value : ""
      );
      setValues(newValues);
    }
    updateLayout(currfooter.id, "footers_count", item.value)
      .then()
      .finally(() => {
        getLayoutDetailsAPI();
      });
    const updateEditorValue = (isNew = false) => {
      for (let i = 0; i < max; i++) {
        const isExist = Boolean(values[i] && data[i]);
        const key = `footers_text_${i + 1}`;
        const val = isNew ? data[i] || "<div></div>" : data[i];
        updateFooterText(currfooter.id, key, val)
          .then((val) => val)
          .finally(() => {
            getLayoutDetailsAPI();
          });
      }
    };

    if (item.value < editorCount) {
      updateEditorValue(false);
    }
    if (item.value > editorCount) {
      updateEditorValue(true);
    }
  };

  const openPdf = async (layout: any, blobURL: string | undefined) => {
    const blob = await pdf(
      <LayoutPdf layout={layout} logo={blobURL} lang={lang} />
    ).toBlob();
    const pdfURL = URL.createObjectURL(blob);
    setPdfUrl(pdfURL);
  };

  return (
    <div className="card card-flush py-4">
      {showPreveiwModal && (
        <LayoutPreviewModal
          modalProps={{ show: showPreveiwModal }}
          pdfUrl={pdfUrl}
          closeLayoutModal={() => {
            setShowPreveiwModal(false);
          }}
        />
      )}
      <div className="d-flex justify-content-end me-9">
        <button
          className="btn btn-primary"
          onClick={() => {
            setPreviewLoading(true);
            if (layout?.headers_logo_id) {
              downloadImageFooterLayout(layout?.headers_logo_id)
                .then(({ data }) => {
                  const fileUrl = URL.createObjectURL(data);

                  openPdf(layout, fileUrl);
                  setShowPreveiwModal(true);
                })
                .catch(() => {
                  openPdf(layout, "");
                  setShowPreveiwModal(true);
                })
                .finally(() => {
                  setPreviewLoading(false);
                });
            } else {
              openPdf(layout, undefined)
                .then(() => {
                  setShowPreveiwModal(true);
                })
                .catch(() => {
                  openPdf(layout, "");
                  setShowPreveiwModal(true);
                })
                .finally(() => {
                  setPreviewLoading(false);
                });
            }
          }}
        >
          {!previewLoading &&
            intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_PREVIEW_MODAL_TITLE",
            })}
          {previewLoading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_LOADING_MESSAGE",
              })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      <div className="card-body">
        <div className="mb-10 d-flex justify-content-between align-items-center">
          <label className="form-label fs-5 fw-bold mb-2">
            {intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_FOOTER_TEXT_SELECTION",
            })}
          </label>
          <div className="w-75">
            <DropDown
              id="editorCountDropDown"
              hideSearch
              // @ts-ignore
              items={editorOptions}
              displayFunc={(item: any) => item.id}
              displayValue={
                editorOptions.find(
                  (option) => option.value === layout?.footers_count
                )?.id || ""
              }
              // @ts-ignore
              onSelectItem={handleEditorCountChange}
              // @ts-ignore
              selectedItem={editorOptions.find(
                (option) => option.value === layout?.footers_count
              )}
            />
          </div>
        </div>
      </div>
      <div className={clsx("card-body pt-0", loading ? "pe-none" : "")}>
        {initRef.current
          ?.filter((foot, ind) => ind < (layout?.footers_count || 1))
          ?.map((footerText, index) => (
            <div className="fv-row mb-10" key={index}>
              <label className="form-label">
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_LAYOUT_FOOTER_TEXT_EDITOR_TITLE",
                })}
                {index + 1}
              </label>
              <SunEditorComponent
                initHtml={initRef?.current[index]}
                // onChange={(html) => {
                //   const newValues = [...initialValues]
                //   newValues[index] = html
                //   setInitialValues([...newValues])
                // }}
                wrapperPosition={index}
                uniqueClassName={`sales-footer-general-desc-${index}`}
                // fromHeaderandFooter
                handleBlur={(html) => {
                  if (html !== initRef?.current[index]) {
                    handleUpdateTexts(index, html);
                  }
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Footertext;
