import React, { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";

import { actions, createPartner } from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  show: boolean;
  closeModal?: () => void;
  getPartnersListAPI?: () => void;
  onChange?: (arg0: any) => void;
  isSelectPartnerType?: boolean;
}

const initialValues = {
  name: "",
  email: "",
};

const AddPartnerModal: FC<Props> = ({
  show,
  closeModal,
  getPartnersListAPI,
  onChange,
  isSelectPartnerType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const [loading, setLoading] = useState<boolean>(false);

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;

  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "partners"
  );

  const AddPartnerSchema = Yup.object().shape({
    name: Yup.string()
      .max(60, intl.formatMessage({ id: "FORM_VALIDATION_60SYMBOLS_REQUIRED" }))
      .required(
        intl.formatMessage({ id: "FORM_VALIDATION_FIELD_NAME_REQUIRED" })
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddPartnerSchema,
    onSubmit(values, { setStatus, setSubmitting }) {
      setLoading(true);
      createPartner(values.name)
        .then(({ data: { partnerDetails } }) => {
          closeModal?.();
          if (isSelectPartnerType) {
            if (onChange) {
              onChange(partnerDetails);
            }
          } else {
            history.push(`/crm/organisation/edit`, { partnerDetails });
          }
          successToast(
            intl.formatMessage({
              id: "PARTNER_MANAGEMENT_ADD_SUCCESS_MESSAGE",
            })
          );
          dispatch(actions.setPartnerDetails(partnerDetails));
        })
        .catch((err) => {
          err.response?.data?.message
            ? setStatus(err.response?.data?.message)
            : err.response?.data?.errors?.name[0]
              ? setStatus(err.response?.data?.errors?.name[0])
              : err.response?.data?.errors?.email
                ? setStatus(err.response.data?.errors?.email)
                : setStatus(
                    intl.formatMessage({
                      id: "PARTNER_MANAGEMENT_ADD_FAILURE_MESSAGE",
                    })
                  );
        })
        .finally(() => {
          getPartnersListAPI?.();
          setLoading(false);
        });
    },
  });

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "PARTNERS_ADD_NEW_PARTNER" })}
        </Modal.Title>
      </Modal.Header>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
        className="overflow-auto"
      >
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="fv-row ">
              <label className="required fw-bold fs-6 mb-2">
                {intl.formatMessage({
                  id: "PARTNERS_ADD_PARTNER_MODAL_PARTNER_NAME",
                })}
              </label>
              <input
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("name")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.name}</span>
                </div>
              )}
            </div>
            {/* <div className="fv-row mb-8">
              <label className="required fw-bold fs-6 mb-2">
                {intl.formatMessage({
                  id: "FORM_VALIDATION_FIELD_LABEL_EMAIL",
                })}
              </label>
              <input
                type="email"
                autoComplete="off"
                {...formik.getFieldProps("email")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              )}
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          {/* <Button type='submit' id='kt_sign_in_submit' className='btn btn-light-primary'> */}
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "FORM_VALIDATION_LOADING_MESSAGE" })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>

          {/* </Button> */}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddPartnerModal;
