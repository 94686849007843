import React from "react";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";

// custom
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import TasksList from "./components/TasksList";

const TasksPage: React.FC = () => {
  const intl = useIntl();

  const tasksBreadCrumbs: Array<PageLink> = [
    // {
    //   title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
    {
      title: intl.formatMessage({ id: "MENU_TASKS" }),
      path: "/tasks",
      isSeparator: false,
      isActive: false,
    },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
  ];

  return (
    <div>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/tasks/overview">
          <PageTitle
            breadcrumbs={[
              ...tasksBreadCrumbs,
              // {
              //   title: intl.formatMessage({ id: "ROUTES_OVERVIEW" }),
              //   path: "/tasks/overview",
              //   isSeparator: false,
              //   isActive: true,
              // },
            ]}
          >
            {intl.formatMessage({ id: "MENU_TASKS" })}
          </PageTitle>
          <TasksList isMenuPoint={true} />
        </Route>

        <Redirect from="/tasks" exact={true} to="/tasks/overview" />
        <Redirect to="/tasks/overview" />
      </Switch>
    </div>
  );
};

export default TasksPage;
