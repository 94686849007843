import React, { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";

// custom
import { useIntl } from "react-intl";
import { addnewRole } from "../redux";
import { successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  show: boolean;
  closeModal: () => void;
  getRolesListAPI: () => void;
}

const AddNewRoleModal: FC<Props> = ({ show, closeModal, getRolesListAPI }) => {
  const intl = useIntl();

  // state
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    name: "",
  };

  const AddPostBasicSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "ADD_ROLE_MODAL_FIELD_NAME_ERROR" })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddPostBasicSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      addnewRole(values.name)
        .then(() => {
          successToast(
            intl.formatMessage({ id: "ADD_ROLE_SUCCESS_MESSAGE" })
          );
          closeModal();
        })
        .catch(() => {
          setStatus(intl.formatMessage({ id: "ADD_ROLE_FAILURE_MESSAGE" }));
        })
        .finally(() => {
          getRolesListAPI();
          setLoading(false);
        });
    },
  });

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "USER_ROLE_ADD_BUTTON" })}
        </Modal.Title>
      </Modal.Header>
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_post_basic_form"
      >
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <p className="text-muted fs-5 fw-bold mb-10">
              {intl.formatMessage({ id: "ADD_ROLE_MODAL_INFO" })}
            </p>

            {/* Title */}
            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
              <span className="required">
                {intl.formatMessage({ id: "ADD_ROLE_MODAL_FIELD_NAME" })}
              </span>
            </label>
            <input
              placeholder={""}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
              {...formik.getFieldProps("name")}
              autoComplete="off"
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-danger">
                <span role="alert">{formik.errors.name}</span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "WEBSITE_GENERAL_LOADING_MESSAGE",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddNewRoleModal;
