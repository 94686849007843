import React, { FC, useEffect, useState } from "react";
import { SVGICON } from "../../../../_metronic/helpers";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import { useIntl } from "react-intl";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import Constants from "../../../config/Constants";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import _ from "lodash";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import {
  PartnerType,
  PartnersContactDetailType,
} from "../types/getPartnersListResponseType";
import {
  addPartnerContacts,
  deletePartnerContacts,
  getPartnerContacts,
} from "../redux";
import SelectCustomerModal from "../../customerManagement/components/SelectCustomerModal";
import { useHistory } from "react-router-dom";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  partner: PartnerType;
  getPartnerDetailsAPI: () => void;
}

const PartnerContacts: FC<Props> = ({ partner, getPartnerDetailsAPI }) => {
  const intl = useIntl();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const [partnerContactDetails, setPartnerContactDetails] =
    useState<PartnersContactDetailType[]>();
    const [allPartnerContacts, setAllPartnerContacts] = useState(partnerContactDetails); 
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "contacts"
  );

  const crudPermissionForPartners: any = enablePermissionForCrud(
    userRoleDetails,
    "partners"
  );
  const [tempSearchText, setTempSearchText] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [checkedCustomers, setCheckedCustomers] = useState<
    PartnersContactDetailType[] | undefined
  >([]);
  const [selectedContacts, setSelectedContacts] = useState<
    PartnersContactDetailType[]
  >([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);
  const [APIloading, setAPILoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [activePage, setActivePage] = useState<number>(1);
  const history = useHistory();

  useEffect(() => {
    getPartnerContactsAPI(partner.id);
  }, [partner]);

  const getPartnerContactsAPI = (id: number) => {
    setAPILoading(true);
    getPartnerContacts(id)
      .then(({ data: { contacts_details } }) => {
        setAllPartnerContacts(contacts_details);
        setPartnerContactDetails(contacts_details);
        setAPILoading(false);
      })
      .finally(() => setAPILoading(false));
  };

  const onSearchTextChange = (text: string) => {
    setTempSearchText(text || "");
  };

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedPartnerContacts =
  partnerContactDetails && partnerContactDetails.length > 10
      ? partnerContactDetails.slice(startIndex, startIndex + itemsPerPage)
      : partnerContactDetails;

  useEffect(() => {
    if (partnerContactDetails?.length === 0 && partnerContactDetails?.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedPartnerContacts]);


  const addSelectedContacts = (contacts: PartnersContactDetailType[]) => {
    let arr = contacts.map((item) => item.id);
    addPartnerContacts(partner?.id, arr)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PARTNERS_CONTACTS_ADD_CONTACTS_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PARTNERS_CONTACTS_ADD_CONTACTS_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        setShowAddCustomerModal(false);
        getPartnerContactsAPI(partner?.id);
      });
  };

  const handleOnSelectCustomers = (customers: PartnersContactDetailType[]) => {
    setSelectedContacts(customers);
    addSelectedContacts(customers);
  };
  const handleDeleteMultiple = (checked: any) => {
    setDeleteModalLoading(true);
    let arr = checked.map((item: any) => item.id);
    deletePartnerContacts(partner?.id, arr)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PARTNERS_CONTACTS_MULTIPLE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PARTNERS_CONTACTS_MULTIPLE_DELETE_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        setShowAddCustomerModal(false);
        getPartnerContactsAPI(partner?.id);
        setCheckedCustomers([]);
        setCheckAllItems(false);
      });
  };

  const deleteSinglePartnerContact = (id: number) => {
    setDeleteModalLoading(true);
    deletePartnerContacts(partner?.id, [id])
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PARTNERS_CONTACT_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PARTNERS_CONTACT_DELETE_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        setShowAddCustomerModal(false);
        getPartnerContactsAPI(partner?.id);
      });
  };

  const onAllItemsCheckChange = () => {
    const updatedCheckAllItems = !checkAllItems;
    setCheckAllItems(updatedCheckAllItems);
    if (updatedCheckAllItems) {
      setCheckedCustomers(partnerContactDetails);
    } else {
      setCheckedCustomers([]);
    }
  };

  const onCheckedChange = (customer: PartnersContactDetailType) => {
    let checkedCustomersCopy = _.clone(checkedCustomers);
    const index = checkedCustomersCopy?.findIndex(
      (checkedCustomer) => checkedCustomer.id === customer.id
    );
    //@ts-ignore
    if (index > -1) {
      //@ts-ignore
      checkedCustomersCopy?.splice(index, 1);
      setCheckAllItems(false);
    } else {
      checkedCustomersCopy?.push(customer);
    }
    setCheckedCustomers(checkedCustomersCopy);
  };

  const isChecked = (customer: PartnersContactDetailType) => {
    const index = checkedCustomers?.findIndex(
      (checkedCustomer) => checkedCustomer.id === customer.id
    );
    //@ts-ignore
    if (index > -1) {
      return true;
    }
    return false;
  };

  const updateDisplayContactsList = () => {
    const updated = allPartnerContacts?.filter((item) => {
      const fullName = 
        `${item?.firstname ? item?.firstname : ""} ${item?.lastname ? item?.lastname : ""}`
        .toLocaleLowerCase();
        
      return fullName.includes((tempSearchText || "").toLocaleLowerCase());
    });
  
    // Save updated list to display
    setPartnerContactDetails(updated);
  }; 

  useEffect(() => {
    updateDisplayContactsList();
  }, [tempSearchText]);

  useEffect(() => {
    if (tempSearchText.length === 0) {
      getPartnerContactsAPI(partner?.id);
      setTempSearchText("");
      setSearchText("");
    }
  }, [tempSearchText]);

  return (
    <div className="card mt-10">
      {showAddCustomerModal && (
        <SelectCustomerModal
          show={showAddCustomerModal}
          closeModal={() => setShowAddCustomerModal(false)}
          onSelectCustomers={(customers) => {
            //@ts-ignore
            handleOnSelectCustomers(customers);
          }}
          isSelectionModal={true}
          selectMultiple
          selectedContacts={partnerContactDetails}
        />
      )}

      {/* begin::Header */}
      <div className="card-header border-0 pt-8 d-flex flex-row justify-content-between mb-2">
        <div className="d-flex align-items-center" style={{ borderWidth: 1 }}>
          <div className="position-relative">
            {tempSearchText && (
              <div
                className="position-absolute"
                style={{
                  right: 8,
                  top: 10,
                  zIndex: 99,
                }}
                role={"button"}
                onClick={() => {
                  setTempSearchText("");
                  setSearchText("");
                  getPartnerContactsAPI(partner?.id);
                }}
              >
                <SVGICON src={CloseIcon} className="svg-icon svg-icon-1 " />
              </div>
            )}
            <input
              type="text"
              className="form-control form-control-solid w-250px position-relative"
              placeholder={intl.formatMessage({
                id: "PARTNERS_LIST_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                paddingRight: "30px",
              }}
              value={tempSearchText}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (tempSearchText && tempSearchText.length >= 3) {
                    setSearchText(tempSearchText);
                  }
                }
              }}
            />
          </div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              if (tempSearchText && tempSearchText.length >= 3) {
                setSearchText(tempSearchText);
              }
            }}
            style={{
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              padding: "11px",
            }}
          >
            <SVGICON src={SearchIcon} className="svg-icon svg-icon-2" />
          </button>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {checkedCustomers && checkedCustomers?.length > 0 ? (
            <>
              <span className="text-dark text-hover-danger fs-6 fw-bolder mx-4">
                {checkedCustomers?.length}{" "}
                {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn  btn-danger"
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "PARTNERS_CONTACT_DELETE_MULTIPLE_CONFIRMATION_MESSAGE",
                    }),
                    () => {
                      handleDeleteMultiple(checkedCustomers);
                    }
                  );
                }}
              >
                <span className="indicator-label">
                  {intl.formatMessage({
                    id: "PARTNERS_CONTACTS_DELETE_BUTTON",
                  })}
                </span>
              </button>
            </>
          ) : (
            <>
              {crudPermissionForPartners?.edit && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setShowAddCustomerModal(true);
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  {intl.formatMessage({
                    id: "CUSTOMER_MANAGEMENT_LIST_ADD_NEW_CUSTOMER",
                  })}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            {/* begin::Table head */}
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                {crudPermissionForPartners?.edit && (
                  <th className="w-25px">
                    <Checkbox
                      onChange={onAllItemsCheckChange}
                      checked={checkAllItems}
                    />
                  </th>
                )}
                <th className="min-w-125px">
                  {intl.formatMessage({
                    id: "PARTNERS_CONTACTS_TABLE_NAME_COLUMN",
                  })}
                </th>
                <th className="min-w-125px">
                  {intl.formatMessage({
                    id: "PARTNERS_CONTACTS_TABLE_EMAIL_COLUMN",
                  })}
                </th>
                <th className="min-w-125px mw-150px">
                  {intl.formatMessage({
                    id: "PARTNERS_CONTACTS_TABLE_ADDRESS_COLUMN",
                  })}
                </th>
                <th className="min-w-50px"></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {APIloading ? (
                <tr>
                  <td valign="top" colSpan={5} className="dataTables_empty">
                    <SimpleLoader />
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedPartnerContacts &&
                    paginatedPartnerContacts?.reverse()?.map((user, i) => {
                      return (
                        <tr key={i}>
                          {/* user name,email,pic */}
                          {crudPermissionForPartners?.edit && (
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
                                <Checkbox
                                  onChange={() => {
                                    onCheckedChange(user);
                                  }}
                                  checked={isChecked(user)}
                                />
                              </div>
                            </td>
                          )}
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                <div className="symbol-label">
                                  {user.profile_photo_url ? (
                                    <img
                                      src={user.profile_photo_url}
                                      alt={
                                        user?.firstname
                                          ? user.firstname
                                          : "contact profile"
                                      }
                                      style={{
                                        minHeight: "100%",
                                        minWidth: "100%",
                                        objectFit: "cover",
                                      }}
                                      className="w-100 cursor-pointer"
                                    />
                                  ) : (
                                    <div className="symbol-label fs-3 bg-light-danger text-danger text-uppercase cursor-pointer">
                                      {(user.firstname || " ")[0]}
                                      {(user.lastname || " ")[0]}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className={`d-flex justify-content-start flex-column ${!crudPermission?.edit && "pe-none"}`}
                                onClick={() => {
                                  history.push(`/crm/contacts/editcustomer`, {
                                    userDetails: user,
                                  });
                                }}
                              >
                                <span
                                  className="text-gray-800 fw-bold text-hover-primary text-capitalize mb-1"
                                  role="button"
                                >
                                  {(user?.firstname || "").concat(
                                    " ",
                                    user?.lastname || ""
                                  )}
                                </span>
                              </div>
                            </div>
                          </td>

                          {/* Email */}
                          <td className="mw-250px">
                            <span className="text-gray-800 fs-6">
                              {user?.email ? user?.email : "-"}
                            </span>
                          </td>

                          {/* Address */}

                          <td className="mw-250px">
                            <span className="text-gray-800 fs-6">
                              {(user?.address_1 ? user?.address_1 : "")
                                .concat(
                                  user?.address_2
                                    ? (user?.address_1 ? "," : "") +
                                        user?.address_2
                                    : ""
                                )
                                .concat(
                                  user?.city
                                    ? (user?.address_2 ? "," : "") + user?.city
                                    : ""
                                )
                                .concat(
                                  user?.country_name
                                    ? (user?.city ? "," : "") +
                                        user?.country_name
                                    : ""
                                )
                                .concat(
                                  user?.zip_code ? user?.zip_code + "." : ""
                                )}
                            </span>
                          </td>

                          {/* actions */}
                          {crudPermissionForPartners?.edit && (
                            <td>
                              <button
                                className="btn btn-icon btn-light btn-active-light-danger btn-sm"
                                onClick={() => {
                                  showDeleteConfirmModal(
                                    intl.formatMessage({
                                      id: "PARTNERS_CONTACT_DELETE_CONFIRMATION_MESSAGE",
                                    }),
                                    () => {
                                      deleteSinglePartnerContact(user?.id);
                                    }
                                  );
                                }}
                              >
                                <SVGICON
                                  src={DeleteIcon}
                                  className="svg-icon-3"
                                />
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </>
              )}

              {/* no data */}
              {!APIloading &&
                partnerContactDetails &&
                partnerContactDetails?.length === 0 && (
                  <NoItemsFound languageKey="CUSTOMER_MANAGEMENT_LIST_NO_ITEMS_FOUND" />
                )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          {partnerContactDetails && partnerContactDetails?.length > 0 && (
            <Pagination
              totalPages={Math.ceil(
                partnerContactDetails.length / itemsPerPage
              )}
              activePage={
                Math.ceil(partnerContactDetails.length / itemsPerPage) === 1
                  ? 1
                  : activePage
              }
              onPageClick={onPageClick}
              itemsPerPage={itemsPerPage}
              showItemsPerPage
              setItemsPerPage={(count) => {
                setItemsPerPage(count);
              }}
            />
          )}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export default PartnerContacts;
