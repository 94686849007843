import axios from "axios";
import {
  AllDealsResponseType,
  TaskCreateResponseType,
  TaskDeleteResponseType,
  TaskLogsResponseType,
  TaskResponseType,
  TaskUpdateResponseType,
} from "../types/TasksResponseType";

export function getTasks(deals_id?: number) {
  return axios.post<TaskResponseType>("/tasks/list", { deals_id });
}

export function createTask(values: any) {
  return axios.post<TaskCreateResponseType>(`/tasks`, values);
}

export function updateTask(task_id: number, values: any) {
  return axios.post<TaskUpdateResponseType>(`/tasks/update/${task_id}`, values);
}

export function updateTaskStatus(tasks_id: number, newStatus: string) {
  return axios.post<TaskUpdateResponseType>(`/tasks/updateStatus/${tasks_id}`, {
    status: newStatus,
  });
}

export function deleteTask(task_id: number) {
  return axios.delete<TaskDeleteResponseType>(`/tasks/${task_id}`);
}

/**
 *! Not being used so commented
 */
// export function deleteMultipleTasks(tasks_id: any[]) {
//   return axios.post<TaskDeleteResponseType>(`/tasks/massDelete`, {tasks_id,});
// }

/**
 *! Not being used so commented
 */
// export function addResponsibleUser(task_id: number, assignees_ids: number[]) {
//   return axios.post(`/tasks/assignees/${task_id}`, {assignees_ids,});
// }

export function getTaskLogs(tasks_id: number) {
  return axios.post<TaskLogsResponseType>("/notesTasks/logs", {
    tasks_id,
  });
}

export async function getAllDeals() {
  return axios.get<AllDealsResponseType>(`/deals/loginUser/responsible`);
}
