import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

import DealLocation from "./DealLocationCard";

interface Props {
  show: boolean;
  closeModal: () => void;
  dealInformation: any;
}

const MapsModal: FC<Props> = ({ show, closeModal, dealInformation }) => {
  const intl = useIntl();

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {intl.formatMessage({
            id: "PARTNERS_GENERAL_DETAILS_FIELD_ADDRESS1",
          })}
        </Modal.Title>
      </Modal.Header>

      <div className="overflow-auto">
        <Modal.Body>
          <DealLocation dealInformation={dealInformation} />
        </Modal.Body>
      </div>

      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MapsModal;
