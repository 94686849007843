import { Action } from "@reduxjs/toolkit";
import {
  DealFilterType,
  DealPipelineDetailsType,
  DealType,
} from "../types/DealResponseType";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  SET_SELECTED_PIPELINE_DETAILS: "SET_SELECTED_PIPELINE_DETAILS",
  SET_DEAL_DETAILS: "SET_DEAL_DETAILS",
  SET_PHASE_TRANSITION_DETAILS: "SET_PHASE_TRANSITION_DETAILS",
  SET_DEAL_PIPELINES: "SET_DEAL_PIPELINES",
  SET_FILTER_DETAILS: "SET_FILTER_DETAILS",
};

export interface DealsInitState {
  pipelineDetails: DealPipelineDetailsType[];
  dealDetails: DealType;
  phaseTransitionDetails: [];
  dealPipelines: { id: number; name: string }[];
  filterdetails: DealFilterType;
}

const initialState: DealsInitState = {
  pipelineDetails: [],
  dealDetails: {
    id: 0,
    name: "",
    created_by: {
      email: "",
      firstname: "",
      lastname: "",
      user_id: 0,
      username: "",
      address_1: "",
      address_2: "",
      city: "",
      country_id: 0,
      zip_code: "",
    },
    is_active: 0,
    started_on: "",
    pipelines_id: 0,
    created_at: "",
    updated_at: "",
    responsible_users: [],
    display_order: 0,
    phase_id: 0,
    phase_name: "",
    tags_id: 0,
    tab_data: [],
    status: "finished",
    can_be_move: false,
    deals_unique_id: "",
    tag_details: {
      colour: "",
      name: "",
    },
    deleted_at: "",
    contacts: null,
    contacts_id: null,
    partners: null,
    partners_id: null,
    message: "",
    approvals: [],
  },
  phaseTransitionDetails: [],
  dealPipelines: [],
  filterdetails: {
    Responsible_Persons: [],
    status: "",
    partner: {},
    contact: {},
    tags: undefined,
  },
};

export const DealsdataReducer = persistReducer(
  { storage, key: "server", whitelist: ["phaseTransitionDetails"] },
  // @ts-ignore
  (state: any = initialState, action: ActionWithPayload<partnerInitState>) => {
    switch (action.type) {
      case actionTypes.SET_SELECTED_PIPELINE_DETAILS: {
        const pipelineDetails = action.payload?.pipelineDetails || [];
        return { ...state, pipelineDetails: pipelineDetails };
      }

      case actionTypes.SET_DEAL_DETAILS: {
        const dealDetails = action.payload?.dealDetails || [];
        return { ...state, dealDetails: dealDetails };
      }

      case actionTypes.SET_PHASE_TRANSITION_DETAILS: {
        const phaseTransitionDetails =
          action.payload?.phaseTransitionDetails || [];
        return { ...state, phaseTransitionDetails: phaseTransitionDetails };
      }

      case actionTypes.SET_DEAL_PIPELINES: {
        const dealPipelines = action.payload?.dealPipelines || [];
        return { ...state, dealPipelines: dealPipelines };
      }

      case actionTypes.SET_FILTER_DETAILS: {
        const filterdetails = action.payload?.filterdetails || [];
        return { ...state, filterdetails: filterdetails };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setDealsDetails: (pipelineDetails: DealPipelineDetailsType[]) => ({
    type: actionTypes.SET_SELECTED_PIPELINE_DETAILS,
    payload: { pipelineDetails },
  }),
  setSingleDealDetails: (dealDetails: DealType) => ({
    type: actionTypes.SET_DEAL_DETAILS,
    payload: { dealDetails },
  }),
  setPhaseTransitionDetails: (phaseTransitionDetails: any[]) => ({
    type: actionTypes.SET_PHASE_TRANSITION_DETAILS,
    payload: { phaseTransitionDetails },
  }),
  setDealPipelines: (dealPipelines: any[]) => ({
    type: actionTypes.SET_DEAL_PIPELINES,
    payload: { dealPipelines },
  }),
  setDealFilterDetails: (filterdetails: DealFilterType) => ({
    type: actionTypes.SET_FILTER_DETAILS,
    payload: { filterdetails },
  }),
};
