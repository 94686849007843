import { FC, useEffect, useRef, useState } from "react";

// custom

import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import { LabelType } from "../../masterdata/types/GetLabelsListResponseType";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { uploadCustomerLabels } from "../redux";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";
import { createNewLabel, getLabelsList } from "../../masterdata/redux/LabelsAPI";
import { actions } from "../../masterdata/redux/MasterdataRedux";
import { useDispatch } from "react-redux";

interface Props {
  customer: UserType;
  getCustomerDetailsAPI: () => void;
}

// const PostGeneralDetailsSchema = Yup.object().shape({
//   title: Yup.string().required('Title is required'),
// })

interface ListProp {
  customer: any;
  customerLabels: any;
  getCustomerDetailsAPI: () => void;
}

export const CustomerLabelsList: FC<ListProp> = ({
  customer,
  customerLabels,
  getCustomerDetailsAPI,
}) => {
  const intl = useIntl();
  const [openTagModal, setOpenTagModal] = useState(false);
  const ref = useRef(null);

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, 'contacts'))

  const handleRemoveLabel = (labelId: number) => {
    const ids = customerLabels
      ?.map((label: any) => {
        if (label?.id !== labelId) {
          return label.id;
        }
      })
      .filter((item: any) => item);
    uploadCustomerLabels(customer.id, ids)
      .then(() => {})
      .finally(() => {
        getCustomerDetailsAPI?.();
        setOpenTagModal(false);
      });
  };

  useEffect(() => {
    function handleClickOutside(event: { target: any; }) {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenTagModal(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <>
      <div className="position-relative mx-2">
        {crudPermission?.edit && <div
          role="button"
          className="badge badge-light-primary"
          onClick={() => {
            setOpenTagModal(!openTagModal);
          }}
          title="Add label"
        >
          {intl.formatMessage({
            id: "DEALS_ADD_LABEL_TITLE",
          })}
        </div>}
        {openTagModal && (
          <div
            className="card position-absolute bg-white d-flex flex-column fs-8 border "
            style={{
              minWidth: "350px",
              left: 0,
              top: "30px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              borderRadius: "10px",
              zIndex: "999",
            }}
            ref={ref}
          >
            <div className="card-header min-h-40px pt-4 d-flex flex-center align-items-center">
              <h2>
                {intl.formatMessage({
                  id: "DEALS_ADD_LABEL_MODAL_TITLE",
                })}
              </h2>
              <i
                className="bi bi-x-lg mb-2 mx-4 cursor-pointer"
                onClick={() => {
                  setOpenTagModal(false);
                }}
              ></i>
              
            </div>
            <div className="card-body">
              <CustomerLabelsSelect
                // @ts-ignore
                customer={customer}
                getCustomerDetailsAPI={getCustomerDetailsAPI}
              />
              {/* {tagsList &&
                        tagsList.map((tag, index) => {
                          return (
                            <>
                              <div
                                className="d-flex mb-2 border px-3 py-1 fs-8 text-white"
                                role="button"
                                style={{
                                  background: tag.colour,
                                  borderRadius: "8px",
                                  width: "fit-content",
                                }}
                                onClick={() => {
                                  handleUpdateTag(tag.id);
                                }}
                              >
                                {tag.name}
                              </div>
                            </>
                          );
                        })} */}
            </div>
            {/* <div className="card-footer d-flex  py-2">
                      <div
                        className="badge badge-primary"
                        role="button"
                        style={{
                          width: "fit-content",
                        }}
                        onClick={() => {
                          setOpenAddTagsModal({
                            show: true,
                            type: "new",
                          });
                        }}
                      >
                        {intl.formatMessage({
                          id: "DEALS_EDIT_ADD_TAG_TITLE",
                        })}
                      </div>
                    </div> */}
          </div>
        )}
      </div>
      {customerLabels?.map((label: any) => (
        <div className="badge badge-secondary me-2">
          <i
            className="la la-close text-gray-600 me-1 text-hover-primary cursor-pointer"
            onClick={() => handleRemoveLabel(label?.id)}
          ></i>
          <span className="me-2">{label.name}</span>
        </div>
      ))}
    </>
  );
};

const CustomerLabelsSelect: FC<Props> = ({
  customer,
  getCustomerDetailsAPI,
}) => {
  const intl = useIntl();

  // selectors
  const labelsList = useSelector<RootState>(
    ({ masterdata }) => masterdata.labelsList
  ) as LabelType[];

  // state
  const [selectedLabels, setSelectedLabels] = useState<GroupType[]>(
    customer.labels || []
  );

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const handleOnBlur = (type: "label") => {
    const ids = selectedLabels.map((selectedItem) => {
      return selectedItem.id;
    });
    type === "label" &&
      uploadCustomerLabels(customer?.id, ids)
        .then(() => {})
        .finally(() => {
          getCustomerDetailsAPI?.();
        });
  };

  const handleOnSelectItem = (item: GroupType, type: "label") => {
    const index = selectedLabels.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );

    // if doesnt exist push item
    if (index === -1) {
      type === "label" && setSelectedLabels(() => [...selectedLabels, item]);
    }
    // if already exists remove item
    else {
      type === "label" &&
        setSelectedLabels((prevState) => {
          prevState.splice(index, 1);
          return [...prevState];
        });
    }
  };


  const createCustomLabel = (newLabel:any)=>{
      setLoading(true);
      createNewLabel(newLabel)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "MASTERDATA_LABEL_CREATE_SUCCESS_MESSAGE",
            })
          );
        })
        .catch((err: { response: { data: { errors: { name: any; }; }; }; }) => {
              errorToast(
                intl.formatMessage({
                  id: "MASTERDATA_LABEL_CREATE_FAILURE_MESSAGE",
                })
              );
        })
        .finally(() => {
          getLabelsList()
          .then(({ data: { data } }) => {
            dispatch(actions.setLabelsList(data));
            const selectedItem = data?.filter((label) => label?.name === newLabel)[0];
            //@ts-ignore
            setSelectedLabels(() => [...selectedLabels, selectedItem])
          })
          .catch((e) => {
          })
          setLoading(false);
        });
    
  }

  return (
    <DropDown
      id="post-groups-dropdown"
      items={labelsList|| []}
      displayFunc={(item) => item.name}
      onSelectItem={(item) => {
        //   @ts-ignore
        handleOnSelectItem(item, "label");
      }}
      selectedItems={selectedLabels}
      enableHoverBackground
      hideSelectedItems
      onMultiSelectBlur={() => {
        handleOnBlur("label");
      }}
      customMessage={intl.formatMessage({
        id: "ADD_LABEL_CREATE_CUSTOM_MESSAGE",
      })}
      onEnterCreateElement={(item) => {
        createCustomLabel(item);
      }}
    />
  );
  // return (
  //   <div className="card card-flush py-4">
  //     <div className="card-header">
  //       <div className="card-title">
  //         <h2>{intl.formatMessage({ id: "CONTACTS_GENERAL_TITLE" })}</h2>
  //       </div>
  //     </div>

  //     <div className="card-body pt-0">
  //       {/* Groups */}
  //       <label className="form-label">
  //         {intl.formatMessage({ id: "CUSTOMER_MANAGEMENT_LABELS" })}
  //       </label>
  //       <DropDown
  //         id="post-groups-dropdown"
  //         multiSelect
  //         items={labelsList}
  //         displayFunc={(item) => item.name}
  //         onSelectItem={(item) => {
  //           //   @ts-ignore
  //           handleOnSelectItem(item, "label");
  //         }}
  //         selectedItems={selectedLabels}
  //         onMultiSelectBlur={() => {
  //           handleOnBlur("label");
  //         }}
  //       />
  //     </div>
  //   </div>
  // );
};

export default CustomerLabelsSelect;
