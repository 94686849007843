import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch } from "react-router";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
// import {TablesWidget10} from '../../../_metronic/partials/widgets'
import config from "../../config";
import PartnersList from "./components/PartnersList";
import EditPartnerDetails from "./components/EditPartnerDetails";

// styles
import "./PartnerManagement.scss";

const PartnerManagement = () => {
  const intl = useIntl();

  const partnerManagementBreadCrumbs: Array<PageLink> = [
    // {
    //   title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // }, 
    {
      title: intl.formatMessage({ id: "MENU_PARTNERMANAGEMENT_OVERVIEW" }),
      path: "/crm/organisation",
      isSeparator: false,
      isActive: false,
    },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {config.APP_NAME}-{intl.formatMessage({ id: "PARTNERS_TITLE" })}
        </title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/crm/organisation/overview">
          <PageTitle
            breadcrumbs={[
              ...partnerManagementBreadCrumbs,
              // {
              //   title: intl.formatMessage({ id: "ROUTES_OVERVIEW" }),
              //   path: "/crm/organisation/overview",
              //   isSeparator: false,
              //   isActive: true,
              // },
            ]}
          >
            {intl.formatMessage({ id: "PARTNERS_TITLE" })}
          </PageTitle>
          <PartnersList />
        </Route>
        <Route path="/crm/organisation/edit">
          <PageTitle
            breadcrumbs={[
              ...partnerManagementBreadCrumbs,
              {
                title: intl.formatMessage({
                  id: "ROUTES_PARTNERMANAGEMENT_EDITPARTNER",
                }),
                path: "/crm/organisation/edit",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({ id: "PARTNERS_TITLE" })}
          </PageTitle>
          <EditPartnerDetails />
        </Route>

        <Redirect
          from="/crm/organisation"
          exact={true}
          to="/crm/organisation/overview"
        />
        <Redirect to="/crm/organisation/overview" />
      </Switch>
    </div>
  );
};

export default PartnerManagement;
