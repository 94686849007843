// import moment from 'moment'
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import { LayoutListType } from "../types/LayoutResponsetypes";
import { updateLayout } from "../redux/LayoutApi";

interface Props {
  layout: LayoutListType;
  getLayoutDetailsAPI: () => void;
}

const HeaderPagesShow: FC<Props> = ({ layout, getLayoutDetailsAPI }) => {
  const intl = useIntl();

  // state
  const [checked, setChecked] = useState(Boolean(layout?.show_pages_in_footer));

  useEffect(() => {
    setChecked(Boolean(layout?.show_pages_in_footer));
  }, [layout]);

  const handleOnToggle = (checked: boolean) => {
    setChecked(checked);
    updateLayout(layout?.id, "show_pages_in_footer", checked ? 1 : 0)
      .then(() => {
        checked
          ? successToast(
              intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_UPDATE_VISIBILITY_SUCCESS_VISIBLE",
              })
            )
          : successToast(
              intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_UPDATE_VISIBILITY_SUCCESS_NOT_VISIBLE",
              })
            );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_UPDATE_VISIBILITY_FAILURE",
          })
        );
        setChecked(!!layout?.show_pages_in_footer);
      })
      .finally(() => {
        getLayoutDetailsAPI?.();
      });
  };

  return (
    <div className="card card-flush ">
      <div className="card-header">
        <div className="card-title">
          <h2>
            {intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_FOOTER_SHOW_PAGE_TITLE",
            })}
          </h2>
        </div>
        <div className="card-toolbar">
          <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              name="post-visibility"
              value=""
              checked={checked}
              style={{ width: "50px", height: "25px" }}
              onChange={(e) => {
                handleOnToggle(e.target.checked);
              }}
            />
          </div>
        </div>
      </div>

      {/* <div className='card-body pt-0 pb-2' style={{marginTop: -10}}>
        <span className='fs-7 text-gray-600'>
          {intl.formatMessage({id: 'POSTS_VISIBILITY_DESC'})}
        </span>
      </div> */}
    </div>
  );
};

export default HeaderPagesShow;
