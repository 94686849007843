import axios from "axios";
import { LayoutListType } from "../types/LayoutResponsetypes";
import { GenericResponseType } from "../../../../types/GenericReponse";

export function getLayoutList() {
  return axios.get<LayoutListType>(`/sales/layouts`);
}

export function addLayout(name: string) {
  return axios.post<GenericResponseType>(`/sales/layouts`, {
    name,
  });
}

export function updateLayoutName(id: number, key: string, value: string) {
  return axios.post<GenericResponseType>(`/sales/layouts/${id}`, {
    type: key,
    value: value,
  });
}

export function deleteLayout(id: number) {
  return axios.delete<GenericResponseType>(`/sales/layouts/${id}`);
}

export function getLayoutDetails(id: number) {
  return axios.get(`/sales/layouts/${id}`);
}

export function updateLayoutLogo(id: number, formBody: any) {
  return axios.post(`/sales/layouts/${id}`, formBody);
}

export function updateFooterText(
  id: number,
  key: string,
  value?: string | null
) {
  return axios.post(`/sales/layouts/${id}`, {
    type: key,
    value: value ? value : null,
  });
}

export function updateLayout(id: number, key: string, value: any) {
  return axios.post(`/sales/layouts/${id}`, {
    type: key,
    value: value,
  });
}

export function multiDeleteLayouts(layouts_id: number[]) {
  return axios.post(`/sales/layouts/massDelete`, { layouts_id });
}

export function downloadImageFooterLayout(id: number) {
  return axios.get(`/mediaManager/downloadFile/${id}`, {
    responseType: "blob",
  });
}
