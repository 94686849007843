import React, { FC, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import moment from "moment";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import { LayoutListType } from "../types/LayoutResponsetypes";
import { useLang } from "../../../../../_metronic/i18n/Metronici18n";
import OrderPreviewLang from "../../../../../_metronic/i18n/messages/orderLanguage";
import { registerFonts } from "../../../../utils/AddFontsToPdf";

registerFonts();

interface Props {
  layout: any;
  logo: string | undefined;
  lang: "de" | "en" | "es" | "fr" | "ja";
}

const LayoutPdf: FC<Props> = ({ layout, logo, lang }) => {
  const local = useLang();

  const [dispayLayout, setDispayLayout] = useState<LayoutListType>(layout);

  useEffect(() => {
    setDispayLayout(layout);
  }, [layout]);

  const cleanedHtml = (text: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");

    const spans = doc.querySelectorAll("*");
    spans.forEach((element) => {
      //@ts-ignore
      if (element?.style) {
        //@ts-ignore
        element.style.color = "";
      }
      // Remove color
    });
    return doc.body.innerHTML;
  };

  const getFooterContent = (html: string) => {
    const _content = (
      <div
        style={{ color: `${dispayLayout?.footer_text_color} !important` }}
        dangerouslySetInnerHTML={{
          __html: cleanedHtml(html),
        }}
      ></div>
    );
    let content = ReactDOMServer.renderToStaticMarkup(_content);
    return content;
  };

  const hasActualContent = (html: string | null) => {
    if (!html) return false;
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const textContent = doc.body.textContent || "";
    return textContent.trim().length > 0;
  };

  const footerTextArray = [
    "footers_text_1",
    "footers_text_2",
    "footers_text_3",
    "footers_text_4",
  ]
    .slice(0, layout?.footers_count || 1)
    .filter((key) =>
      hasActualContent(dispayLayout[key as keyof LayoutListType] as string)
    );

  const generateLangObject = (lang: string) => {
    const result: any = {};

    Object.keys(OrderPreviewLang).forEach((key) => {
      //@ts-ignore
      result[key] = OrderPreviewLang[key][lang];
    });

    return result;
  };
  const langObject = generateLangObject(lang);
  return (
    <Document>
      <Page
        size="A4"
        style={[
          styles.body,
          {
            fontFamily: dispayLayout?.font
              ? dispayLayout?.font === "Segeo UI"
                ? "Roboto"
                : dispayLayout?.font
              : "Roboto",
          },
        ]}
      >
        <View
          fixed
          style={[
            styles.header,
            {
              backgroundColor: dispayLayout?.header_background_color || "white",
              justifyContent:
                dispayLayout?.headers_logo_position === "right"
                  ? "flex-end"
                  : dispayLayout?.headers_logo_position === "left"
                    ? "flex-start"
                    : "center",
              height: "27mm",
              alignItems: "center",
            },
          ]}
        >
          {logo && (
            <Image
              style={{
                width: "auto",
                height:
                  dispayLayout?.headers_logo_size === "large"
                    ? "52px"
                    : dispayLayout?.headers_logo_size === "small"
                      ? "30px"
                      : "42px",
                paddingHorizontal: "10mm",
              }}
              source={logo}
            />
          )}
        </View>

        <View
          fixed
          style={{
            backgroundColor: dispayLayout?.footer_background_color
              ? dispayLayout?.footer_background_color
              : "#ffffff",
            color: dispayLayout?.footer_text_color
              ? dispayLayout?.footer_text_color
              : "#000000",
            fontSize: "10px",
            position: "absolute",
            width: "100%",
            bottom: 0,
            paddingHorizontal: "20mm",
            marginTop: "5mm",
            paddingTop: "3.5mm",
            paddingBottom: dispayLayout?.show_created_date_in_footer
              ? "0mm"
              : "4mm",
          }}
        >
          {dispayLayout?.show_pages_in_footer && (
            <Text
              style={[
                styles.pageNumber,
                {
                  color: "#000000",
                  position: "absolute",
                  right: "25mm",
                  top: "-18px",
                },
              ]}
              render={({ pageNumber, totalPages }) =>
                `${langObject?.sales_pdf_page} ${pageNumber} ${langObject?.sales_pdf_page_of} ${totalPages}`
              }
            />
          )}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "3mm",
            }}
          >
            {footerTextArray.length > 0 && (
              <View style={{ display: "flex", flexDirection: "row" }}>
                {footerTextArray.map((key: any) => (
                  <Html style={styles.footerText}>
                    {getFooterContent(
                      //@ts-ignore
                      dispayLayout[key as keyof LayoutListType]
                    )}
                  </Html>
                ))}
              </View>
            )}

            {dispayLayout?.show_created_date_in_footer && (
              <View
                fixed
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "4mm",
                }}
              >
                {dispayLayout?.show_created_date_in_footer && (
                  <Text
                    fixed
                    style={{ justifyContent: "flex-start", fontSize: "10px" }}
                  >
                    {local === "de"
                      ? moment(dispayLayout?.created_at).format("DD.MM.yyyy")
                      : moment(dispayLayout?.created_at).format("YYYY-MM-DD")}
                  </Text>
                )}
              </View>
            )}
          </View>
        </View>
        <View fixed style={[styles.marker, styles.mark1]}></View>
        <View fixed style={[styles.marker, styles.mark2]}></View>
        <View fixed style={[styles.marker, styles.mark3]}></View>
      </Page>
    </Document>
  );
};

export default LayoutPdf;

const styles = StyleSheet.create({
  body: {
    fontFamily: "Roboto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  pageNumber: {
    fontSize: "10px",
    textAlign: "center",
  },
  Date: {
    fontSize: 9,
    textAlign: "right",
  },
  pdfFooter: {
    position: "absolute",
    width: "100%",
    bottom: "0px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },
  pdfPageInfo: {
    width: "100%",
    bottom: "0px",
    padding: "10px",
    height: "auto",
    display: "flex",
    flexDirection: "row",
  },
  pdfDate: {
    width: "calc(100% - 30mm)",
    bottom: "0px",
    height: "auto",
    display: "flex",
    flexDirection: "row",
  },
  pdfHeader: {
    height: "30mm",
    borderBottom: "1px solid #000",
    display: "flex",
    alignItems: "center",
  },
  footerTextElement: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flex: 1,
    textAlign: "left",
    fontSize: "10pt",
    padding: "10px",
    paddingHorizontal: "30px",
  },
  marker: {
    position: "absolute",
    width: "5mm",
    borderTop: "1.5px",
    borderColor: "#000",
  },
  mark1: {
    top: "87mm",
    left: 0,
  },
  mark2: {
    top: "148.5mm",
    left: 0,
  },
  mark3: {
    bottom: "105mm",
    left: 0,
  },
  footerText: {
    flex: 1,
    fontSize: "10px",
    textAlign: "left",
  },
});
