import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { TeamType } from "../../teams/types/TeamsResponseType";
import { useIntl } from "react-intl";
import TeamsList from "../../teams/components/TeamsList";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSelectTeams: (teams: TeamType[]) => void;
  isSelectionModal?: boolean;
}

const SelectTeamsModal: FC<Props> = ({
  show,
  closeModal,
  onSelectTeams,
  isSelectionModal,
}) => {
  const intl = useIntl();
  const [hideMainModal, setHideMainModal] = useState(false);
  return (
    <Modal
      show={show}
      centered
      dialogClassName="extra-large-size-modal"
      className={`${hideMainModal ? "d-none" : "d-block"}`}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "DEAL_SELECT_TEAMS_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <div className="overflow-auto">
        <Modal.Body
          className={`${isSelectionModal ? "selections_modal " : ""} mb-8 overflow-inherit`}
          style={{ padding: "20px" }}
        >
          <TeamsList
            type="select"
            onSelectTeams={onSelectTeams}
            isSelectionModal={true}
            onHideModal={(value: boolean) => {
              setHideMainModal(value);
            }}
          />
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default SelectTeamsModal;
