import { useIntl } from "react-intl";

const NoItemsFound = ({ languageKey }: { languageKey: string }) => {
  const intl = useIntl();
  return (
    <tr className="odd">
      <td valign="top" colSpan={12} className="dataTables_empty">
        <div
          className="d-flex flex-column flex-center"
          style={{ height: "65vh" }}
        >
          <div className="fs-1 fw-bolder text-dark mb-4">
            {intl.formatMessage({
              id: languageKey,
            })}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default NoItemsFound;
