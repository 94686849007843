import React, { FC, useEffect, useState } from "react";

// images
import BlankAvatar from "../../../../../_metronic/assets/icons/blankAvatar.svg";

// custom
import Constants from "../../../../config/Constants";
// import SelectFileModal from "../../../../sharedComponents/selectFileModal/selectFileModal";
import { FileType } from "../../../filemanager/types/getFilemanagerDataResponseType";
import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import { useIntl } from "react-intl";
import { LayoutListType } from "../types/LayoutResponsetypes";
import { deleteConfirmAlert } from "../../../../sharedComponents/Alert";
import { getResolutionFileUrl } from "../../../../utils/getResolutionFile";
import { updateLayout, updateLayoutLogo } from "../redux/LayoutApi";
import DropDown from "../../../../sharedComponents/dropdown/Dropdown";
import GoogleFonts from "../../../../config/google-fonts.json";

interface Props {
  layout: LayoutListType;
  getLayoutDetailsAPI: () => void;
}

interface FileUploadType {
  file: FileType;
  status?: "success" | "failure" | "loading";
}

interface GoogleFontType {
  id: string;
  family: string;
}

interface ColorFontDetailsType {
  id: number;
  font: string;
  success_alert: string;
  warning_alert: string;
  error_alert: string;
  accent: string;
  accent_text_color: string;
  created_at: string;
  updated_at: string | null;
  dark_mode_accent: string;
  dark_mode_accent_text_color: string;
  hyperlink_color: string;
  dark_mode_hyperlink_color: string;
}

const UploadLayoutLogo: FC<Props> = ({ layout, getLayoutDetailsAPI }) => {
  const [mainFile, setMainFile] = useState<FileUploadType | undefined>();
  const [showFileSelectModal, setShowFileSelectModal] =
    useState<boolean>(false);
  const [option, setOption] = useState<any>(
    layout?.headers_logo_position || ""
  );
  const [selectedFont, setSelectedFont] = useState<GoogleFontType>();
  const [colorsFonts, setColorsFonts] = useState<ColorFontDetailsType[]>();

  const intl = useIntl();
  const ALIGN_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "MASTERDATA_SALES_LAYOUT_LOGO_ALIGNMENT_OPTIONS" })
  );

  const LAYOUT_LOGO_SIZE_OPTIONS = (JSON.parse(
    intl.formatMessage({ id: "MASTERDATA_SALES_LAYOUT_LOGO_SIZE_OPTIONS" })
  ) || []) as any[];

  const getIntialLogoSize = () => {
    const index = LAYOUT_LOGO_SIZE_OPTIONS.findIndex(
      (LAYOUT_LOGO_SIZE_OPTION) =>
        LAYOUT_LOGO_SIZE_OPTION.value === layout?.headers_logo_size
    );
    return index >= 0
      ? LAYOUT_LOGO_SIZE_OPTIONS[index]
      : LAYOUT_LOGO_SIZE_OPTIONS[0];
  };
  const [selectedImageSize, setSelectedImageSize] =
    useState(getIntialLogoSize());

  const closeMainFileModal = () => {
    setShowFileSelectModal(false);
  };

  const onFileSelect = (file: any) => {
    closeMainFileModal();
    setMainFile({ file, status: "loading" });

    const formData = new FormData();
    formData.append("type", "headers_logo_url");
    formData.append("value", file);
    updateLayoutLogo(layout?.id, formData)
      .then(() => {
        setMainFile({ file, status: "success" });
        successToast(
          `Image ${intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_LOGO_FILE_UPLOAD_SUCCESS",
          })}`
        );
      })
      .catch(() => {
        setMainFile({ file, status: "failure" });
        errorToast(
          `${intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_LOGO_FILE_UPLOAD_FALIURE",
          })} Image`
        );
      })
      .finally(() => {
        getLayoutDetailsAPI?.();
      });
  };

  const OnOptionSelect = (key: string, value: string) => {
    updateLayout(layout?.id, key, value)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_LOGO_UPDATE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_LOGO_UPDATE_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        getLayoutDetailsAPI?.();
      });
  };

  const removeMainFile = (type?: string) => {
    const formData = new FormData();
    formData.append("type", "headers_logo_url");
    formData.append("value", ``);
    updateLayoutLogo(layout.id, formData)
      .then(() => {
        setMainFile(undefined);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "REMOVE_FILE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getLayoutDetailsAPI?.();
      });
  };

  useEffect(() => {
    setOption(layout?.headers_logo_position || "");
  }, [layout]);

  useEffect(() => {
    setSelectedFont(
      GoogleFonts.find((GoogleFont) => GoogleFont.family === layout.font)
    );
  }, [layout]);

  const handleImageSizeChange = (item: any) => {
    if (layout?.headers_logo_size !== item.value) {
      updateLayout(layout?.id, "headers_logo_size", item.value)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_LOGO_SIZE_UPDATE_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_LOGO_SIZE_UPDATE_ERROR_MESSAGE",
            })
          );
        })
        .finally(() => {
          getLayoutDetailsAPI?.();
        });
    }
  };

  const handleSelectFont = (item: any) => {
    if (layout?.font !== item.family) {
      updateLayout(layout?.id, "font", item.family)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_FONT_FAMILY_UPDATE_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_FONT_FAMILY_UPDATE_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getLayoutDetailsAPI?.();
        });
    }
  };

  return (
    <>
      <div className="card card-flush " style={{ maxHeight: "40%" }}>
        {/* {showFileSelectModal && (
        <SelectFileModal
          show={showFileSelectModal}
          closeModal={closeMainFileModal}
          onSelectFile={onFileSelect}
          allowedMimetypes={Constants.mimeTypes.image}
          isMultiple={false}
          isSelectionModal={true}
        />
      )} */}

        <div className="card-header">
          <div className="card-title">
            <h2>
              {intl.formatMessage({ id: "MASTERDATA_SALES_LAYOUT_LOGO_TITLE" })}
            </h2>
          </div>
        </div>

        <div className="card-body pt-0 d-flex flex-column justify-content-center align-items-center">
          {/* <div className='d-flex justify-content-center mb-3'> */}
          <div className="image-input image-input-outline d-flex justify-content-center">
            <img
              // src={mainFile?.file.file_path || event.event_image_url || BlankAvatar}
              src={
                mainFile?.file.file_path
                  ? getResolutionFileUrl(
                      "144",
                      mainFile?.file.file_path,
                      mainFile?.file.resolutions
                    )
                  : layout?.headers_logo_url
                    ? getResolutionFileUrl(
                        "144",
                        layout?.headers_logo_url || ""
                      )
                    : BlankAvatar
              }
              style={{
                height: "100%",
                width: "100%",
                maxHeight: "136px",
                maxWidth: "241.5px",
                objectFit: "contain",
              }}
              alt="img"
              className="image-input-wrapper"
              draggable={false}
            />

            <label
              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
              data-kt-image-input-action="change"
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Change avatar"
              // onClick={() => {
              //   setShowFileSelectModal(true)
              // }}
            >
              <input
                type="file"
                onChange={(e) => {
                  onFileSelect(e.target?.files?.[0]);
                }}
              />
              <i className="bi bi-pencil-fill fs-7"></i>
            </label>
            {mainFile?.file.file_path || layout?.headers_logo_url ? (
              <span
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute"
                data-bs-toggle="tooltip"
                style={{ bottom: -5, right: -9 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteConfirmAlert(
                    intl.formatMessage({
                      id: "REMOVE_FILE_TITLE",
                    }),
                    () => {
                      removeMainFile();
                    }
                  );
                }}
              >
                <i className="bi bi-x fs-2"></i>
              </span>
            ) : null}
          </div>
          {/* </div> */}
          <div className="text-muted fs-7 text-center mt-3">
            {`${intl.formatMessage({
              id: "ONLY",
            })} ${Constants.mimeTypes.image.join(", ")} ${intl.formatMessage({
              id: "FILES_ACCEPTED",
            })}`}
          </div>
        </div>
      </div>

      <div className="card card-flush">
        <div className="card-header">
          <div className="card-title">
            <h2>
              {intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_LOGO_ALIGNMENT_TITLE",
              })}
            </h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <DropDown
            id="postTypeDropDown"
            hideSearch
            items={ALIGN_OPTIONS || []}
            displayFunc={(item) => {
              return item.name;
            }}
            displayValue={
              ALIGN_OPTIONS.find((postion: any) => {
                return postion.value === option;
              })?.name || ""
            }
            // @ts-ignore
            onSelectItem={(item) => {
              // @ts-ignore
              setOption(item?.value);
              // @ts-ignore
              OnOptionSelect("headers_logo_position", item?.value);
            }}
            // @ts-ignore
            selectedItem={ALIGN_OPTIONS.find((postion: any) => {
              return postion.value === option;
            })}
            // selectedItem={
            //   ALIGN_OPTIONS.find((position: any) => {
            //     return position.value === option
            //   })?.name || ''
            // }
          />
        </div>
      </div>
      <div className="card card-flush">
        <div className="card-header">
          <div className="card-title">
            <h2>
              {intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_LOGO_SIZE_TITLE",
              })}
            </h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <DropDown
            hideSearch
            id="header-logo-image-sizes-dropdown"
            items={LAYOUT_LOGO_SIZE_OPTIONS}
            displayFunc={(item) => {
              return item.name;
            }}
            displayValue={selectedImageSize.name}
            // @ts-ignore
            onSelectItem={(item) => {
              setSelectedImageSize(item);
              handleImageSizeChange(item);
            }}
            selectedItem={selectedImageSize}
          />
        </div>
      </div>
      <div className="card card-flush">
        <div className="card-header">
          <div className="card-title">
            <h2>
              {intl.formatMessage({ id: "MASTERDATA_SALES_LAYOUT_FONT" })}
            </h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <DropDown
            id="header-settings-font-select"
            //@ts-ignore
            items={GoogleFonts}
            searchkey={"family"}
            // @ts-ignore
            displayFunc={(item) => {
              // @ts-ignore
              return item.family;
            }}
            displayValue={selectedFont?.family}
            // @ts-ignore
            onSelectItem={(item) => {
              //@ts-ignore
              setSelectedFont(item);
              handleSelectFont(item);
            }}
            // @ts-ignore
            selectedItem={selectedFont}
          />
        </div>
      </div>
    </>
  );
};

export default UploadLayoutLogo;
