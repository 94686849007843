import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import user from "../../../../_metronic/assets/icons/user.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import CalendarIcon from "../../../../_metronic/assets/icons/calendar.svg";
import TasksIcon from "../../../../_metronic/assets/icons/list-menu.svg";
import role from "../../../assets/icons/role.svg";

import { UserType } from "../../../../app/modules/userManagement/types/getUsersListResponseType";
import { RootState } from "../../../../setup";
import { actions } from "../../../../app/modules/auth";

import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import {
  enablePermissionForCrud,
  isPermissionEnabledForResource,
} from "../../../../app/utils/PermisisionEnabledForResource";
import { RoleType } from "../../../../app/modules/RolesAndPermissions/types/getRolesResponseType";
import { getRoleDetails } from "../../../../app/modules/RolesAndPermissions/redux";

export function AsideMenuMain() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const id = localStorage.getItem("pId");

  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const pipelineList = useSelector<RootState>(
    ({ deals }) => deals.dealPipelines
  ) as any[];
  const userRoleDetails = useSelector<RootState>(
    (state) => state.auth.roleDetails
  ) as RoleType;

  // @ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "pipelines"
  );

  const [showloadData, setShowLoadData] = useState(false);

  useEffect(() => {
    if (userData?.id) {
      setShowLoadData(true);
    }
    if (id) {
      dispatch(actions.setActivePipelineId(Number(id)));
    }
  }, []);

  useEffect(() => {
    getRoleDetails(userData?.role.id)
      .then(({ data: { rolesDetails } }) => {
        dispatch(actions.setRoleDetails(rolesDetails));
      })
      .catch((error) => console.log(error?.response?.data?.message));
  }, []);

  return (
    <div
      className="d-flex flex-column justify-content-between h-100"
      style={{
        whiteSpace: "nowrap",
        overflowX: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {showloadData && (
        <div>
          <AsideMenuItem
            to="/dashboard"
            title={intl.formatMessage({ id: "MENU_DASHBOARD" })}
            fontIcon="bi-app-indicator"
            icon={"/media/icons/duotune/general/gen001.svg"}
          />

          <AsideMenuItem
            to="/calendar"
            title={intl.formatMessage({ id: "MENU_CALENDAR" })}
            fontIcon="bi-chat-left"
            className="pt-1"
            icon={CalendarIcon}
          />

          {isPermissionEnabledForResource(userRoleDetails, "tasks") && (
            <AsideMenuItem
              to="/tasks"
              title={intl.formatMessage({ id: "MENU_TASKS" })}
              fontIcon="bi-chat-left"
              className="pt-1"
              icon={TasksIcon}
            />
          )}

          {(isPermissionEnabledForResource(userRoleDetails, "file-manager") ||
            isPermissionEnabledForResource(userRoleDetails, "deals") ||
            isPermissionEnabledForResource(userRoleDetails, "pipelines")) && (
            <AsideMenuItemWithSub
              to=""
              title={intl.formatMessage({ id: "MENU_CONTENT" })}
              fontIcon="bi-chat-left"
              className="pt-1"
              icon={"/media/icons/duotune/abstract/abs026.svg"}
            >
              <>
                {pipelineList && pipelineList.length > 0 ? (
                  pipelineList.map((pipeline, index) => (
                    <div
                      className="ms-3"
                      onClick={() => {
                        localStorage.setItem("pId", pipeline.id.toString());
                        dispatch(actions.setActivePipelineId(pipeline.id));
                      }}
                      key={index}
                    >
                      <AsideMenuItem
                        key={index}
                        to={`/content/deals`}
                        title={pipeline.name.toUpperCase()}
                        fontIcon="bi-chat-left"
                        icon={"/media/icons/duotune/ecommerce/ecm011.svg"}
                        index={pipeline.id}
                      />
                    </div>
                  ))
                ) : (
                  <>
                    {crudPermission?.create && (
                      <div>
                        <AsideMenuItem
                          to={`/projects/pipelines`}
                          title={intl.formatMessage({
                            id: "PROJECTS_PIPELINE_ADD_NEW_BUTTON",
                          })}
                          fontIcon="bi-chat-left"
                          icon={PlusIcon}
                        />
                      </div>
                    )}
                  </>
                )}
              </>

              {isPermissionEnabledForResource(
                userRoleDetails,
                "file-manager"
              ) && (
                <AsideMenuItem
                  to="/file-manager"
                  title={intl.formatMessage({ id: "MENU_FILEMANAGER" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/folder.svg"}
                />
              )}
              {isPermissionEnabledForResource(
                userRoleDetails,
                "archived-deals"
              ) && (
                <AsideMenuItem
                  to="/deals/archived"
                  title={intl.formatMessage({ id: "MENU_DEALS_ARCHIVED" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/archive.svg"}
                />
              )}
            </AsideMenuItemWithSub>
          )}

          {(isPermissionEnabledForResource(userRoleDetails, "products") ||
            isPermissionEnabledForResource(userRoleDetails, "partners") ||
            isPermissionEnabledForResource(userRoleDetails, "contacts") ||
            isPermissionEnabledForResource(userRoleDetails, "users") ||
            isPermissionEnabledForResource(userRoleDetails, "teams") ||
            isPermissionEnabledForResource(
              userRoleDetails,
              "roles-and-permissions"
            ) ||
            isPermissionEnabledForResource(userRoleDetails, "categories")) && (
            <AsideMenuItemWithSub
              to="/management"
              title={intl.formatMessage({ id: "MENU_MANAGEMENT" })}
              fontIcon="bi-chat-left"
              className="pt-1"
              icon={"/media/icons/duotune/communication/group.svg"}
            >
              {isPermissionEnabledForResource(userRoleDetails, "products") && (
                <AsideMenuItem
                  to="/sales/products"
                  title={intl.formatMessage({ id: "MENU_PRODUCTS" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/abstract/abs027.svg"}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "partners") && (
                <AsideMenuItem
                  to="/crm/organisation"
                  title={intl.formatMessage({ id: "MENU_SYSTEM_PARTNER" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/ecommerce/ecm008.svg"}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "contacts") && (
                <AsideMenuItem
                  to="/crm/contacts"
                  title={intl.formatMessage({ id: "MENU_SYSTEM_CUSTOMER" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/general/user.svg"}
                />
              )}
              {(userData?.role.id === 1 ||
                isPermissionEnabledForResource(userRoleDetails, "users")) && (
                <AsideMenuItem
                  to="/system/user"
                  title={intl.formatMessage({ id: "MENU_SYSTEM_USER" })}
                  fontIcon="bi-chat-left"
                  icon={user}
                />
              )}
              {(userData?.role.id === 1 ||
                isPermissionEnabledForResource(userRoleDetails, "teams")) && (
                <AsideMenuItem
                  to="/system/teams"
                  title={intl.formatMessage({
                    id: "MENU_TEAMS",
                  })}
                  icon="/media/icons/duotune/ecommerce/ecm008.svg"
                />
              )}
              {userData?.role.id === 1 &&
                isPermissionEnabledForResource(
                  userRoleDetails,
                  "roles-and-permissions"
                ) && (
                  <>
                    <AsideMenuItem
                      to="/system/roles-permissions"
                      title={intl.formatMessage({
                        id: "ROLES_AND_PERMISSIONS_TITLE",
                      })}
                      icon={role}
                    />
                  </>
                )}
              {isPermissionEnabledForResource(
                userRoleDetails,
                "categories"
              ) && (
                <AsideMenuItem
                  to="/master-data/categories"
                  title={intl.formatMessage({ id: "MENU_CATEGORIES" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                />
              )}
            </AsideMenuItemWithSub>
          )}
          {/* commented for master branch */}
          <AsideMenuItemWithSub
            to=""
            title={intl.formatMessage({ id: "MENU_SALES" })}
            fontIcon="bi-chat-left"
            icon={"/media/icons/duotune/ecommerce/tag.svg"}
          >
            <AsideMenuItem
              to="/sales/offers"
              title={intl.formatMessage({
                id: "MENU_SALES_OFFERS",
              })}
              fontIcon="bi-chat-left"
              icon={"/media/icons/duotune/files/clipboard.svg"}
            />
            <AsideMenuItem
              to="/sales/invoices"
              title={intl.formatMessage({
                id: "MENU_SALES_INVOICES",
              })}
              fontIcon="bi-chat-left"
              icon={"/media/icons/duotune/files/document.svg"}
            />
            <AsideMenuItem
              to="/sales/master-data/layout"
              title={intl.formatMessage({
                id: "MENU_SALES_MASTERDATA_LAYOUT",
              })}
              fontIcon="bi-chat-left"
              icon={"/media/icons/duotune/layouts/lay001.svg"}
            />

            <AsideMenuItem
              to="/sales/master-data/notes"
              title={intl.formatMessage({
                id: "MENU_SALES_MASTERDATA_NOTES",
              })}
              fontIcon="bi-chat-left"
              icon={"/media/icons/duotune/files/fil026.svg"}
            />
            <AsideMenuItem
              to="/sales/master-data/settings"
              title={intl.formatMessage({
                id: "MENU_SALES_MASTERDATA_SETTINGS",
              })}
              fontIcon="bi-chat-left"
              icon={"/media/icons/duotune/general/gen057.svg"}
            />
          </AsideMenuItemWithSub>

          {(isPermissionEnabledForResource(userRoleDetails, "pipelines") ||
            isPermissionEnabledForResource(userRoleDetails, "fields") ||
            isPermissionEnabledForResource(userRoleDetails, "tabs") ||
            isPermissionEnabledForResource(userRoleDetails, "groups") ||
            isPermissionEnabledForResource(userRoleDetails, "tags") ||
            isPermissionEnabledForResource(userRoleDetails, "labels") ||
            isPermissionEnabledForResource(userRoleDetails, "contacts-types") ||
            isPermissionEnabledForResource(
              userRoleDetails,
              "contacts_titles"
            ) ||
            isPermissionEnabledForResource(userRoleDetails, "products-types") ||
            isPermissionEnabledForResource(
              userRoleDetails,
              "system-messages"
            ) ||
            isPermissionEnabledForResource(
              userRoleDetails,
              "industries_sectors"
            )) && (
            <AsideMenuItemWithSub
              to="/editor"
              title={intl.formatMessage({ id: "MENU_PROJECTS" })}
              fontIcon="bi-chat-left"
              icon={"/media/icons/duotune/communication/com002.svg"}
            >
              {isPermissionEnabledForResource(userRoleDetails, "pipelines") && (
                <AsideMenuItem
                  to="/projects/pipelines"
                  title={intl.formatMessage({ id: "MENU_PIPELINES" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/communication/com009.svg"}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "fields") && (
                <AsideMenuItem
                  to="/projects/master-data/fields"
                  title={intl.formatMessage({ id: "MENU_FIELDS" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "tabs") && (
                <AsideMenuItem
                  to="/projects/master-data/tabs"
                  title={intl.formatMessage({ id: "MENU_TABS" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "groups") && (
                <AsideMenuItem
                  to="/master-data/groups"
                  title={intl.formatMessage({ id: "MENU_GROUPS" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "tags") && (
                <AsideMenuItem
                  to="/master-data/tags"
                  title={intl.formatMessage({ id: "MENU_TAGS" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                />
              )}
              {isPermissionEnabledForResource(userRoleDetails, "labels") && (
                <AsideMenuItem
                  to="/master-data/labels"
                  title={intl.formatMessage({ id: "MENU_LABELS" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                />
              )}
              {isPermissionEnabledForResource(
                userRoleDetails,
                "contacts-types"
              ) && (
                <AsideMenuItem
                  to="/master-data/contact-types"
                  title={intl.formatMessage({ id: "MENU_CONTACTTYPES" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                />
              )}
              {isPermissionEnabledForResource(
                userRoleDetails,
                "contacts_titles"
              ) && (
                <AsideMenuItem
                  to="/master-data/contact-titles"
                  title={intl.formatMessage({ id: "MENU_CONTACTTITLES" })}
                  fontIcon="bi-chat-left"
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                />
              )}
              {isPermissionEnabledForResource(
                userRoleDetails,
                "industries_sectors"
              ) && (
                <AsideMenuItem
                  to="/master-data/industries-sectors"
                  title={intl.formatMessage({
                    id: "MASTERDATA_INDUSTRIESSECTORS_TITLE",
                  })}
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                />
              )}
              {isPermissionEnabledForResource(
                userRoleDetails,
                "products-types"
              ) && (
                <AsideMenuItem
                  to="/master-data/product-types"
                  title={intl.formatMessage({
                    id: "MASTERDATA_PRODUCT_TYPES_TITLE",
                  })}
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                />
              )}
              {isPermissionEnabledForResource(
                userRoleDetails,
                "system-messages"
              ) && (
                <AsideMenuItem
                  to="/master-data/system-messages"
                  title={intl.formatMessage({
                    id: "MASTERDATA_SYSTEM_MESSAGES_TITLE",
                  })}
                  icon={"/media/icons/duotune/files/sub-menu.svg"}
                />
              )}
            </AsideMenuItemWithSub>
          )}

          {isPermissionEnabledForResource(userRoleDetails, "google") && (
            <AsideMenuItemWithSub
              to="/channel"
              title={intl.formatMessage({ id: "MENU_SETTINGS" })}
              fontIcon="bi-chat-left"
              className="pt-1"
              icon={"/media/icons/duotune/general/satellite.svg"}
            >
              <AsideMenuItem
                to="/interfaces/google"
                title={intl.formatMessage({ id: "MENU_GOOGLE" })}
                fontIcon="bi-chat-left"
                icon={"/media/icons/duotune/communication/google-icon.svg"}
              />
              <AsideMenuItem
                to="/settings/company"
                title={intl.formatMessage({ id: "MENU_COMAPANY_SETTINGS" })}
                fontIcon="bi-chat-left"
                icon={"/media/icons/duotune/communication/company.svg"}
              />
            </AsideMenuItemWithSub>
          )}
        </div>
      )}
    </div>
  );
}
