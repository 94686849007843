import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";

import ColorPickerInput from "../../../../sharedComponents/colorPickerInput/ColorPickerInput";

import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";

import { LayoutListType } from "../types/LayoutResponsetypes";
import { updateLayout } from "../redux/LayoutApi";

interface Props {
  layout: LayoutListType;
  getLayoutDetailsAPI: () => void;
}

const LayoutBackgroundColor: FC<Props> = ({ layout, getLayoutDetailsAPI }) => {
  const intl = useIntl();
  const initialValues = {
    optimized_color: (layout && layout?.header_background_color) || "#f86d6d",
  };

  const ColorAndFontSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues,
    validationSchema: ColorAndFontSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      updateLayout(
        layout?.id,
        "headers_background_color",
        values.optimized_color
      )
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_BACKGROUND_COLOR_UPDATE_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_BACKGROUND_COLOR_ERROR_MESSAGE",
            })
          );
        })
        .finally(() => {
          getLayoutDetailsAPI();
        });
    },
  });

  return (
    <div className="card card-flush py-4">
      <div className="card-header">
        <div className="card-title">
          <h2>
            {intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_BACKGROUND_TITLE",
            })}
          </h2>
        </div>
      </div>
      <div
        className="card-body pt-0"
        onBlur={() => {
          formik.handleSubmit();
        }}
      >
        {/* <label className=' fs-5 fw-bold mb-2'>
          {intl.formatMessage({id: 'MASTERDATA_SALES_NOTES_OPTIMIZED_LABEL'})}
        </label> */}
        <ColorPickerInput
          formikProps={formik.getFieldProps("optimized_color")}
          onColorChange={(color) => {
            formik.setFieldValue("optimized_color", color);
          }}
        />
      </div>
    </div>
  );
};

export default LayoutBackgroundColor;
