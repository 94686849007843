import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import _ from "lodash";
import clsx from "clsx";

import { SVGICON } from "../../../../../_metronic/helpers";
import PlusIcon from "../../../../../_metronic/assets/icons/plus.svg";
import { useLang } from "../../../../../_metronic/i18n/Metronici18n";

import DateTimePicker from "../../../../sharedComponents/dateTimePicker/DateTimePicker";
import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";
import DropDown from "../../../../sharedComponents/dropdown/Dropdown";

import PaymentPositionsList from "./PaymentPositionsList";

import {
  OrderExternalItemType,
  PaymentPositionType,
} from "../types/OrdersTypes";
import { InvoiceType } from "../types/InvoicesTypes";
import { addPayments, deletePosition } from "../redux";
import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";

interface Props {
  show: boolean;
  loading?: boolean;
  closeModal: () => void;
  invoice: InvoiceType | null | any;
  type?: string;
  getOrderDetailsAPI?: () => void;
}
const InvoicePaymentModal: FC<Props> = ({
  show,
  loading: _loading,
  closeModal,
  invoice,
  type,
  getOrderDetailsAPI,
}) => {
  const intl = useIntl();
  const local = useLang();
  const [date, setDate] = useState<Date | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [paidPrice, setPaidPrice] = useState<number>(0);
  const [positions, setPositions] = useState<PaymentPositionType[]>([]);
  const [duePrice, setDuePrice] = useState<number>(0);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [checkedPositions, setCheckedPositions] = useState<
    PaymentPositionType[]
  >([]);
  const [checkedAllPositions, setCheckedAllPositions] =
    useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] = useState<string>();

  const {
    isOpen,
    showDeleteConfirmModal,
    setDeleteModalLoading,
    hideDeleteConfirmModal,
  } = useGlobalModalContext();

  const editorOptions = [
    { id: "1", name: "Single Payment", value: "single_payment" },
    { id: "2", name: "Position Payment", value: "partial_payment" },
  ];

  const initialValues = {
    type: type,
    payment_date: "",
    total_amount: "",
    amount_paid: "",
    paid: type === "single_payment" ? "paid" : "",
  };

  const schema = Yup.object().shape({
    payment_date: Yup.string().required(
      intl.formatMessage({
        id: "PAYMENT_TARGET_DATE_IS_REQUIRED",
      })
    ),
    amount_paid: Yup.number()
      .min(
        0,
        intl.formatMessage({
          id: "PRICE_SHOULD_BE_ZERO",
        })
      )
      .max(
        +duePrice,
        intl.formatMessage({
          id: "COUNT_SHOULD_BE_MORE_THEN_DUE_DATE",
        })
      )
      .required(
        intl.formatMessage({
          id: "PAYMENT_TARGET_PRICE_IS_REQUIRED",
        })
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setFieldValue, setFieldTouched }) => {
      setLoading(true);
      let data = {
        status: "partiallyPaid",
        amount_paid: Number(values.amount_paid) * 100,
        amount_paid_at: values.payment_date,
      };
      if (selectedPayment === "single_payment") {
        data.status = "paid";
      }
      if (Number(values.amount_paid) === Number(duePrice)) {
        data.status = "paid";
      }
      addPayments(invoice?.id, data)
        .then(() => {
          successToast(
            intl.formatMessage({ id: "PAYMENT_POSITIONS_ADDED_SUCCESSFULLY" })
          );
          setDate(undefined);
          setFieldValue("payment_date", "");
          setFieldValue("amount_paid", "");
          setFieldTouched("payment_date", false);
          setFieldTouched("amount_paid", false);
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({ id: "PAYMENT_POSITIONS_FAILED_TO_ADD" })
          );
        })
        .finally(() => {
          getOrderDetailsAPI?.();
          setLoading(false);
        });
    },
  });

  const handlePaymentMethodChange = (option: any) => {
    setSelectedPayment(option.value);
    if (option.value === "single_payment") {
      formik.setFieldValue("amount_paid", +formik.values.total_amount);
    } else {
      formik.setFieldValue("amount_paid", "");
    }
  };

  useEffect(() => {
    const dueAmount = Number(
      (Number(+formik?.values?.total_amount) - Number(paidPrice || 0)).toFixed(
        2
      )
    );
    setDuePrice(dueAmount);
  }, [formik?.values?.total_amount, paidPrice]);

  const deleteMultiplePositions = (id: number) => {
    setDeleteModalLoading(true);
    deletePosition(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id:
              checkedPositions?.length > 1
                ? "PAYMENT_POSITIONS_DELETED_SUCCESSFULLY"
                : "PAYMENT_POSITION_DELETED_SUCCESSFULLY",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id:
              checkedPositions?.length > 1
                ? "FAILED_TO_DELETE_PAYMENT_POSITIONS"
                : "FAILED_TO_DELETE_PAYMENT_POSITION",
          })
        );
      })
      .finally(() => {
        hideDeleteConfirmModal();
        setDeleteModalLoading(false);
        setCheckedAllPositions(false);
        setCheckedPositions([]);
        getOrderDetailsAPI?.();
      });
  };

  const getInternalInitialValues = (internalItems: any) => {
    let arr: any[] = [];
    internalItems?.forEach((item: any) => {
      arr.push({
        id: item?.id,
        title: item?.product_title,
        description: item?.product_description || "",
        quantity: item?.quantity || 1,
        price: item?.unit_price ? (item?.unit_price / 100).toFixed(2) : null,
        tax_percentage: item?.tax_percentage || null,
        discount_percentage: item?.discount_percentage,
      });
    });

    return arr;
  };

  const getProductPrice = (item: OrderExternalItemType) => {
    if (!item.price) {
      return 0;
    }

    let initialPrice =
      Number(item.price) * Number(item.quantity ? item.quantity : 1);
    let discountAmount = item.discount_percentage
      ? initialPrice * (Number(item.discount_percentage) / 100)
      : 0;

    let discountedPrice = initialPrice - discountAmount;
    let taxAmount = item.tax_percentage
      ? discountedPrice * (Number(item.tax_percentage) / 100)
      : 0;

    return discountedPrice + taxAmount;
  };

  const getTotalAmount = (order: InvoiceType) => {
    let result = 0;
    [...getInternalInitialValues(order?.sales_details?.sale_items)]?.forEach(
      (item) => {
        result += +getProductPrice(item).toFixed(2);
      }
    );
    formik.setFieldValue("total_amount", result);

    let paidAmount =
      order?.paymentDetails?.reduce(
        // @ts-ignore
        (acc, curr) => acc + Number(curr?.amount_paid),
        0
      ) || 0;
    setPaidPrice(paidAmount / 100);
    return result;
  };

  useEffect(() => {
    // @ts-ignore
    getTotalAmount(invoice);
  }, [invoice]);

  useEffect(() => {
    if (selectedPayment === "single_payment") {
      formik.setFieldValue(
        "amount_paid",
        (+formik.values.total_amount).toFixed(2)
      );
    }
  }, [selectedPayment, formik.values.total_amount]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/,/g, "");
    formik.setFieldValue("amount_paid", value);
  };

  return (
    <Modal
      show={show}
      centered
      dialogClassName={clsx("extra-large-size-modal", isOpen ? "d-none" : "")}
      contentClassName={_loading ? "pe-none" : ""}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({
            id: "INVOICE_PAMENT_POSITIONS",
          })}
        </Modal.Title>
      </Modal.Header>
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_post_basic_form"
      >
        <Modal.Body className="overflow-inherit">
          <div className="d-flex align-items-star justify-content-between border-bottom p-4">
            <div className="w-200px">
              <div className="fw-bolder fs-4 ">
                {intl.formatMessage({ id: "INVOICES_NUMBER" })}
              </div>
              <div className=" fs-6">
                {(invoice?.final_invoice_number || "")
                  .replaceAll("-", "")
                  .replaceAll(" ", "")}
              </div>
            </div>
            <div className=" w-250px">
              <div className="fw-bolder fs-4 ">
                {intl.formatMessage({ id: "PAYMENT_POSITION_PARTNER" })}
              </div>
              <div className=" fs-6">{invoice?.to_company_name || ""}</div>
            </div>
            <div className="w-250px">
              <div className="fw-bolder fs-4">
                {intl.formatMessage({ id: "PAYMENT_POSITION_INVOICE_DATE" })}
              </div>
              <div className=" fs-6">
                {/* {invoice?.invoice_date}{' '} */}
                {local === "de"
                  ? moment(invoice?.invoice_date).format("DD.MM.yyyy")
                  : moment(invoice?.invoice_date).format("YYYY-MM-DD")}
              </div>
            </div>
            <div className="w-300px">
              <div className="fw-bolder fs-4">
                {intl.formatMessage({
                  id: "PAYMENT_POSITION_INVOICE_GROSS_AND_PAID_STATUS",
                })}
              </div>
              {invoice?.status === "paid" ? (
                <div className="text-success fs-6">
                  0 EUR /{(+formik?.values?.total_amount).toLocaleString(local)}{" "}
                  EUR
                </div>
              ) : (
                <div className="text-success fs-6">
                  {(+formik?.values?.total_amount - paidPrice).toLocaleString(
                    local
                  )}{" "}
                  EUR /{(+formik?.values?.total_amount).toLocaleString(local)}{" "}
                  EUR
                </div>
              )}
            </div>
          </div>

          {invoice?.status === "paid" ? (
            <div className="d-flex justify-content-center align-items-center p-10 mt-6">
              <span className="fw-bolder fs-2">
                {" "}
                {intl.formatMessage({ id: "PAYMENT_STATUS_MESSAGE" })}
              </span>
            </div>
          ) : (
            <>
              {invoice?.paymentDetails?.length === 0 && (
                <div className="my-10 d-flex justify-content-between align-items-center">
                  <label className="form-label fs-5 fw-bold mb-2">
                    {intl.formatMessage({
                      id: "PAYMENT_METHOD_SELECTION_TITLE",
                    })}
                  </label>
                  <div className="w-75">
                    <DropDown
                      id="paymentMethodDropDown"
                      hideSearch
                      // @ts-ignore
                      items={editorOptions}
                      displayFunc={(items: any) => items.name}
                      // @ts-ignore
                      displayValue={
                        // @ts-ignore
                        editorOptions.find(
                          (option) => option.value === selectedPayment
                        )?.name ||
                        intl.formatMessage({
                          id: "PAYMENT_METHOD_SELECTION",
                        })
                      }
                      onSelectItem={handlePaymentMethodChange}
                      // @ts-ignore
                      selectedItem={editorOptions.find(
                        (option) => option.value === selectedPayment
                      )}
                    />
                  </div>
                </div>
              )}
              {/* @ts-ignore */}
              {(selectedPayment || invoice?.paymentDetails?.length > 0) && (
                <div>
                  {!!Math.round(
                    +formik?.values?.total_amount - paidPrice || 0
                  ) && (
                    <div className="d-flex align-items-start justify-content-between p-4 pb-0">
                      <div className="align-items-start d-flex justify-content-start gap-5">
                        <div className="w-300px">
                          <label className="d-flex align-items-center fs-5 mt-4 fw-bold mb-2 ">
                            <span className="">
                              {intl.formatMessage({
                                id: "INVOICES_DATE_TITLE",
                              })}
                            </span>
                          </label>
                          <DateTimePicker
                            onDateChange={(date) => {
                              setDate(date);
                              formik.setFieldValue(
                                "payment_date",
                                moment(date).format("YYYY-MM-DD")
                              );
                            }}
                            placeholder={intl.formatMessage({
                              id: "SELECT_DATE_PLACEHOLDER",
                            })}
                            selectedDate={date}
                            hideTimeSelect={true}
                            // @ts-ignore
                            minDate={invoice?.created_at}
                            noMaxDate
                          />
                          {formik.touched.payment_date &&
                            formik.errors.payment_date && (
                              <div className="text-danger">
                                <span role="alert">
                                  {formik.errors.payment_date}
                                </span>
                              </div>
                            )}
                        </div>
                        <div>
                          <label className="d-flex align-items-center fs-5 mt-4 fw-bold mb-2 ">
                            <span className="">
                              {intl.formatMessage({
                                id: "PAYMENT_POSITIONS_BID_PRICE",
                              })}
                            </span>
                          </label>

                          <input
                            {...formik.getFieldProps("amount_paid")}
                            className="form-control form-control-solid mb-3 mb-lg-0"
                            disabled={selectedPayment === "single_payment"}
                            onChange={handleChange}
                          />

                          {formik.touched.amount_paid &&
                            formik.errors.amount_paid && (
                              <div className="text-danger">
                                <span role="alert">
                                  {formik.errors.amount_paid}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      {!checkedPositions?.length && (
                        <Button className="btn btn-primary mt-12" type="submit">
                          {!loading && (
                            <>
                              {!(selectedPayment === "single_payment") && (
                                <SVGICON
                                  src={PlusIcon}
                                  className="svg-icon-2 svg-icon-white"
                                />
                              )}
                              {intl.formatMessage({
                                id:
                                  selectedPayment === "single_payment"
                                    ? "INVOICE_COMPLETE_PAYMENT_TITLE"
                                    : "ADD_PAYMENT_POSITION",
                              })}
                            </>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              {intl.formatMessage({
                                id: "ORDER_MANAGEMENT_ADD_ORDER_MODAL_SUBMIT_LOADING",
                              })}
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </Button>
                      )}
                    </div>
                  )}

                  {invoice?.status === "partiallyPaid" && (
                    <div
                      className="ps-4"
                      style={{ maxHeight: "400px", overflow: "auto" }}
                    >
                      <PaymentPositionsList
                        positions={invoice?.paymentDetails || []}
                        // getPositionsList={getPositionsList}
                        loading={listLoading}
                        setCheckedPositions={setCheckedPositions}
                        checkedPositions={checkedPositions}
                        checkedAllPositions={checkedAllPositions}
                        setCheckedAllPositions={setCheckedAllPositions}
                        deletePosition={(id) => {
                          deleteMultiplePositions(id);
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            className="btn btn-secondary"
            variant="secondary"
            onClick={() => {
              closeModal?.();
            }}
            type="button"
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default InvoicePaymentModal;
