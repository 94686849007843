import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { pdf } from "@react-pdf/renderer";
import moment from "moment";
import * as Yup from "yup";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLang } from "../../../../../_metronic/i18n/Metronici18n";

// css
import "./Invoices.scss";

import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import DateTimePicker from "../../../../sharedComponents/dateTimePicker/DateTimePicker";
import DropDown from "../../../../sharedComponents/dropdown/Dropdown";
import DynamicDropdown from "../../../../sharedComponents/DynamicDropdown/DynamicDropdown";
import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";

import SelectPaymentModal from "./SelectPaymentModal";
import InvoicePaymentModal from "./InvoicePaymentModal";
import OrderPreview from "./InvoicePreview";
import AddOrderItems from "./AddInvoiceItems";

import CreateCustomerModal from "../../Offers/components/CreateCustomerModal";
import UpdateOrderTaxModal from "../../Offers/components/UpdateOfferTaxModal";
import SalesSettingModal from "../../Offers/components/SalesSettingModal";
import EditAddressModal from "../../Offers/components/EditAddressModal";

import { RootState } from "../../../../../setup";
import { UserType } from "../../../userManagement/types/getUsersListResponseType";
import { getSalesSettings } from "../../../salesMasterData/SalesSettings/redux/SalesSettingsApi";
import { getLayoutDetails } from "../../../salesMasterData/SalesLayout/redux/LayoutApi";
import { LayoutListType } from "../../../salesMasterData/SalesLayout/types/LayoutResponsetypes";
import { CountryType } from "../../Offers/types/GetCountriesListResponseTypes";
import { OrderContactType, OrderPartnerType } from "../types/OrdersTypes";
import {
  createInvoice,
  invoiceUpdate,
  cancelInvoice,
  finalizeInovice,
  getInvoiceDetails,
  deleteInvoice,
} from "../redux";
import {
  getProductsAndItems,
  getSalesContacts,
  updateSaleCurrency,
  updateSaleDetails,
} from "../../Offers/redux/SalesApi";
import { actions } from "../redux/InvoicesRedux";
import { actions as OfferActions } from "../../Offers/redux/SalesOffersredux";
import { InvoiceType } from "../types/InvoicesTypes";

const EditOrder: FC = () => {
  const dispatch = useDispatch();
  const { state = {} } = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const local = useLang();

  // @ts-ignore
  const sharedOrder: InvoiceType = state.order || {};
  // @ts-ignore
  const layoutID = state?.order?.headerID;

  const countries = useSelector<RootState>(
    ({ masterdata }) => masterdata.countriesList
  ) as CountryType[];
  const [selectedToCountry, setSelectedToCountry] =
    useState<CountryType | null>();
  const [order, setOrder] = useState<InvoiceType>(sharedOrder);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState<
    Date | undefined
  >(order?.invoice_date ? moment(order?.invoice_date).toDate() : undefined);

  const ORDER_CURRENCY_TYPES = JSON.parse(
    intl.formatMessage({ id: "ORDER_CURRENCY_TYPES" })
  ) as any[];

  const INVOICE_PAYMENT_TARGET_TYPES = JSON.parse(
    intl.formatMessage({ id: "INVOICE_PAYMENT_TARGET_TYPES" })
  ) as any[];

  const getInitialCurrency = () => {
    return ORDER_CURRENCY_TYPES.filter(
      (item) => item.value === (order?.sales_details?.currency || "eur")
    )?.[0];
  };
  const getInitialTargetType = () => {
    return INVOICE_PAYMENT_TARGET_TYPES.filter(
      (item) => item.value === (order?.payment_target_interval || "day")
    )?.[0];
  };
  const [selectedCurrency, setSelectedCurrency] =
    useState<any>(getInitialCurrency());
  const [selectedTargetType, setSelectedTargetType] = useState<any>(
    getInitialTargetType()
  );
  const [paymentIntervalCount, setPaymentIntervalCount] = useState<number>(
    order?.payment_target_count || 1
  );
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [showOrderTaxEditModal, setShowOrderTaxEditModal] = useState(false);
  const [showCreateContactModal, setShowCreateContactModal] = useState<any>({
    show: false,
    value: "",
  });
  const [layoutFooterDetails, setLayoutFooterDetails] =
    useState<LayoutListType>();
  const [selectedStatus, setSelectedStatus] = useState<{
    id: string;
    name: string;
    value: number;
    function: () => void;
  } | null>(null);

  const [showpaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [selectstatusModal, setSelectstatusModal] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<any>([]);

  const [salesSettingModal, setSalesSettingModal] = useState<boolean>(
    layoutID || sharedOrder?.invoice_layouts_id ? false : true
  );

  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  useEffect(() => {
    setPaymentIntervalCount(order?.payment_target_count || 1);
  }, [order]);

  const editorOptions = [
    {
      id: "0",
      name: intl.formatMessage({
        id: "FINALIZE",
      }),
      value: 1,
      function: () => {
        // Call API and handle promises
        if (isNotValidToConfirm(order)) {
          errorToast(
            intl.formatMessage({ id: "ORDER_GENERATE_ORDER_INFO_MESSAGE" })
          );
        } else {
          return finalizeInovice(order?.id)
            .then(() => {
              successToast(
                intl.formatMessage({ id: "INVOICE_UPDATE_SUCCESS_MESSAGE" })
              );
            })
            .catch((error) => {
              errorToast(
                intl.formatMessage({ id: "INVOICE_UPDATE_FAILURE_MESSAGE" })
              );
            })
            .finally(() => {
              getInvoiceDetailsAPI();
            });
        }
      },
    },
    // {
    //   id: "1",
    //   name: intl.formatMessage({
    //     id: "SEND",
    //   }),
    //   value: 2,
    //   function: () => {},
    // },
    {
      id: "2",
      name: intl.formatMessage({
        id: "PAYMENTS",
      }),
      value: 3,
      function: () => {},
    },
    {
      id: "3",
      name: intl.formatMessage({
        id: "CANCEL",
      }),
      value: 4,
      function: () => {
        cancelInvoice(order?.id)
          .then(() => {
            successToast(
              intl.formatMessage({ id: "INVOICE_UPDATE_SUCCESS_MESSAGE" })
            );
          })
          .catch((error) => {
            errorToast(
              intl.formatMessage({ id: "INVOICE_UPDATE_FAILURE_MESSAGE" })
            );
          })
          .finally(() => {
            getInvoiceDetailsAPI();
          });
      },
    },
    {
      id: "4",
      name: intl.formatMessage({
        id: "DELETE",
      }),
      value: 5,
      function: () => {},
    },
    // {
    //   id: "5",
    //   name: intl.formatMessage({
    //     id: "ORIGINAL_TITLE",
    //   }),
    //   value: 6,
    //   function: () => {},
    // },
    // {
    //   id: "6",
    //   name: intl.formatMessage({
    //     id: "CANCELLED_TITLE",
    //   }),
    //   value: 7,
    //   function: () => {},
    // },
    {
      id: "7",
      name: intl.formatMessage({
        id: "GO_TO_OFFERS",
      }),
      value: 8,
      function: () => {},
    },
    {
      id: "8",
      name: intl.formatMessage({
        id: "GO_TO_ORDER",
      }),
      value: 9,
      function: () => {},
    },
  ];

  const handleDeleteInvoice = () => {
    setDeleteModalLoading(true);
    deleteInvoice(order?.id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "INVOICE_SINGLE_INVOICE_DELETE_SUCCESS_MESSAGE",
          })
        );
        history.push("/sales/invoices/overview");
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "INVOICE_SINGLE_INVOICE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getInvoiceDetails(order?.id);
      });
  };

  const resetAdditionalInfo = async () => {
    await formik.setFieldValue(
      "from_tax_id",
      order?.sales_details?.from_tax_id || null
    );

    await formik.setFieldValue(
      "from_tax_number",
      order?.sales_details?.from_tax_number || null
    );
    await formik.setFieldValue(
      "from_creditor_number",
      order?.sales_details?.from_creditor_number || null
    );
  };

  const initialValues = {
    from_name: order?.sales_details?.from_name,
    from_email: order?.sales_details?.from_email || "",
    from_address: order?.sales_details?.from_address,
    from_company_name: order?.sales_details?.from_company_name,
    from_city: order?.sales_details?.from_city,
    from_zipcode: order?.sales_details?.from_zipcode,
    from_countries_id: Number(order?.sales_details?.from_country_id),
    from_tax_id: order?.sales_details?.from_tax_id,
    from_tax_number: order?.sales_details?.from_tax_number,
    from_creditor_number: order?.sales_details?.from_creditor_number,
    from_contact_person: order?.sales_details?.from_contact_person,
    from_contact_person_email: order?.sales_details?.from_contact_person_email,
    from_contact_person_phone: order?.sales_details?.from_contact_person_phone,
    to_type: order?.sales_details?.to_type || "business",
    to_firstname: order?.sales_details?.to_firstname,
    to_lastname: order?.sales_details?.to_lastname,
    to_email: order?.sales_details?.to_email,
    to_address: order?.sales_details?.to_address,
    to_city: order?.sales_details?.to_city,
    to_zipcode: order?.sales_details?.to_zipcode,
    to_countries_id: order?.sales_details?.to_country_id,
    to_company_name: order?.sales_details?.to_company_name || "",
    module: "invoices",
  };

  const orderDetailsSchema = Yup.object().shape({
    to_email: Yup.string()
      .email(
        intl.formatMessage({
          id: "AUTH_FORGOT_PASSWORD_EMAIL_VALIDATION_MESSAGE",
        })
      )
      .nullable()
      .required(
        intl.formatMessage({ id: "PARTNERS_ACTIVITIES_EMAIL_IS_REQUIRED" })
      ),
    module: Yup.string().required(),
    to_type: Yup.string(),
    to_company_name: Yup.string()
      .when("to_type", {
        is: "business",
        then: Yup.string().required(
          intl.formatMessage({ id: "ORDER_TO_COMPANY_NAME_REQUIRED_MESSAGE" })
        ),
        otherwise: Yup.string().nullable(),
      })
      .nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: orderDetailsSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const {
        to_type,
        to_company_name,
        to_firstname,
        to_lastname,
        to_address,
        to_email,
        to_city,
        to_zipcode,
        to_countries_id,
        module,
      } = values;
      if (sharedOrder?.id === null) {
        createInvoice({
          to_type,
          to_company_name,
          to_firstname,
          to_lastname,
          to_address,
          to_email,
          to_city,
          to_zipcode,
          to_countries_id,
          module,
        })
          .then(({ data: { invoice } }) => {
            history.replace({ state: { order: invoice[0] } });
            return invoice[0]?.sales_id;
          })
          .then((sales_id) => {
            updateSaleDetails(sales_id, values)
              .then(() => {
                successToast(
                  intl.formatMessage({
                    id: "INVOICE_GENERAL_ADDED_SUCCESS_MESSAGE",
                  })
                );
              })
              .catch(() => {
                errorToast(
                  intl.formatMessage({
                    id: "INVOICE_GENERAL_ADDED_FAILURE_MESSAGE",
                  })
                );
              })
              .finally(() => {
                getInvoiceDetailsAPI();
              });
          })
          .catch((error) => console.error(error?.response?.data?.message));
      } else {
        updateSaleDetails(
          order?.sales_id ? order?.sales_id : sharedOrder?.sales_id,
          values
        )
          .then(() => {
            successToast(
              intl.formatMessage({
                id: "INVOICE_GENERAL_UPDATE_SUCCESS_MESSAGE",
              })
            );
          })
          .catch(() => {
            errorToast(
              intl.formatMessage({
                id: "INVOICE_GENERAL_UPDATE_FAILURE_MESSAGE",
              })
            );
          })
          .finally(() => {
            //@ts-ignore
            getInvoiceDetailsAPI(order?.id ? order?.id : sharedOrder);
            setShowOrderTaxEditModal(false);
            setShowEditAddressModal(false);
            setShowCreateContactModal({
              show: false,
              value: "",
            });
          });
      }
    },
  });

  const getInvoiceDetailsAPI = (_id?: number) => {
    let id = sharedOrder.id || _id;
    if (id) {
      getInvoiceDetails(id)
        .then(({ data: { invoiceDetails } }) => {
          setOrder(invoiceDetails);
          history.replace({ state: { order: invoiceDetails } });
          dispatch(actions.setInvoiceDetails(invoiceDetails));
        })
        .catch((error) => console.error(error?.response?.data?.message));
    }
  };

  const getDate = (type: string, count: number | undefined) => {
    if (type === "day") {
      return moment(order?.invoice_date).add(count, "days");
    }
    if (type === "week") {
      return moment(order?.invoice_date).add(count, "weeks");
    }
    if (type === "month") {
      return moment(order?.invoice_date).add(count, "months");
    }
  };

  const handleUpdatePaymentTarget = (
    type: "payment_target_interval" | "payment_target_count",
    item?: any,
    count?: number
  ) => {
    const date = getDate(item?.value, count);

    if (sharedOrder?.id === null) {
      createInvoice(formik.values)
        .then(({ data }) => {
          history.replace({ state: { order: data?.invoice[0] } });
          const invoice_id = data?.invoice[0]?.id;
          return invoice_id;
        })
        .then((invoice_id) => {
          let datee = date ? moment(date).format("Y-MM-DD") : undefined;
          if (
            item?.value !== order?.payment_target_interval ||
            count !== order?.payment_target_count
          ) {
            invoiceUpdate(
              invoice_id,
              type,
              type === "payment_target_interval"
                ? item?.value
                : type === "payment_target_count"
                  ? count
                  : null,
              datee
            )
              .then(() => {
                successToast(
                  intl.formatMessage({
                    id: "INVOICE_DATE_UPDATE_SUCCESS_MESSAGE",
                  })
                );
              })
              .catch(() => {
                errorToast(
                  intl.formatMessage({
                    id: "INVOICE_DATE_UPDATE_FAILURE_MESSAGE",
                  })
                );
              })
              .finally(() => {
                getInvoiceDetailsAPI();
              });
          }
        })
        .catch((error) => console.error(error?.response?.data?.message));
    } else {
      let datee = moment(date).format("Y-MM-DD");
      if (
        item?.value !== order?.payment_target_interval ||
        count !== order?.payment_target_count
      ) {
        invoiceUpdate(
          //@ts-ignore
          order?.id ? order?.id : sharedOrder.id,
          type,
          type === "payment_target_interval"
            ? item?.value
            : type === "payment_target_count"
              ? count
              : null,
          datee
        )
          .then(() => {
            successToast(
              intl.formatMessage({
                id: "INVOICE_DATE_UPDATE_SUCCESS_MESSAGE",
              })
            );
          })
          .catch(() => {
            errorToast(
              intl.formatMessage({
                id: "INVOICE_DATE_UPDATE_FAILURE_MESSAGE",
              })
            );
          })
          .finally(() => {
            getInvoiceDetailsAPI();
          });
      }
    }
  };

  const updateOrderDates = (type: string, date: Date | null) => {
    if (sharedOrder?.id === null) {
      createInvoice(formik.values)
        // .then(({ data: { salesOrders } }) => {
        //   history.replace({ state: { order: salesOrders } });
        //   return salesOrders;
        // })
        .then(({ data }) => {
          history.replace({ state: { order: data?.invoice[0] } });
          return data?.invoice[0]?.id;
        })
        .then((invoice_id) => {
          let datee = date ? moment(date).format("Y-MM-DD") : null;
          if (moment(order?.invoice_date).format("Y-MM-DD") !== datee) {
            invoiceUpdate(invoice_id, type, datee)
              .then(() => {
                successToast(
                  intl.formatMessage({
                    id: "INVOICE_DATE_UPDATE_SUCCESS_MESSAGE",
                  })
                );
              })
              .catch(() => {
                errorToast(
                  intl.formatMessage({
                    id: "INVOICE_DATE_UPDATE_FAILURE_MESSAGE",
                  })
                );
              })
              .finally(() => {
                getInvoiceDetailsAPI?.(invoice_id);
              });
          }
        })
        .catch((error) => console.error(error?.response?.data?.message));
    } else {
      let datee = moment(date).format("Y-MM-DD");
      if (moment(order?.invoice_date).format("Y-MM-DD") !== datee) {
        //@ts-ignore
        invoiceUpdate(order?.id ? order?.id : sharedOrder, type, datee)
          .then(() => {
            successToast(
              intl.formatMessage({ id: "INVOICE_DATE_UPDATE_SUCCESS_MESSAGE" })
            );
          })
          .catch(() => {
            errorToast(
              intl.formatMessage({ id: "INVOICE_DATE_UPDATE_FAILURE_MESSAGE" })
            );
          })
          .finally(() => {
            getInvoiceDetailsAPI();
          });
      }
    }
  };

  // useEffect(() => {
  //   if (!showTo && order?.additional_details?.delivery_date_to) {
  //     setSelectedDeliveryDateTo(undefined);
  //     updateOrderDates("delivery_date_to", null);
  //   }
  // }, [showTo]);

  const onSelectCurrency = (item: any) => {
    if (order?.sales_details?.currency !== item?.value) {
      updateSaleCurrency(order?.sales_details?.id, item?.value)
        .then(() => {
          successToast(
            intl.formatMessage({ id: "ORDER_CURRENCY_UPDATE_SUCCESS_MESSAGE" })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({ id: "ORDER_CURRENCY_UPDATE_FAILURE_MESSAGE" })
          );
        })
        .finally(() => {
          getInvoiceDetailsAPI();
        });
    }
  };

  const getFilteredOptions = (order: InvoiceType) => {
    const availableOptions = editorOptions.filter((option) => {
      switch (order?.status) {
        case "draft":
          return (
            option.name === intl.formatMessage({ id: "FINALIZE" }) ||
            option.name === intl.formatMessage({ id: "DELETE" })
          );

        case "open":
          return (
            option.name === intl.formatMessage({ id: "PAYMENTS" }) ||
            option.name === intl.formatMessage({ id: "CANCEL" })
          );

        case "paid":
          return option.name === intl.formatMessage({ id: "CANCEL" });

        case "partiallyPaid":
        case "overDue":
          return (
            option.name === intl.formatMessage({ id: "PAYMENTS" }) ||
            option.name === intl.formatMessage({ id: "CANCEL" })
          );

        case "cancel":
          return (
            option.name === intl.formatMessage({ id: "ORIGINAL_TITLE" }) ||
            option.name === intl.formatMessage({ id: "CANCELLED_TITLE" })
          );

        default:
          return false;
      }
    });

    // if (order?.offer_number) {
    //   availableOptions.push(
    //     // @ts-ignore
    //     editorOptions.find(
    //       (option) => option.name === intl.formatMessage({ id: "GO_TO_OFFERS" })
    //     )
    //   );
    // }

    return availableOptions;
  };

  useEffect(() => {
    const tempfilteredOptios = getFilteredOptions(order);
    setFilteredOptions([...tempfilteredOptios]);
    setSelectedStatus(null);
    // setSelectedDeliveryDateFrom(
    //   order?.additional_details?.delivery_date_from
    //     ? moment(order?.additional_details?.delivery_date_from).toDate()
    //     : undefined
    // );
    // setSelectedDeliveryDateTo(
    //   order?.additional_details?.delivery_date_to
    //     ? // @ts-ignore
    //       moment(order?.additional_details?.delivery_date_to).toDate()
    //     : undefined
    // );
  }, [order, sharedOrder?.id]);

  useEffect(() => {
    getInvoiceDetailsAPI();
  }, [sharedOrder?.id]);

  useEffect(() => {
    setSelectedToCountry(
      countries?.filter(
        //@ts-ignore
        (item) => item.id === Number(order?.to_country_id)
      )?.[0]
    );
    // setShowTo(order?.additional_details?.delivery_date_to ? true : false);
  }, [order]);

  const resetAddress = async () => {
    await formik.setFieldValue("to_address", null);

    await formik.setFieldValue("to_city", null);
    await formik.setFieldValue("to_zipcode", null);
    await formik.setFieldValue("to_countries_id", null);
    setSelectedToCountry(null);
  };

  const handleClickItem = async (
    type: string,
    contact?: OrderContactType,
    partner?: OrderPartnerType
  ) => {
    if (type === "partner") {
      resetAddress();
      await formik.setFieldValue("to_company_name", partner?.name);
      await formik.setFieldValue("to_email", contact?.email || "");
      // await formik.setFieldValue(
      //   "to_name",
      //   contact?.firstname + " " + contact?.lastname
      // );
      await formik.setFieldValue("to_firstname", contact?.firstname);
      await formik.setFieldValue("to_lastname", contact?.lastname);
      await formik.setFieldValue("to_type", "business");

      await formik.setFieldValue("to_address", partner?.address);

      await formik.setFieldValue("to_city", partner?.city);
      await formik.setFieldValue("to_zipcode", partner?.zip_code);

      if (partner?.countries_id) {
        await formik.setFieldValue("to_countries_id", partner?.countries_id);
        setSelectedToCountry(
          countries?.filter((item) => item.id == partner?.countries_id)?.[0]
        );
      }

      formik.handleSubmit();
    } else if (type === "contact") {
      resetAddress();
      await formik.setFieldValue("to_company_name", null);
      await formik.setFieldValue("to_email", contact?.email || "");
      // await formik.setFieldValue(
      //   "to_name",
      //   contact?.firstname + " " + contact?.lastname
      // );
      await formik.setFieldValue("to_firstname", contact?.firstname);
      await formik.setFieldValue("to_lastname", contact?.lastname);
      await formik.setFieldValue("to_type", "customer");

      await formik.setFieldValue(
        "to_address",
        `${contact?.address_1 ? contact?.address_1 : ""}${
          contact?.address_2
            ? (contact?.address_1 ? ", " : "") + contact?.address_2
            : ""
        }`
      );

      await formik.setFieldValue("to_city", contact?.city);
      await formik.setFieldValue("to_zipcode", contact?.zip_code);

      if (contact?.countries_id) {
        await formik.setFieldValue("to_countries_id", contact?.countries_id);
        setSelectedToCountry(
          countries?.filter((item) => item.id == contact?.countries_id)?.[0]
        );
      }

      formik.handleSubmit();
    } else if (type && partner) {
      await formik.setFieldValue("to_company_name", partner?.name);
      await formik.setFieldValue("to_type", "business");
      await formik.setFieldValue("to_address", partner?.address);

      await formik.setFieldValue("to_city", partner?.city);
      await formik.setFieldValue("to_zipcode", partner?.zip_code);

      if (partner?.countries_id) {
        await formik.setFieldValue("to_countries_id", partner?.countries_id);
        setSelectedToCountry(
          countries?.filter((item) => item.id == partner?.countries_id)?.[0]
        );
      }

      setShowCreateContactModal({
        show: true,
        value: type,
      });
    } else {
      await formik.setFieldValue("to_company_name", null);
      await formik.setFieldValue("to_type", "customer");
      setShowCreateContactModal({
        show: true,
        value: type,
      });
    }
  };

  const updateProcessor = (user: UserType) => {
    const payload = {
      ...formik.values,
      from_contact_person: user?.firstname + " " + user?.lastname,
      from_contact_person_email: user?.email,
      from_contact_person_phone: user?.telephone,
    };
    if (sharedOrder?.id === null) {
      createInvoice(formik.values)
        .then(({ data: { invoice } }) => {
          history.replace({ state: { order: invoice[0] } });
          return invoice[0].sales_id;
        })
        .then((sales_id) => {
          updateSaleDetails(sales_id, payload)
            .then(() => {
              successToast(
                intl.formatMessage({
                  id: "SALES_PROCESSOR_ADDED_SUCCESS_MESSAGE",
                })
              );
            })
            .catch(() => {
              errorToast(
                intl.formatMessage({
                  id: "SALES_PROCESSOR_ADDED_FAILURE_MESSAGE",
                })
              );
            })
            .finally(() => {
              getInvoiceDetailsAPI();
            });
        })
        .catch((error) => console.error(error?.response?.data?.message));
    } else {
      updateSaleDetails(
        order?.sales_id ? order?.sales_id : sharedOrder?.sales_id,
        payload
      )
        .then(() => {
          successToast(
            intl.formatMessage({ id: "SALES_PROCESSOR_UPDATE_SUCCESS_MESSAGE" })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({ id: "SALES_PROCESSOR_UPDATE_FAILURE_MESSAGE" })
          );
        })
        .finally(() => {
          getInvoiceDetailsAPI();
        });
    }
  };

  const handleProcessorClick = (user: UserType) => {
    formik.setFieldValue(
      "from_contact_person",
      user?.firstname + " " + user?.lastname
    );
    formik.setFieldValue("from_contact_person_email", user?.email);
    formik.setFieldValue("from_contact_person_phone", user?.telephone);
    updateProcessor(user);
  };

  const handleGetOrderSettings = () => {
    getSalesSettings()
      .then(
        ({
          data: {
            settingsDetails: { salesSettingsDetails },
          },
        }) => {
          let headerFooterId = salesSettingsDetails?.filter((item) => {
            return item.id === 3;
          })?.[0]?.answer;

          if (Number(headerFooterId)) {
            getLayoutDetails(Number(headerFooterId))
              .then(({ data: { data } }) => {
                setLayoutFooterDetails(data);
              })
              .catch((error) => console.error(error?.response?.data?.message));
          }
        }
      )
      .catch((error) => console.error(error?.response?.data?.message));
  };

  useEffect(() => {
    handleGetOrderSettings();
  }, []);

  useEffect(() => {
    getProductsAndItems()
      .then(({ data: { productDetails } }) => {
        dispatch(OfferActions.setProductsAndItems(productDetails));
        dispatch(actions.setProductsAndItems(productDetails));
      })
      .catch((error) => console.error(error?.response?.data?.message));
    getSalesContacts()
      .then(({ data }) => {
        dispatch(OfferActions.setOfferPartnersAndContacts(data));
        dispatch(actions.setInvoicePartnersAndContacts(data));
      })
      .catch((error) => console.error(error?.response?.data?.message));
  }, []);

  function isNotValidToConfirm(order: InvoiceType) {
    return (
      !order?.sales_details?.to_firstname ||
      !order?.sales_details?.to_lastname ||
      !order?.sales_details?.to_email ||
      order?.sales_details?.sale_items?.length === 0 ||
      !order?.sales_details?.sale_items?.every(
        (item: any) =>
          Number(item.unit_price) &&
          Number(item.unit_price) >= 0 &&
          Number(item.tax_percentage) &&
          Number(item.tax_percentage) >= 0
      )
    );
  }

  const getClassName = (status: string) => {
    if (status === "draft") {
      return "draft";
    } else if (status === "open") {
      return "open";
    } else if (status === "paid") {
      return "paid";
    } else if (status === "partiallyPaid") {
      return "partiallyPaid";
    } else if (status === "overDue") {
      return "overDue";
    } else {
      return "cancel";
    }
  };

  const resetAddressInfo = async () => {
    await formik.setFieldValue(
      "to_zipcode",
      order?.sales_details?.to_zipcode || null
    );
    await formik.setFieldValue(
      "to_city",
      order?.sales_details?.to_city || null
    );
    await formik.setFieldValue(
      "to_email",
      order?.sales_details?.to_email || null
    );
    await formik.setFieldValue(
      "to_address",
      order?.sales_details?.to_address || null
    );
    await formik.setFieldValue(
      "to_countries_id",
      order?.sales_details?.to_country_id || null
    );

    setSelectedToCountry(
      countries?.filter(
        (item) => item?.id === Number(order?.sales_details?.to_country_id)
      )?.[0]
    );
  };

  return (
    <>
      {showEditAddressModal && (
        <EditAddressModal
          show={showEditAddressModal}
          closeModal={() => {
            setShowEditAddressModal(false);
            resetAddressInfo();
            setShowEditAddressModal(false);
          }}
          formik={formik}
          selectedToCountry={selectedToCountry}
          setSelectedToCountry={setSelectedToCountry}
        />
      )}
      {showOrderTaxEditModal && (
        <UpdateOrderTaxModal
          show={showOrderTaxEditModal}
          closeModal={() => {
            resetAdditionalInfo();
            setShowOrderTaxEditModal(false);
          }}
          formik={formik}
        />
      )}
      {showCreateContactModal.show && (
        <CreateCustomerModal
          show={showCreateContactModal.show}
          closeModal={() => {
            setShowCreateContactModal({
              show: false,
              value: "",
            });
          }}
          initialValue={showCreateContactModal.value}
          formik={formik}
        />
      )}
      {selectstatusModal && !showpaymentModal && (
        <SelectPaymentModal
          show={selectstatusModal}
          closeModal={() => {
            setSelectstatusModal(false);
          }}
          onSelectPayment={(option: string) => {
            setPaymentType(option);
          }}
        />
      )}
      {showpaymentModal && (
        <InvoicePaymentModal
          show={showpaymentModal}
          closeModal={() => {
            setShowPaymentModal(false);
          }}
          invoice={order}
          type={paymentType}
          getOrderDetailsAPI={getInvoiceDetailsAPI}
        />
      )}
      {salesSettingModal && (
        <SalesSettingModal
          show={salesSettingModal}
          closeModal={() => {
            setSalesSettingModal(false);
          }}
          layoutInvoiceID={layoutID}
          fromInvoiceEdit
          fromOrderEdit
          fromSalesOfferEdit
          comingFromInvoice
        />
      )}

      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-xxl"
        >
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
              <div className="card pt-4 mt-7">
                <div className="card-body p-6 px-10">
                  {/* Status Badge | Preview Button | Workflow Dropdown | Warning badge */}
                  <div className="pb-4 d-flex align-items-center justify-content-between gap-4">
                    {/* Status Badge */}
                    <div>
                      <span
                        className={`${getClassName(
                          order?.status
                        )} badge text-uppercase p-4`}
                      >
                        {order?.status}
                      </span>
                    </div>
                    {/* Preview Button | Workflow Dropdown | Warning Badge */}
                    <div className="d-flex align-items-center gap-4">
                      {/* Preview Button */}
                      <OrderPreview
                        order={order}
                        layoutFooterDetails={layoutFooterDetails}
                      />

                      {/* Workflow Dropdown */}
                      <DropDown
                        // key={selectedTargetType?.name}
                        id="workflowDropown"
                        // @ts-ignore
                        items={filteredOptions}
                        displayFunc={(item) => {
                          return item.name;
                        }}
                        onSelectItem={async (item: any) => {
                          setSelectedStatus(item);
                          if (item.id === "2") {
                            setShowPaymentModal(true);
                          }
                          if (item.id === "4") {
                            showDeleteConfirmModal(
                              intl.formatMessage({
                                id: "INVOICE_DELETE_CONFIRMATION_MESSAGE",
                              }),
                              () => {
                                handleDeleteInvoice();
                              }
                            );
                          }
                          if (item.id === "7") {
                            history.push(`/sales/offers/edit`, {
                              offer: {
                                // @ts-ignore
                                id: order?.offer_id,
                                sales_orders_details: {
                                  // @ts-ignore
                                  offer_layout_id: order?.offer_layout_id,
                                },
                              },
                            });
                          }
                          if (item.function) {
                            try {
                              const response = await item.function();
                            } catch (error) {}
                          }
                        }}
                        displayValue={
                          selectedStatus?.name ||
                          intl.formatMessage({
                            id: "OPTION_METHOD_SELECTION",
                          })
                        }
                        hideSearch
                        disabled={isNotValidToConfirm(order)}
                      />

                      {/* Warning Badge */}
                      <div className="rounded-circle bg-secondary d-flex justify-content-center align-items-center p-2">
                        {isNotValidToConfirm(order) ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="message">
                                {intl.formatMessage({
                                  id: "ORDER_GENERATE_ORDER_INFO_MESSAGE",
                                })}
                              </Tooltip>
                            }
                          >
                            <i className="cursor-pointer bi bi-info-circle-fill fs-7 text-danger"></i>
                          </OverlayTrigger>
                        ) : (
                          <i className="bi bi-check-circle-fill fs-7 text-success"></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* Invoice Details */}
                    <div className="row gx-8 mb-5">
                      <div className="col-lg-5">
                        {/* To Contact */}
                        <div className="mb-5">
                          <label className="form-label required">
                            {intl.formatMessage({
                              id: "SUBSCRIBERS_INVITED_CONTACTS_CONTACT_COLUMN",
                            })}
                          </label>
                          <DynamicDropdown
                            id="customer_dropdown"
                            handleItemClick={handleClickItem}
                            selectedItem={
                              formik.values.to_firstname &&
                              formik.values.to_lastname
                                ? `${formik.values.to_firstname} ${formik.values.to_lastname}`
                                : null
                            }
                            disabled={
                              order?.sales_details?.is_editable === 0 ||
                              (order?.status && order?.status !== "draft")
                            }
                          />
                        </div>

                        {/* To Address */}
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <label className="form-label mb-2">
                              {intl.formatMessage({
                                id: "ORDER_ADDRESS_INFO_LABEL",
                              })}
                            </label>
                            {order?.status === "draft" && (
                              <span
                                className={`text-primary text-lowercase mb-2 ${
                                  order?.status && order?.status === "draft"
                                    ? "cursor-pointer"
                                    : "pe-none"
                                }`}
                                onClick={() => {
                                  order?.status &&
                                    order?.status === "draft" &&
                                    setShowEditAddressModal(true);
                                }}
                              >
                                {intl.formatMessage({
                                  id: "CUSTOMER_MANAGEMENT_USER_ACTION_EDIT",
                                })}
                              </span>
                            )}
                          </div>
                          <div className="form-control form-control-solid min-h-100px">
                            {formik.values.to_company_name && (
                              <div className="text-gray-700 d-flex gap-2">
                                <span className="fw-bold text-break">
                                  {order?.sales_details?.to_company_name}
                                </span>
                              </div>
                            )}
                            {formik.values.to_firstname &&
                              formik.values.to_lastname && (
                                <div className="text-gray-700 d-flex gap-2">
                                  <span className="fw-bold text-break">
                                    {order?.sales_details?.to_firstname +
                                      " " +
                                      order?.sales_details?.to_lastname}
                                  </span>
                                </div>
                              )}

                            <div className="text-gray-700 d-flex gap-2">
                              <span className="fw-bold d-flex flex-column">
                                {formik.values.to_address && (
                                  <div>
                                    <div className="text-break">
                                      {
                                        formik.values.to_address?.split(
                                          ","
                                        )?.[0]
                                      }
                                    </div>
                                    <div className="text-break">
                                      {
                                        formik.values.to_address?.split(
                                          ","
                                        )?.[1]
                                      }
                                    </div>
                                  </div>
                                )}

                                {(formik.values.to_zipcode ||
                                  formik.values.to_city) && (
                                  <span className="text-break">
                                    {formik.values.to_zipcode
                                      ? formik.values.to_zipcode + " "
                                      : ""}
                                    {formik.values.to_city
                                      ? formik.values.to_city
                                      : ""}
                                  </span>
                                )}
                                {formik.values.to_countries_id && (
                                  <span className="text-break">
                                    {
                                      countries?.filter(
                                        (item) =>
                                          item.id ==
                                          formik.values.to_countries_id
                                      )?.[0]?.name
                                    }
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 gap-5 flex-column d-flex">
                        <div className="d-flex flex-column gap-5">
                          {/* Invoice Date | Payment Target */}
                          <div className="row d-flex align-items-center">
                            {/* Invoice Date */}
                            <div className="col-12 col-lg-6">
                              <label className="form-label">
                                {intl.formatMessage({
                                  id: "INVOICES_INVOICE_DATE_SELECT_LABEL",
                                })}
                              </label>
                              <DateTimePicker
                                onDateChange={(date) => {
                                  setSelectedInvoiceDate(date);
                                }}
                                onCalendarClose={(date) => {
                                  date &&
                                    updateOrderDates("invoice_date", date);
                                }}
                                selectedDate={selectedInvoiceDate}
                                hideTimeSelect
                                disabledRes={
                                  order?.status && order?.status !== "draft"
                                }
                                placeholder={intl.formatMessage({
                                  id: "SELECT_DATE_PLACEHOLDER",
                                })}
                                noMaxDate
                              />
                            </div>

                            {/* Payment Target */}
                            <div className="col-12 col-lg-6">
                              <label className="form-label">
                                {intl.formatMessage({
                                  id: "INVOICES_PAYMENT_TARGET_LABEL",
                                })}
                              </label>
                              <div className="row mx-0">
                                <div
                                  className="col-12 col-md-6 rounded px-0"
                                  style={{ background: "#f5f8fa" }}
                                >
                                  <div
                                    className="d-flex justify-content-between"
                                    style={{ height: "43px" }}
                                  >
                                    <DropDown
                                      dropdownWrapperClassName="min-w-90px w-auto"
                                      key={selectedTargetType?.name}
                                      id="orderCurrencyDropown"
                                      items={INVOICE_PAYMENT_TARGET_TYPES}
                                      displayFunc={(item) => {
                                        return item.name;
                                      }}
                                      selectedItem={selectedTargetType}
                                      onSelectItem={(item) => {
                                        setSelectedTargetType(item);
                                        handleUpdatePaymentTarget(
                                          "payment_target_interval",
                                          item,
                                          undefined
                                        );
                                      }}
                                      displayValue={selectedTargetType?.name}
                                      hideSearch
                                      disabled={
                                        order?.status &&
                                        order?.status !== "draft"
                                      }
                                      size="medium"
                                    />
                                    <div>
                                      <input
                                        className="form-control form-control-solid"
                                        style={{
                                          maxWidth: "45px",
                                          minWidth: "45px",
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0,
                                        }}
                                        type="number"
                                        value={paymentIntervalCount}
                                        onChange={(e) => {
                                          const value = +e.target.value;
                                          if (value >= 0) {
                                            setPaymentIntervalCount(value);
                                          } else {
                                            setPaymentIntervalCount(1);
                                          }
                                        }}
                                        onBlur={(e) => {
                                          const value = +e.target.value;
                                          if (value >= 0) {
                                            handleUpdatePaymentTarget(
                                              "payment_target_count",
                                              selectedTargetType,
                                              value
                                            );
                                          } else {
                                            handleUpdatePaymentTarget(
                                              "payment_target_count",
                                              selectedTargetType,
                                              0
                                            );
                                          }
                                        }}
                                        disabled={
                                          order?.status &&
                                          order?.status !== "draft"
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                {order?.payment_target && (
                                  <div className="col-12 col-md-6">
                                    <span
                                      className="form-control form-control-solid fw-bold text-gray-700"
                                      style={{ minWidth: "130px" }}
                                    >
                                      {local === "de"
                                        ? moment(order?.payment_target).format(
                                            "DD.MM.yyyy"
                                          )
                                        : moment(order?.payment_target).format(
                                            "YYYY-MM-DD"
                                          )}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Invoice Number | Currency */}
                        <div className="row d-flex align-items-center">
                          {/* Invoice Number */}
                          <div className="col-6">
                            <label className="form-label">
                              {intl.formatMessage({
                                id: "INVOICES_NUMBER",
                              })}
                            </label>
                            <input
                              type="text"
                              disabled={true}
                              className="form-control text-wrap form-control-solid py-3 pe-none form-control-md"
                              style={{ minHeight: "42px" }}
                              value={order?.final_invoice_number || ""}
                            />
                          </div>

                          {/* Currency */}
                          <div className="col-6">
                            <label className="form-label">
                              {intl.formatMessage({
                                id: "ORDER_CURRENCY_SELECT_LABEL",
                              })}
                            </label>
                            <DropDown
                              id="orderCurrencyDropown"
                              items={ORDER_CURRENCY_TYPES}
                              displayFunc={(item) => {
                                return item.name;
                              }}
                              selectedItem={selectedCurrency}
                              onSelectItem={(item) => {
                                onSelectCurrency(item);
                                setSelectedCurrency(item);
                              }}
                              displayValue={selectedCurrency?.name}
                              hideSearch
                              disabled={
                                order?.status && order?.status !== "draft"
                              }
                              size="medium"
                            />
                          </div>
                        </div>

                        {/* Processor */}
                        <div className="mb-5">
                          <label className="form-label">
                            {intl.formatMessage({
                              id: "ORDER_EDIT_PROCESSOR_DROPDOWN_LABEL",
                            })}
                          </label>
                          <DynamicDropdown
                            id="users_dropdown"
                            isUsers
                            handleProcessorClick={handleProcessorClick}
                            selectedItem={
                              order?.sales_details?.from_contact_person
                            }
                            disabled={
                              order?.status &&
                              order?.status !== "draft" &&
                              order?.status !== "open"
                            }
                          />
                        </div>

                        {/* Additional Details */}
                        <div className="card-title m-0 d-flex justify-content-end align-items-end h-100">
                          {order?.status === "draft" && (
                            <span
                              className={`fw-bold text-primary m-0 fs-6 ${
                                order?.status === "draft"
                                  ? "cursor-pointer"
                                  : "pe-none"
                              } `}
                              onClick={() => {
                                order?.sales_details?.is_editable !== 0 &&
                                  setShowOrderTaxEditModal(true);
                              }}
                            >
                              +{" "}
                              {intl.formatMessage({
                                id: "EVENT_REGISTRATION_ADDITIONAL_INFORMATION_TITLE",
                              })}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Invoice Items Components */}
                    <AddOrderItems
                      order={order}
                      getOrderDetailsAPI={getInvoiceDetailsAPI}
                      orderId={order?.id ? order?.id : sharedOrder?.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOrder;
