import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";

import { salesNumbersDetails } from "../types/SalesSettingstype";
import { updateNumberSettingsDetails } from "../redux/SalesSettingsApi";
import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import SimpleLoader from "../../../../sharedComponents/Loader/SimpleLoader";
import TextInput from "../../../../sharedComponents/TextInput/TextInput";
import DropDown from "../../../../sharedComponents/dropdown/Dropdown";

interface Props {
  numberCancelledInvoices?: salesNumbersDetails;
  getSalesSettingsApi: () => void;
}

const computeNextNumber = (values: any) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, "0");
  if (!values?.prefix) return;

  let nextNumber = values.prefix;
  if (values.enable_year) {
    nextNumber += currentYear;
  }

  if (values.enable_month) {
    nextNumber += currentMonth;
  }
  const numberOfDigits = parseInt(values.no_of_digits);
  const startNumber = values.next_number
    ?.toString()
    ?.padStart(numberOfDigits, "0");

  nextNumber += startNumber;

  return nextNumber;
};

const LayoutCancelInvoice: FC<Props> = ({
  numberCancelledInvoices,
  getSalesSettingsApi,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [cancelledInvoice, setCancelledInvoice] =
    useState<salesNumbersDetails>();
  const [showlistModal, setShowListModal] = useState<boolean>(false);
  const Digit_Options = JSON.parse(
    intl.formatMessage({ id: "MASTERDATA_SALES_NO_OF_DIGITS_VALUES" })
  );
  const [nextNumber, setNextNumber] = useState(
    computeNextNumber(cancelledInvoice)
  );

  useEffect(() => {
    // @ts-ignore
    setCancelledInvoice(numberCancelledInvoices?.[0]);
  }, [numberCancelledInvoices]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      prefix: cancelledInvoice?.prefix,
      enable_year: cancelledInvoice?.enable_year,
      enable_month: cancelledInvoice?.enable_month,
      starting_number: cancelledInvoice?.starting_number,
      no_of_digits: cancelledInvoice?.no_of_digits,
      can_edit: cancelledInvoice?.can_edit,
    });
    setNextNumber(computeNextNumber(cancelledInvoice));
  }, [cancelledInvoice]);

  const initialValues = {
    module: "cancelled_invoices",
    prefix: cancelledInvoice?.prefix,
    enable_year: cancelledInvoice?.enable_year,
    enable_month: cancelledInvoice?.enable_month,
    starting_number: cancelledInvoice?.starting_number,
    no_of_digits: cancelledInvoice?.no_of_digits,
    can_edit: cancelledInvoice?.can_edit,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      prefix: Yup.string()
        .required(
          intl.formatMessage({
            id: "AUTH_NEW_PASSWORD_REQUIRED_VALIDATION_MESSAGE",
          })
        )
        .max(
          3,
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_PREFIX_ERROR_VALIDATION",
          })
        ),
      // .matches(/^[A-Za-z]{3}$/, intl.formatMessage({id: 'ERROR_PREFIX_LETTERS_ONLY'})),
      starting_number: Yup.number()
        .typeError(
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_STARTING_NUMBER_ERROR_VALIDATION",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH_NEW_PASSWORD_REQUIRED_VALIDATION_MESSAGE",
          })
        )
        .max(
          99999,
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_STARTING_NUMBER_ERROR_VALIDATION_1",
          })
        ),
      no_of_digits: Yup.number()
        .oneOf(
          [3, 4, 5, 6],
          intl.formatMessage({
            id: "MASTERDATA_SALES_SETTINGS_NUMBER_OF_DIGIT_ERROR_VALIDATION",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH_NEW_PASSWORD_REQUIRED_VALIDATION_MESSAGE",
          })
        ),
      // next_number: Yup.number().max(99999),
    }),
    onSubmit: (values) => {
      setLoading(true);
      updateNumberSettingsDetails(formik.values)
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_SETTINGS_UPDATE_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "MASTERDATA_SALES_SETTINGS_UPDATE_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getSalesSettingsApi();
          setLoading(false);
        });
    },
  });

  const disabledValue = cancelledInvoice?.can_edit === 1;

  return (
    <div className="card mt-10">
      <div className="card-header">
        <div className="card-title">
          <h2>
            {intl.formatMessage({
              id: "MASTERDATA_SALES_SETTINGS_CANCELLED_INVOICE_PAGE_TITLE",
            })}
          </h2>
        </div>
      </div>

      <div className="card-body pt-4">
        {loading && <SimpleLoader fullLoader />}
        <div className="table-responsive px-5 table_actions_overflow">
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                <th className="min-w-150px ms-4">
                  {intl.formatMessage({
                    id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_1",
                  })}
                </th>
                <th className="min-w-125px" style={{ textAlign: "center" }}>
                  {intl.formatMessage({
                    id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_2",
                  })}
                </th>
                <th
                  className="min-w-125px mw-150px"
                  style={{ textAlign: "center" }}
                >
                  {intl.formatMessage({
                    id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_3",
                  })}
                </th>
                <th
                  className="min-w-125px mw-150px"
                  style={{ textAlign: "center" }}
                >
                  {intl.formatMessage({
                    id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_4",
                  })}
                </th>
                <th
                  className="min-w-125px mw-150px"
                  style={{ textAlign: "center" }}
                >
                  {intl.formatMessage({
                    id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_5",
                  })}
                </th>
                <th
                  className="min-w-125px mw-150px"
                  style={{ textAlign: "center" }}
                >
                  {intl.formatMessage({
                    id: "MASTERDATA_SALES_SETTINGS_TABLE_COLUMN_6",
                  })}
                </th>
              </tr>
            </thead>

            <tbody className="">
              <tr className="align-top">
                <td className="mw-100px">
                  <div
                    className="form-input form-input-control"
                    onBlur={() => {
                      if (cancelledInvoice?.prefix !== formik.values.prefix) {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      value={formik.values.prefix || ""}
                      onChange={(e) =>
                        formik.setFieldValue("prefix", e.target.value)
                      }
                      maxLength={3}
                      disabled={!disabledValue}
                    />
                    {formik.touched.prefix && formik.errors.prefix && (
                      <div className="text-danger">
                        <span role="alert" style={{ fontSize: "smaller" }}>
                          {formik.errors.prefix}
                        </span>
                      </div>
                    )}
                  </div>
                </td>

                <td className="mw-100px">
                  <div className="px-2 d-flex justify-content-center mt-3  align-items-center form-check form-switch form-switch-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      // @ts-ignore
                      checked={formik.values.enable_year === 1}
                      {...formik.getFieldProps("enable_year")}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "enable_year",
                          e.target.checked ? 1 : 0
                        );
                        formik.handleSubmit();
                      }}
                      style={{ width: "50px", height: "25px" }}
                      disabled={!disabledValue}
                    />
                  </div>
                </td>

                <td className="mw-100px">
                  <div className="px-2 d-flex justify-content-center mt-3  align-items-center form-check form-switch form-switch-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      // @ts-ignore
                      checked={formik.values.enable_month === 1}
                      // {...formik.getFieldProps('enable_month')}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "enable_month",
                          e.target.checked ? 1 : 0
                        );
                        formik.handleSubmit();
                      }}
                      style={{ width: "50px", height: "25px" }}
                      disabled={!disabledValue}
                    />
                  </div>
                </td>

                <td className="mw-150px">
                  <div
                    className=" px-2 form-input form-input-control"
                    onBlur={() => {
                      if (
                        cancelledInvoice?.starting_number !==
                        formik.values?.starting_number
                      ) {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    <TextInput
                      value={formik.values?.starting_number || ""}
                      onChange={(value) => {
                        formik.setFieldValue("starting_number", value);
                      }}
                      disabled={!disabledValue}
                    />
                    {formik.touched.starting_number &&
                      formik.errors.starting_number && (
                        <div className="text-danger">
                          <span role="alert" style={{ fontSize: "smaller" }}>
                            {formik.errors.starting_number}
                          </span>
                        </div>
                      )}
                  </div>
                </td>

                <td className="mw-150px">
                  {/* <div
                    className=' px-2  form-input form-input-control'
                    onBlur={() => {
                      if (invoicesNumberData?.no_of_digits !== formik.values?.no_of_digits) {
                        formik.handleSubmit()
                      }
                    }}
                  >
                    <TextInput
                      value={formik.values?.no_of_digits || ''}
                      onChange={(value) => {
                        formik.setFieldValue('no_of_digits', value)
                      }}
                    />
                    {formik.errors.no_of_digits && (
                      <div className='text-danger'>
                        <span role='alert' style={{fontSize: 'smaller'}}>
                          {formik.errors.no_of_digits}
                        </span>
                      </div>
                    )}
                  </div> */}
                  <div className="">
                    <DropDown
                      disabled={!disabledValue}
                      id="DigitsDropDown"
                      hideSearch
                      items={Digit_Options || []}
                      displayFunc={(item) => {
                        return item.name;
                      }}
                      displayValue={
                        Digit_Options.find((postion: any) => {
                          // @ts-ignore
                          return postion.value === formik.values?.no_of_digits;
                        })?.name || ""
                      }
                      // @ts-ignore
                      onSelectItem={(item) => {
                        // @ts-ignore
                        formik.setFieldValue("no_of_digits", item?.value);
                        // @ts-ignore
                        formik.handleSubmit();
                      }}
                      // @ts-ignore
                      selectedItem={Digit_Options.find((postion: any) => {
                        return postion.value === formik.values?.no_of_digits;
                      })}
                      // selectedItem={
                      //   ALIGN_OPTIONS.find((position: any) => {
                      //     return position.value === option
                      //   })?.name || ''
                      // }
                    />
                  </div>
                </td>

                <td className="mw-150px">
                  <div className=" px-2  form-input form-input-control">
                    <TextInput
                      // @ts-ignore
                      value={nextNumber || ""}
                      // onChange={(value) => {
                      //   formik.setFieldValue('next_number', value)
                      // }}
                      disabled
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <div>
        <div
          className='card mt-4 p-4 d-flex flex-row align-items-center justify-content-start mt-4'
          style={{backgroundColor: '#f5f8fa', height: '70px'}}
        >
          <div className='fw-bold mx-5' style={{width: '50%'}}>
            {intl.formatMessage({
              id: 'SALES_NO_O',
            })}
          </div>
          <div style={{width: '50%'}}>
            <div
              className=' px-2  form-input form-input-control '
              onClick={(e) => {
                e.stopPropagation()
                setShowListModal(true)
              }}
            >
              <span
                style={{backgroundColor: '#eef3f7'}}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer'
              ></span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default LayoutCancelInvoice;
