import React, { useEffect, useState } from "react";
import CompanyLogo from "./CompanyLogo";
import { getCompanySettings } from "../redux/CompanySettingsAPi";
import CompanyGeneralDetails from "./CompanyGeneralDetails";

const CompanyGeneralSettings = () => {
  const [companySettings, setCompanySettings] = useState<any>();

  const getCompanySettingsAPI = () => {
    getCompanySettings().then((data) => {
      setCompanySettings(data?.data?.data);
    });
  };

  useEffect(() => {
    getCompanySettingsAPI();
  }, []);

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex flex-column flex-lg-row mt-7">
          <div
            className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10"
            style={{ flex: "0 0 300px" }}
          >
            <CompanyLogo companySettings={companySettings} />
          </div>
          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            <CompanyGeneralDetails partner={companySettings} />
          </div>
        </div>
        <div className="d-flex flex-column gap-7 gap-lg-10 mt-10"></div>
      </div>
    </>
  );
};

export default CompanyGeneralSettings;
