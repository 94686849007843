import React, { FC, useEffect, useState } from "react";
import { LogType } from "../types/DealResponseType";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { SVGICON } from "../../../../_metronic/helpers";
import LogIcon from "../../../../_metronic/assets/icons/log.svg";
import moment from "moment";
import Constants from "../../../config/Constants";
import Pagination from "../../../sharedComponents/pagination/Pagination";

interface Props {
  fieldLogs: LogType[];
  show: boolean;
  closeModal: () => void;
}

const FieldLogsModal: FC<Props> = ({ fieldLogs, show, closeModal }) => {
  const intl = useIntl();

  //   state
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedLogs =
    fieldLogs && fieldLogs.length > 10
      ? fieldLogs.slice(startIndex, startIndex + itemsPerPage)
      : fieldLogs;

  useEffect(() => {
    if (paginatedLogs.length === 0 && fieldLogs.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedLogs]);

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Field Logs</Modal.Title>
      </Modal.Header>

      <div className="overflow-auto">
      <Modal.Body className="overflow-inherit">
        <div className="p-4">
          {paginatedLogs?.map((log, index) => {
            return (
              <div className="d-flex  mb-8" key={index}>
                <SVGICON
                  src={LogIcon}
                  className="svg-icon-2 me-4 svg-icon-primary"
                />
                <span className="  fw-600 fs-7 d-block text-start ps-0">
                  {log.recorded_by_name} updated the field{" "}
                  <span className="fw-semibold">{log.field_name}</span> on{" "}
                  {moment(log.updated_at).format("DD.MM.YYYY")}{" "}
                  {log?.action?.oldValue && (
                    <>
                      {" "}
                      from{" "}
                      <span className="fw-semibold">
                        {log?.action?.oldValue}
                      </span>
                    </>
                  )}{" "}
                  to{" "}
                  <span className="fw-semibold">{log?.action?.newValue}</span>.
                </span>
              </div>
            );
          })}
          {/* end::Table */}
          {paginatedLogs.length > 0 && (
            <Pagination
              totalPages={Math.ceil(paginatedLogs.length / itemsPerPage)}
              activePage={
                Math.ceil(paginatedLogs.length / itemsPerPage) === 1
                  ? 1
                  : activePage
              }
              onPageClick={onPageClick}
              noCard
              itemsPerPage={itemsPerPage}
              showItemsPerPage
              setItemsPerPage={(count) => {
                setItemsPerPage(count);
              }}
            />
          )}
        </div>
      </Modal.Body>
      </div>

      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FieldLogsModal;
