import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { Formik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

// images
import SettingsIcon from "../../../../_metronic/assets/icons/settings.svg";
import { SVGICON } from "../../../../_metronic/helpers";

import { loginAfter2FA, verifyCode } from "../redux/TwoFactorAuthAPI";
import * as auth from "../../auth/redux/AuthRedux";
import { useIntl } from "react-intl";
import Cookies from "js-cookie";

interface Props {
  show: boolean;
  closeModal: () => void;
  userId: number | undefined;
  email: string;
  password: string;
}

interface FormValuesType {
  verify_code?: string | undefined;
}

const initialValues = {
  verify_code: "",
};

const LoginTwoFactorAuthModal: FC<Props> = ({
  show,
  closeModal,
  userId,
  email,
  password,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  // state
  const [step, setStep] = useState<1 | 2>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAuthMethod, setSelectedAuthMethod] = useState<1 | 2>(2);
  const [error, setError] = useState(false);

  // @ts-ignore
  const clientDetails_id = parseInt(
    sessionStorage.getItem("clientDetails_id") || "0"
  );
  const clientDetails_secret =
    sessionStorage.getItem("clientDetails_secret") || "";

  const CodeValidationSchema = Yup.object().shape({
    verify_code: Yup.string().required(
      intl.formatMessage({
        id: "USERS_AUTH_2FA_VERIFY_CODE_VALIDATION_MESSAGE",
      })
    ),
  });

  // const ChooseAuthMethod = () => {
  //   return (
  //     <div>
  //       <p className="text-muted fs-5 fw-bold mb-10">
  //         {intl.formatMessage({ id: "USERS_LOGIN_INFO" })}
  //       </p>
  //       <div className="">
  //         {/* Recovery cod3 */}
  //         <label
  //           className={clsx(
  //             "btn  bg-hover-light-primary   border-hover-primary  p-7 d-flex align-items-center mb-5",
  //             selectedAuthMethod === 1
  //               ? "bg-light-primary border-primary border border-dashed"
  //               : "btn-outline btn-outline-dashed btn-outline-default"
  //           )}
  //           onClick={() => {
  //             setSelectedAuthMethod(1);
  //           }}
  //         >
  //           <SVGICON
  //             src={SettingsIcon}
  //             className={"svg-icon-4x svg-icon-primary me-4"}
  //           />
  //           <span className="d-block fw-bold text-start">
  //             <span className="text-dark fw-bolder d-block fs-3">
  //               {intl.formatMessage({ id: "USERS_LOGIN_RECOVERY_MODE" })}
  //             </span>
  //             <span className="text-muted fw-bold fs-6">
  //               {intl.formatMessage({ id: "USERS_AUTHENTICATOR_APP_INFO" })}
  //             </span>
  //           </span>
  //         </label>

  //         {/* Authenticator code */}
  //         <label
  //           className={clsx(
  //             "btn  bg-hover-light-primary   border-hover-primary  p-7 d-flex align-items-center mb-5",
  //             selectedAuthMethod === 2
  //               ? "bg-light-primary border-primary border border-dashed"
  //               : "btn-outline btn-outline-dashed btn-outline-default"
  //           )}
  //           onClick={() => {
  //             setSelectedAuthMethod(2);
  //           }}
  //         >
  //           <SVGICON
  //             src={SettingsIcon}
  //             className={"svg-icon-4x svg-icon-primary me-4"}
  //           />
  //           <span className="d-block fw-bold text-start">
  //             <span className="text-dark fw-bolder d-block fs-3">
  //               {intl.formatMessage({ id: "USERS_LOGIN_AUTHENTICATOR_CODE" })}
  //             </span>
  //             <span className="text-muted fw-bold fs-6">
  //               {intl.formatMessage({ id: "USERS_AUTHENTICATOR_APP_INFO" })}
  //             </span>
  //           </span>
  //         </label>
  //       </div>
  //     </div>
  //   );
  // };

  let handleCodeSubmit = () => {};
  const EnterCodeForm = () => {
    return (
      <div>
        {error && (
          <div className="mb-7 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {intl.formatMessage({ id: "USERS_INCORRECT_AUTH_CODE" })}
            </div>
          </div>
        )}
        {/* <p className='text-muted fs-5 fw-bold mb-10'>You’ll have to enter your Code to login</p> */}
        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
          <span className="required">
            {selectedAuthMethod === 1
              ? intl.formatMessage({ id: "USERS_LOGIN_RECOVERY_MODE" })
              : intl.formatMessage({ id: "USERS_LOGIN_AUTHENTICATOR_CODE" })}
          </span>
        </label>
        <Formik
          initialValues={initialValues}
          validationSchema={CodeValidationSchema}
          onSubmit={(values, formikHelpers) => {
            // @ts-ignore
            onFormSubmit(values, formikHelpers);
          }}
        >
          {({
            errors,
            values,
            touched,
            status,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            setStatus,
          }) => {
            handleCodeSubmit = handleSubmit;
            return (
              <>
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "USERS_VERIFY_CODE_PLACEHOLDER",
                  })}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  name="verify_code"
                  autoComplete="off"
                  onChange={handleChange("verify_code")}
                  value={values.verify_code}
                  autoFocus={true}
                />
                {touched.verify_code && errors.verify_code && (
                  <div className="text-danger">
                    <span role="alert">{errors.verify_code}</span>
                  </div>
                )}
              </>
            );
          }}
        </Formik>
        {selectedAuthMethod === 2 && (
          <div
            className="pt-4 text-gray-600 cursor-pointer text-hover-primary fs-7"
            onClick={() => setSelectedAuthMethod(1)}
          >
            {intl.formatMessage({
              id: "USERS_2FA_AUTHENTICATOR_CODE_MODAL_RECOVERY_INFO",
            })}
          </div>
        )}
      </div>
    );
  };

  const saveAccessToken = (accessToken: string) => {
    sessionStorage.setItem("accessToken", accessToken);
    Cookies.set("accessToken", accessToken, {
      sameSite: "strict", // Protect against CSRF attacks
      expires: 1 / 96,
    });
    setTimeout((accessToken: string) => {
      dispatch(auth.actions.login(accessToken));
    }, 0);
  };

  const saveRefreshToken = (refresh_token: string) => {
    localStorage.setItem("refreshToken", refresh_token);
  };

  const onFormSubmit = (
    values: FormValuesType,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const code_name =
      selectedAuthMethod === 1 ? "recovery_code" : "verify_code";
    if (values.verify_code && userId) {
      setLoading(true);
      verifyCode(
        userId,
        code_name === "recovery_code" ? values.verify_code : "",
        code_name === "verify_code" ? values.verify_code : ""
      )
        .then((data) => {
          setLoading(true);
          loginAfter2FA(
            userId,
            email,
            password,
            clientDetails_id,
            clientDetails_secret
          )
            .then(
              ({
                data: {
                  userDetails,
                  tokenDetails: { access_token, refresh_token } = {},
                },
              }) => {
                // @ts-ignore
                dispatch(auth.actions.setUser(userDetails));
                setLoading(false);
                access_token && saveAccessToken(access_token);
                refresh_token && saveRefreshToken(refresh_token);
              }
            )
            .catch(() => {
              formikHelpers.setStatus(
                intl.formatMessage({ id: "USERS_INCORRECT_AUTH_CODE" })
              );
              setError(true);
            })
            .finally(() => {
              setLoading(false);
              // setError(true)
            });
        })
        .catch(() => {
          formikHelpers.setStatus(
            intl.formatMessage({ id: "USERS_INCORRECT_AUTH_CODE" })
          );
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="cam-modal-container">
      <Modal
        show={show}
        centered
        dialogClassName="medium-size-modal"
        contentClassName={loading ? "pe-none" : ""}
        backdrop="static"
        onHide={closeModal}
      >
        <Modal.Header closeButton>
            <Modal.Title>
              {selectedAuthMethod === 2
                ? intl.formatMessage({
                    id: "USERS_2FA_AUTHENTICATOR_CODE_MODAL_TITLE",
                  })
                : intl.formatMessage({
                    id: "USERS_2FA_RECOVERY_CODE_MODAL_TITLE",
                  })}
            </Modal.Title>
        </Modal.Header>
        <div className="overflow-auto">
          <Modal.Body className="pt-10 pb-10 px-lg-17 overflow-inherit">
            <EnterCodeForm />
          </Modal.Body>
        </div>
        <Modal.Footer className="justify-content-center px-lg-17">
          <>
            <Button variant="secondary" onClick={closeModal}>
              {intl.formatMessage({ id: "CLOSE_BUTTON" })}
            </Button>
            <Button
              onClick={() => {
                handleCodeSubmit();
              }}
            >
              {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LoginTwoFactorAuthModal;
