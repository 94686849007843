import axios from "axios";

// Custom types
import { GenericResponseType } from "../../../types/GenericReponse";
import { NotesResponseType } from "../types/getNotesResponseTypes";
import { TaskLogsResponseType } from "../../tasks/types/TasksResponseType";

// Utility to generate payload based on presence of users_id and deals_id
const generatePayload = (
  users_id?: number | null,
  deals_id?: number | null,
  note?: string
) => {
  const payload: any = {};
  if (users_id) {
    payload.users_id = users_id;
  }
  if (deals_id) {
    payload.deals_id = deals_id;
  }
  if (note) {
    payload.description = note;
  }
  return payload;
};

// Fetch notes
export function getNotes(users_id?: number | null, deals_id?: number | null) {
  let url = "";
  let payload = {};
  if (users_id) {
    url = "/contactsNotes";
    payload = { users_id };
  }
  if (deals_id) {
    url = "/dealsNotes";
    payload = { deals_id };
  }
  return axios.post<NotesResponseType>(url, payload);
}

// Fetch note by ID
export function getNotesWithId(note_id: number) {
  return axios.get<NotesResponseType>(`/notes/${note_id}`);
}

// Create a new note
export function createNote(
  note: string,
  users_id?: number | null,
  deals_id?: number | null
) {
  let url = "";
  if (deals_id) {
    url = "/dealsNotes/create";
  }
  if (users_id) {
    url = "/contactsNotes/create";
  }
  return axios.post<NotesResponseType>(
    url,
    generatePayload(users_id, deals_id, note)
  );
}

// Update an existing note
export function updateNote(
  note_id: number,
  note: string,
  users_id?: number | null,
  deals_id?: number | null
) {
  let url = "";
  if (deals_id) {
    url = `/dealsNotes/update/${note_id}`;
  }
  if (users_id) {
    url = `/contactsNotes/update/${note_id}`;
  }
  return axios.post<NotesResponseType>(
    url,
    generatePayload(users_id, deals_id, note)
  );
}

// Pin or unpin a note
export function updateNotePin(
  note_id: number,
  is_pinned: number,
  users_id?: number | null,
  deals_id?: number | null
) {
  let url = "";
  if (deals_id) {
    url = `/dealsNotes/updateIsPinned/${note_id}`;
  }
  if (users_id) {
    url = `/contactsNotes/updateIsPinned/${note_id}`;
  }
  return axios.post<NotesResponseType>(url, {
    is_pinned,
  });
}

// Delete a note
export function deleteNote(
  note_id: number,
  users_id?: number | null,
  deals_id?: number | null
) {
  let url = "";
  if (deals_id) {
    url = `/dealsNotes/${note_id}`;
  }
  if (users_id) {
    url = `/contactsNotes/${note_id}`;
  }
  return axios.delete<GenericResponseType>(url);
}

// get note logs
/**
 * ! change with correct url
 * TODO: add typescript for response type
 */
export function getNoteLogs(notes_id: number) {
  return axios.post<TaskLogsResponseType>("/notesTasks/logs", {
    notes_id,
  });
}
