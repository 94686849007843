import axios from "axios";
import { GenericResponseType } from "../../../../types/GenericReponse";

// get all sales notes
export function getNotesList() {
  return axios.get<any>(`/sales/notes`);
}

// add sales note
export function addNotes(name: string) {
  return axios.post<GenericResponseType>(`/sales/notes`, {
    name,
  });
}

// update sales note
export function updateNotes(id: number, obj: any) {
  return axios.post(`/sales/notes/${id}`, obj);
}

// delete single sales note
export function deleteNotes(id: number) {
  return axios.delete<GenericResponseType>(`/sales/notes/${id}`);
}

// delete multiple sales notes
export function multiDeleteNotes(notes_id: number[]) {
  return axios.post(`/sales/notes/massDelete`, { notes_id });
}
