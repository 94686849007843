import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  PhaseApproverType,
  PipelinePhaseType,
  PipelineType,
} from "../types/PipelinesResponseType";
import { KTSVG, SVGICON } from "../../../../_metronic/helpers";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import InfoIcon from "../../../../_metronic/assets/icons/info.svg";
import SVG from "react-inlinesvg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import OpenLockIcon from "../../../../_metronic/assets/icons/lockOpen.svg";
import closeLockIcon from "../../../../_metronic/assets/icons/lockClose.svg";
import FilemanagerIcon from "../../../../_metronic/assets/icons/filemanager.svg";
import clsx from "clsx";
import {
  SortPhasesTabs,
  SortPipelinePhases,
  addPhaseRoles,
  addPhaseTabs,
  deletePhaseTabs,
  deletePipelinePhase,
  getPipelineByID,
  getPipelinesList,
  importTabsFromPipeline,
  needApproval,
  updateApprovers,
  updateDisplayInMobile,
  updatePhase,
  updatePipelineStatus,
  updatePipelines,
} from "../redux/PipelinesAPI";
import AddPipelinePhaseModal from "./AddPipelinePhaseModal";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { TabType } from "../../projectMasterData/types/TabsResponseType";
import { TabSideBar } from "../../projectMasterData/components/EditTabs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TabDropzone from "../../projectMasterData/components/TabDropzone";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { sortGroupsParams } from "../types/request";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import "../PipelinePage.scss";
import {
  TabsModal,
  TabsModalProps,
} from "../../projectMasterData/components/ProjectTabs";
import { getTabsList } from "../../projectMasterData/redux/TabsAPI";
import { useDispatch } from "react-redux";
import { actions } from "../../projectMasterData/redux/ContentMasterRedux";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import withScrolling from "react-dnd-scrolling";
import SelectedUsersModal from "../../teams/components/SelectUsersModal";
import { Button, CloseButton, Modal } from "react-bootstrap";
import PipelineTabsAndFields from "./PipelineTabsAndFields";
import SelectTabsModal from "../../projectMasterData/components/SelectTabsModal";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { actions as PipelineActions } from "../redux/PipelinesRedux";
import { actions as DealActions } from "../../deals/redux/DealsRedux";
import ScrollToTop from "../../../sharedComponents/ScrollToTop/ScrollToTop";
import { getDealPipelines } from "../../deals/redux/DealsAPI";

const EditPipeline = () => {
  const { state = {} } = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const cardWidth = useRef(null);
  const PIPELINE_TRANSITION_TYPE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "PIPELINE_TRANSITION_TYPE_OPTIONS" })
  ) as {
    id: number;
    name: string;
    title: string;
  }[];
  const PIPELINE_STATUS_TYPE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "PIPELINE_STATUS_TYPE_OPTIONS" })
  ) as {
    id: number;
    name: string;
    title: string;
  }[];
  const rolesList = useSelector<RootState>(
    //   @ts-ignore
    ({ rolesAndPermissionsManagement }) => rolesAndPermissionsManagement?.roles
  ) as RoleType[];
  const tabsList = useSelector<RootState>(
    // @ts-ignore
    ({ contentMaster }) => contentMaster.tabsList
  ) as TabType[];
  const singlePipeline = useSelector<RootState>(
    // @ts-ignore
    ({ pipelines }) => pipelines.pipelineDetails
  ) as PipelineType;

  const pipelineList = useSelector<RootState>(
    // @ts-ignore
    ({ pipelines }) => pipelines.pipelinesList
  ) as PipelineType[];

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "pipelines"
  );
  const crudPermissionForTabs: any = enablePermissionForCrud(
    userRoleDetails,
    "tabs"
  );

  // @ts-ignore
  const pipeline: PipelineType = state.pipelineDetails || singlePipeline || {};
  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const dispatch = useDispatch();

  //   state
  const [activePhase, setActivePhase] = useState<PipelinePhaseType>(
    pipeline.phases?.[0]
  );
  const [currPipeline, setCurrPipeline] = useState<PipelineType>(pipeline);
  const [isChecked, setIsChecked] = useState(
    activePhase?.need_approvals === 1 ? true : false
  );
  const [showPhaseModal, setShowPhaseModal] = useState(false);
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const [selectedRole, setSelectedRole] = useState<RoleType[]>(
    activePhase?.roles || []
  );
  const [phaseHeading, setPhaseHeading] = useState(activePhase?.name || "");
  const [pipelineHeading, setPipelineHeading] = useState(pipeline.name || "");
  const [phaseIsRequired, setPhaseIsRequired] = useState(
    activePhase?.is_required
  );
  const [phaseIsEndPhase, setPhaseIsEndPhase] = useState(
    activePhase?.condition === "end" && activePhase?.display_order !== null
      ? 1
      : 0
  );
  const [loading, setLoading] = useState(false);
  const [phaseTabActiveIndex, setPhaseTabActiveIndex] = useState(0);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedPhases, setSelectedPhases] = useState([]);
  const [fieldData, setFieldData] = useState<TabType[]>(tabsList);
  const [pipelineTransition, setPipelineTransition] = useState(
    pipeline?.transition_type || ""
  );
  const [pipelineStatus, setPipelineStatus] = useState(pipeline?.status || "");
  const [addTabModal, setAddTabModal] = useState<TabsModalProps>({
    show: false,
    type: "new",
  });
  const [openUserModal, setOpenUserModal] = useState<{
    show: boolean;
    type: string;
    approvalValue: number;
  }>({
    show: false,
    type: "",
    approvalValue: 0,
  });
  const [selectedTab, setSelectedTab] = useState<TabType>(
    activePhase?.tabs?.[0]
  );

  const ScrollingComponent = withScrolling("div");
  const getOtherPhases = (pipeline: any, activePhase: any) => {
    return pipeline?.phases?.filter(
      (phase: any) => phase?.id !== activePhase?.id && phase?.tabs?.length > 0
    );
  };
  const otherPhases = getOtherPhases(pipeline, activePhase);

  const handleToggleChange = (
    checked: boolean | ((prevState: boolean) => boolean)
  ) => {
    if (activePhase?.phaseApprovers?.length === 0 && !isChecked) {
      // Open the modal if no approvers are present
      setOpenUserModal({
        show: true,
        type: "primary",
        approvalValue: checked ? 1 : 0,
      });
      // Keep the toggle checked but modal will confirm the action
      setIsChecked(checked);
    } else {
      // Directly handle approval if approvers are already present
      handleApproval(checked ? 1 : 0);
      setIsChecked(checked);
    }
  };

  useEffect(() => {
    if (activePhase?.phaseApprovers?.length === 0) {
      setIsChecked(false);
    }
  }, [activePhase?.phaseApprovers]);

  useEffect(() => {
    if (activePhase?.phaseApprovers?.length > 0) {
      setIsChecked(activePhase?.need_approvals === 1);
    }
  }, [activePhase]);

  useEffect(() => {
    if (pipeline && pipeline.phases && pipeline.phases.length > 0) {
      setSelectedRole(activePhase?.roles || []);
      setPhaseHeading(activePhase?.name);
      setPhaseIsRequired(activePhase?.is_required);
      setPhaseIsEndPhase(
        activePhase?.condition === "end" && activePhase?.display_order !== null
          ? 1
          : 0
      );
    }
    if (pipeline?.id) {
      setCurrPipeline(pipeline);
    }
  }, [activePhase]);

  useEffect(() => {
    if (activePhase && activePhase?.id) {
      const cloneActivePhase = pipeline.phases.filter(
        (d) => d?.id === activePhase?.id
      )[0];
      if (cloneActivePhase && cloneActivePhase?.id) {
        setActivePhase(cloneActivePhase);
        setSelectedTab(cloneActivePhase?.tabs?.[0]);
      } else {
        setActivePhase(pipeline.phases?.[0]);
        setSelectedTab(pipeline.phases?.[0]?.tabs?.[0]);
      }
    } else {
      setActivePhase(pipeline.phases?.[0]);
      setSelectedTab(pipeline.phases?.[0]?.tabs?.[0]);
    }
  }, [pipeline]);

  useEffect(() => {
    ScrollToTop();
  }, []);

  const getPipelineDetailsById = () => {
    setLoading(true);
    getPipelineByID(currPipeline?.id)
      .then(({ data: { pipeline } }) => {
        history.replace({ state: { pipelineDetails: pipeline } });
        setCurrPipeline(pipeline);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeletePipelinePhase = (id: number) => {
    setDeleteModalLoading(true);
    deletePipelinePhase(id)
      .then((data) => {
        // setActivePhase(pipeline.phases?.[0]);
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById();
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
      });
  };

  const handleOnSelectItem = (item: RoleType) => {
    const selectedItemsArr = selectedRole || [];
    const index = selectedItemsArr.findIndex(
      (selectedItem) => selectedItem?.id === item?.id
    );
    // if doesnt exist push item
    if (index === -1) {
      setSelectedRole(() => [...selectedRole, item]);
    }
    // if already exists remove item
    else {
      setSelectedRole((prevState) => {
        prevState.splice(index, 1);
        return [...prevState];
      });
    }
  };

  const handleOnSelectPhase = (item: any) => {
    const index = selectedPhases.findIndex(
      // @ts-ignore
      (selectedItem) => selectedItem?.id === item?.id
    );

    if (index === -1) {
      // @ts-ignore
      setSelectedPhases((prev: any) => [...prev, item]);
    } else {
      setSelectedPhases((prevState) => {
        prevState.splice(index, 1);
        return [...prevState];
      });
    }
  };

  const handleOnBlur = () => {
    const ids = selectedRole.map((selectedItem) => {
      return selectedItem?.id;
    });
    if (activePhase?.id) {
      setRoleLoading(true);
      addPhaseRoles(activePhase?.id, ids || [])
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "PROJECTS_PIPELINE_ROLE_UPDATE_SUCCES_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "PROJECTS_PIPELINE_ROLE_UPDATE_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getPipelineDetailsById?.();
          setRoleLoading(false);
        });
    }
  };

  const handlePhaseHeading = (phaseIsRequired: any) => {
    updatePhase(
      currPipeline?.id,
      activePhase?.id,
      phaseHeading,
      phaseIsRequired,
      activePhase.is_endPhase
    )
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_SUCCES_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handleIsEndpHase = (isEndPhase: number) => {
    updatePhase(
      currPipeline?.id,
      activePhase?.id,
      phaseHeading,
      phaseIsRequired,
      isEndPhase
    )
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handleIsDisplayMobile = (isMobile: number) => {
    updateDisplayInMobile(activePhase?.id, isMobile)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handleApproval = (need_approvals: number) => {
    needApproval(activePhase?.id, need_approvals)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PIPELINE_APPROVAL_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PIPELINE_APPROVAL_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handlePipelineHeading = (type: string, value: string) => {
    updatePipelines(
      currPipeline?.id,
      pipelineHeading,
      type === "transition" ? value : pipelineTransition,
      pipelineStatus
    )
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_NAME_SUCCES_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_NAME_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const updateContentPipelines = () => {
    getDealPipelines()
      .then(({ data: { pipelines } }) => {
        dispatch(DealActions.setDealPipelines(pipelines));
      })
      .catch((error) => console.log(error?.response?.data?.message));
  };

  const handlePipelineList = () => {
    setLoading(true);
    getPipelinesList()
      .then(({ data: { pipelines } }) => {
        dispatch(PipelineActions.setPipelinesList(pipelines));
      })
      .catch((error) => console.log(error?.response?.data?.message))
      .finally(() => {
        setLoading(false);
        updateContentPipelines();
      });
  };

  const handlePipelineStatus = (status: string) => {
    updatePipelineStatus(currPipeline?.id, status)
      .then(() => {
        handlePipelineList();
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_STATUS_SUCCES_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_STATUS_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handleDrop = useCallback(
    (
      dropzone: {
        childrenCount: any;
        path: any;
        phase: number;
        pipeline: number;
      },
      item: { id: number; isNew: any; type: any }
    ) => {
      // console.log(item);
      if (item && item.isNew) {
        const existingPhase = currPipeline.phases.filter(
          (data) => data.id === dropzone.phase
        )[0];
        const existingTabIds = existingPhase.tabs.map((data) => data.id) || [];
        // console.log(existingTabIds);
        addPhaseTabs(dropzone.phase, [...existingTabIds, item.id])
          .then(() => {
            successToast(
              intl.formatMessage({
                id: "PROJECTS_PIPELINE_TAB_ADD_SUCCES_MESSAGE",
              })
            );
          })
          .catch(() => {
            errorToast(
              intl.formatMessage({
                id: "PROJECTS_PIPELINE_TAB_ADD_FAILURE_MESSAGE",
              })
            );
          })
          .finally(() => {
            getPipelineDetailsById?.();
          });
      }
    },
    [pipeline]
  );

  const handleDeletePipelinePhaseTab = (id: number) => {
    // const filteredTabs = activePhase?.tabs.filter((data) => data.id !== id);
    // const ids =
    //   filteredTabs && filteredTabs.length > 0
    //     ? filteredTabs.map((data) => data.id)
    //     : [];
    setDeleteModalLoading(true);
    deletePhaseTabs(activePhase?.id, [id])
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_TAB_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_TAB_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setPhaseTabActiveIndex(0);
        getPipelineDetailsById();
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
      });
  };

  const handlePipelinePhaseSort = (sortedList: PipelinePhaseType[]) => {
    const newSortedTabsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTabsArr.push({
        id: sortedListItem.id,
        newposition: i + 1,
      });
    });
    // sort existing tags API Call
    SortPipelinePhases(currPipeline.id, newSortedTabsArr)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "PIPELINE_PHASE_SORTING_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {})
      .finally(() => {
        getPipelineDetailsById();
      });
  };

  const handlePhaseTabSort = (sortedList: TabType[]) => {
    const newSortedTabsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTabsArr.push({
        id: sortedListItem.id,
        newposition: i + 1,
      });
    });
    // sort existing tags API Call
    SortPhasesTabs(activePhase?.id, newSortedTabsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getPipelineDetailsById();
      });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const updateDisplayTabsList = () => {
    const updatedTabsList = fieldData.filter((tab) => {
      if (
        (tab.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setFieldData(updatedTabsList);
  };

  useEffect(() => {
    updateDisplayTabsList();

    if (searchText?.length === 0) {
      setFieldData(tabsList);
    }

    return () => {
      setFieldData(tabsList);
    };
  }, [tabsList, searchText]);

  const dragPhase = useRef<number>(0);
  const dragOverPhase = useRef<number>(0);

  const dragTab = useRef<number>(0);
  const dragOverTab = useRef<number>(0);

  const getTabsListAPI = () => {
    getTabsList().then(({ data: { data } }) => {
      dispatch(actions.setTabsList(data));
    });
  };

  const closeModal = () => {
    setShowAddModal(false);
  };

  const hasNative = document && document.elementsFromPoint;

  function getDropTargetElementsAtPoint(x: any, y: any, dropTargets: any) {
    return dropTargets.filter((t: any) => {
      const rect = t.getBoundingClientRect();
      return (
        x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
      );
    });
  }

  // use custom function only if elementsFromPoint is not supported
  const backendOptions = {
    getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
  };

  const handleUpdateApprovers = (approverUsers: UserType[], type: string) => {
    const result: { users_id: number; condition: string }[] = [];
    const existingResult: { users_id: number; condition: string }[] = [];
    approverUsers.map((data) => {
      let obj = {
        users_id: data.id,
        condition: type,
      };
      result.push(obj);
    });

    activePhase.phaseApprovers.map((data) => {
      let obj = {
        users_id: data.users_id,
        condition: data?.condition,
      };
      existingResult.push(obj);
    });

    updateApprovers(activePhase.id, [...result, ...existingResult])
      .then((data) => {
        successToast(
          intl.formatMessage({ id: "APPROVERS_ADDED_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "APPROVERS_ADDED_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getPipelineDetailsById();
      });
  };

  const handleSubmit = () => {
    importTabsFromPipeline(
      activePhase?.id,
      selectedPhases?.map((phase: any) => phase?.id)
    )
      .then((data) => {
        closeModal?.();
        successToast(
          intl.formatMessage({ id: "TABS_IMPORTED_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        closeModal?.();
        errorToast(intl.formatMessage({ id: "TABS_IMPORTED_FAILURE_MESSAGE" }));
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        setSelectedPhases([]);
        getPipelineDetailsById();
      });
  };

  const deleteApprover = (user: PhaseApproverType) => {
    setDeleteModalLoading(true);
    const result = activePhase.phaseApprovers.filter(
      (data) => data.users_id !== user.users_id
    );
    updateApprovers(activePhase.id, result)
      .then((data) => {
        successToast(
          intl.formatMessage({ id: "APPROVERS_REMOVED_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "APPROVERS_REMOVED_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(true);
        hideDeleteConfirmModal();
        getPipelineDetailsById();
      });
  };

  const handleAddTabs = (addTabs: TabType[]) => {
    const existingTabIds = activePhase.tabs.map((data) => data.id) || [];
    const newTabIds = addTabs.map((data) => data.id) || [];
    addPhaseTabs(activePhase.id, [...existingTabIds, ...newTabIds])
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_TAB_ADD_SUCCES_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_TAB_ADD_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  return (
    <>
      <DndProvider
        backend={isMobile ? TouchBackend : HTML5Backend}
        options={backendOptions}
      >
        <div className="mt-4">
          {showPhaseModal && (
            <AddPipelinePhaseModal
              show={showPhaseModal}
              closeModal={() => {
                setShowPhaseModal(false);
              }}
              pipeline={currPipeline}
              getPipelineDetailsById={getPipelineDetailsById}
            />
          )}
          {addTabModal.show && (
            // <TabsModal
            //   ModalProps={addTabModal}
            //   closeModal={() => {
            //     setAddTabModal({ show: false, type: "new" });
            //   }}
            //   getTabsListAPI={getTabsListAPI}
            // />
            <SelectTabsModal
              show={addTabModal.show}
              closeModal={() => {
                setAddTabModal({
                  show: false,
                });
              }}
              onSelectedTabs={(tabs) => {
                if (tabs && tabs.length > 0) {
                  setAddTabModal({
                    show: false,
                  });
                  handleAddTabs(tabs);
                }
              }}
              selectedTabs={activePhase.tabs}
              isSelectionModal={true}
            />
          )}
          {openUserModal.show && (
            <SelectedUsersModal
              show={openUserModal.show}
              closeModal={() => {
                setOpenUserModal({
                  ...openUserModal,
                  show: false,
                });
                if (activePhase?.phaseApprovers?.length === 0) {
                  setIsChecked(false);
                }
              }}
              onSelectUsers={(customers) => {
                if (activePhase.phaseApprovers.length === 0) {
                  handleApproval(openUserModal.approvalValue);
                }
                handleUpdateApprovers(customers, openUserModal.type);
                // console.log(customers);
                setOpenUserModal({
                  ...openUserModal,
                  show: false,
                });
              }}
              isSelectionModal={true}
              selectedContacts={activePhase.phaseApprovers}
            />
          )}
          {/* pipline name header */}

          {/* General Details */}
          <div
            className={`mt-7 card  bgi-position-y-center bgi-no-repeat ${
              !crudPermission?.edit && "pe-none"
            }`}
            style={{
              padding: "30px",
              marginBottom: "25px",
            }}
          >
            <h4>
              {intl.formatMessage({
                id: "PIPELINE_DETAILS_GENERAL_DETAILS_TTILE",
              })}
            </h4>
            <div className="row">
              <div className="d-flex col-9 mt-2">
                {/* Transition Type */}
                <div className="col-8">
                  {/* <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                    <span className="">
                      {intl.formatMessage({
                        id: "PIPELINE_DETAILS_TRANSITION_TYPE",
                      })}
                    </span>
                  </label> */}
                  <div className=" d-flex flex-row align-items-center">
                    <div
                      className="me-1 "
                      style={{
                        flex: 1,
                      }}
                    >
                      <div className="d-flex flex-row align-items-center transitionDropdown">
                        <DropDown
                          hideSearch
                          id="users-titles-dropdown"
                          items={PIPELINE_TRANSITION_TYPE_OPTIONS}
                          displayFunc={(item) => {
                            // @ts-ignore
                            return item.title;
                          }}
                          displayValue={
                            PIPELINE_TRANSITION_TYPE_OPTIONS.find(
                              (TITLES_OPTION) =>
                                TITLES_OPTION.name === pipelineTransition
                            )?.title || ""
                          }
                          onSelectItem={(item) => {
                            setPipelineTransition(item.name);
                            handlePipelineHeading("transition", item.name);
                          }}
                          selectedItem={PIPELINE_TRANSITION_TYPE_OPTIONS.find(
                            (TITLES_OPTION) =>
                              TITLES_OPTION.name === pipelineTransition
                          )}
                        />
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        zIndex: "11",
                      }}
                    >
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={intl.formatMessage({
                          id: "PIPELINE_TRANSITION_INFO_MESSAGE",
                        })}
                        // data-tooltip-place="top"
                      >
                        <SVG src={InfoIcon} className="svg-icon mx-2" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* button group / preview, pipeline status */}
              <div className="d-flex col-3 mt-2 align-items-center justify-content-end gap-3 p-0">
                <>
                  {pipelineStatus &&
                    crudPermission?.edit &&
                    PIPELINE_STATUS_TYPE_OPTIONS.map((status) => {
                      if (status.name !== pipelineStatus)
                        return (
                          <div className="me-2">
                            <button
                              className={clsx(
                                "btn  btn-md me-1",
                                status.id === 1
                                  ? " bg-success"
                                  : status.id === 2
                                    ? "bg-secondary"
                                    : "bg-danger"
                              )}
                              onClick={(e) => {
                                // @ts-ignore
                                setPipelineStatus(status.name);
                                handlePipelineStatus(status.name);
                              }}
                            >
                              {status.title}
                            </button>
                          </div>
                        );
                    })}
                </>
              </div>
            </div>
          </div>
          {/* new UI to edit pipeline name, adding phases and sorting */}
          <div
            className={`card ${!crudPermission?.edit && "pe-none"}`}
            style={{
              padding: "30px",
              marginBottom: "25px",
            }}
            ref={cardWidth}
          >
            {/* Pipeline Name */}
            <div className="col-12">
              {/* <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="">
                  {intl.formatMessage({
                    id: "PIPELINE_DETAILS_PIPELINE_NAME",
                  })}
                </span>
              </label> */}
              <div className=" d-flex flex-row align-items-center mb-4">
                <input
                  type="text"
                  value={pipelineHeading}
                  onChange={(e) => {
                    setPipelineHeading(e.target.value);
                  }}
                  style={{
                    flex: 1,
                    border: "none",
                    outline: "none",
                  }}
                  className="panelHeading me-4 fw-bold py-4"
                  onBlur={() => {
                    if (pipelineHeading.length === 0) {
                      setPipelineHeading(activePhase?.name || "");
                    } else if (pipeline?.name !== pipelineHeading) {
                      handlePipelineHeading("heading", pipelineHeading);
                    }
                  }}
                />
              </div>
            </div>

            <div className="d-flex col-12 align-items-center gap-5 ">
              <div
                className="d-flex rounded   "
                style={{
                  minWidth: "250px",
                  maxWidth: "100%",
                  overflow: "auto",
                }}
              >
                {currPipeline?.phases?.map((phase, index) => {
                  if (phase?.condition === "start") {
                    return (
                      <div className="w-100 min-w-100px" key={index}>
                        <div
                          className="mb-2 d-flex align-items-center justify-content-center h-10px fw-bold position-relative borderStyle me-1"
                          role="button"
                          onClick={() => {
                            if (activePhase?.id !== phase.id) {
                              setActivePhase(phase);
                              setSelectedTab(phase?.tabs?.[0]);
                            }
                          }}
                          style={{
                            flex: 1,
                            background: "#3766C7",
                          }}
                        ></div>
                        <div className="d-flex  text-muted textEllipsis">
                          {crudPermission?.edit && (
                            <SVGICON
                              src={DragIcon}
                              className="svg-icon-4 svg-icon-hover-primary me-2"
                            />
                          )}
                          {phase.name}
                        </div>
                      </div>
                    );
                  } else {
                    if (phase?.display_order) {
                      return (
                        <div
                          className="w-100 min-w-100px"
                          key={index}
                          draggable
                          role="button"
                          onClick={() => {
                            if (activePhase?.id !== phase.id) {
                              setActivePhase(phase);
                              setSelectedTab(phase?.tabs?.[0]);
                            }
                          }}
                          onDragStart={(e) => {
                            e.stopPropagation();
                            dragPhase.current = index;
                          }}
                          onDragEnter={(e) => {
                            e.stopPropagation();
                            dragOverPhase.current = index;
                            // console.log(index);
                          }}
                          onDragEnd={(e) => {
                            e.stopPropagation();
                            if (dragPhase.current !== dragOverPhase.current) {
                              const clonePhases = [...currPipeline.phases];

                              const deletedSlice = clonePhases.splice(
                                dragPhase.current,
                                1
                              )[0];
                              clonePhases.splice(
                                dragOverPhase.current,
                                0,
                                deletedSlice
                              );
                              const phaseWithDisplayOrder = clonePhases.filter(
                                (data) => data?.display_order
                              );

                              handlePipelinePhaseSort(phaseWithDisplayOrder);
                            }
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div
                            className="mb-2 d-flex align-items-center justify-content-center h-10px fw-bold position-relative borderStyle me-1"
                            key={index}
                            style={{
                              flex: 1,
                              background: "#3766C7",
                            }}
                          ></div>
                          <div className="d-flex  text-muted textEllipsis">
                            {crudPermission?.edit && (
                              <SVGICON
                                src={DragIcon}
                                className="svg-icon-4 svg-icon-hover-primary me-2"
                              />
                            )}
                            {phase.name}
                          </div>
                        </div>
                      );
                    }
                  }
                })}

                {currPipeline?.phases?.map((phase, index) => {
                  if (
                    phase?.condition === "end" &&
                    phase?.display_order == undefined
                  ) {
                    return (
                      <div className="w-100 min-w-100px" key={index}>
                        <div
                          className="mb-2 d-flex align-items-center justify-content-center h-10px fw-bold position-relative borderStyle me-1"
                          role="button"
                          style={{
                            flex: 1,
                            background: "#3766C7",
                          }}
                          onClick={() => {
                            if (activePhase?.id !== phase.id) {
                              setActivePhase(phase);
                              setSelectedTab(phase?.tabs?.[0]);
                            }
                          }}
                        ></div>
                        <div className="d-flex align-items-center justify-content-center text-muted textEllipsis">
                          {crudPermission?.edit && (
                            <SVGICON
                              src={DragIcon}
                              className="svg-icon-4 svg-icon-hover-primary me-2"
                            />
                          )}
                          {phase.name}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="d-flex justify-content-end px-0 align-items-center">
                {crudPermission?.edit && (
                  <button
                    className={clsx("btn btn-primary  btn-sm me-1")}
                    onClick={() => {
                      setShowPhaseModal(true);
                    }}
                    style={{ minWidth: "120px" }}
                  >
                    <SVGICON src={PlusIcon} className="svg-icon-2" />
                    {intl.formatMessage({ id: "PROJECTS_PHASES_TITLE" })}
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* phase tab card */}
          <div className="d-flex flex-column flex-xl-row"></div>

          <div className="d-flex flex-column flex-xl-row ">
            {/* Phase edit Card */}
            <div
              className="  d-flex flex-column flex-row-fluid   flex-fill me-lg-0 me-xl-7"
              style={{
                flex: 1,
                // marginRight: "25px",
              }}
            >
              <div
                className="card  "
                style={{
                  marginBottom: "25px",
                  padding: "30px",
                }}
              >
                {/* phase name edit and delete buttons */}
                <div className="mb-4 d-flex flex-row align-items-center justify-content-between borderNone">
                  <div
                    className={`d-flex flex-row align-items-center ${
                      !crudPermission?.edit && "pe-none"
                    }`}
                    style={{
                      flex: 1,
                    }}
                  >
                    <input
                      type="text"
                      value={phaseHeading}
                      onChange={(e) => {
                        setPhaseHeading(e.target.value);
                      }}
                      style={{
                        flex: 1,
                        border: "none",
                        outline: "none",
                      }}
                      className="panelHeading me-4 fw-bold py-4"
                      onBlur={() => {
                        if (phaseHeading.length === 0) {
                          setPhaseHeading(activePhase?.name || "");
                        } else if (activePhase?.name !== phaseHeading) {
                          handlePhaseHeading(phaseIsRequired);
                        }
                      }}
                    />
                  </div>

                  <div>
                    {crudPermission?.edit && (
                      <button
                        className="btn btn-icon btn-light  btn-active-light-primary btn-sm mt-2 me-2"
                        onClick={() => {
                          if (userData.role.id === 1) {
                            setPhaseIsRequired(phaseIsRequired === 0 ? 1 : 0);
                            handlePhaseHeading(phaseIsRequired === 0 ? 1 : 0);
                          }
                        }}
                      >
                        <SVGICON
                          src={
                            phaseIsRequired === 1 ? closeLockIcon : OpenLockIcon
                          }
                          className="svg-icon-2"
                        />
                      </button>
                    )}

                    {(!activePhase?.can_delete &&
                      activePhase?.is_required === 0) ||
                    userData?.role.id === 1
                      ? activePhase?.display_order && (
                          <button
                            className="btn btn-icon btn-light  btn-active-light-danger btn-sm mt-2"
                            onClick={() => {
                              showDeleteConfirmModal(
                                intl.formatMessage({
                                  id: "PROJECTS_PIPELINE_DELETE_CONFIRM_MESSAGE",
                                }),
                                () => {
                                  handleDeletePipelinePhase(activePhase?.id);
                                }
                              );
                            }}
                          >
                            <SVGICON src={DeleteIcon} className="svg-icon-3" />
                          </button>
                        )
                      : activePhase?.display_order && (
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              zIndex: "11",
                            }}
                          >
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={
                                activePhase?.is_required === 1
                                  ? intl.formatMessage({
                                      id: "PIPELINE_PHASE_NOT_DELTET_REQUIRED_REASON_INFO_MESSAGE",
                                    })
                                  : intl.formatMessage({
                                      id: "PIPELINE_PHASE_NOT_DELTET_DEALS_ATTACH_REASON_INFO_MESSAGE",
                                    })
                              }
                              data-tooltip-place="top"
                            >
                              <SVG src={InfoIcon} className="svg-icon mx-2" />
                            </a>

                            <Tooltip
                              id="my-tooltip"
                              place="top"
                              style={{
                                backgroundColor: "#f5f8fa",
                                color: "black",
                              }}
                              border="1px solid green"
                            />
                          </div>
                        )}
                  </div>
                </div>
                {/* tabs */}
                <div className="d-flex w-100">
                  <div
                    style={{
                      minWidth: "10px",
                      maxWidth: "80%",
                      overflowX: "auto",
                      overflowY: "hidden"
                    }}
                  >
                    {activePhase &&
                      activePhase?.tabs &&
                      activePhase?.tabs.length > 0 && (
                        <div className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-6 fw-bold">
                          {activePhase?.tabs.map((tab, i) => {
                            if (tab) {
                              return (
                                <div
                                  className={`d-flex align-items-center ${
                                    !crudPermission?.edit && "pe-none"
                                  }`}
                                  draggable
                                  onDragStart={(e) => {
                                    e.stopPropagation();
                                    dragTab.current = i;
                                  }}
                                  onDragEnter={(e) => {
                                    e.stopPropagation();
                                    dragOverTab.current = i;
                                  }}
                                  onDragEnd={(e) => {
                                    e.stopPropagation();
                                    if (
                                      dragTab.current !== dragOverTab.current
                                    ) {
                                      const cloneTabs = [...activePhase?.tabs];
                                      const deletedSlice = cloneTabs.splice(
                                        dragTab.current,
                                        1
                                      )[0];
                                      cloneTabs.splice(
                                        dragOverTab.current,
                                        0,
                                        deletedSlice
                                      );
                                      handlePhaseTabSort(cloneTabs);
                                    }
                                  }}
                                  onDragOver={(e) => {
                                    //   e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                >
                                  <span>
                                    {crudPermission?.edit && (
                                      <SVGICON
                                        src={DragIcon}
                                        className="svg-icon-2 svg-icon-hover-primary me-3"
                                      />
                                    )}
                                  </span>
                                  <span
                                    className="nav-item d-flex me-4"
                                    key={i}
                                    onClick={() => {
                                      if (phaseTabActiveIndex !== i) {
                                        setPhaseTabActiveIndex(i);
                                        setSelectedTab(activePhase?.tabs?.[i]);
                                        dispatch(
                                          actions.setTabDetails(
                                            activePhase.tabs[i]
                                          )
                                        );
                                      }
                                    }}
                                  >
                                    <span
                                      className={clsx(
                                        "nav-link text-active-primary pb-2 m-0 text-truncate",
                                        phaseTabActiveIndex === i
                                          ? "active"
                                          : ""
                                      )}
                                      role={"button"}
                                      style={{
                                        width: "100px",
                                      }}
                                    >
                                      {tab.name}
                                    </span>
                                    {crudPermission?.edit && (
                                      <button
                                        className={clsx(
                                          "btn btn-icon btn-sm",
                                          phaseTabActiveIndex === i
                                            ? "btn-icon-danger"
                                            : ""
                                        )}
                                        role={"button"}
                                        onClick={() => {
                                          showDeleteConfirmModal(
                                            intl.formatMessage({
                                              id: "PROJECTS_PIPELINE_PHASE_TAB_DELETE_CONFIRM_MESSAGE",
                                            }),
                                            () => {
                                              handleDeletePipelinePhaseTab(
                                                tab.id
                                              );
                                            }
                                          );
                                        }}
                                      >
                                        <SVGICON
                                          src={DeleteIcon}
                                          className="svg-icon-3 mb-2"
                                        />
                                      </button>
                                    )}
                                  </span>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </div>
                      )}
                  </div>
                  <div>
                    {crudPermissionForTabs?.read && (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          setAddTabModal({
                            show: true,
                            type: "new",
                          });
                        }}
                      >
                        <SVGICON src={PlusIcon} className="svg-icon-2" />
                        {intl.formatMessage({
                          id: "PROJECTS_PIPELINE_TABS_TITLE",
                        })}
                      </button>
                    )}
                  </div>
                </div>
                {/* end tabs */}
              </div>
              {activePhase && activePhase?.tabs?.length > 0 && (
                <div
                  className="card"
                  style={{
                    padding: "30px",
                  }}
                >
                  <PipelineTabsAndFields
                    selectedTab={selectedTab}
                    onChangeTabName={getPipelineDetailsById}
                  />
                </div>
              )}
            </div>
            {/* right side phase questions Card */}
            <div
              className="  d-flex flex-column  w-100 w-xl-300px mt-lg-7 mt-xl-0"
              style={{ flex: "0 0 400px" }}
            >
              <div
                className="card"
                style={{
                  marginBottom: "25px",
                }}
              >
                <div
                  className={`card-body ${!crudPermission?.edit && "pe-none"}`}
                >
                  {/* questionirre */}
                  {/* Roles dropdown */}
                  <div className="position-relative mb-4">
                    <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                      <span className="">
                        {intl.formatMessage({
                          id: "ADD_ROLE_MODAL_FIELD_NAME",
                        })}
                      </span>
                    </label>

                    <div className="d-flex ">
                      <div
                        style={{
                          flex: 1,
                        }}
                        className="position-relative"
                      >
                        <DropDown
                          id="phaseRoleTypeDropdown"
                          hideSearch
                          multiSelect
                          items={
                            rolesList.filter((role) => role.id !== 1) || []
                          }
                          selectedItems={selectedRole}
                          onSelectItem={(item) => {
                            //   @ts-ignore
                            handleOnSelectItem(item);
                          }}
                          displayFunc={(item) => {
                            return item.name;
                          }}
                          // onMultiSelectBlur={() => {
                          //     handleOnBlur();
                          // }}
                        />
                      </div>

                      {crudPermission?.edit && (
                        <div>
                          <button
                            className="btn  btn-light btn-active-light-primary btn-lg  ms-2"
                            onClick={() => {
                              handleOnBlur();
                            }}
                          >
                            {!roleLoading &&
                              intl.formatMessage({ id: "SAVE_BUTTON" })}
                            {roleLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                {intl.formatMessage({
                                  id: "MASTERDATA_TAGS_LOADING_MESSAGE",
                                })}{" "}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* selected roles message */}
                  <div className="mt-10 firstPhaseTab">
                    <p className="text-info">
                      {selectedRole.length > 0
                        ? `${intl.formatMessage({
                            id: "EDIT_PIPELINE_ROLE_INFO_MESSAGE1",
                          })} ${selectedRole
                            .map((role) => role.name)
                            .join(" ,")} ${intl.formatMessage({
                            id: "EDIT_PIPELINE_USERS_INFO",
                          })}`
                        : intl.formatMessage({
                            id: "EDIT_PIPELINE_ROLE_INFO_MESSAGE2",
                          })}
                    </p>
                  </div>

                  <div className="d-flex  flex-column">
                    <div className="d-flex flex-stack mb-4">
                      <div className="fw-bold">
                        {intl.formatMessage({
                          id: "PIPELINE_PROJECTS_QUESTION2",
                        })}
                      </div>
                      <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="notifications"
                          value=""
                          checked={
                            activePhase?.display_in_mobile === 1 ? true : false
                          }
                          style={{ width: "45px", height: "22px" }}
                          onChange={(e) => {
                            handleIsDisplayMobile(e.target.checked ? 1 : 0);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-row mt-6">
                    {crudPermission?.edit && (
                      <div
                        role="button"
                        className="badge badge-primary"
                        onClick={() => {
                          setShowAddModal(true);
                        }}
                        title="Import"
                      >
                        {intl.formatMessage({
                          id: "PIPELINE_IMPORT_BUTTON",
                        })}
                      </div>
                    )}
                    {crudPermission?.edit && (
                      <div
                        style={{
                          position: "relative",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          data-tooltip-id="import-tooltip"
                          data-tooltip-content={intl.formatMessage({
                            id: "PIPELINE_TOOLTIP_INFO",
                          })}
                          data-tooltip-place="top"
                        >
                          <SVG src={InfoIcon} className="svg-icon mx-2" />
                        </span>

                        <Tooltip
                          id="import-tooltip"
                          place="top"
                          style={{
                            backgroundColor: "#f5f8fa",
                            color: "#a1a5b7",
                            zIndex: 99,
                            whiteSpace: "normal",
                            maxWidth: "250px",
                            wordWrap: "break-word",
                          }}
                          border="1px solid #a1a5b7"
                          className="btn text-dark my-2"
                        />
                      </div>
                    )}
                    <Modal
                      show={showAddModal}
                      centered
                      contentClassName={loading ? "pe-none" : "AddNew"}
                      dialogClassName={"medium-size-modal"}
                      backdrop="static"
                    >
                      <Modal.Header>
                        <Modal.Title>
                          {intl.formatMessage({ id: "PIPELINE_IMPORT_TABS" })}
                        </Modal.Title>
                        <CloseButton
                          // @ts-ignore
                          onClick={() => {
                            setShowAddModal(false);
                          }}
                        />
                      </Modal.Header>

                      <Modal.Body>
                        <div className="p-4">
                          <div className="mb-4">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="">
                                {intl.formatMessage({
                                  id: "PIPELINE_PHASES",
                                })}
                              </span>
                            </label>
                          </div>
                          <div className="mb-4">
                            <DropDown
                              id="post-groups-dropdown"
                              multiSelect={true}
                              items={otherPhases?.map((phase: any) => {
                                return { id: phase?.id, name: phase?.name };
                              })}
                              displayFunc={(item) => item.name}
                              selectedItems={selectedPhases}
                              displayValue={
                                otherPhases.filter(
                                  //@ts-ignore
                                  (
                                    item: any //@ts-ignore
                                  ) => item?.name === selectedPhases?.name
                                )[0]?.name
                              }
                              onSelectItem={(item) => {
                                //   @ts-ignore
                                handleOnSelectPhase(item);
                              }}
                              //selectedItems={selectedLabels}
                              enableHoverBackground
                              hideSelectedItems
                              // onMultiSelectBlur={() => {
                              //   handleOnBlur("label");
                              // }}
                            />
                          </div>
                        </div>
                      </Modal.Body>

                      <Modal.Footer className="justify-content-center">
                        <Button
                          variant="secondary"
                          onClick={() => {
                            closeModal();
                            setSelectedPhases([]);
                          }}
                        >
                          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          {!loading &&
                            intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_TAGS_LOADING_MESSAGE",
                              })}{" "}
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
              {activePhase?.condition !== "start" &&
                activePhase?.display_order !== null && (
                  <div className="card">
                    <div className="card-body">
                      {/* questionirre */}
                      <div className="d-flex  flex-column">
                        {activePhase?.condition !== "start" &&
                          activePhase?.display_order !== null && (
                            <div className="d-flex flex-stack mb-4">
                              <div className="fw-bold fs-6">
                                {intl.formatMessage({
                                  id: "PIPELINE_PROJECTS_QUESTION1",
                                })}
                              </div>
                              <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-4">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="notifications"
                                  value=""
                                  checked={phaseIsEndPhase === 1 ? true : false}
                                  style={{ width: "45px", height: "22px" }}
                                  onChange={(e) => {
                                    setPhaseIsEndPhase(
                                      e.target.checked ? 1 : 0
                                    );
                                    handleIsEndpHase(e.target.checked ? 1 : 0);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                      </div>
                      <div>
                        {activePhase?.condition === "start" ||
                        (activePhase?.condition === "end" &&
                          activePhase?.display_order === null)
                          ? null
                          : pipeline?.transition_type === "step-by-step" && (
                              <div className="d-flex flex-stack mb-2">
                                <div className="fw-bold fs-6">
                                  {intl.formatMessage({
                                    id: "PIPELINE_PROJECTS_QUESTION3",
                                  })}
                                </div>
                                <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-4">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="notifications"
                                    value=""
                                    checked={isChecked}
                                    style={{ width: "45px", height: "22px" }}
                                    onChange={(e) => {
                                      handleToggleChange(e.target.checked);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                      </div>
                      <div className="mb-4">
                        {activePhase?.need_approvals === 1 && isChecked && (
                          <div className="  flex-wrap pt-1 borderNone">
                            {/* Primary Users */}
                            <div>
                              <div className="fw-bold fs-6">
                                {intl.formatMessage({
                                  id: "PIPELINE_APPROVAL_PRIMARY_USERS",
                                })}
                              </div>
                              {activePhase?.phaseApprovers &&
                              activePhase?.phaseApprovers.length > 0 ? (
                                <div className="symobleDetails d-flex justify-content-between align-items-center ms-3">
                                  <div className="symbol-group symbol-hover flex-nowrap">
                                    {activePhase?.phaseApprovers?.map(
                                      (user, index) => {
                                        if (user?.condition === "primary") {
                                          return (
                                            <>
                                              <div
                                                key={index}
                                                className="symbol symbol-35px symbol-circle"
                                                style={{ position: "relative" }}
                                                onMouseOver={() => {
                                                  const tooltipEle =
                                                    document.getElementById(
                                                      `role-user-tooltip-${index}${activePhase?.id}`
                                                    );
                                                  if (tooltipEle) {
                                                    tooltipEle.style.display =
                                                      "flex";
                                                  }
                                                }}
                                                onMouseOut={() => {
                                                  const tooltipEle =
                                                    document.getElementById(
                                                      `role-user-tooltip-${index}${activePhase?.id}`
                                                    );
                                                  if (tooltipEle) {
                                                    tooltipEle.style.display =
                                                      "none";
                                                  }
                                                }}
                                              >
                                                <div
                                                  id={`role-user-tooltip-${index}${activePhase?.id}`}
                                                  className="position-absolute bg-light px-3 py-2 fs-8"
                                                  style={{
                                                    display: "none",
                                                    backgroundColor: "white",
                                                    top: -60,
                                                    borderRadius: 5,
                                                  }}
                                                >
                                                  {`${user.firstname}  ${user.lastname}`}
                                                </div>

                                                <div>
                                                  <span className="symbol-label bg-warning text-inverse-warning fw-bold position-relative">
                                                    {(user.firstname ||
                                                      " ")[0].toUpperCase()}
                                                  </span>
                                                  <span
                                                    className="position-absolute bg-secondary border d-flex align-items-center"
                                                    style={{
                                                      top: "-5px",
                                                      left: "-5px",
                                                      borderRadius: "20px",
                                                      height: "17px",
                                                      width: "17px",
                                                    }}
                                                    onClick={() => {
                                                      if (
                                                        activePhase?.phaseApprovers &&
                                                        activePhase
                                                          ?.phaseApprovers
                                                          .length > 0 &&
                                                        activePhase?.phaseApprovers.filter(
                                                          (temp) =>
                                                            temp?.condition ===
                                                            "primary"
                                                        ).length > 0
                                                      ) {
                                                        setDeleteModalLoading(
                                                          false
                                                        );
                                                        showDeleteConfirmModal(
                                                          intl.formatMessage({
                                                            id: "APPROVER_PRIMARY_DELETE_CONFIRM_MESSAGE",
                                                          }),
                                                          () => {
                                                            deleteApprover(
                                                              user
                                                            );
                                                            if (
                                                              activePhase
                                                                ?.phaseApprovers
                                                                ?.length === 1
                                                            ) {
                                                              setIsChecked(
                                                                false
                                                              );
                                                            }
                                                          }
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <SVGICON
                                                      src={CloseIcon}
                                                      className="svg svg-icon "
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                              {index ===
                                                activePhase?.phaseApprovers
                                                  .length -
                                                  1 && (
                                                <div
                                                  className="symbol symbol-35px symbol-circle "
                                                  onClick={() => {
                                                    setOpenUserModal({
                                                      show: true,
                                                      type: "primary",
                                                      approvalValue:
                                                        activePhase?.need_approvals ===
                                                        1
                                                          ? 1
                                                          : 0,
                                                    });
                                                  }}
                                                >
                                                  <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                                    <SVGICON
                                                      src={PlusIcon}
                                                      className="svg-icon-2 svg-icon-white"
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                            </>
                                          );
                                        } else {
                                          return (
                                            <>
                                              {index ===
                                                activePhase?.phaseApprovers
                                                  .length -
                                                  1 && (
                                                <div
                                                  className="symbol symbol-35px symbol-circle "
                                                  onClick={() => {
                                                    setOpenUserModal({
                                                      show: true,
                                                      type: "primary",
                                                      approvalValue:
                                                        activePhase?.need_approvals ===
                                                        1
                                                          ? 1
                                                          : 0,
                                                    });
                                                  }}
                                                >
                                                  <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                                    <SVGICON
                                                      src={PlusIcon}
                                                      className="svg-icon-2 svg-icon-white"
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                            </>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div
                                    className="symbol symbol-35px symbol-circle "
                                    role="button"
                                    onClick={() => {
                                      setOpenUserModal({
                                        show: true,
                                        type: "primary",
                                        approvalValue:
                                          activePhase?.need_approvals === 1
                                            ? 1
                                            : 0,
                                      });
                                    }}
                                  >
                                    <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                      <SVGICON
                                        src={PlusIcon}
                                        className="svg-icon-2 svg-icon-white"
                                      />
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                            {/* Secondary Users */}
                            <div>
                              {activePhase?.phaseApprovers &&
                                activePhase?.phaseApprovers.length > 0 &&
                                activePhase?.phaseApprovers.filter(
                                  (temp) => temp?.condition === "primary"
                                ).length > 0 && (
                                  <>
                                    <div className="fw-bold fs-6">
                                      {intl.formatMessage({
                                        id: "PIPELINE_APPROVAL_SECONDARY_USERS",
                                      })}
                                    </div>
                                    <div className="symobleDetails d-flex justify-content-between align-items-center ms-3">
                                      <div className="symbol-group symbol-hover flex-nowrap">
                                        {activePhase?.phaseApprovers?.map(
                                          (user, index) => {
                                            if (
                                              user?.condition === "secondary"
                                            ) {
                                              return (
                                                <>
                                                  <div
                                                    key={index}
                                                    className="symbol symbol-35px symbol-circle"
                                                    style={{
                                                      position: "relative",
                                                    }}
                                                    onMouseOver={() => {
                                                      const tooltipEle =
                                                        document.getElementById(
                                                          `role-user-tooltip-${index}${activePhase?.id}`
                                                        );
                                                      if (tooltipEle) {
                                                        tooltipEle.style.display =
                                                          "flex";
                                                      }
                                                    }}
                                                    onMouseOut={() => {
                                                      const tooltipEle =
                                                        document.getElementById(
                                                          `role-user-tooltip-${index}${activePhase?.id}`
                                                        );
                                                      if (tooltipEle) {
                                                        tooltipEle.style.display =
                                                          "none";
                                                      }
                                                    }}
                                                  >
                                                    <div
                                                      id={`role-user-tooltip-${index}${activePhase?.id}`}
                                                      className="position-absolute bg-light px-3 py-2 fs-8"
                                                      style={{
                                                        display: "none",
                                                        backgroundColor:
                                                          "white",
                                                        top: -60,
                                                        borderRadius: 5,
                                                      }}
                                                    >
                                                      {`${user.firstname}  ${user.lastname}`}
                                                    </div>

                                                    <div>
                                                      <span className="symbol-label bg-warning text-inverse-warning fw-bold position-relative">
                                                        {(user.firstname ||
                                                          " ")[0].toUpperCase()}
                                                      </span>
                                                      <span
                                                        className="position-absolute bg-secondary border d-flex align-items-center"
                                                        style={{
                                                          top: "-5px",
                                                          left: "-5px",
                                                          borderRadius: "20px",
                                                          height: "17px",
                                                          width: "17px",
                                                        }}
                                                        onClick={() => {
                                                          setDeleteModalLoading(
                                                            false
                                                          );
                                                          showDeleteConfirmModal(
                                                            intl.formatMessage({
                                                              id: "APPROVER_SECONDARY_DELETE_CONFIRM_MESSAGE",
                                                            }),
                                                            () => {
                                                              deleteApprover(
                                                                user
                                                              );
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        <SVGICON
                                                          src={CloseIcon}
                                                          className="svg svg-icon "
                                                        />
                                                      </span>
                                                    </div>
                                                  </div>
                                                  {index ===
                                                    activePhase?.phaseApprovers
                                                      .length -
                                                      1 && (
                                                    <div
                                                      className=""
                                                      onClick={() => {
                                                        setOpenUserModal({
                                                          show: true,
                                                          type: "secondary",
                                                          approvalValue:
                                                            activePhase?.need_approvals ===
                                                            1
                                                              ? 1
                                                              : 0,
                                                        });
                                                      }}
                                                    >
                                                      <div className="symbol symbol-35px symbol-circle">
                                                        <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                                          <SVGICON
                                                            src={PlusIcon}
                                                            className="svg-icon-2 svg-icon-white"
                                                          />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            } else {
                                              return (
                                                <>
                                                  {index ===
                                                    activePhase?.phaseApprovers
                                                      .length -
                                                      1 && (
                                                    <div
                                                      className=""
                                                      onClick={() => {
                                                        setOpenUserModal({
                                                          show: true,
                                                          type: "secondary",
                                                          approvalValue:
                                                            activePhase?.need_approvals ===
                                                            1
                                                              ? 1
                                                              : 0,
                                                        });
                                                      }}
                                                      role="button"
                                                    >
                                                      <div className="symbol symbol-35px symbol-circle">
                                                        <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                                          <SVGICON
                                                            src={PlusIcon}
                                                            className="svg-icon-2 svg-icon-white"
                                                          />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            }
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </DndProvider>
    </>
  );
};

export default EditPipeline;
