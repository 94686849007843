import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { codeVerification } from "../redux/AuthCRUD";
import { useIntl } from "react-intl";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";

const initialValues = {
  code: "",
};

const codeSchema = Yup.object().shape({
  code: Yup.string().required("please enter the code"),
});
export interface CodeVerifyProps {
  email: string;
  submitted: () => void;
}

export function CodeVerification({ email, submitted }: CodeVerifyProps) {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const intl = useIntl();
  const formik = useFormik({
    initialValues,
    validationSchema: codeSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      // console.log(email);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setHasErrors(undefined);
      setTimeout(() => {
        codeVerification(email, values.code)
          .then(() => {
            setHasErrors(false);
            successToast(
              intl.formatMessage({ id: "CODE_VERIFICATION" })
            );
            submitted();
          })
          .catch(() => {
            setHasErrors(true);
            setSubmitting(false);
            setStatus("The code is incorrect");
            errorToast(
              intl.formatMessage({ id: "CODE_VERIFICATION_FAILED" })
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }, 1000);
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_code_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">
            {intl.formatMessage({ id: "AUTH_CODE_VERIFICATION_TITLE" })}
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            {intl.formatMessage({ id: "AUTH_CODE_VERIFICATION_CODE_MESSAGE" })}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {intl.formatMessage({
                id: "AUTH_CODE_VERIFICATION_ERROR_MESSAGE",
              })}
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            {intl.formatMessage({ id: "AUTH_CODE_VERIFICATION_CODE" })}
          </label>
          <input
            type="string"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("code")}
            className={clsx(
              "form-control form-control-lg form-control-solid"
              //   {'is-invalid': formik.touched.code && formik.errors.code},
              //   {
              //     'is-valid': formik.touched.code && !formik.errors.code,
              //   }
            )}
          />
          {formik.touched.code && formik.errors.code && (
            <div className="text-danger">
              <span role="alert">{formik.errors.code}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-light-primary fw-bolder me-4"
          >
            {!loading && intl.formatMessage({ id: "SAVE_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "WORDPRESS_LOADING_MESSAGE" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
            >
              {intl.formatMessage({ id: "CANCEL_BUTTON" })}
            </button>
          </Link>{" "}
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}
