import React, { useEffect, useState } from "react";
import { GoogleAccountType } from "../types/googleResponseType";
import { deleteGoogleAccount, getGoogleAccountsList } from "../redux/GoogleAPI";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { useIntl } from "react-intl";
import { SVGICON } from "../../../../_metronic/helpers";

// images
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import AddGoogleAccountModel from "./AddGoogleAccountModel";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import NoItemsFound from "../../../sharedComponents/NoItemsFound/NoItemsFound";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";

interface ModalProps {
  show: boolean;
  type?: string;
  account?: GoogleAccountType;
}

const GoogleList = () => {
  // context
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const intl = useIntl();

  // state
  const [allGoogleAccounts, setAllGoogleAccounts] = useState<
    GoogleAccountType[]
  >([]);
  const [allGoogleAccountsAPILoading, setAllGoogleAccountsAPILoading] =
    useState<boolean>(false);
  const [displayAccountsList, setDisplayAccountsList] = useState<
    GoogleAccountType[]
  >([]);
  const [showGoogleAccountModal, setShowGoogleAccountModal] =
    useState<ModalProps>({
      show: false,
    });
  const [searchText, setSearchText] = useState("");

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "google"
  );

  const getGoogleAccountsListAPI = () => {
    setAllGoogleAccountsAPILoading(true);
    getGoogleAccountsList()
      .then(({ data: { googleDetails = [] } }) => {
        setAllGoogleAccounts(googleDetails);
      })
      .finally(() => {
        setAllGoogleAccountsAPILoading(false);
      });
  };

  useEffect(() => {
    getGoogleAccountsListAPI();

    return () => {
      setAllGoogleAccounts([]);
    };
  }, []);

  const updateDisplayAccountsList = () => {
    const updatedAcountsList = allGoogleAccounts.filter((account) => {
      if (
        (account.app_name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayAccountsList(updatedAcountsList);
  };

  useEffect(() => {
    updateDisplayAccountsList();
  }, [allGoogleAccounts, searchText]);

  const closeModal = () => {
    setShowGoogleAccountModal({
      show: false,
    });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const deleteAccount = (id: number) => {
    setDeleteModalLoading(true);
    deleteGoogleAccount(id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "GOOGLE_ACCOUNT_DELETE_SUCCESS" })
        );
      })
      .catch(() => {
        errorToast(intl.formatMessage({ id: "GOOGLE_ACCOUNT_DELETE_FAILURE" }));
      })
      .finally(() => {
        getGoogleAccountsListAPI();
        hideDeleteConfirmModal();
        setDeleteModalLoading(false);
      });
  };

  return (
    <div className="card card-flush py-4 mt-7">
      {showGoogleAccountModal.show && (
        <AddGoogleAccountModel
          modalProps={showGoogleAccountModal}
          closeModal={closeModal}
          getGoogleAccountsListAPI={getGoogleAccountsListAPI}
        />
      )}
      <div className="card-header">
        <div className="card-title">
          <h2>{intl.formatMessage({ id: "GOOGLE_HEADER_PROJECTS" })}</h2>
        </div>
      </div>

      <div className="card-header border-0  d-flex flex-row justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <SVGICON
            src={SearchIcon}
            className="svg-icon svg-icon-1 position-absolute ms-6"
          />
          <input
            type="text"
            className="form-control form-control-solid w-250px ps-15"
            placeholder={intl.formatMessage({
              id: "GOOGLE_ACCOUNT_SEARCH_PLACEHOLDER",
            })}
            onChange={(e) => {
              onSearchTextChange(e.target.value);
            }}
          />
        </div>
        {crudPermission?.create && (
          <div className="d-flex justify-content-end align-items-center">
            <button
              className="btn  btn-primary"
              onClick={() => {
                setShowGoogleAccountModal({
                  show: true,
                  type: "new",
                });
              }}
            >
              {intl.formatMessage({ id: "GOOGLE_ADD_ACCOUNT" })}
            </button>
          </div>
        )}
      </div>

      <div className="card-body py-3">
        <div className="table-responsive">
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "GOOGLE_NAME" })}
                </th>
                <th className="min-w-125px">
                  {intl.formatMessage({ id: "GOOGLE_CLIENT_ID" })}
                </th>
                <th className="min-w-100px"></th>
              </tr>
            </thead>
            <tbody>
              {displayAccountsList.map((account, i) => {
                return (
                  <tr key={i}>
                    {/* App name */}
                    <td>
                      <span className="text-gray-800 fw-bold text-hover-primary">
                        {account.app_name}
                      </span>
                    </td>

                    <td>
                      <span className="text-primary fw-bold fs-6">
                        {account.client_id}
                      </span>
                    </td>

                    {/* actions */}
                    {(crudPermission?.edit || crudPermission?.delete) && (
                      <td>
                        <div className="d-flex justify-content-end">
                          {crudPermission?.edit && (
                            <button
                              className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
                              onClick={() => {
                                setShowGoogleAccountModal({
                                  show: true,
                                  type: "edit",
                                  account,
                                });
                              }}
                            >
                              <SVGICON src={EditIcon} className="svg-icon-3" />
                            </button>
                          )}
                          {crudPermission?.delete && (
                            <button
                              className="btn btn-icon btn-light btn-active-light-primary btn-sm"
                              onClick={() => {
                                showDeleteConfirmModal(
                                  intl.formatMessage({
                                    id: "GOOGLE_DELETE_ACCOUNT_CONFIRM",
                                  }),
                                  () => {
                                    deleteAccount(account.id);
                                  }
                                );
                              }}
                            >
                              <SVGICON
                                src={DeleteIcon}
                                className="svg-icon-3"
                              />
                            </button>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}

              {allGoogleAccountsAPILoading && (
                <tr>
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <SimpleLoader />
                  </td>
                </tr>
              )}
              {/* no data */}
              {!allGoogleAccountsAPILoading &&
                displayAccountsList.length === 0 && (
                  <NoItemsFound languageKey="NO_ITEMS_FOUND" />
                )}
            </tbody>
          </table>

          {/* end::Table */}
          {/* {displayAccountsList.length > 0 && (
        <Pagination
          totalPages={Math.ceil(displayUsers.length / 10)}
          activePage={activePage}
          onPageClick={onPageClick}
        />
      )} */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  );
};

export default GoogleList;
