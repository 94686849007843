import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";
import ActionsDropdown from "../../../../sharedComponents/ActionsDropdown/ActionsDropdown";

import InvoicePaymentModal from "./InvoicePaymentModal";

import { deleteInvoice, forceDeleteInvoice, cancelInvoice } from "../redux";
import { InvoiceType } from "../types/InvoicesTypes";

interface Props {
  order: InvoiceType;
  loading: boolean;
  getOrdersListAPI: (
    status: string,
    ordersSearchText: string,
    itemsPerPage: number,
    activePage: number
  ) => void;
  tabActiveIndex: number;
  status: string;
  itemsPerPage: number;
  activePage: number;
  ordersSearchText: string;
  getOrdersTrashListAPI: () => void;
  handleRestoreOrder: (order: InvoiceType) => void;
  paginatedItems: InvoiceType[];
  fromInvoice?: any;
  layoutFooterDetails: any;
  getInvoicesListAPI: () => void;
  setLoading: any;
}

const InvoicesListActionsDropDown: FC<Props> = ({
  order,
  getOrdersListAPI,
  tabActiveIndex,
  status,
  itemsPerPage,
  activePage,
  ordersSearchText,
  getOrdersTrashListAPI,
  handleRestoreOrder,
  paginatedItems,
  layoutFooterDetails,
  getInvoicesListAPI,
  setLoading,
}) => {
  // const intl = useIntl()

  const history = useHistory();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const intl = useIntl();

  const [showpaymentModal, setShowPaymentModal] = useState<boolean>(false);

  const onEditClick = () => {
    history.push(`/sales/invoices/edit`, { order });
  };

  const deleteExistingOrder = (id: number) => {
    setDeleteModalLoading(true);
    deleteInvoice(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "INVOICE_SINGLE_INVOICE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "INVOICE_SINGLE_INVOICE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOrdersListAPI?.(
          status,
          ordersSearchText,
          itemsPerPage,
          paginatedItems?.length === 1 && activePage - 1 > 1
            ? activePage - 1
            : activePage
        );
      });
  };

  const forceDeleteExistingOrder = (id: number) => {
    setDeleteModalLoading(true);
    forceDeleteInvoice(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "INVOICE_SINGLE_INVOICE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "INVOICE_SINGLE_INVOICE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOrdersTrashListAPI?.();
      });
  };

  const dropdownOptions = [
    ...(tabActiveIndex === 1
      ? [
          {
            name: intl.formatMessage({ id: "EDIT_BUTTON" }),
            onClick: onEditClick,
          },
          {
            name: intl.formatMessage({ id: "DELETE_BUTTON" }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "INVOICE_DELETE_CONFIRMATION_MESSAGE",
                }),
                () => {
                  deleteExistingOrder(order?.id);
                }
              );
            },
            className: "text-danger",
          },
        ]
      : []),
    ...(tabActiveIndex === 7
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_RESTORE" }),
            onClick: () => {
              handleRestoreOrder(order);
            },
          },
          {
            name: intl.formatMessage({ id: "COMMON_DELETE_PERMANENT" }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "INVOICE_SINGLE_DELETE_CONFIRM_MESSAGE",
                }),
                () => {
                  forceDeleteExistingOrder(order?.id);
                }
              );
            },
            className: "text-danger",
          },
        ]
      : []),
    ...(order?.status === "open" ||
    order?.status === "partiallyPaid" ||
    order?.status === "overDue"
      ? [
          {
            name: intl.formatMessage({
              id: "PAYMENTS",
            }),
            onClick: () => {
              setShowPaymentModal(true);
            },
          },
        ]
      : []),
    ...(order?.status === "open" ||
    order?.status === "paid" ||
    order?.status === "partiallyPaid" ||
    order?.status === "overDue"
      ? [
          {
            name: intl.formatMessage({
              id: "CANCEL",
            }),
            onClick: () => {
              cancelInvoice(order?.id)
                .then(() => {
                  successToast(
                    intl.formatMessage({ id: "INVOICE_UPDATE_SUCCESS_MESSAGE" })
                  );
                })
                .catch((error) => {
                  errorToast(
                    intl.formatMessage({ id: "INVOICE_UPDATE_FAILURE_MESSAGE" })
                  );
                })
                .finally(() => {
                  getInvoicesListAPI();
                });
            },
          },
        ]
      : []),
    ...((order?.status === "open" ||
      order?.status === "paid" ||
      order?.status === "partiallyPaid" ||
      order?.status === "overDue" ||
      order?.status === "cancel") &&
    // @ts-ignore
    order?.offer_number
      ? [
          {
            name: intl.formatMessage({
              id: "GO_TO_OFFERS",
            }),
            onClick: () => {
              history.push(`/sales/offers/edit`, {
                offer: {
                  // @ts-ignore
                  id: order?.offer_id,
                  sales_orders_details: {
                    // @ts-ignore
                    offer_layout_id: order?.offer_layout_id,
                  },
                },
              });
            },
          },
        ]
      : []),
  ];

  if (dropdownOptions?.length > 0) {
    return (
      <>
        {showpaymentModal && (
          <InvoicePaymentModal
            show={showpaymentModal}
            closeModal={() => {
              setShowPaymentModal(false);
            }}
            invoice={order}
            getOrderDetailsAPI={getInvoicesListAPI}
          />
        )}

        <div className="dropdown d-flex flex-row justify-content-end">
          {/* @ts-ignore */}
          <ActionsDropdown options={dropdownOptions} />
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default InvoicesListActionsDropDown;
