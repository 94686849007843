import axios from "axios";
import { settingsDetailsTypeResponseType } from "../types/SalesSettingstype";

export function getSalesSettings() {
  return axios.get<settingsDetailsTypeResponseType>("/sales/settings");
}

export function updateSalesSettingsDetails(
  id: number,
  answer: string | number | null
) {
  return axios.post(`/sales/settings/${id}`, { answer });
}
export function updateNumberSettingsDetails(data: any) {
  return axios.post(`/sales/settings/numbers`, { ...data });
}
