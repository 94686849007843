import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import config from "../../config";
import LayoutList from "./SalesLayout/components/LayoutList";
import EditLayout from "./SalesLayout/components/EditLayout";
import SalesNotes from "./SalesNotes/components/SalesNotes";
import SalesSetting from "./SalesSettings/components/SalesSettings";

const SalesMasterPage = () => {
  const intl = useIntl();

  const salesLayoutsCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MENU_SALES_MASTERDATA_LAYOUT" }),
      path: "/sales/master-data/layout",
      isSeparator: false,
      isActive: false,
    },
  ];

  const salesNotesBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MASTERDATA_SALES_NOTES_PAGE_TITLE" }),
      path: "/sales/master-data/notes",
      isSeparator: false,
      isActive: false,
    },
  ];

  const salesSettingsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MASTERDATA_SALES_SETTINGS_PAGE_TITLE" }),
      path: "/sales/master-data/settings",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {config.APP_NAME}-{intl.formatMessage({ id: "MENU_SALES" })}
        </title>
      </Helmet>
      <Switch>
        <Route exact path="/sales/master-data/layout/overview">
          <PageTitle breadcrumbs={salesLayoutsCrumbs}>
            {intl.formatMessage({
              id: "MENU_SALES_MASTERDATA_LAYOUT",
            })}
          </PageTitle>
          <LayoutList />
        </Route>

        <Route exact path="/sales/master-data/layout/edit">
          <PageTitle breadcrumbs={salesLayoutsCrumbs}>
            {intl.formatMessage({
              id: "MENU_SALES_MASTERDATA_LAYOUT",
            })}
          </PageTitle>
          <EditLayout />
        </Route>

        <Route exact path="/sales/master-data/notes/overview">
          <PageTitle breadcrumbs={salesNotesBreadCrumbs}>
            {intl.formatMessage({ id: "MENU_SALES_MASTERDATA_NOTES" })}
          </PageTitle>
          <SalesNotes />
        </Route>

        <Route exact path="/sales/master-data/settings/overview">
          <PageTitle breadcrumbs={salesSettingsBreadCrumbs}>
            {intl.formatMessage({ id: "MENU_SALES_MASTERDATA_SETTINGS" })}
          </PageTitle>
          <SalesSetting />
        </Route>

        {/* Default for Layout */}
        <Redirect
          from="/sales/master-data/layout"
          exact={true}
          to="/sales/master-data/layout/overview"
        />
        {/* Default for Layout Edit */}
        <Redirect
          from="/sales/master-data/layout/edit"
          exact={true}
          to="/sales/master-data/layout/overview"
        />

        {/* Default for Notes */}
        <Redirect
          from="/sales/master-data/notes"
          exact={true}
          to="/sales/master-data/notes/overview"
        />
        {/* Default for Settings */}
        <Redirect
          from="/sales/master-data/settings"
          exact={true}
          to="/sales/master-data/settings/overview"
        />
        {/* Default redirect to */}
        <Redirect to="/sales/master-data/layout/overview" />
      </Switch>
    </div>
  );
};

export default SalesMasterPage;
