import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

// custom
import { Route, Switch } from "react-router";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import config from "../../config";
import RolesAndResources from "./components/RolesAndResources";

const RolesAndPermissionsPage = () => {
  const intl = useIntl();

  const userManagementBreadCrumbs: Array<PageLink> = [
    // {
    //   title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
    {
      title: intl.formatMessage({ id: "ROLES_AND_PERMISSIONS_TITLE" }),
      path: "/system/roles-permissions",
      isSeparator: false,
      isActive: false,
    },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {config.APP_NAME}-{intl.formatMessage({ id: "USERS_TITLE" })}
        </title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/system/roles-permissions">
          <PageTitle
            breadcrumbs={[
              ...userManagementBreadCrumbs,
              // {
              //   title: "Overview",
              //   path: "roles-permissions",
              //   isSeparator: false,
              //   isActive: true,
              // },
            ]}
          >
            {intl.formatMessage({ id: "ROLES_AND_PERMISSIONS_TITLE" })}
          </PageTitle>
          <RolesAndResources />
        </Route>

        {/* <Redirect from='/system/user' exact={true} to='/system/user/overview' /> */}
        {/* <Redirect to='/system/roles-permissions' /> */}
      </Switch>
    </div>
  );
};

export default RolesAndPermissionsPage;
