import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import _ from "lodash";

// constants
import Constants from "../../../../config/Constants";

// icons
import { SVGICON } from "../../../../../_metronic/helpers";
import SearchIcon from "../../../../../_metronic/assets/icons/search.svg";
import notFound from "../../../../../_metronic/assets/icons/notFound.png";
import PlusIcon from "../../../../../_metronic/assets/icons/plus.svg";
import DeleteIcon from "../../../../../_metronic/assets/icons/delete.svg";
import EditIcon from "../../../../../_metronic/assets/icons/edit.svg";

// shared components
import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";
import SimpleLoader from "../../../../sharedComponents/Loader/SimpleLoader";

// components
import AddEditNotesModal from "./AddEditSalesNotesModal";
import Checkbox from "../../../../sharedComponents/Checkbox/Checkbox";
import Pagination from "../../../../sharedComponents/pagination/Pagination";

// api handlers
import {
  deleteNotes,
  getNotesList,
  multiDeleteNotes,
} from "../redux/SalesNotesAPI";
import NoItemsFound from "../../../../sharedComponents/NoItemsFound/NoItemsFound";
import { errorToast, successToast } from "../../../../sharedComponents/toasts/Toasts";

interface NotesTypeProps {
  isSelectionModal?: boolean;
  onHandleApply?: (id: number) => void;
  selectedItem?: any;
}

interface ModalProps {
  show: boolean;
  type?: string;
  notes?: any;
}

const SalesNotes: FC<NotesTypeProps> = ({
  isSelectionModal,
  onHandleApply,
  selectedItem,
}) => {
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const intl = useIntl();
  const [notesList, setNotesList] = useState<any[]>([]);
  const [displayNotesList, setDisplayNotesList] = useState<any[]>([]);

  const [templateLoading, setTemplateLoading] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState<ModalProps>({
    show: false,
  });

  const [searchText, setSearchText] = useState("");
  const [checkedNotes, setCheckedNotes] = useState<any[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );

  const getNotesListAPI = () => {
    setTemplateLoading(true);
    setCheckedNotes([]);
    getNotesList()
      // @ts-ignore
      .then(({ data: { data } }) => {
        setNotesList(data);
      })
      .catch((error) => console.log(error?.response?.data?.message))
      .finally(() => {
        setTemplateLoading(false);
      });
  };

  useEffect(() => {
    getNotesListAPI();
    if (selectedItem) {
      setCheckedNotes([selectedItem]);
    }
    return () => {
      setNotesList([]);
    };
  }, []);

  const updateDisplayNotesList = () => {
    const updatedNotesList = notesList.filter((note) => {
      if (
        (note.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    setDisplayNotesList(updatedNotesList);
  };

  useEffect(() => {
    updateDisplayNotesList();

    return () => {
      setDisplayNotesList([]);
    };
  }, [notesList, searchText]);

  const closeModal = () => {
    setShowNotesModal({
      show: false,
    });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const handleDeleteNote = (id: number) => {
    setDeleteModalLoading(true);
    deleteNotes(id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_NOTE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_NOTE_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getNotesListAPI();
        hideDeleteConfirmModal();
        setDeleteModalLoading(false);
      });
  };

  const onCheckedChange = (note: any) => {
    if (isSelectionModal) {
      setCheckedNotes([note]);
    } else {
      let checkedNotessCopy = _.clone(checkedNotes);
      const index = checkedNotessCopy.findIndex(
        (checkedNote) => checkedNote.id === note.id
      );
      if (index > -1) {
        checkedNotessCopy.splice(index, 1);
      } else {
        checkedNotessCopy.push(note);
      }
      setCheckedNotes(checkedNotessCopy);
    }
  };

  const isChecked = (note: any) => {
    const index = checkedNotes?.findIndex(
      (checkedHeader: any) => checkedHeader?.id === note?.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const handleMultiDeleteNotes = (id?: number) => {
    const ids = checkedNotes?.map((item) => item.id);
    setDeleteModalLoading(true);
    multiDeleteNotes(id ? [id] : ids)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_NOTES_MULTI_DELETE_SUCCESS_MESSAGE",
          })
        );
        setCheckedNotes([]);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "MASTERDATA_SALES_NOTES_MULTI_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getNotesListAPI();
      });
  };

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedList =
    displayNotesList && displayNotesList.length > 10
      ? displayNotesList.slice(startIndex, startIndex + itemsPerPage)
      : displayNotesList;

  useEffect(() => {
    if (paginatedList.length === 0 && displayNotesList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedList]);

  return (
    <div className="mt-7">
      {showNotesModal.show && (
        <AddEditNotesModal
          modalProps={showNotesModal}
          closeModal={closeModal}
          getNotesListAPI={getNotesListAPI}
        />
      )}

      <div className="card p-4 d-flex flex-row justify-content-between">
        <div className="d-flex flex-row seainrch-container ">
          <div className="d-flex align-items-center">
            <SVGICON
              src={SearchIcon}
              className="svg-icon svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              className="form-control form-control-solid w-250px ps-15 mx-4"
              placeholder={intl.formatMessage({
                id: "MASTERDATA_SALES_NOTES_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>
        {/* add new site button */}
        {checkedNotes?.length > 0 ? (
          <div>
            {!isSelectionModal && (
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedNotes.length}{" "}
                {intl.formatMessage({ id: "PARTNERS_LIST_SELECTED" })}
              </span>
            )}
            {isSelectionModal ? (
              <button
                className="btn  btn-primary"
                onClick={() => {
                  onHandleApply?.(checkedNotes?.[0]?.id);
                }}
              >
                {intl.formatMessage({
                  id: "APPLY_BUTTON",
                })}
              </button>
            ) : (
              <button
                className={"btn btn-danger"}
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "MASTERDATA_SALES_NOTES_DELETE_SELECTED_CONFIRM_MESSAGE",
                    }),
                    handleMultiDeleteNotes
                  );
                }}
              >
                {intl.formatMessage({
                  id: "PARTNERS_LIST_DELETE_SELECTED",
                })}
              </button>
            )}
          </div>
        ) : (
          !isSelectionModal && (
            <div>
              <button
                className="btn  btn-primary"
                onClick={() => {
                  setShowNotesModal({
                    show: true,
                    type: "new",
                  });
                }}
              >
                <SVGICON src={PlusIcon} className="svg-icon-2" />
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_NOTES_ADD_NEW_NOTES",
                })}
              </button>
            </div>
          )
        )}
      </div>

      {templateLoading && paginatedList.length === 0 && (
        <table style={{ display: "flex", justifyContent: "center" }}>
          <tbody>
            <tr style={{ display: "flex", justifyContent: "center" }}>
              <td valign="top" colSpan={7} className="dataTables_empty">
                <SimpleLoader />
              </td>
            </tr>
          </tbody>
        </table>
      )}

      <div
        style={
          isSelectionModal
            ? { overflowY: "auto", maxHeight: "calc(100vh - 400px)" }
            : {}
        }
      >
        {paginatedList &&
          paginatedList?.length > 0 &&
          paginatedList.map((notes, i) => {
            return (
              <div
                className="card mt-5 p-4 d-flex flex-row align-items-center justify-content-between"
                key={i}
              >
                <div className="d-flex flex-row align-items-center">
                  <div className="mx-5 form-check form-check-sm form-check-custom form-check-solid me-2">
                    <Checkbox
                      onChange={() => {
                        onCheckedChange(notes);
                      }}
                      checked={isChecked(notes)}
                    />
                  </div>

                  <div
                    className={`mx-5 fw-bold fs-5 min-w-150px text-hover-primary ${
                      isSelectionModal ? "pe-none" : "cursor-pointer"
                    } `}
                    onClick={() => {
                      setShowNotesModal({
                        show: true,
                        type: "edit",
                        notes,
                      });
                    }}
                  >
                    {notes.name}
                  </div>
                </div>

                <div className="d-flex">
                  {!isSelectionModal && (
                    <button
                      className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
                      onClick={() => {
                        setShowNotesModal({
                          show: true,
                          type: "edit",
                          notes: notes,
                        });
                      }}
                      disabled={isSelectionModal}
                    >
                      <SVGICON src={EditIcon} className="svg-icon-3" />
                    </button>
                  )}
                  {!isSelectionModal && (
                    <button
                      className="btn btn-icon btn-light btn-active-light-primary btn-sm"
                      onClick={() => {
                        showDeleteConfirmModal(
                          intl.formatMessage({
                            id: "MASTERDATA_SALES_NOTES_DELETE_CONFIRM_MESSAGE",
                          }),
                          () => {
                            handleDeleteNote(notes.id);
                          }
                        );
                      }}
                      disabled={isSelectionModal}
                    >
                      <SVGICON src={DeleteIcon} className="svg-icon-3" />
                    </button>
                  )}
                </div>
              </div>
            );
          })}

        {displayNotesList.length > 0 && !templateLoading && (
          <div className="card mt-5 pe-3">
            <Pagination
              totalPages={Math.ceil(displayNotesList.length / itemsPerPage)}
              activePage={
                Math.ceil(displayNotesList.length / itemsPerPage) === 1
                  ? 1
                  : activePage
              }
              onPageClick={onPageClick}
              itemsPerPage={itemsPerPage}
              showItemsPerPage
              setItemsPerPage={(count) => {
                setItemsPerPage(count);
                setActivePage(1);
              }}
              noCard
            />
          </div>
        )}

        {paginatedList?.length === 0 && !templateLoading && (
          <div className="d-flex justify-content-center align-items-center">
            <NoItemsFound languageKey="NO_ITEMS_FOUND" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesNotes;
