import React, { FC, useRef, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";

// Components
import { DateRangePicker } from "react-date-range";
import { Button } from "react-bootstrap";

// css
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { CalenderDateSelectionTypes } from "../types/TasksPropsType";

export interface ModalComponentProps {
  filters: any;
  setTabFilters: (newFilters: any) => void;
  closeModal: () => void;
}

const DateRangeFilterModal: FC<ModalComponentProps> = ({
  filters,
  setTabFilters,
  closeModal,
}) => {
  const intl = useIntl();

  // refs
  const outsideHandlerRef = useRef(null);

  const initialRange = {
    startDate: filters?.start_date
      ? filters?.start_date
      : moment().subtract(15, "days").toDate(),
    endDate: filters?.end_date ? filters?.end_date : moment().toDate(),
    key: "selection",
  };

  // state
  const [tempSelectedDateRange, setTempSelectedDateRange] =
    useState<CalenderDateSelectionTypes>(initialRange);

  const handleSubmit = () => {
    if (tempSelectedDateRange.key === "selection") {
      const { startDate, endDate } = tempSelectedDateRange;
      setTabFilters({
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      });
    }
    closeModal();
  };

  return (
    <OutsideClickHandler
      ref={outsideHandlerRef}
      onOutsideClick={() => {
        closeModal();
      }}
    >
      <div
        className="position-fixed d-flex flex-column"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50% , -50%)",
          zIndex: 999,
          boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
          backgroundColor: "white",
        }}
      >
        <div className="d-flex justify-content-end">
          <Button
            variant="secondary"
            size="sm"
            className="my-4"
            onClick={closeModal}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="my-4 mx-4 w-100px"
            onClick={handleSubmit}
          >
            {intl.formatMessage({
              id: "TASKS_LIST_FILTER_DATE_RANGE_APPLY",
            })}
          </Button>
        </div>

        <DateRangePicker
          onChange={(item) => {
            if (item?.selection) {
              setTempSelectedDateRange({ ...item?.selection });
            }
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          // @ts-ignore
          ranges={[tempSelectedDateRange]}
          direction={window.innerWidth < 1250 ? "vertical" : "horizontal"}
        />
      </div>
    </OutsideClickHandler>
  );
};

export default DateRangeFilterModal;
