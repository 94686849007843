import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TabType, panels } from "../types/TabsResponseType";
import { useIntl } from "react-intl";
import { SVGICON } from "../../../../_metronic/helpers";
import InfoIcon from "../../../../_metronic/assets/icons/info.svg";
import SVG from "react-inlinesvg";
import { FieldType } from "../types/FieldsReponseTypes";
import { FileUpload } from "../../products/components/ProductDocuments";
import clsx from "clsx";
import DateTimePicker from "../../../sharedComponents/dateTimePicker/DateTimePicker";
import moment from "moment";
import SunEditorComponent from "../../../sharedComponents/textEditor/SunEditor";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { DealPipelineDetailsType } from "../../deals/types/DealResponseType";
import SelectProductCategories from "../../deals/components/SelectProductCategories";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import SelectProduct from "../../deals/components/SelectProduct";
import { useDispatch } from "react-redux";
import { actions } from "../redux";
import {
  imageURLType,
  ProductType,
} from "../../products/types/ProductsResponseType";
import { getByProductID } from "../../products/redux/ProductsAPI";
import { Tooltip } from "react-tooltip";
import GoToIcon from "../../../../_metronic/assets/icons/goto.svg";
import CopyIcon from "../../../../_metronic/assets/icons/copy.svg";
import VideoIcon from "../../../../_metronic/assets/icons/video.svg";
import AudioIcon from "../../../../_metronic/assets/icons/audio.svg";
import PDFIcon from "../../../../_metronic/assets/icons/pdf.svg";
import PPTIcon from "../../../../_metronic/assets/icons/ppt.svg";
import PPTXIcon from "../../../../_metronic/assets/icons/pptx.svg";
import CSVIcon from "../../../../_metronic/assets/icons/Csv.svg";
import WORDIcon from "../../../../_metronic/assets/icons/word-icon.png";
import FileIcon from "../../../../_metronic/assets/icons/defaultFile.svg";
import TXTIcon from "../../../../_metronic/assets/icons/txt-icon.png";
import XLSX from "../../../../_metronic/assets/icons/xlsx.svg";
import { bytesToSize } from "../../../utils/BytesToSize";
import { getFile } from "../../filemanager/redux";
import ProductFilePreviewModal from "../../products/components/ProductFilePreviewModal";
import {
  successToast,
  warningToast,
} from "../../../sharedComponents/toasts/Toasts";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";

interface Props {
  currPhase: DealPipelineDetailsType | undefined;
  dealTab: TabType;
  type: string;
  onSubmitHandle?: (id: any, fId: any, value: any, value_json?: any) => void;
  deleteFile?: (
    pipelines_phases_tabs_id: number,
    tabs_panels_fields_id: number,
    file_id: number
  ) => void;
}

const TabPreview: FC<Props> = ({
  currPhase,
  dealTab,
  type,
  onSubmitHandle,
  deleteFile,
}) => {
  const { state = {} } = useLocation();
  const intl = useIntl();
  // @ts-ignore
  const tab: TabType = dealTab || {};

  // state
  const [currPanel, setCurrPanel] = useState<panels[]>(tab.panels);

  useEffect(() => {
    if (tab && tab.panels && tab.panels.length > 0) {
      const data = tab.panels.filter((panel) => {
        return panel.sections.some((section) => section.fields_data.length > 0);
      });
      if (data && data.length > 0) {
        setCurrPanel(data);
      }
    }
  }, [tab]);

  return (
    <div
      className="card"
      style={{
        marginTop: type === "edit" ? 0 : "13px",
      }}
    >
      <div className={type === "edit" ? "card-body  p-8" : "card-body   p-8"}>
        {currPanel &&
          currPanel.length > 0 &&
          currPanel.map((panel, index) => {
            return (
              <div
                className={clsx(
                  "card d-flex  accordion accordion-icon-toggle borderNone",
                  currPanel.length - 1 === index ? "mb-0" : "mb-8"
                )}
                key={index}
                id={`kt_accordion_${index}`}
              >
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id={`kt_accordion_${index}_header_${index}`}
                  >
                    <button
                      className="accordion-button fs-4 fw-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#kt_accordion_${index}_body_${index}`}
                      aria-expanded="true"
                      aria-controls={`kt_accordion_${index}_body_${index}`}
                      title={panel.heading}
                    >
                      <h4
                        className=""
                        style={{
                          maxWidth: "500px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          margin: 0,
                          color: "#3F64AD",
                        }}
                      >
                        {panel.heading}
                      </h4>
                    </button>
                  </h2>
                  <div
                    id={`kt_accordion_${index}_body_${index}`}
                    className="accordion-collapse collapse show"
                    aria-labelledby={`kt_accordion_${index}_header_${index}`}
                    data-bs-parent={`#kt_accordion_${index}`}
                  >
                    <div className="accordion-body">
                      <div className="card-body d-flex gap-12 p-0">
                        {panel.sections && panel.sections.length > 0 ? (
                          panel.sections.map((section, index) => (
                            <div style={{ flex: 1 }} key={index}>
                              {
                                section.fields_data &&
                                section.fields_data.length > 0
                                  ? section.fields_data.map((field, index) => (
                                      <div
                                        style={{
                                          flex: 1,
                                        }}
                                        key={index}
                                        className="mb-4"
                                      >
                                        <DynamicFormInput
                                          currPhase={currPhase}
                                          field={field}
                                          type={type}
                                          id={field.id}
                                          onSubmitHandle={(
                                            fId,
                                            value,
                                            value_json
                                          ) => {
                                            onSubmitHandle?.(
                                              field.pipeline_phase_tab_id,
                                              fId,
                                              value,
                                              value_json
                                            );
                                          }}
                                          index={index}
                                          deleteFile={(tId, fId, file_id) => {
                                            deleteFile?.(tId, fId, file_id);
                                          }}
                                        />
                                      </div>
                                    ))
                                  : null
                                // <div className="text-muted fs-6 d-flex justify-content-center align-items-center">
                                //   {intl.formatMessage({
                                //     id: "NO_FIELDS_MESSAGE",
                                //   })}
                                // </div>
                              }
                            </div>
                          ))
                        ) : (
                          <div className="text-muted fs-6 d-flex justify-content-center align-items-center">
                            {intl.formatMessage({ id: "NO_SECTION_MESSAGE" })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TabPreview;

interface FormProps {
  field: FieldType;
  type: string;
  id: number;
  onSubmitHandle: (fId: any, value: any, value_json?: any) => void;
  index: number;
  deleteFile: (tab_id: number, field_id: number, file_id: number) => void;
  currPhase: DealPipelineDetailsType | undefined;
}

export const DynamicFormInput: FC<FormProps> = ({
  field,
  type,
  id,
  onSubmitHandle,
  index,
  deleteFile,
  currPhase,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const fieldProductList = useSelector<RootState>(
    // @ts-ignore
    ({ contentMaster }) => contentMaster.fieldProductDetails
  ) as {
    product: ProductType;
    field_id: number | undefined;
  }[];
  const local = useLang();

  // state
  const [selectDropdown, setSelectDropdown] = useState<
    { id: number; name: string }[]
  >(
    (type === "edit" && field.type === "dropdown" && JSON.parse(field.value)) ||
      []
  );

  const [checkedBoxes, setCheckedBoxes] = useState<{ name: string }[]>(
    (type === "edit" && field.type === "checkbox" && JSON.parse(field.value)) ||
      []
  );

  const [previewLoading, setPreviewLoading] = useState<string | undefined>();
  const [previewFileURL, setPreviewFileURL] = useState<string>();
  const [previewFileExtension, setPreviewFileExtension] = useState<any>();
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

  const [dropdownOptions, setDropdownOptions] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);

  const [selectedFiles, setSelectedFiles] = useState<
    File[] | { id: number; url: string }[]
  >((type === "edit" && field.type === "uploader" && field.value_json) || []);

  const [openProductModal, setOpenProductModal] = useState<{
    show: boolean;
    category: GroupType[] | undefined;
    field_id: undefined | FieldType;
  }>({
    show: false,
    category: undefined,
    field_id: undefined,
  });
  const [openProductCategoryModal, setOpenProductCategoryModal] = useState<{
    show: boolean;
    field: undefined | FieldType;
  }>({
    show: false,
    field: undefined,
  });

  const [fileLoading, setFileLoading] = useState(false);

  const isDisabled =
    userData?.role?.id === 1 ||
    (currPhase && currPhase?.roles?.length === 0) ||
    (currPhase?.roles &&
      currPhase?.roles?.length > 0 &&
      currPhase?.roles.filter((role) => role.id === userData?.role?.id).length >
        0)
      ? false
      : true;

  const initialValues = {
    textBox:
      type === "edit" && field.type === "text" && id === field.id
        ? field.value || ""
        : "",
    link:
      type === "edit" && field.type === "link" && id === field.id
        ? field.value || ""
        : "",
    numberBox:
      type === "edit" && field.type === "number" && id === field.id
        ? field.value || ""
        : "",
    longText:
      type === "edit" && field.type === "longtext" && id === field.id
        ? field.value || ""
        : "",
    dateDetails:
      type === "edit" && field.type === "date" && id === field.id
        ? field.value && !isNaN(Date.parse(field.value))
          ? field.value
          : ""
        : "",
  };

  const AddNewFieldSchema = Yup.object().shape({
    textBox: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(
        field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })
      ),
    numberBox: Yup.string()
      .matches(
        /^-?\d*\.?\d*$/,
        intl.formatMessage({ id: "FIELD_TYPE_NUMBER_VALIDATION_MESSAGE" })
      ) // Allow negative and decimal numbers
      .required(
        field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })
      ),
    longText: Yup.string().required(
      field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })
    ),
    dateDetails: Yup.string()
      .required(
        field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })
      )
      .nullable(),
    // Link field with URL validation and nullable option
    link: Yup.string()
      .nullable() // Allows null values
      .url(intl.formatMessage({ id: "FIELD_TYPE_LINK_VALIDATION_MESSAGE" })),
    // .required(
    //   field.name + " " + intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })
    // ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema:
      (field.is_required === 1 || field.type === "link") && AddNewFieldSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {},
  });

  useEffect(() => {
    if (type && type === "edit") {
      formik.handleSubmit();
    }
  }, [field]);

  const handleFileUpload = (files: File[]) => {
    onSubmitHandle?.(field.tab_panels_fields_id, null, files);
  };

  const handleOnSelectItem = (item: { id: number; name: string }) => {
    const selectedItemsArr = selectDropdown || [];
    const index = selectedItemsArr.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    // if doesnt exist push item
    if (index === -1) {
      formik.handleSubmit();
      onSubmitHandle?.(
        field.tab_panels_fields_id,
        JSON.stringify([...selectDropdown, item])
      );
      setSelectDropdown(() => [...selectDropdown, item]);
    }
    // if already exists remove item
    else {
      selectDropdown.splice(index, 1);
      formik.handleSubmit();
      onSubmitHandle?.(
        field.tab_panels_fields_id,
        JSON.stringify(selectDropdown)
      );
      setSelectDropdown((prevState) => {
        prevState.splice(index, 1);
        return [...prevState];
      });
    }
  };

  const handleOnCheckItem = (item: { name: string }) => {
    const selectedItemsArr = checkedBoxes || [];
    const index = selectedItemsArr.findIndex(
      (selectedItem) => selectedItem.name === item.name
    );
    // if doesnt exist push item
    if (index === -1) {
      setCheckedBoxes(() => [...(checkedBoxes || []), item]);
    }
    // if already exists remove item
    else {
      setCheckedBoxes((prevState) => {
        prevState.splice(index, 1);
        return [...prevState];
      });
    }
  };

  useEffect(() => {
    if (type === "edit" && field.type === "dropdown") {
      const resData: {
        id: number;
        name: string;
      }[] = [];
      field.options?.map((f: { name: string }, index) => {
        let obj: {
          id: number;
          name: string;
        } = {
          id: 0,
          name: "",
        };
        obj.id += index;
        obj.name = f.name;
        resData.push(obj);
      });
      setDropdownOptions(resData);
    }
  }, [field]);

  useEffect(() => {
    if (type === "edit" && field.type === "checkbox") {
      setCheckedBoxes(JSON.parse(field.value));
    }
  }, [field?.id]);

  const isChecked = (item: { name: string }) => {
    const index = checkedBoxes?.findIndex(
      (checkedPipeline) => checkedPipeline.name === item.name
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const handleFieldValue = (field: FieldType) => {
    if (
      fieldProductList &&
      fieldProductList.length > 0 &&
      field.value &&
      field.value !== undefined
    ) {
      const removedData = fieldProductList.filter(
        (data) => data.field_id === field.id
      )[0];
      return removedData?.product?.name;
    } else {
      return "";
    }
  };

  const getProductDetails = (product_id: number, field_id: number) => {
    getByProductID(product_id).then(({ data }) => {
      let result: {
        product: ProductType;
        field_id: number | undefined;
      }[] = [];
      const { productDetails } = data || {};
      const obj = {
        product: productDetails,
        field_id: field_id,
      };
      result = [...fieldProductList];
      result.push(obj);
      dispatch(actions.setFieldProductDetails(result));
    });
  };

  function getFileExtensionFromUrl(url: string) {
    const match = url.match(/\.([^.]+)$/);
    return match ? match[1] : null;
  }

  const handlePreviewURL = (fileUrl: imageURLType) => {
    // @ts-ignore
    setPreviewLoading(fileUrl.url);
    // @ts-ignore
    const fileExtension = getFileExtensionFromUrl(fileUrl.url);
    setPreviewFileExtension(fileExtension);
    getFile(fileUrl.id)
      .then((res) => {
        // console.log(response.data);
        let fileObjURL = (res && res.data.fileDetails.file_url) || undefined;
        setPreviewFileURL(fileObjURL);
        setShowPreviewModal(true);
      })
      .catch((err) => {})
      .finally(() => {
        setPreviewLoading(undefined);
      });
  };

  const closePreviewModal = () => {
    setShowPreviewModal(false);
  };

  const getIconForFileType = (file: {
    id?: number;
    url: any;
    name?: string;
    type: any;
  }) => {
    switch (file?.type) {
      case "pdf":
        return PDFIcon;
      case "mp4":
      case "m4v":
        return VideoIcon;
      case "mp3":
        return AudioIcon;
      case "txt":
        return TXTIcon;
      case "xlsx":
        return XLSX;
      case "doc":
      case "docx":
        return WORDIcon;
      case "csv":
        return CSVIcon;
      case "ppt":
        return PPTIcon;
      case "pptx":
        return PPTXIcon;
      case "jpeg":
      case "jpg":
      case "png":
      case "gif":
      case "bmp":
      case "heif":
      case "heic":
      case "webp":
      case "psd":
      case "svg":
      case "eps":
      case "ai":
      case "ico":
      case "cr2":
      case "cr3":
      case "nef":
      case "arw":
      case "raf":
      case "orf":
      case "rw2":
      case "pef":
      case "exr":
      case "indd":
      case "jp2":
      case "j2k":
        return file?.url;
      default:
        return FileIcon;
    }
  };

  useEffect(() => {
    if (
      field.type === "product" &&
      field?.value &&
      field?.value !== undefined &&
      id === field.id
    ) {
      getProductDetails(Number(field.value), field.id);
    }

    if (field.type === "dropdown" && type === "edit") {
      if (field?.value && field?.value !== null && id === field.id) {
        setSelectDropdown(JSON.parse(field.value));
      }
    }
  }, [field]);

  return (
    <div key={index + field?.tab_panels_fields_id}>
      {/* {openProductCategoryModal.show && openProductCategoryModal.field ? (
        <SelectProductCategories
          show={openProductCategoryModal.show}
          closeModal={() => {
            setOpenProductCategoryModal({
              show: false,
              field: undefined,
            });
          }}
          onSelectCategory={(category) => {
            setOpenProductModal({
              show: true,
              category,
              field_id: openProductCategoryModal.field,
            });
            setOpenProductCategoryModal({
              show: false,
              field: undefined,
            });
          }}
        />
      ) : null} */}

      {openProductModal.show ? (
        <SelectProduct
          show={openProductModal.show}
          closeModal={() => {
            setOpenProductModal({
              show: false,
              category: undefined,
              field_id: undefined,
            });
          }}
          isSelectionModal={true}
          onSelectProduct={(product) => {
            const obj = {
              product: product[0],
              field_id: openProductModal.field_id?.id,
            };

            let result: {
              product: ProductType;
              field_id: number | undefined;
            }[] = [];
            result.push(obj);
            // const index = fieldProductList.findIndex(
            //   (data) => data.field_id === openProductModal.field_id?.id
            // );

            // if (index === -1) {
            //   result = [...fieldProductList, obj];
            // } else {
            //   result = [...fieldProductList];
            //   result.splice(index, 1);
            //   result.push(obj);
            // }
            // if (fieldProductList && fieldProductList.length > 0) {
            // } else {
            //   result.push(obj);
            // }

            dispatch(actions.setFieldProductDetails(result));
            onSubmitHandle(
              openProductModal.field_id?.tab_panels_fields_id,
              product[0].id,
              ""
            );
            formik.handleSubmit();
            setOpenProductModal({
              ...openProductModal,
              show: false,
            });
          }}
        />
      ) : null}
      {field.type !== "linkButton" && (
        <div className="d-flex align-items-center mb-2">
          <label
            className={
              field.is_required ? "fw-bold fs-6 required " : "fw-bold fs-6 "
            }
          >
            {field.name}
          </label>
          {field?.tooltip && (
            <div
              style={{
                position: "relative",
                cursor: "pointer",
              }}
            >
              <span
                data-tooltip-id="my-preview-tooltip"
                data-tooltip-content={field.tooltip}
                data-tooltip-place="top"
              >
                <SVG src={InfoIcon} className="svg-icon mx-2" />
              </span>

              <Tooltip
                id="my-preview-tooltip"
                place="top"
                style={{
                  color: "black",
                  backgroundColor: "#e9ecef",
                  zIndex: 999,
                  whiteSpace: "normal",
                  maxWidth: "250px",
                  wordWrap: "break-word",
                }}
                className="btn text-dark"
                border="1px solid #6c757d"
              />
            </div>
          )}
        </div>
      )}
      {field.type === "product" ? (
        <>
          <div className="d-flex">
            <input
              type="text"
              className="form-control form-control-lg form-control-solid me-4"
              disabled={true}
              placeholder={field?.placeholder && field?.placeholder}
              value={field.id === id ? handleFieldValue(field) : ""}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                setOpenProductModal({
                  show: true,
                  category: undefined,
                  field_id: field,
                });
              }}
            >
              {intl.formatMessage({ id: "CUSTOMERS_SELECT_PARTNER_TITLE" })}
            </button>
          </div>
          <div>
            {field.is_required === 1 && !field.value && (
              <div className="text-danger mt-2">
                <span role="alert">
                  {field.name +
                    " " +
                    intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}
                </span>
              </div>
            )}
          </div>
        </>
      ) : field.type === "text" ? (
        <div>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            disabled={type === "edit" && !isDisabled ? false : true}
            placeholder={field?.placeholder && field?.placeholder}
            {...formik.getFieldProps("textBox")}
            onBlur={(e) => {
              const updatedValue = e.target.value;

              if (field.value !== updatedValue) {
                if (field.value !== null || updatedValue !== "") {
                  formik.handleSubmit();
                  onSubmitHandle?.(field.tab_panels_fields_id, updatedValue);
                }
              }
            }}
            onChange={(e) => {
              // Set field value dynamically
              formik.setFieldValue("textBox", e.target.value);

              // Auto-detect changes due to autofill and trigger API call
              if (document.activeElement?.matches("input:-webkit-autofill")) {
                formik.handleSubmit();
                onSubmitHandle?.(field.tab_panels_fields_id, e.target.value);
              }
            }}
          />
          {field.is_required === 1 && !field.value && (
            <div className="text-danger mt-2">
              <span role="alert">
                {field.name +
                  " " +
                  intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}
              </span>
            </div>
          )}
        </div>
      ) : field.type === "uploader" ? (
        <div className={type === "edit" && !isDisabled ? "" : "pe-none"}>
          <div className="d-flex flex-column flex-sm-row align-items-start">
            {/* File preview cards */}
            <div className="d-flex flex-column align-items-start w-100">
              <div className="d-flex flex-wrap justify-content-start">
                {/* File Upload Section */}
                <div
                  style={{ height: "160px", marginLeft: "-18px" }}
                  className="mb-8"
                >
                  <FileUpload
                    onFileUpload={async (files: File[]) => {
                      setFileLoading(true);
                      await handleFileUpload(files);
                      setTimeout(() => {
                        setFileLoading(false);
                      }, 800);
                    }}
                    showPlusIcon
                    loading={fileLoading}
                    isDisabled={type === "edit" && !isDisabled ? false : true}
                  />
                </div>
                {/* Files Section (File previews) */}

                {field.value_json.map((file, index) => {
                  return (
                    <div
                      className="card border p-5 me-5 mt-6 mb-3 bg-hover-light-primary"
                      key={index}
                      style={{ height: "160px", width: "140px" }}
                    >
                      <div
                        onClick={() => {
                          // @ts-ignore
                          handlePreviewURL(file);
                        }}
                        className="d-flex flex-column align-items-center mt-2 cursor-pointer"
                      >
                        <img
                          src={getIconForFileType(file)}
                          height="60px"
                          width="60px"
                          style={{ objectFit: "cover" }}
                          className="mb-4"
                          alt=""
                        />
                        <span
                          className="text-truncate text-primary fw-bold"
                          style={{ maxWidth: "120px" }}
                        >
                          {file?.name}
                        </span>
                        <span className="badge text-muted mb-2 me-2">
                          {/* {bytesToSize(file?.size, 1)} */}
                          {local === "de"
                            ? /* @ts-ignore */
                              moment(file?.created_at).format("DD.MM.yyyy")
                            : /* @ts-ignore */
                              moment(file?.created_at).format("YYYY-MM-DD")}
                        </span>
                      </div>
                      <div
                        className="position-absolute bg-light btn btn-icon btn-active-light-danger d-flex justify-content-center align-items-center"
                        style={{
                          right: -15,
                          top: -10,
                          zIndex: 50,
                          borderRadius: "50px",
                          height: "25px",
                          width: "25px",
                        }}
                        role={"button"}
                        onClick={() => {
                          showDeleteConfirmModal(
                            "Are you sure you want to delete the file?",
                            () => {
                              deleteFile(
                                Number(field.pipeline_phase_tab_id),
                                field.tab_panels_fields_id,
                                file.id
                              );
                            }
                          );
                        }}
                      >
                        <SVGICON
                          src={CloseIcon}
                          className="svg-icon svg-icon-1 svg-icon-grey-800"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {field.is_required === 1 && field.value_json.length === 0 && (
            <div className="text-danger mt-2">
              <span role="alert">
                {field.name +
                  " " +
                  intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}
              </span>
            </div>
          )}
        </div>
      ) : field.type === "longtext" ? (
        <div>
          <SunEditorComponent
            key={field.value} // Ensure the editor re-renders with new content
            initHtml={formik.values.longText || ""}
            handleBlur={(html) => {
              const updatedValue = html.replace(/^\s+|\s+$/g, ""); // Remove leading/trailing whitespace
              if (updatedValue || updatedValue !== "<p><br></p>") {
                if (field.value !== updatedValue) {
                  if (
                    field.value !== null ||
                    (updatedValue !== "<p><br></p>" && updatedValue !== "")
                  ) {
                    formik.setFieldValue("longText", updatedValue);
                    onSubmitHandle?.(field.tab_panels_fields_id, updatedValue);
                    formik.handleSubmit();
                  }
                }
              }
            }}
            isDiasbled={type === "edit" && !isDisabled ? false : true}
            wrapperPosition={field.id}
            loading={false}
            hideSubmit={false}
          />
          {field.is_required === 1 && !field.value && (
            <div className="text-danger mt-2">
              <span role="alert">
                {field.name +
                  " " +
                  intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}
              </span>
            </div>
          )}
        </div>
      ) : field.type === "date" ? (
        <div>
          <DateTimePicker
            onDateChange={(date) => {}}
            onCalendarClose={(date) => {
              const newDate = date
                ? moment(date).format("YYYY-MM-DD")
                : field.value
                  ? moment(field.value).format("YYYY-MM-DD")
                  : ""; // Handle empty date
              if (field.value !== newDate) {
                if (field.value !== null || newDate !== "") {
                  formik.setFieldValue("dateDetails", newDate);
                  onSubmitHandle?.(field.tab_panels_fields_id, newDate);
                  formik.handleSubmit();
                }
              }
            }}
            // @ts-ignore
            selectedDate={
              formik.values.dateDetails &&
              !isNaN(Date.parse(formik.values.dateDetails))
                ? new Date(formik.values.dateDetails) // Set valid date
                : null // Handle empty or invalid date
            }
            placeholder={field?.placeholder && field?.placeholder}
            hideTimeSelect
            type={"date"}
            noMinDate
            //minDate={moment().subtract(2, "months").toDate()}
            disabledRes={type === "edit" && !isDisabled ? false : true}
          />

          {field.is_required === 1 && !field.value && (
            <div className="text-danger mt-2">
              <span role="alert">
                {field.name +
                  " " +
                  intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}
              </span>
            </div>
          )}
        </div>
      ) : field.type === "dropdown" ? (
        <div key={field?.tab_panels_fields_id}>
          <DropDown
            id="tab-parent-dropdown"
            multiSelect
            // @ts-ignore
            items={dropdownOptions || []}
            // @ts-ignore
            selectedItems={selectDropdown}
            onSelectItem={(item) => {
              //   @ts-ignore
              handleOnSelectItem(item);
            }}
            // @ts-ignore
            displayFunc={(item) => {
              return item.name;
            }}
            onMultiSelectBlur={() => {
              // formik.handleSubmit();
              // onSubmitHandle?.(
              //   field.tab_panels_fields_id,
              //   JSON.stringify(selectDropdown)
              // );
            }}
            disabled={type === "edit" && !isDisabled ? false : true}
            searchPlaceholder={field?.placeholder && field?.placeholder}
          />
          {field.is_required === 1 && selectDropdown.length === 0 && (
            <div className="text-danger mt-2">
              <span role="alert">
                {field.name +
                  " " +
                  intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}
              </span>
            </div>
          )}
        </div>
      ) : field.type === "radio" ? (
        <>
          <div className="d-flex">
            {field.options?.map((option: { name: string }, index) => (
              <div
                className="form-check-custom"
                style={{
                  marginRight: "20px",
                  //flex:1
                }}
                key={index}
              >
                <input
                  id={`${option.name + "_" + field.tab_panels_fields_id}`}
                  className="form-check-input cursor-pointer me-2"
                  type="radio"
                  name={`${field.name + "_" + field.tab_panels_fields_id}`}
                  checked={field.value === option.name ? true : false}
                  onChange={() => {
                    // If the current field.value is the same as the option.name, set it to empty string to uncheck
                    const newValue =
                      field.value === option.name ? "" : option.name;
                    formik.handleSubmit();
                    onSubmitHandle?.(field.tab_panels_fields_id, newValue);
                  }}
                  disabled={type === "edit" && !isDisabled ? false : true}
                />
                <label
                  //htmlFor={`${option.name + "_" + field.tab_panels_fields_id}`}
                  className={"fw-bold fs-6 me-2"}
                >
                  {option.name}
                </label>
              </div>
            ))}
          </div>
          <div>
            {field.is_required === 1 && !field.value && (
              <div className="text-danger mt-2">
                <span role="alert">
                  {field.name +
                    " " +
                    intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}
                </span>
              </div>
            )}
          </div>
        </>
      ) : field.type === "checkbox" ? (
        <>
          <div className="d-flex">
            {field.options?.map((option: { name: string }, index) => {
              return (
                <div
                  className="form-check form-check-sm form-check-custom form-check-solid"
                  style={{
                    marginRight: "20px",
                    //flex:1
                  }}
                  key={index}
                  //  onBlur={() => {
                  //     formik.handleSubmit();
                  //     onSubmitHandle?.(
                  //       field.tab_panels_fields_id,
                  //       checkedBoxes && checkedBoxes.length > 0
                  //         ? JSON.stringify(checkedBoxes)
                  //         : null
                  //     );
                  //   }}
                >
                  <input
                    onChange={(e) => {
                      handleOnCheckItem(option);
                    }}
                    className="form-check-input widget-9-check me-2"
                    type="checkbox"
                    id={
                      field.name +
                      "_" +
                      field.tab_panels_fields_id +
                      "_" +
                      option.name
                    }
                    checked={isChecked(option)}
                    onBlur={() => {
                      formik.handleSubmit();
                      onSubmitHandle?.(
                        field.tab_panels_fields_id,
                        checkedBoxes && checkedBoxes.length > 0
                          ? JSON.stringify(checkedBoxes)
                          : null
                      );
                    }}
                    disabled={type === "edit" && !isDisabled ? false : true}
                  />
                  <label
                    className={"fw-bold fs-6 me-2"}
                    // htmlFor={
                    //   field.name +
                    //   "_" +
                    //   field.tab_panels_fields_id +
                    //   "_" +
                    //   option.name
                    // }
                  >
                    {option.name}
                  </label>
                </div>
              );
            })}
          </div>
          <div>
            {field.is_required === 1 && !field.value && (
              <div className="text-danger mt-2">
                <span role="alert">
                  {field.name +
                    " " +
                    intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}
                </span>
              </div>
            )}
          </div>
        </>
      ) : field.type === "number" ? (
        <div>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            disabled={type === "edit" && !isDisabled ? false : true}
            placeholder={field?.placeholder && field?.placeholder}
            {...formik.getFieldProps("numberBox")}
            onBlur={(e) => {
              if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                if (field.value !== e.target.value) {
                  if (field.value !== null || e.target.value !== "") {
                    formik.handleSubmit();
                    onSubmitHandle?.(
                      field.tab_panels_fields_id,
                      e.target.value
                    );
                  }
                }
              }
            }}
            onChange={(e) => {
              const value = e.target.value;
              // Allow only digits, optional decimal, and negative sign
              if (/^-?\d*\.?\d*$/.test(value)) {
                formik.setFieldValue("numberBox", value);
                // Auto-detect changes due to autofill and trigger API call
                if (document.activeElement?.matches("input:-webkit-autofill")) {
                  formik.handleSubmit();
                  onSubmitHandle?.(field.tab_panels_fields_id, value);
                }
              }
            }}
          />
          {/* {console.log(field)} */}
          {field.is_required === 1 && !field.value && (
            <div className="text-danger mt-2">
              <span role="alert">
                {field.name +
                  " " +
                  intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}
              </span>
            </div>
          )}
        </div>
      ) : field.type === "link" ? (
        <div>
          <div>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                disabled={type === "edit" && !isDisabled ? false : true}
                placeholder={field?.placeholder && field?.placeholder}
                {...formik.getFieldProps("link")}
                onBlur={(e) => {
                  const updatedValue = e.target.value;

                  if (field.value !== updatedValue) {
                    if (field.value !== null || updatedValue !== "") {
                      if (updatedValue.length > 0) {
                        if (!formik.errors.link) {
                          formik.handleSubmit();
                          onSubmitHandle?.(
                            field.tab_panels_fields_id,
                            updatedValue
                          );
                        }
                      } else {
                        formik.handleSubmit();
                        onSubmitHandle?.(
                          field.tab_panels_fields_id,
                          updatedValue
                        );
                      }
                    }
                  }
                }}
                onChange={(e) => {
                  formik.setFieldValue("link", e.target.value);
                }}
              />
              <div
                className={clsx(
                  "input-group-text border",
                  "toolip_copy_container",
                  !field.value && "disabled"
                )}
                role="button"
                onClick={() => {
                  try {
                    if (field.value && !formik.errors.link) {
                      navigator.clipboard.writeText(field.value);
                      successToast(
                        intl.formatMessage({
                          id: "FIELD_TYPE_LINK_COPY_SUCCESS",
                        })
                      );
                    } else {
                      warningToast(
                        intl.formatMessage({
                          id: "FIELD_TYPE_LINK_COPY_FAILURE",
                        })
                      );
                    }
                  } catch (e) {}
                }}
              >
                <span>
                  <SVGICON src={CopyIcon} className="svg-icon svg-icon-5" />
                </span>
                <Tooltip
                  id="link-field-tooltip-copy"
                  anchorSelect=".toolip_copy_container"
                >
                  {intl.formatMessage({
                    id: "FIELD_TYPE_LINK_COPY",
                  })}
                </Tooltip>
              </div>
              <div
                className={clsx(
                  "input-group-text border",
                  "toolip_goto_container",
                  !field.value && "disabled"
                )}
                role="button"
                onClick={() =>
                  field.value &&
                  !formik.errors.link &&
                  window.open(field.value, "_blank")
                }
              >
                <span>
                  <SVGICON src={GoToIcon} className="svg-icon svg-icon-8" />
                </span>
                <Tooltip
                  id="link-field-tooltip-goto"
                  anchorSelect=".toolip_goto_container"
                >
                  {intl.formatMessage({
                    id: "FIELD_TYPE_LINK_GOTO",
                  })}
                </Tooltip>
              </div>
            </div>
          </div>
          {formik?.errors?.link && (
            <div className="text-danger mt-2">
              <span role="alert">{formik.errors.link}</span>
            </div>
          )}
          {field.is_required === 1 && !field.value && (
            <div className="text-danger mt-2">
              <span role="alert">
                {field.name +
                  " " +
                  intl.formatMessage({ id: "COMMOM_IS_REQUIRED" })}
              </span>
            </div>
          )}
        </div>
      ) : field.type === "linkButton" ? (
        <button
          type="button"
          className="btn btn-primary min-w-100px mt-4"
          onClick={() => field.data && window.open(field.data, "_blank")}
        >
          {field.name}
        </button>
      ) : null}
      {previewFileURL && (
        <div>
          <ProductFilePreviewModal
            show={showPreviewModal}
            previewFileURL={previewFileURL}
            previewFileExtension={previewFileExtension}
            closeModal={closePreviewModal}
          />
        </div>
      )}
    </div>
  );
};
