import React, { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import clsx from "clsx";

import { NoteModalProps } from "../types/getNotesResponseTypes";
import { updateNote } from "../redux";
import { successToast } from "../../../sharedComponents/toasts/Toasts";

interface ModelComponentProps {
  ModalProps: NoteModalProps;
  closeModal: () => void;
  getNotesListAPI: () => void;
}

const EditNoteModal: FC<ModelComponentProps> = ({
  ModalProps,
  closeModal,
  getNotesListAPI,
}) => {
  const intl = useIntl();
  const { show, note } = ModalProps;

  // state
  const [loading, setLoading] = useState(false);

  const UpdateNoteSchema = Yup.object()
    .shape({
      note_id: Yup.number().required(),
      note: Yup.string().required(
        intl.formatMessage({
          id: "CUSTOMER_MANAGEMENT_PROFILE_NOTE_VALIDATION_MESSAGE",
        })
      ),
      users_id: Yup.number().nullable(),
      deals_id: Yup.number().nullable(),
    })
    .test(
      "at-least-one-id",
      intl.formatMessage({
        id: "CUSTOMER_MANAGEMENT_PROFILE_NOTES_ID_VALIDATION_MESSAGE",
      }),
      function (value) {
        // Ensure that at least one of users_id or deals_id is not null
        return value.users_id !== null || value.deals_id !== null;
      }
    );

  const initialValues = {
    note_id: note?.notes_id,
    note: note?.description || "",
    users_id: note?.users_id,
    deals_id: note?.deals_id,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: UpdateNoteSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      updateNote(
        // @ts-ignore
        values.note_id,
        values.note,
        values.users_id,
        values.deals_id
      )
        .then(() => {
          closeModal();
          successToast(
            intl.formatMessage({
              id: "CUSTOMER_MANAGEMENT_PROFILE_NOTE_UPDATED_SUCCESS_MESSAGE",
            })
          );
        })
        .catch((err) => {
          err.response?.data?.errors?.name
            ? setStatus(err.response.data?.errors?.name)
            : setStatus(
                intl.formatMessage({
                  id: "CUSTOMER_MANAGEMENT_PROFILE_NOTE_UPDATED_FAILURE_MESSAGE",
                })
              );
        })
        .finally(() => {
          // recall  get categories list API
          getNotesListAPI();
          setLoading(false);
        });
    },
  });

  return (
    <Modal
      show={show}
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      centered
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({
            id: "CUSTOMER_MANAGEMENT_PROFILE_NOTE_EDIT_MODAL_TITLE",
          })}
        </Modal.Title>
      </Modal.Header>

      <div className="overflow-auto">
        <Modal.Body className="overflow-inherit">
          <div className="py-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "CUSTOMER_MANAGEMENT_PROFILE_NOTE_EDIT_MODAL_FIELD_NAME",
                  })}
                </span>
              </label>
              <textarea
                {...formik.getFieldProps("note")}
                className={clsx(
                  "form-control form-control-lg form-control-solid customer-note-textarea"
                )}
                rows={7}
                autoComplete="off"
              />
              {formik.touched.note && formik.errors.note && (
                <div className="text-primary">
                  <span role="alert">{formik.errors.note}</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_NOTE_EDIT_MODAL_LOADING_MESSAGE",
              })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditNoteModal;
