import React, { FC, useState } from "react";
// import * as Yup from 'yup'
// import _ from 'lodash'

// custom
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { GroupType } from "../../masterdata/types/GetGroupsListResponseType";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { useIntl } from "react-intl";
import {
  IndustryGroupType,
  IndustrySectorType,
} from "../../partnersMasterData/types/getIndustryGroupsResponseType";
// import SelfPartnerDetails from '../../system/components/settings/SelfPartnerDetails'
import { LabelType } from "../../masterdata/types/GetLabelsListResponseType";
import { PartnerType } from "../types/getPartnersListResponseType";
import {
  updatePartnerCategories,
  updatePartnerGroups,
  updatePartnerIndustrySectors,
  updatePartnerLabels,
  updatePartnerTags,
} from "../redux";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

interface Props {
  partner: PartnerType;
  getPartnerDetailsAPI: () => void;
}

// const PostGeneralDetailsSchema = Yup.object().shape({
//   title: Yup.string().required('Title is required'),
// })

const PartnerTagsDetails: FC<Props> = ({ partner, getPartnerDetailsAPI }) => {
  const industryGroupsList = useSelector<RootState>(
    ({ masterdata }) => masterdata.industryGroupsList
  ) as IndustryGroupType[];
  // const groupsList = useSelector<RootState>(({masterdata}) => masterdata.groupsList) as GroupType[]
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "partners"
  );
  // const categoriesList = useSelector<RootState>(
  //   ({masterdata}) => masterdata.categoriesList
  // ) as GroupType[]
  // const tagsList = useSelector<RootState>(({masterdata}) => masterdata.tagsList) as GroupType[]
  const labelsList = useSelector<RootState>(
    ({ masterdata }) => masterdata.labelsList
  ) as LabelType[];

  // state
  // const [currPartner, setCurrPartner] = useState<PartnerType>(partner)
  // const [loading, setLoading] = useState<boolean>(false)
  const [selectedSectors, setSelectedSectors] = useState<IndustrySectorType[]>(
    partner.industries_sectors || []
  );
  const [selectedGroups, setSelectedGroups] = useState<GroupType[]>(
    partner.groups || []
  );
  const [selectedCategories, setSelectedCategories] = useState<GroupType[]>(
    partner.categories || []
  );
  const [selectedTags, setSelectedTags] = useState<GroupType[]>(
    partner.tags || []
  );
  const [selectedLabels, setSelectedLabels] = useState<GroupType[]>(
    partner.labels || []
  );

  const flattenIndustryGroups = (industryGroups: IndustryGroupType[] = []) => {
    const sectors: any = [];
    industryGroups.forEach((industryGroup) => {
      if (industryGroup?.sectors?.length > 0) {
        sectors.push({
          id: industryGroup.id,
          name: industryGroup.name,

          isGroupLabel: true,
        });
      }
      (industryGroup.sectors || []).forEach((industryGroupSector) => {
        // sectors.push(industryGroupSector.name + '(' + industryGroup.name + ')')
        sectors.push({
          id: industryGroupSector.id,
          name: industryGroupSector.name,
        });
      });
    });
    return sectors;
  };

  const industrySectors = flattenIndustryGroups(industryGroupsList);

  const handleOnBlur = (
    type: "sector" | "group" | "category" | "tag" | "label"
  ) => {
    const selectedItemsArr =
      type === "sector"
        ? selectedSectors
        : type === "group"
          ? selectedGroups
          : type === "category"
            ? selectedCategories
            : type === "tag"
              ? selectedTags
              : selectedLabels;
    const ids = selectedItemsArr.map((selectedItem) => {
      return selectedItem.id;
    });
    type === "sector" &&
      updatePartnerIndustrySectors(partner.id, ids).finally(() => {
        getPartnerDetailsAPI();
      });
    type === "group" &&
      updatePartnerGroups(partner.id, ids).finally(() => {
        getPartnerDetailsAPI();
      });
    type === "category" &&
      updatePartnerCategories(partner.id, ids).finally(() => {
        getPartnerDetailsAPI();
      });
    type === "tag" &&
      updatePartnerTags(partner.id, ids).finally(() => {
        getPartnerDetailsAPI();
      });
    type === "label" &&
      updatePartnerLabels(partner.id, ids).finally(() => {
        getPartnerDetailsAPI();
      });
  };

  const handleOnSelectItem = (
    item: GroupType,
    type: "sector" | "group" | "category" | "tag" | "label"
  ) => {
    const selectedItemsArr =
      type === "sector"
        ? selectedSectors
        : type === "group"
          ? selectedGroups
          : type === "category"
            ? selectedCategories
            : type === "tag"
              ? selectedTags
              : selectedLabels;
    const index = selectedItemsArr.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );

    // if doesnt exist push item
    if (index === -1) {
      type === "sector" && setSelectedSectors(() => [...selectedSectors, item]);
      type === "group" && setSelectedGroups(() => [...selectedGroups, item]);
      type === "category" &&
        setSelectedCategories(() => [...selectedCategories, item]);
      type === "tag" && setSelectedTags(() => [...selectedTags, item]);
      type === "label" && setSelectedLabels(() => [...selectedLabels, item]);
    }
    // if already exists remove item
    else {
      type === "sector" &&
        setSelectedSectors((prevState) => {
          prevState.splice(index, 1);
          return [...prevState];
        });
      type === "group" &&
        setSelectedGroups((prevState) => {
          prevState.splice(index, 1);
          return [...prevState];
        });
      type === "category" &&
        setSelectedCategories((prevState) => {
          prevState.splice(index, 1);
          return [...prevState];
        });
      type === "tag" &&
        setSelectedTags((prevState) => {
          prevState.splice(index, 1);
          return [...prevState];
        });
      type === "label" &&
        setSelectedLabels((prevState) => {
          prevState.splice(index, 1);
          return [...prevState];
        });
    }
  };

  return (
    <div className={`card card-flush py-4 ${!crudPermission?.edit && "pe-none"}`}>
      <div className="card-header">
        <div className="card-title">
          <h2>
            {intl.formatMessage({
              id: "PARTNERS_GENERAL_TITLE",
            })}
          </h2>
        </div>
      </div>

      <div className="card-body pt-0">
        {/* Sectors */}
        <label className="form-label">{"Sectors"}</label>
        <DropDown
          id="partner-sectors-dropdown"
          multiSelect
          items={industrySectors}
          // @ts-ignore
          displayFunc={(item) => item.name}
          onSelectItem={(item) => {
            //   @ts-ignore
            handleOnSelectItem(item, "sector");
          }}
          selectedItems={selectedSectors}
          onMultiSelectBlur={() => {
            handleOnBlur("sector");
          }}
        />

        {/* Labels */}
        <div className="mt-8">
          <label className="form-label">
            {intl.formatMessage({ id: "PARTNERS_LABELS_TAGS_TITLE" })}
          </label>
          <DropDown
            id="post-categories-dropdown"
            multiSelect
            items={labelsList}
            displayFunc={(item) => item.name}
            onSelectItem={(item) => {
              //   @ts-ignore
              handleOnSelectItem(item, "label");
            }}
            selectedItems={selectedLabels}
            onMultiSelectBlur={() => {
              handleOnBlur("label");
            }}
          />
        </div>

        {/* Groups */}
        {/* <label className='form-label mt-8'>
          {intl.formatMessage({id: 'POST.EDIT_POST.POST_TYPE_SECTION.GROUPS.TITLE'})}
        </label>
        <DropDown
          id='post-groups-dropdown'
          // hideSearch
          multiSelect
          items={groupsList}
          displayFunc={(item) => item.name}
          onSelectItem={(item) => {
            //   @ts-ignore
            handleOnSelectItem(item, 'group')
          }}
          selectedItems={selectedGroups}
          onMultiSelectBlur={() => {
            handleOnBlur('group')
          }}
        /> */}

        {/* categories */}
        {/* <div className='mt-8'>
          <label className='form-label'>
            {intl.formatMessage({id: 'POST.EDIT_POST.POST_TYPE_SECTION.CATEGORIES.TITLE'})}
          </label>
          <DropDown
            id='post-categories-dropdown'
            // hideSearch
            multiSelect
            items={categoriesList}
            displayFunc={(item) => item.name}
            onSelectItem={(item) => {
              //   @ts-ignore
              handleOnSelectItem(item, 'category')
            }}
            selectedItems={selectedCategories}
            onMultiSelectBlur={() => {
              handleOnBlur('category')
            }}
          />
        </div> */}

        {/* Tags */}
        {/* <div className='mt-8'>
          <label className='form-label'>
            {intl.formatMessage({id: 'POST.EDIT_POST.POST_TYPE_SECTION.TAGS.TITLE'})}
          </label>
          <DropDown
            id='post-tags-dropdown'
            // hideSearch
            multiSelect
            items={tagsList}
            displayFunc={(item) => item.name}
            onSelectItem={(item) => {
              //   @ts-ignore
              handleOnSelectItem(item, 'tag')
            }}
            selectedItems={selectedTags}
            onMultiSelectBlur={() => {
              handleOnBlur('tag')
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default PartnerTagsDetails;
