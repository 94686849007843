import React from "react";
import { useIntl } from "react-intl";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { Redirect, Route, Switch } from "react-router-dom";
import SalesOfferOverview from "./components/OfferOverview";
import EditOffer from "./components/EditOffer";

const SalesOfferPage = () => {
  const intl = useIntl();

  const SalesOfferBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "OFFERS_ROUTE" }),
      path: "/sales/offers",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
    <div>
      <Switch>
        <Route path="/sales/offers/overview">
          <PageTitle>{intl.formatMessage({ id: "OFFERS_ROUTE" })}</PageTitle>
          <SalesOfferOverview />
        </Route>
        <Route path="/sales/offers/edit">
          <PageTitle
            breadcrumbs={[
              ...SalesOfferBreadCrumbs,
              {
                title: intl.formatMessage({
                  id: "OFFERS_DETAILS_ROUTE",
                }),
                path: "/sales/offers/edit",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({ id: "OFFERS_ROUTE" })}
          </PageTitle>
          <EditOffer />
        </Route>

        <Redirect
          from="/sales/offers"
          exact={true}
          to="/sales/offers/overview"
        />
        <Redirect to="/sales/offers" />
      </Switch>
    </div>
  );
};

export default SalesOfferPage;
