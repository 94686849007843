import React, { FC } from "react";
import { useIntl } from "react-intl";

// custom
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { deleteTask } from "../redux/tasksAPI";
import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import { TaskType } from "../types/TasksResponseType";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  taskDetails: TaskType;
  getTasksListAPI?: () => void;
  setShowAddTaskModal?: () => void;
  setShowTaskLogsModal?: () => void;
}

const TasksListActionsDropdown: FC<Props> = ({
  taskDetails,
  getTasksListAPI,
  setShowAddTaskModal,
  setShowTaskLogsModal,
}) => {
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(userRoleDetails, "tasks");

  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const onEditProductClick = () => {
    setShowAddTaskModal?.();
  };

  const onViewTaskLogsClick = () => {
    setShowTaskLogsModal?.();
  };

  const deleteExistingPost = (task_id: number) => {
    setDeleteModalLoading(true);
    deleteTask(task_id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "TASKS_DELETE_POST_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "TASKS_DELETE_POST_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        // recall  get  list API
        getTasksListAPI?.();
      });
  };

  const dropdownOptions = [
    ...(crudPermission?.edit
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_EDIT" }),
            onClick: onEditProductClick,
          },
        ]
      : []),
    ...(crudPermission?.delete
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_DELETE" }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({ id: "TASKS_DELETE_POST_CONFIRM_MESSAGE" }),
                () => {
                  deleteExistingPost(taskDetails?.id);
                }
              );
            },
            className: "text-danger",
          },
        ]
      : []),
    ...(crudPermission?.read
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_VIEW_LOGS" }),
            onClick: onViewTaskLogsClick,
          },
        ]
      : []),
  ];

  if (dropdownOptions.length > 0) {
    return (
      <div className="dropdown d-flex flex-row justify-content-end">
        <ActionsDropdown options={dropdownOptions} />
      </div>
    );
  } else {
    return null;
  }
};

export default TasksListActionsDropdown;
