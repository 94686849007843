// import { useIntl } from "react-intl";
// import { DealType } from "../types/DealResponseType";
// import { FC} from "react";
// import {
//   MapContainer,
//   Marker,
//   TileLayer,
// } from "react-leaflet";
// import LocationIcon2 from "../../../../_metronic/assets/icons/locationRedMarker.svg";
// import L from "leaflet";

// interface LocationProps {
//   dealInformation: DealType;
// }

// const DealLocation: FC<LocationProps> = ({ dealInformation }) => {
//   const intl = useIntl();

//   return (
//     <div
//       className="card mb-8 borderNone"
//       style={{
//         minHeight: "200px",
//       }}
//     >
//       <div className="card-body">
//         <div className="d-flex justify-content-between align-items-center fs-5 fw-bolder mb-2">
//           <h4>{intl.formatMessage({ id: "DEAL_LOCATION" })}</h4>
//         </div>
//         <div>
//           {(!dealInformation?.contacts?.latitude && !dealInformation?.partners?.latitude) ? (
//             <div className="text-muted fs-8 d-flex align-items-center justify-content-center mt-10">
//             {intl.formatMessage({
//               id: "ADDRESS_NOT_AVAILABLE",
//             })}
//           </div>
//           ) : (
//             <div
//               className="card-body px-0 py-3 "
//               style={{ maxHeight: "200px" }}
//             >
//               <MapContainer
//                 // @ts-ignore
//                 center={[(Number(dealInformation?.contacts?.latitude)|| Number(dealInformation?.partners?.latitude)), (Number(dealInformation?.contacts?.longitude)||Number(dealInformation?.partners?.longitude))]}
//                 zoom={16}
//                 scrollWheelZoom={false}
//                 attributionControl={false}
//                 touchZoom
//                 style={{
//                   zIndex: "11",
//                   maxHeight: "200px",
//                   borderRadius:'5px'
//                 }}
//               >
//                 <TileLayer
//                   attribution=""
//                   url="https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}@2x.jpg?key=yogaTYiQoWRwC6wfHQQK"
//                 />
//                 <Marker
//                   position={[(Number(dealInformation?.contacts?.latitude)|| Number(dealInformation?.partners?.latitude)), (Number(dealInformation?.contacts?.longitude)||Number(dealInformation?.partners?.longitude))]}
//                   icon={L.icon({
//                     iconUrl: LocationIcon2,
//                     iconSize: [30, 30],
//                     iconAnchor: [10, 10],
//                     className: `blinking`,
//                   })}
//                 ></Marker>
//               </MapContainer>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DealLocation;

import React, { FC } from "react";
import { useIntl } from "react-intl";
import { DealType } from "../types/DealResponseType";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import configs from "../../../config";

interface LocationProps {
  dealInformation: DealType;
}

const DealLocation: FC<LocationProps> = ({ dealInformation }) => {
  const intl = useIntl();
  const API_KEY = configs.GOOGLE_MAPS_API_KEY as string;

  const isContactAddressAvailable =
    dealInformation?.contacts?.latitude || dealInformation?.contacts?.longitude;
  const isPartnerAddressAvailable =
    dealInformation?.partners?.latitude || dealInformation?.partners?.longitude;

  const latitude =
    Number(dealInformation?.contacts?.latitude) ||
    Number(dealInformation?.partners?.latitude);

  const longitude =
    Number(dealInformation?.contacts?.longitude) ||
    Number(dealInformation?.partners?.longitude);

  return (
    <>
      {!(!isContactAddressAvailable && !isPartnerAddressAvailable) && (
        <div
          className="card p-0 m-0 mb-8 rounded-2"
          style={{
            minHeight: "180px",
            borderRadius: "20px",
          }}
        >
          <div className="card-body p-0 m-0">
            {/* <div className="d-flex justify-content-between align-items-center fs-5 fw-bolder mb-2">
          <h4>{intl.formatMessage({ id: "DEAL_LOCATION" })}</h4>
        </div> */}
            <div>
              {
                <div
                  className="card-body p-0 rounded-2 overflow-hidden"
                  style={{ position: "relative", maxHeight: "180px" }}
                >
                  {/* Invisible clickable link over the Google watermark */}
                  <a
                    href={`https://www.google.com/maps?q=${latitude},${longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "0px",
                      width: "70px",
                      height: "25px",
                      zIndex: 10,
                      cursor: "pointer",
                      background: "transparent",
                    }}
                  >
                    {/* Invisible link for Google watermark */}
                  </a>
                  <APIProvider apiKey={API_KEY}>
                    <Map
                      style={{ width: "100%", height: "180px" }}
                      defaultCenter={{ lat: latitude, lng: longitude }}
                      defaultZoom={17}
                      mapId={"satellite"}
                      mapTypeId={"satellite"}
                      disableDefaultUI={false}
                      defaultTilt={0}
                    />
                    <AdvancedMarker
                      position={{ lat: latitude, lng: longitude }}
                    />
                  </APIProvider>
                </div>
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DealLocation;
