import swal from "sweetalert";

// styles
import "./index.scss";
// import {useState} from 'react'
import { successToast } from "../toasts/Toasts";
import { useIntl } from "react-intl";
// import {useIntl} from 'react-intl'

export const SuccessAlert = (
  text: string,
  onSuccess?: () => void,
  title?: string,
  onClose?: () => void
) => {
  swal({
    title: title ? title : "Success",
    text: text,
    icon: "success",
    // @ts-ignore
    buttons: {
      confirm: {
        text: "Okay",
        className: "btn btn-light-primary swal-button--confirm",
        closeModal: true,
        value: true,
      },
    },
    closeOnClickOutside: true,
  })
    .then((e) => {
      if (e) {
        onSuccess?.();
      }
    })
    .finally(() => {
      onClose?.();
    });
  setTimeout(() => {
    const confirmButton = document.querySelector(".swal-button--confirm");
    if (confirmButton) {
      // @ts-ignore
      confirmButton?.focus();
    }
  }, 0);
};

export const errorAlert = (text: string, showCopy?: string) => {
  swal({
    text: text,
    icon: "error",
    // @ts-ignore
    buttons: {
      confirm: {
        text: "Okay",
        className: "btn btn-danger",
        closeModal: true,
        value: "confirm",
      },
      CopyToClipboard:
        showCopy === "copy"
          ? {
              text: "copy",
              className: "btn btn-secondary",
              closeModal: true,
              visible: true,
              value: text,
            }
          : null,
    },
    closeOnClickOutside: true,
  }).then((res) => {
    if (showCopy === "copy" && res !== "confirm") {
      navigator.clipboard?.writeText(res).then(() => {
        successToast("Copied to cliboard!");
      });
    }
  });
};

export const deleteConfirmAlert = (
  text: string,
  onDelete?: (text?: string) => void,
  deleteText?: string
) => {
  swal({
    text: text,
    icon: "warning",
    // @ts-ignore
    buttons: {
      close: {
        text: "No, cancel",
        className: "btn btn-secondary",
        closeModal: true,
        value: false,
      },
      confirm: {
        text: deleteText ? deleteText : "Yes, delete",
        className: "btn btn-danger",
        closeModal: true,
        value: true,
      },
    },
    closeOnClickOutside: false,
    // @ts-ignore
    allowEscapeKey: false,
    // @ts-ignore
    showLoaderOnConfirm: true,
  }).then((e) => {
    if (e) {
      onDelete?.("Yes");
    } else {
      onDelete?.("No");
    }
  });
};

export const TwoButtonConfirmAlert = (
  text: string,
  onSelectVal?: (val: boolean) => void
) => {
  swal({
    text: text,
    icon: "warning",
    // @ts-ignore
    buttons: {
      close: {
        text: "No",
        className: "btn btn-primary",
        closeModal: true,
        value: false,
      },
      confirm: {
        text: "Yes",
        className: "btn btn-primary",
        closeModal: true,
        value: true,
      },
    },
    closeOnClickOutside: false,
    // @ts-ignore
    allowEscapeKey: false,
    // @ts-ignore
    showLoaderOnConfirm: true,
  }).then((e) => {
    if (e) {
      onSelectVal?.(true);
    } else {
      onSelectVal?.(false);
    }
  });
};
