import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { useIntl } from "react-intl";

import { KTSVG } from "../../../../_metronic/helpers";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";

import NotesListActionDropdown from "./NotesListActionDropdown";
import { NoteDetailsType } from "../types/getNotesResponseTypes";
import { updateNotePin } from "../redux";
import NotesLogsModal from "./NoteLogsModal";
import {
  successToast,
  warningToast,
} from "../../../sharedComponents/toasts/Toasts";

// Permissions related imports
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

interface NoteCardProps {
  module: string;
  note: NoteDetailsType;
  onEdit: (note: any) => void;
  getNotesListAPI: () => void;
}

const NoteCard: FC<NoteCardProps> = ({
  module,
  note,
  onEdit,
  getNotesListAPI,
}) => {
  const local = useLang();
  const intl = useIntl();
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(
    userRoleDetails,
    "contacts"
  );
  const crudPermissionForDeals: any = enablePermissionForCrud(
    userRoleDetails,
    "deals"
  );

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [showTaskLogsModal, setShowTaskLogsModal] = useState<boolean>(false);

  const {
    createdUserDetails,
    description,
    is_pinned,
    notes_id,
    created_at,
    updated_at,
    users_id,
    deals_id,
  } = note;
  const { profile_photo_url, firstname, lastname } = createdUserDetails;

  const handlePin = (note_id: number, isPinned: boolean) => {
    const is_pinned = isPinned ? 1 : 0;
    setLoading(true);
    updateNotePin(note_id, is_pinned, users_id, deals_id)
      .then(() => {
        is_pinned
          ? successToast(
              intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_PIN_NOTE_MESSAGE",
              })
            )
          : warningToast(
              intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_PROFILE_UNPIN_NOTE_MESSAGE",
              })
            );
        getNotesListAPI();
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  return (
    <>
      {showTaskLogsModal && (
        <NotesLogsModal
          note={note}
          show={showTaskLogsModal}
          closeModal={() => {
            setShowTaskLogsModal(false);
          }}
        />
      )}
      <div className="timeline-item">
        <div className="timeline-line w-50px"></div>
        <div className="timeline-icon symbol symbol-circle me-4">
          <div className="symbol-label symbol-50px bg-light">
            {profile_photo_url ? (
              <img
                className="rounded-circle"
                src={profile_photo_url}
                alt={`${firstname} ${lastname}`}
                style={{ height: "50px", width: "50px", objectFit: "cover" }}
              />
            ) : (
              <KTSVG
                path="/media/icons/duotune/communication/com003.svg"
                className="svg-icon-2 svg-icon-gray-500"
              />
            )}
          </div>
        </div>

        <div className="timeline-content overflow-visible mb-4 mt-n1">
          <div
            className={clsx(
              "customer-note-item mb-5",
              !is_pinned && "not-pinned"
            )}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="fs-6 fw-bold me-3">
                  {`${firstname} ${lastname}`}
                </div>
                <div className="text-muted me-2 fs-7">
                  <span>
                    {local === "de"
                      ? moment(created_at).format("DD.MM.yyyy HH:mm")
                      : moment(created_at).format("YYYY-MM-DD h:mm A")}
                  </span>
                </div>
                {((crudPermissionForDeals?.edit && module === "deals" && deals_id) ||
                  (crudPermission?.edit && module === "contacts" && users_id)) && (
                  <button
                    type="button"
                    className="btn btn-sm btn-icon btn-light btn-active-light-primary me-4 pin-btn"
                    onClick={() => !loading && handlePin(notes_id, !is_pinned)}
                    disabled={loading}
                  >
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <i
                        className={`bi bi-pin-angle-fill fs-4 ${is_pinned ? "text-primary" : ""}`}
                      ></i>
                    )}
                  </button>
                )}
              </div>
              <div className="d-flex align-items-center">
                <NotesListActionDropdown
                  getNotesListAPI={getNotesListAPI}
                  noteDetails={note}
                  onEdit={onEdit}
                  setShowNoteLogsModal={() => setShowTaskLogsModal(true)}
                />
              </div>
            </div>

            <div>
              {updated_at && (
                <div className="text-muted me-2 fs-7">
                  <span className="fw-bold">
                    {intl.formatMessage({
                      id: "CUSTOMER_MANAGEMENT_PROFILE_NOTES_LAST_UPDATED_DATETIME",
                    })}
                    :&nbsp;
                  </span>
                  <span>
                    {local === "de"
                      ? moment(updated_at).format("DD.MM.YYYY HH:mm")
                      : moment(updated_at).format("YYYY-MM-DD h:mm A")}
                  </span>
                </div>
              )}
              <p className="fs-6 text-gray-700">{description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoteCard;
