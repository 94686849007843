import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// custom
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import AuthInit from "./modules/auth/redux/AuthInit";
import { Routes } from "./routing/Routes";
import GlobalModal from "./sharedComponents/modals/ModalContext";

// import { useSelector } from "react-redux";
// import { RootState } from "../setup";
import Gleap from "gleap"; // Please make sure to call this method only once! Gleap.initialize("yrDAxxLcJsvTEtYwQy7n0Ww3BTACWsM2");
import { useLang } from "../_metronic/i18n/Metronici18n";

// import config from "./config";

type Props = {
  basename?: string;
};

const App: React.FC<Props> = ({ basename }) => {
  // gleap integration and language set up
  Gleap.initialize("yrDAxxLcJsvTEtYwQy7n0Ww3BTACWsM2");
  const locale = useLang();
  Gleap.setLanguage(locale);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <GlobalModal>
                <ToastContainer />
                <Routes />
              </GlobalModal>
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export { App };
