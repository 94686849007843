import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import clsx from "clsx";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import CloseButton from "../../../../sharedComponents/closeButton/CloseButton";
import { addLayout, getLayoutDetails, updateLayout } from "../redux/LayoutApi";
import { successToast } from "../../../../sharedComponents/toasts/Toasts";

interface ModalProps {
  show: boolean;
  type?: string;
  layout?: any;
}

interface LayoutProps {
  modalProps: ModalProps;
  closeModal: () => void;
  getLayoutListAPI: () => void;
}

const AddLayoutModal: FC<LayoutProps> = ({
  modalProps,
  closeModal,
  getLayoutListAPI,
}) => {
  const { show, type, layout } = modalProps;
  // state
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const history = useHistory();

  const layoutSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({
        id: "MASTERDATA_SALES_LAYOUT_ADD_TITLE_IS_REQUIRED",
      })
    ),
  });

  const initialValues = {
    name: type === "edit" ? layout?.name || "" : "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: layoutSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      if (type === "edit") {
        updateLayout(layout?.id, "name", values?.name)
          .then(() => {
            closeModal();
            successToast(
              intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_EDIT_CONFIRM_MESSAGE",
              })
            );
          })
          .catch((err) => {
            setStatus(
              err.response.data?.errors?.value[0] ||
                intl.formatMessage({
                  id: "MASTERDATA_SALES_LAYOUT_EDIT_FAILURE_MESSAGE",
                })
            );
          })
          .finally(() => {
            setLoading(false);
            getLayoutListAPI?.();
          });
      } else {
        addLayout(values.name)
          //@ts-ignore
          .then(async ({ data: { headerFooterId } }) => {
            closeModal();
            let createdItem = await getLayoutDetails(headerFooterId)
              // @ts-ignore
              .then(({ data: { data } }) => {
                return data;
              });
            successToast(
              intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_ADD_SUCCESS_MESSAGE",
              })
            );
            history.push(`/sales/master-data/layout/edit`, {
              headerState: createdItem,
            });
          })
          .catch((err) => {
            setStatus(
              err.response.data?.errors?.name[0] ||
                intl.formatMessage({
                  id: "MASTERDATA_SALES_LAYOUT_ADD_FAILURE_MESSAGE",
                })
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  return (
    <Modal
      show={show}
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      centered
      backdrop="static"
    >
      <Modal.Header>
        <div className="d-flex flex-row flex-fill align-items-center justify-content-between">
          <Modal.Title>
            {type === "edit"
              ? intl.formatMessage({ id: "MASTERDATA_SALES_EDIT_LAYOUT_TITLE" })
              : intl.formatMessage({
                  id: "MASTERDATA_SALES_ADD_NEW_LAYOUT_TITLE",
                })}
          </Modal.Title>

          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            {/* layout name */}
            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
              <span className="required">
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_LAYOUT_TEMPLATE_NAME",
                })}
              </span>
            </label>
            <input
              placeholder={""}
              className={clsx(
                "form-control form-control-lg form-control-solid"
              )}
              {...formik.getFieldProps("name")}
              autoComplete="off"
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-danger">
                {/*@ts-ignore */}
                <span role="alert">{formik.errors.name}</span>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "MASTERDATA_SALES_LAYOUT_LOADING_MESSAGE",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddLayoutModal;
