import axios from "axios";
import {
  FieldResponseByID,
  FieldsResponseType,
} from "../types/FieldsReponseTypes";

export function getFieldsList() {
  return axios.get<FieldsResponseType>(`/fields`);
}

export function createField(
  name: any,
  type: any,
  is_fixed: any,
  tooltip: any,
  placeholder?: any,
  enable_tooltip?: any,
  options?: any,
  data?: string
) {
  return axios.post(`/fields`, {
    name,
    type,
    is_fixed,
    tooltip,
    placeholder,
    enable_tooltip,
    options: options ? options : null,
    data: data ? data : null,
  });
}

export function getFieldByID(id: number) {
  return axios.get<FieldResponseByID>(`/fields/${id}`);
}

export function updateFields(
  id: number | undefined,
  name: string,
  type: string,
  is_fixed: any,
  options: any,
  tooltip: any,
  placeholder: any,
  enable_tooltip: any,
  data: string
) {
  return axios.post(`/fields/${id}`, {
    name,
    type,
    options,
    is_fixed,
    tooltip,
    placeholder,
    enable_tooltip,
    data,
  });
}

export function deleteField(id: number) {
  return axios.delete(`/fields/${id}`);
}

export function massDeleteFields(fields_id: any[]) {
  return axios.post(`/fields/massDelete`, {
    fields_id,
  });
}

export function fieldSort(fields_sorting: any[]) {
  return axios.post(`/fields/sorting`, {
    fields_sorting,
  });
}

export function deleteSelectedField(section_fields_id: any[]) {
  return axios.post(`/tab/panels/sections/delete/fields`, {
    section_fields_id,
  });
}

export function updatePanelHeading(tab_panel_id: any, heading: any) {
  return axios.post(`/tab/panels/update/header/${tab_panel_id}`, {
    heading,
  });
}
