import React, { FC } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";

import ColorPickerInput from "../../../../sharedComponents/colorPickerInput/ColorPickerInput";

import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";

import { useFormik } from "formik";
import { LayoutListType } from "../types/LayoutResponsetypes";
import { updateLayout } from "../redux/LayoutApi";

interface SystemSettingsProps {
  layout: LayoutListType;
  getLayoutDetailsAPI: () => void;
}

const FooterColors: FC<SystemSettingsProps> = ({
  layout,
  getLayoutDetailsAPI,
}) => {
  const intl = useIntl();
  const initialValues = {
    optimized_color: (layout && layout?.footer_background_color) || "#f86d6d",
    optimized_text_color: (layout && layout?.footer_text_color) || "",
  };

  const ColorAndFontSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues,
    validationSchema: ColorAndFontSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (layout?.footer_background_color !== values?.optimized_color) {
        updateLayout(
          layout?.id,
          "footers_background_color",
          values.optimized_color
        )
          .then(() => {
            successToast(
              intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_FOOTER_BACKGROUND_COLOR_UPDATE_MESSAGE",
              })
            );
          })
          .catch(() => {
            errorToast(
              intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_FOOTER_BACKGROUND_COLOR_ERROR_MESSAGE",
              })
            );
          })
          .finally(() => {
            getLayoutDetailsAPI();
          });
      }

      if (layout?.footer_text_color !== values?.optimized_text_color) {
        updateLayout(
          layout?.id,
          "footers_text_color",
          values.optimized_text_color
        )
          .then(() => {
            successToast(
              intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_FOOTER_TEXT_COLOR_UPDATE_MESSAGE",
              })
            );
          })
          .catch(() => {
            errorToast(
              intl.formatMessage({
                id: "MASTERDATA_SALES_LAYOUT_FOOTER_TEXT_COLOR_ERROR_MESSAGE",
              })
            );
          })
          .finally(() => {
            getLayoutDetailsAPI();
          });
      }
    },
  });

  return (
    <div className="card card-flush">
      <div className="card-header mb-3">
        <div className="card-title">
          <h2>
            {intl.formatMessage({
              id: "MASTERDATA_SALES_LAYOUT_FOOTER_COLOR_TITLE",
            })}
          </h2>
        </div>
      </div>

      {/* background color */}
      <div
        className="card-body pt-0"
        onBlur={() => {
          formik.handleSubmit();
        }}
      >
        <label className="fs-5 fw-bold mb-2">
          {intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_FOOTER_BACKGROUND_TITLE",
          })}
        </label>
        <ColorPickerInput
          formikProps={formik.getFieldProps("optimized_color")}
          onColorChange={(color) => {
            formik.setFieldValue("optimized_color", color);
          }}
        />
      </div>

      {/*  text color */}
      <div
        className="card-body pt-0"
        onBlur={() => {
          formik.handleSubmit();
        }}
      >
        <label className="fs-5 fw-bold mb-2">
          {intl.formatMessage({
            id: "MASTERDATA_SALES_LAYOUT_FOOTER_TEXT_TITLE",
          })}
        </label>
        <ColorPickerInput
          formikProps={formik.getFieldProps("optimized_text_color")}
          onColorChange={(color) => {
            formik.setFieldValue("optimized_text_color", color);
          }}
        />
      </div>
    </div>
  );
};

export default FooterColors;
