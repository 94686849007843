import { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { dealProps } from "../types/DealPropsType";
import { Tooltip } from "react-tooltip";
import { useIntl } from "react-intl";
import clsx from "clsx";

const PhaseColumn = ({
  // @ts-ignore
  children,
  // @ts-ignore
  className,
  // @ts-ignore
  title,
  // @ts-ignore
  index,
  // @ts-ignore
  count,
  // @ts-ignore
  phase,
  // @ts-ignore
  userData,
}) => {
  const intl = useIntl();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "deal",
    drop: () => ({ name: title, index: index }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    // Override monitor.canDrop() function
    canDrop: (item: dealProps) => {
      //   const { currentPhaseName } = item;
      return true;
    },
  });

  const [isManualEndPhase, setIsManualEndPhase] = useState<boolean>(false);

  const getBackgroundColor = () => {
    if (isOver) {
      if (canDrop) {
        return "#f1faff";
      } else if (!canDrop) {
        return "rgb(255,188,188)";
      }
    } else {
      return "";
    }
  };

  const getMinHeight = () => {
    if (isOver) {
      if (canDrop) {
        return "150px";
      } else if (!canDrop) {
        return "0px";
      }
    } else {
      return "";
    }
  };

  const activeDealCount = () => {
    // Counts active deals if phase is "end" and counts in-progress deals otherwise
    return (phase?.condition === "end" && phase?.display_order === null)  ? phase?.deals?.length : phase?.deals?.filter((deal: any) => deal?.status === "inProgress")?.length;
  };
  useEffect(() => {
    const res = phase?.condition === "end" && phase?.display_order ? true : false;
    setIsManualEndPhase(res);
  }, []);

  return (
    <>
      <div
        ref={drop}
        className={clsx(
          className,
          isOver && canDrop
            ? "bg-light-primary rounded border-primary border border-dashed"
            : ""
        )}
        style={{
          backgroundColor: getBackgroundColor(),
          flex: 1,
          minHeight: getMinHeight(),
        }}
      >
        <div>
          <div className="phase-title">
            <div
              className={clsx(
                "card p-4 my-3",
                isManualEndPhase && `toolip_container_column-${phase.phase_id}`
              )}
              data-tooltip-id={`temp-end-phase-${phase.phase_id}`}
            >
              <h4 className="fs-5 m-0 me-2">
                {title}
                {isManualEndPhase && (
                  <span className="text-danger">*&nbsp;</span>
                )}
                ({activeDealCount()})
              </h4>
              {/* <div
                className="text-muted badge bg-light-secondary"
                style={{
                  border: "0.5px dotted #ccc",
                  borderRadius: "50px",
                  }}
                  >
                  {count}
                  </div> */}
            </div>
            {isManualEndPhase && (
              <Tooltip
                place="top"
                id={`temp-end-phase-${phase.phase_id}`}
                anchorSelect={`.toolip_container_column-${phase.phase_id}`}
              >
                {intl.formatMessage({
                  id: "DEALS_IS_MANUAL_END_PHASE",
                })}
              </Tooltip>
            )}
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export default PhaseColumn;
