import React, { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import clsx from "clsx";

// svg
import { SVGICON } from "../../../../_metronic/helpers";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import LabelIcon from "../../../../_metronic/assets/icons/label.svg";
// import StatusIcon from "../../../../_metronic/assets/icons/flag.svg";
import TaskTypeIcon from "../../../../_metronic/assets/icons/person-digging.svg";
import DealIcon from "../../../../_metronic/assets/icons/file-contract.svg";
import CallIcon from "../../../../_metronic/assets/icons/telephone.svg";
import EmailIcon from "../../../../_metronic/assets/icons/envelope.svg";
import OthersIcon from "../../../../_metronic/assets/icons/clipboard.svg";
import AddUserIcon from "../../../../_metronic/assets/icons/add-user.svg";
import StopwatchIcon from "../../../../_metronic/assets/icons/stopwatch.svg";
import ListCheckIcon from "../../../../_metronic/assets/icons/list-check.svg";

// Components
import DatePicker from "react-datepicker";
import { Button, CloseButton, Modal } from "react-bootstrap";

// Custom Components
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import SelectedUsersModal from "../../teams/components/SelectUsersModal";
import { UserSymbol, AddUserSymbol } from "./UserSymbol";

import { RootState } from "../../../../setup";
import { createTask, updateTask } from "../redux";

// Types
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { TaskAddEditModalProps } from "../types/TasksPropsType";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import DynamicDropdown from "../../../sharedComponents/DynamicDropdown/DynamicDropdown";

export interface ModalComponentProps {
  modalProps: TaskAddEditModalProps;
  closeModal?: () => void;
  getTasksListAPI?: () => void;
}

type Deal = {
  id: number;
  name: string;
  status: string;
};

type GroupedDeals = {
  [pipelineName: string]: Deal[];
};

const AddTaskModal: FC<ModalComponentProps> = ({
  modalProps,
  closeModal,
  getTasksListAPI,
}) => {
  const intl = useIntl();
  const local = useLang();

  const { deals_id, show, action, taskDetails, usersDeals } = modalProps;

  // state
  const [loading, setLoading] = useState<boolean>(false);

  const [showUsersModal, setShowUsersModal] = useState<boolean>(false);
  const usersList = useSelector<RootState>(
    // @ts-ignore
    ({ userManagement }) => userManagement.users
  ) as UserType[];

  const PRIORITY_TYPES = JSON.parse(
    intl.formatMessage({ id: "TASKS_ADD_TASK_PRIORITY_TYPES" })
  ) as any[];
  const TYPE_TYPES = JSON.parse(
    intl.formatMessage({ id: "TASKS_ADD_TASK_TYPE_TYPES" })
  ) as any[];
  // const STATUS_TYPES = JSON.parse(
  //   intl.formatMessage({ id: "TASKS_ADD_TASK_STATUS_TYPES" })
  // ) as any[];

  const initialValues = {
    deals_id: deals_id || taskDetails?.deal?.id,
    title: taskDetails?.title || "",
    type: taskDetails?.type || TYPE_TYPES.find((item) => item.id === 2).name,
    module: taskDetails?.module || (deals_id ? "deals" : "contacts"),
    // status: taskDetails?.status || "to-do",
    due_date: taskDetails?.due_date || "",
    priority:
      taskDetails?.priority ||
      PRIORITY_TYPES.find((item) => item.id === 1).name,
    description: taskDetails?.description || "",
    assignees_ids:
      (taskDetails?.assignees &&
        taskDetails?.assignees?.length > 0 &&
        taskDetails?.assignees.map((item) => item.id)) ||
      [],
  };

  const validationSchema = Yup.object().shape({
    deals_id: Yup.string().nullable(),
    type: Yup.string()
      .required(
        intl.formatMessage({ id: "TASKS_TYPE_FIELD_VALIDATION_MESSAGE" })
      )
      .oneOf([...TYPE_TYPES.map((item) => item.name)]),
    title: Yup.string().required(
      intl.formatMessage({ id: "TASKS_TITLE_FIELD_VALIDATION_MESSAGE" })
    ),
    module: Yup.string().required(
      intl.formatMessage({ id: "TASKS_DUE_DATE_FIELD_VALIDATION_MESSAGE" })
    ),
    // status: Yup.string().required(
    //   intl.formatMessage({ id: "TASKS_STATUS_FIELD_VALIDATION_MESSAGE" })
    // ),
    due_date: Yup.string().required(
      intl.formatMessage({ id: "TASKS_DUE_DATE_FIELD_VALIDATION_MESSAGE" })
    ),
    priority: Yup.string()
      .required(
        intl.formatMessage({ id: "TASKS_PRIORITY_FIELD_VALIDATION_MESSAGE" })
      )
      .oneOf([...PRIORITY_TYPES.map((item) => item.name)]),
    description: Yup.string(),
    assignees_ids: Yup.array().of(
      Yup.number()
        .required()
        //TODO: update the condition to only select users which are not already selected (optional)
        .oneOf([...usersList.map((user) => user.id)])
    ),
  });

  const handleUpdate = (task_id: number, values: any) => {
    // if deals_id is not present then remove deals_id from the api payload
    const { deals_id, ...params } = values;
    if (deals_id) {
      params.deals_id = deals_id;
    }
    updateTask(task_id, params)
      .then(() => {
        closeModal?.();
        successToast(
          intl.formatMessage({ id: "TASKS_EDIT_TASK_SUCCESS_MESSAGE" })
        );
      })
      .catch((err) => {
        let errorMessage = intl.formatMessage({
          id: "TASKS_EDIT_TASK_ERROR_MESSAGE",
        });
        if (err.response?.data?.errors?.email) {
          errorMessage = err.response.data?.errors?.email;
        }
        errorToast(errorMessage);
      })
      .finally(() => {
        getTasksListAPI?.();
        setLoading(false);
      });
  };

  const handleCreate = (values: any) => {
    // if deals_id is not present then remove deals_id from the api payload
    const { deals_id, ...params } = values;
    if (deals_id) {
      params.deals_id = deals_id;
    }
    createTask(params)
      .then(() => {
        closeModal?.();
        successToast(
          intl.formatMessage({ id: "TASKS_ADD_NEW_TASK_SUCCESS_MESSAGE" })
        );
      })
      .catch((err) => {
        let errorMessage = intl.formatMessage({
          id: "TASKS_ADD_NEW_TASK_ERROR_MESSAGE",
        });
        if (err.response?.data?.errors?.email) {
          errorMessage = err.response.data?.errors?.email;
        }
        errorToast(errorMessage);
      })
      .finally(() => {
        getTasksListAPI?.();
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      if (action === "edit") {
        // @ts-ignore
        handleUpdate(taskDetails?.id, values);
      } else {
        handleCreate(values);
      }
    },
  });

  const addUserHandle = (users: UserType[]) => {
    const ids = users.map((checkedTeam) => {
      return checkedTeam.id;
    });

    const existingUsersIds = formik.values.assignees_ids.map((checkedUser) => {
      return checkedUser;
    });
    const finalIds = [...ids, ...existingUsersIds];
    formik.setFieldValue("assignees_ids", finalIds);
  };

  const removeUserhandle = (user_id: number) => {
    const existingUsersIds = formik.values.assignees_ids.filter(
      (id) => id !== user_id
    );
    formik.setFieldValue("assignees_ids", existingUsersIds);
  };

  const responsiblePersons = useMemo(
    () =>
      usersList.filter((user) => formik.values.assignees_ids.includes(user.id)),
    [formik.values.assignees_ids]
  );

  const groupedDeals: GroupedDeals = usersDeals
    ?.filter((deal) => deal?.status === "publish")
    .reduce((acc, deal: any) => {
      // @ts-ignore
      const pipelineName = deal?.pipeline_name;
      // @ts-ignore
      if (!acc[pipelineName]) {
        // @ts-ignore
        acc[pipelineName] = [];
      } // @ts-ignore
      acc[pipelineName].push(deal);
      return acc;
    }, {});

  const uniquePipelines = Object.keys(groupedDeals).map((pipelineName) => ({
    pipelineName,
    deals: groupedDeals[pipelineName],
  }));

  const handleDealSelect = (deal_id: number) => {
    formik.setFieldValue("deals_id", deal_id);
  };

  return (
    <>
      {showUsersModal && (
        <SelectedUsersModal
          show={showUsersModal}
          closeModal={() => setShowUsersModal(false)}
          onSelectUsers={(customers) => {
            setShowUsersModal(false);
            addUserHandle(customers);
          }}
          isSelectionModal={true}
          selectedContacts={responsiblePersons}
        />
      )}
      <Modal
        show={show}
        centered
        dialogClassName="medium-size-modal"
        contentClassName={showUsersModal ? "d-none" : "d-block"}
        backdrop="static"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <Modal.Title>
            {action === "new"
              ? intl.formatMessage({ id: "TASKS_ADD_NEW_MODAL_TITLE" })
              : intl.formatMessage({ id: "TASKS_EDIT_NEW_MODAL_TITLE" })}
          </Modal.Title>
          <CloseButton className="ms-2" onClick={closeModal} />
        </Modal.Header>
        <form
          onSubmit={formik.handleSubmit}
          className="overflow-auto"
          noValidate
          id="kt_login_signin_form"
        >
          <Modal.Body className="overflow-inherit">
            <div className="p-4">
              {formik.status && (
                <div className="mb-10 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}

              {/* Debugging */}
              {/* <p className="text-info fw-bold mb-0">
                Formik Values:{" "}
                <span className="text-muted">
                  {JSON.stringify(formik.values)}
                </span>
              </p>
              <p className="text-info fw-bold mb-0">
                Formik Error:{" "}
                <span className="text-muted">
                  {JSON.stringify(formik.errors)}
                </span>
              </p>
              <br /> */}

              {/* Task Title */}
              <div className="row mb-8">
                <div className="col-1 py-3">
                  <label className="form-label d-flex required">
                    <SVGICON src={LabelIcon} className="svg-icon-1 me-n1" />
                  </label>
                </div>
                <div className="col-11">
                  <input
                    type="text"
                    autoComplete="off"
                    {...formik.getFieldProps("title")}
                    className={clsx(
                      "form-control form-control-lg form-control-solid"
                    )}
                    placeholder={intl.formatMessage({
                      id: "TASKS_ADD_TASK_TITLE_FIELD_PLACEHOLDER",
                    })}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.title}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Due Date and Priority */}
              <div className="row mb-8">
                <div className="col-1 py-3">
                  <label className="form-label d-flex required">
                    <SVGICON src={StopwatchIcon} className="svg-icon-1 me-0" />
                  </label>
                </div>
                <div className="col-11">
                  <div className="row align-items-start">
                    {/* Due Date */}
                    <div className="col-7">
                      <DatePicker
                        name="due_date"
                        className="form-control form-control-lg form-control-solid"
                        selected={
                          formik.values.due_date
                            ? new Date(formik.values.due_date)
                            : null
                        }
                        onChange={(val) =>
                          formik.setFieldValue(
                            "due_date",
                            moment(val).format("YYYY-MM-DD HH:mm:ss")
                          )
                        }
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={10}
                        timeCaption="Time"
                        dateFormat={
                          local === "de"
                            ? "dd.MM.yyyy HH:mm"
                            : "yyyy-MM-dd h:mm aa"
                        }
                        placeholderText={intl.formatMessage({
                          id: "TASKS_ADD_TASK_DUE_DATE_FIELD_PLACEHOLDER",
                        })}
                        customInput={
                          <span className="text-muted" role="button">
                            {formik.values.due_date
                              ? formik.values.due_date
                              : intl.formatMessage({
                                  id: "TASKS_ADD_TASK_DUE_DATE_FIELD_PLACEHOLDER",
                                })}
                          </span>
                        }
                      />
                      {formik.touched.due_date && formik.errors.due_date && (
                        <div className="text-danger">
                          <span role="alert">{formik.errors.due_date}</span>
                        </div>
                      )}
                    </div>
                    {/* Priority */}
                    <div className="col-5">
                      <DropDown
                        id="priority"
                        placeholder={intl.formatMessage({
                          id: "TASKS_ADD_TASK_PRIORITY_FIELD_PLACEHOLDER",
                        })}
                        items={PRIORITY_TYPES || []}
                        onSelectItem={(item) => {
                          formik.setFieldValue("priority", item.name);
                        }}
                        displayValue={
                          PRIORITY_TYPES.filter(
                            (item) => item.name === formik.values.priority
                          )[0]?.title
                        }
                        // @ts-ignore
                        displayFunc={(item) => item?.title}
                        hideSearch
                      />
                      {formik.touched.priority && formik.errors.priority && (
                        <div className="text-danger">
                          <span role="alert">{formik.errors.priority}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Task Type */}
              <div className="row mb-8">
                <div className="col-1 py-3">
                  <label className="form-label d-flex">
                    <SVGICON src={TaskTypeIcon} className="svg-icon-1 me-0" />
                  </label>
                </div>
                <div className="col-11">
                  <DropDown
                    id="type"
                    placeholder={intl.formatMessage({
                      id: "TASKS_ADD_TASK_TYPE_FIELD_NAME",
                    })}
                    items={TYPE_TYPES || []}
                    onSelectItem={(item) => {
                      formik.setFieldValue("type", item.name);
                    }}
                    displayValue={
                      TYPE_TYPES.filter(
                        (item) => item.name === formik.values.type
                      )[0]?.title
                    }
                    displayFunc={(item) => {
                      return (
                        <span className="d-flex align-items-center">
                          <SVGICON
                            src={
                              item.name === "call"
                                ? CallIcon
                                : item.name === "email"
                                  ? EmailIcon
                                  : item.name === "other"
                                    ? OthersIcon
                                    : null
                            }
                            className="svg-icon-3 me-4"
                          />
                          {/* @ts-ignore */}
                          {item?.title}
                        </span>
                      );
                    }}
                    hideSearch
                  />
                  {formik.touched.type && formik.errors.type && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.type}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Status Type */}
              {/* {action === "edit" && (
                <div className="row mb-8">
                  <div className="col-1 py-3">
                    <label className="form-label d-flex required">
                      <SVGICON src={StatusIcon} className="svg-icon-1 me-0" />
                    </label>
                  </div>
                  <div className="col-11">
                    <DropDown
                      id="status"
                      placeholder={intl.formatMessage({
                        id: "TASKS_ADD_TASK_STATUS_FIELD_NAME",
                      })}
                      items={STATUS_TYPES || []}
                      // @ts-ignore
                      selectedItem={formik.values.status}
                      onSelectItem={(item) => {
                        formik.setFieldValue("status", item.name);
                      }}
                      displayValue={
                        STATUS_TYPES.filter(
                          (item) => item.name === formik.values.status
                        )[0]?.title
                      }
                      // @ts-ignore
                      displayFunc={(item) => item?.title}
                      hideSearch
                    />
                    {formik.touched.status && formik.errors.status && (
                      <div className="text-danger">
                        <span role="alert">{formik.errors.status}</span>
                      </div>
                    )}
                  </div>
                </div>
              )} */}

              {/* Select Deal */}
              {!deals_id && (
                <div className="row mb-8">
                  <div className="col-1 py-3">
                    <SVGICON src={DealIcon} className="svg-icon-1 me-0" />
                  </div>
                  <div className="col-11">
                    <DynamicDropdown
                      id="deals_id"
                      handleDealSelect={handleDealSelect}
                      selectedItem={
                        usersDeals.filter(
                          (item) => item.id === formik.values.deals_id
                        )[0]?.name
                      }
                      isDeals
                      dealsList={uniquePipelines}
                    />
                    {formik.touched.deals_id && formik.errors.deals_id && (
                      <div className="text-danger">
                        <span role="alert">{formik.errors.deals_id}</span>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Task Description */}
              <div className="row mb-8">
                <div className="col-1 py-3">
                  <label className="form-label">
                    <SVGICON src={ListCheckIcon} className="svg-icon-1 me-0" />
                  </label>
                </div>
                <div className="col-11">
                  <textarea
                    autoComplete="off"
                    {...formik.getFieldProps("description")}
                    className={clsx(
                      "form-control form-control-lg form-control-solid task-description-textarea"
                    )}
                    rows={5}
                    placeholder={intl.formatMessage({
                      id: "TASKS_ADD_TASK_DESCRIPTION_FIELD_NAME",
                    })}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.description}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Add Responsible Persons */}
              <div className="row mb-8">
                <div className="col-1 py-3"></div>
                <div className="col-11">
                  <label className="form-label">
                    {intl.formatMessage({
                      id: "TASKS_FILTER_RESPONSIBLE_PERSONS_FIELD_NAME",
                    })}
                  </label>
                </div>
                <div className="col-1 py-3">
                  <label className="form-label">
                    <SVGICON src={AddUserIcon} className="svg-icon-1 me-0" />
                  </label>
                </div>
                <div className="col-11">
                  <div className="d-flex justify-content-start">
                    <div className="symbol-group symbol-hover flex-nowrap">
                      {responsiblePersons?.map((data, i) => (
                        <React.Fragment key={i}>
                          <UserSymbol
                            data={data}
                            handleRemove={removeUserhandle}
                          />
                        </React.Fragment>
                      ))}
                      <AddUserSymbol
                        tooltipText="Add User"
                        onClick={() => {
                          setShowUsersModal(true);
                        }}
                      />
                    </div>
                  </div>
                  {formik.touched.assignees_ids &&
                    formik.errors.assignees_ids && (
                      <div className="text-danger">
                        <span role="alert">{formik.errors.assignees_ids}</span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="justify-content-center">
            <Button variant="secondary" onClick={closeModal}>
              {intl.formatMessage({ id: "CLOSE_BUTTON" })}
            </Button>
            <Button
              type="submit"
              id="kt_sign_in_submit"
              className="btn  btn-primary"
            >
              {!loading && (
                <span className="indicator-label">
                  {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                </span>
              )}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "CUSTOMER_MANAGEMENT_ADD_CUSTOMER_LOADING_MESSAGE",
                  })}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddTaskModal;
