import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import CloseButton from "../../../../sharedComponents/closeButton/CloseButton";
import LayoutList from "../../SalesLayout/components/LayoutList";

interface Props {
  show: boolean;
  closeModal: () => void;
  isSelectionModal?: boolean;
  onHandleApply?: (id: number) => void;
  selectedfooter?: any;
}

const LayoutListModal: FC<Props> = ({
  show,
  closeModal,
  isSelectionModal,
  onHandleApply,
  selectedfooter,
}) => {
  const intl = useIntl();
  const [isFilter, setIsFilter] = useState(false);

  return (
    <Modal
      show={show}
      centered
      dialogClassName={"extra-large-size-modal"}
      contentClassName={isFilter ? "d-none" : ""}
      onHide={closeModal}
    >
      <Modal.Header>
        <div className="d-flex flex-row flex-fill align-items-center justify-content-between  ">
          <Modal.Title>
            {intl.formatMessage({
              id: "MASTERDATA_SALES_SETTINGS_LAYOUT_MODAL_TITLE",
            })}
          </Modal.Title>
          <CloseButton onClose={closeModal} />
        </div>
      </Modal.Header>
      <Modal.Body
        className={`${isSelectionModal ? "selections_modal" : ""} pt-0`}
      >
        <LayoutList
          isSelectionModal={isSelectionModal}
          onHandleApply={onHandleApply}
          selectedfooter={selectedfooter}
        />
      </Modal.Body>
    </Modal>
  );
};

export default LayoutListModal;
