import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";

import "./Invoices.scss";

import { useGlobalModalContext } from "../../../../sharedComponents/modals/ModalContext";

import { SVGICON } from "../../../../../_metronic/helpers";
import CloseIcon from "../../../../../_metronic/assets/icons/close.svg";
import PlusIcon from "../../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../../_metronic/assets/icons/search.svg";
import { useLang } from "../../../../../_metronic/i18n/Metronici18n";
import { formatPriceLocale } from "../../../../utils/PriceFormatLocale";

import {
  errorToast,
  successToast,
} from "../../../../sharedComponents/toasts/Toasts";
import Tabs from "../../../../sharedComponents/tabs/Tabs";
import Checkbox from "../../../../sharedComponents/Checkbox/Checkbox";
import SimpleLoader from "../../../../sharedComponents/Loader/SimpleLoader";
import Pagination from "../../../../sharedComponents/pagination/Pagination";
import NoItemsFound from "../../../../sharedComponents/NoItemsFound/NoItemsFound";

import InvoicesListActionsDropDown from "./InvoicesListActionsDropDown";
import InvoicePDF from "./InvoicePDF";

import { RootState } from "../../../../../setup";
import {
  OrderExternalItemType,
  InvoicePaginationType,
} from "../types/InvoicesTypes";
import { actions as invoicesActions } from "../redux/InvoicesRedux";
import { LayoutListType } from "../../../salesMasterData/SalesLayout/types/LayoutResponsetypes";
import { getSalesSettings } from "../../../salesMasterData/SalesSettings/redux/SalesSettingsApi";
import {
  downloadImageFooterLayout,
  getLayoutDetails,
} from "../../../salesMasterData/SalesLayout/redux/LayoutApi";

import {
  getInvoices,
  getInvoicesTrashList,
  getInvoiceDetails,
  restoreInvoice,
  massDeleteInvoices,
  massForceDeleteInvoices,
  massRestoreInvoices,
} from "../redux";
import { getProductsAndItems } from "../../Offers/redux/SalesApi";
import { InvoiceType } from "../types/InvoicesTypes";

const InvoiceOverVeiw: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useLang();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const invoices = useSelector<RootState>(
    // @ts-ignore
    ({ invoices }) => invoices?.invoicesList
  ) as InvoiceType[];

  const invoicesSearchText = useSelector<RootState>(
    // @ts-ignore
    ({ invoices }) => invoices?.invoicesSearchText
  ) as string;

  const invoicesActivePageData = useSelector<RootState>(
    // @ts-ignore
    ({ invoices }) => invoices?.activePageDetails as number
  );

  const invoicesPaginationData = useSelector<RootState>(
    // @ts-ignore
    ({ invoices }) => invoices?.invoicesPagination
  ) as InvoicePaginationType;

  const invoicesTabActiveIndex = useSelector<RootState>(
    // @ts-ignore
    ({ invoices }) => invoices?.invoicesTabActiveIndex as number
  );
  const [tabActiveIndex, setTabActiveIndex] = useState<number>(
    //@ts-ignore
    invoicesTabActiveIndex || 0
  );
  const orderSearchText = tabActiveIndex === 7 ? "" : invoicesSearchText;

  const [invoicesList, setInvoiceList] = useState<InvoiceType[]>([]);
  const [invoicesPagination, setInvoicesPagination] =
    useState<InvoicePaginationType>(invoicesPaginationData);
  const [searchText, setSearchText] = useState<string>(invoicesSearchText);
  const [checkedInvoices, setCheckedInvoices] = useState<InvoiceType[]>([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);
  const [createdLoading, setCreateLoading] = useState<boolean>(false);
  const [layoutFooterDetails, setLayoutFooterDetails] =
    useState<LayoutListType>();
  const [headerID, setHeaderID] = useState<any>();
  const [activePage, setActivePage] = useState<number>(
    //@ts-ignore
    Array.isArray(invoicesActivePageData) ? 1 : invoicesActivePageData || 1
  );

  const [status, setStatus] = useState<string>(
    invoicesTabActiveIndex === 1
      ? "draft"
      : invoicesTabActiveIndex === 2
        ? "open"
        : invoicesTabActiveIndex === 3
          ? "paid"
          : invoicesTabActiveIndex === 4
            ? "partiallyPaid"
            : invoicesTabActiveIndex === 5
              ? "overDue"
              : invoicesTabActiveIndex === 6
                ? "cancel"
                : "all"
  );

  // Custom Pagination Items
  const customPaginationItems = JSON.parse(
    intl.formatMessage({ id: "TASKS_NUMBER_ITEMS_PER_PAGE_OPTIONS" })
  ) as any[];

  const [itemsPerPage, setItemsPerPage] = useState(
    customPaginationItems[0].value
  );
  const [getInvoiceListAPICompleted, setGetInvoicesListAPICompleted] =
    useState<boolean>(false);

  const getInvoicesListAPI = (
    statusParam?: any,
    ordersSearchTextParam?: string,
    limit?: number,
    page?: number
  ) => {
    if (page) {
      dispatch(invoicesActions.setInvoicesActivePageDetails(page));
    }
    setGetInvoicesListAPICompleted(false);
    setInvoiceList([]);
    getInvoices(
      statusParam ? statusParam : status,
      ordersSearchTextParam,
      limit ? limit : 10,
      page
    )
      .then(({ data: { invoices = [], paginationDetails } }) => {
        setActivePage(page ? page : activePage);
        setInvoicesPagination(paginationDetails);
        setInvoiceList(invoices);
        dispatch(
          invoicesActions.setInvoicesActivePageDetails(
            paginationDetails?.current_page
          )
        );
        dispatch(invoicesActions.setInvoicesList(invoices));
        dispatch(invoicesActions.setInvoicesPagination(paginationDetails));
      })
      .catch((error) => {
        console.log(error);
        // console.error(error?.response?.data?.message);
      })
      .finally(() => {
        setGetInvoicesListAPICompleted(true);
      });
  };

  const getOrdersTrashListAPI = () => {
    setGetInvoicesListAPICompleted(false);
    getInvoicesTrashList()
      .then(({ data: { invoices = [] } }) => {
        dispatch(invoicesActions.setInvoicesList(invoices));
        setInvoiceList(invoices);
      })
      .catch((error) => console.error(error?.response?.data?.message))
      .finally(() => {
        setGetInvoicesListAPICompleted(true);
        setSearchText("");
        dispatch(invoicesActions.setInvoicesSearchText(""));
      });
  };

  const updateTrashOrdersList = () => {
    const updateTrashOrders = invoices?.filter((item) =>
      (
        (item?.sales_details?.to_name || "") +
        (item?.sales_details?.from_name || "")
      )
        .toLocaleLowerCase()
        .includes((searchText || "").toLocaleLowerCase())
    );
    setInvoiceList(updateTrashOrders);
  };

  useEffect(() => {
    checkedInvoices?.length === 0 && setCheckAllItems(false);
    checkedInvoices?.length !== 0 &&
      checkedInvoices?.length === invoicesList?.length &&
      setCheckAllItems(true);
  }, [checkedInvoices]);

  useEffect(() => {
    invoicesList?.length === 0 && setCheckedInvoices([]);
    return () => {
      setCheckedInvoices([]);
    };
  }, [invoicesList]);

  const onPageClick = (page: number) => {
    setActivePage(page);
    dispatch(invoicesActions.setInvoicesActivePageDetails(page));
  };

  useEffect(() => {
    if (tabActiveIndex === 7) {
      setGetInvoicesListAPICompleted(false);
      getOrdersTrashListAPI();
    } else {
      getInvoicesListAPI(status, searchText, itemsPerPage, activePage);
    }
  }, [itemsPerPage, activePage]);

  const onSearchTextChange = (text: string) => {
    setSearchText(text || "");
  };

  const handleGetOrderSettings = () => {
    getSalesSettings()
      .then(
        ({
          data: {
            settingsDetails: { salesSettingsDetails },
          },
        }) => {
          let headerFooterId = salesSettingsDetails?.filter((item) => {
            return item.id === 3;
          })?.[0]?.answer;
          setHeaderID(headerFooterId);
          if (Number(headerFooterId)) {
            getLayoutDetails(Number(headerFooterId))
              .then(({ data: { data } }) => {
                setLayoutFooterDetails(data);
              })
              .catch((error) => console.error(error?.response?.data?.message));
          }
        }
      )
      .catch((error) => console.error(error?.response?.data?.message));
  };

  const generatePdfDocument = async (order: InvoiceType, minus: boolean) => {
    setCreateLoading(true);
    getInvoiceDetails(order?.id)
      .then(({ data: { invoiceDetails } }) => {
        if (layoutFooterDetails?.headers_logo_id) {
          downloadImageFooterLayout(layoutFooterDetails?.headers_logo_id)
            .then(async ({ data }) => {
              const fileUrl = URL.createObjectURL(data);
              const blob = await pdf(
                <InvoicePDF
                  invoice={invoiceDetails}
                  layoutFooterDetails={layoutFooterDetails}
                  logoURL={fileUrl}
                  minus={minus}
                  lang={lang}
                />
              ).toBlob();
              saveAs(blob, order?.invoice_number);
            })
            .catch((error) => console.error(error?.response?.data?.message))
            .finally(() => {
              setTimeout(() => {
                setCreateLoading(false);
              }, 1000);
            });
        } else {
          const downloadPdf = async () => {
            const blob = await pdf(
              <InvoicePDF
                invoice={invoiceDetails}
                layoutFooterDetails={layoutFooterDetails}
                logoURL={undefined}
                minus={minus}
                lang={lang}
              />
            ).toBlob();
            saveAs(blob, order?.invoice_number);
          };
          downloadPdf().finally(() => {
            setTimeout(() => {
              setCreateLoading(false);
            }, 1000);
          });
        }
      })
      .catch((error) => console.error(error?.response?.data?.message));
  };

  useEffect(() => {
    handleGetOrderSettings();
  }, []);

  const onTabChange = (index: number) => {
    if (index !== tabActiveIndex) {
      dispatch(invoicesActions.setInvoicesTabActiveIndex(index));
      setSearchText("");
      dispatch(invoicesActions.setInvoicesSearchText(""));
      setActivePage(1);
      setTabActiveIndex(index);
      setInvoiceList([]);
      if (index === 1) {
        setStatus("draft");
        getInvoicesListAPI("draft", "", itemsPerPage, 1);
      } else if (index === 2) {
        setStatus("open");
        getInvoicesListAPI("open", "", itemsPerPage, 1);
      } else if (index === 3) {
        setStatus("paid");
        getInvoicesListAPI("paid", "", itemsPerPage, 1);
      } else if (index === 4) {
        setStatus("partiallyPaid");
        getInvoicesListAPI("partiallyPaid", "", itemsPerPage, 1);
      } else if (index === 5) {
        setStatus("overDue");
        getInvoicesListAPI("overDue", "", itemsPerPage, 1);
      } else if (index === 6) {
        setStatus("cancel");
        getInvoicesListAPI("cancel", "", itemsPerPage, 1);
      } else if (index === 7) {
        getOrdersTrashListAPI();
      } else {
        setStatus("all");
        getInvoicesListAPI("all", "", itemsPerPage, 1);
      }
    }
  };

  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedItems =
    invoicesList && invoicesList?.length > itemsPerPage
      ? invoicesList.slice(startIndex, startIndex + itemsPerPage)
      : invoicesList;

  const onAllItemsCheckChange = () => {
    const updatedCheckAllItems = !checkAllItems;
    setCheckAllItems(updatedCheckAllItems);
    if (updatedCheckAllItems) {
      setCheckedInvoices(tabActiveIndex !== 8 ? invoicesList : paginatedItems);
    } else {
      setCheckedInvoices([]);
    }
  };

  const deleteSelectedItems = () => {
    const ordersIds = checkedInvoices.map((order) => {
      return order.id;
    });
    setDeleteModalLoading(true);
    massDeleteInvoices(ordersIds)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "INVOICES_DELETE_SUCCESS_MESSAGE",
          })
        );
        setCheckedInvoices([]);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "INVOICES_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getInvoicesListAPI(
          status,
          searchText,
          itemsPerPage,
          paginatedItems?.length === ordersIds?.length && activePage - 1 > 1
            ? activePage - 1
            : activePage
        );
        dispatch(invoicesActions.setInvoicesActivePageDetails(activePage));
      });
  };

  const forceDeleteSelectedItems = () => {
    const ids = checkedInvoices.map((item) => {
      return item.id;
    });
    setDeleteModalLoading(true);
    massForceDeleteInvoices(ids)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "INVOICES_FORCE_MASS_DELETE_SUCCESS_MESSAGE",
          })
        );
        setCheckedInvoices([]);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "INVOICES_FORCE_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOrdersTrashListAPI();
        setCheckAllItems(false);
      });
  };

  const onCheckedChange = (order: InvoiceType) => {
    let ordersCopy = _.clone(checkedInvoices);

    const index = ordersCopy.findIndex((item) => item.id === order.id);
    if (index > -1) {
      ordersCopy.splice(index, 1);
      setCheckAllItems(false);
    } else {
      ordersCopy.push(order);
    }
    setCheckedInvoices(ordersCopy);
  };

  const isChecked = (order: InvoiceType) => {
    const index = checkedInvoices.findIndex((item) => item.id === order.id);
    if (index > -1) {
      return true;
    }
    return false;
  };

  const handleSearch = () => {
    dispatch(invoicesActions.setInvoicesSearchText(""));
    setSearchText("");
    getInvoicesListAPI(status, "", itemsPerPage, 1);
  };

  const handleRestoreOrder = (order: InvoiceType) => {
    restoreInvoice(order.id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "INVOICES_RESTORE_SUCCESS_MESSAGE" })
        );
        setCheckedInvoices([]);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "INVOICES_RESTORE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getOrdersTrashListAPI();
      });
  };

  const restoreSelectedItems = () => {
    const ids = checkedInvoices.map((item) => {
      return item.id;
    });
    setDeleteModalLoading(true);
    massRestoreInvoices(ids)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "INVOICES_MASS_RESTORE_SUCCESS_MESSAGE" })
        );
        setCheckedInvoices([]);
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "INVOICES_MASS_RESTORE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getOrdersTrashListAPI();
      });
  };

  useEffect(() => {
    if (
      tabActiveIndex !== 7 &&
      orderSearchText?.length > 2 &&
      searchText?.length === 0
    ) {
      onSearchTextChange("");
      dispatch(invoicesActions.setInvoicesSearchText(""));
    }

    if (tabActiveIndex === 7 && searchText?.length === 0) {
      setSearchText("");
      setInvoiceList(invoices);
    }
  }, [searchText, orderSearchText]);

  useEffect(() => {
    getProductsAndItems()
      .then(({ data: { productDetails } }) => {
        dispatch(invoicesActions.setProductsAndItems(productDetails));
      })
      .catch((error) => console.error(error?.response?.data?.message));
  }, []);

  const [loading, setLoading] = useState<boolean>(false);

  const getClassName = (status: string) => {
    if (status === "draft") {
      return "draft";
    } else if (status === "open") {
      return "open";
    } else if (status === "paid") {
      return "paid";
    } else if (status === "partiallyPaid") {
      return "partiallyPaid";
    } else if (status === "overDue") {
      return "overDue";
    } else {
      return "cancel";
    }
  };

  const getInternalInitialValues = (internalItems: any) => {
    let arr: any[] = [];
    internalItems?.forEach((item: any) => {
      arr.push({
        id: item?.id,
        title: item?.product_title,
        description: item?.product_description || "",
        quantity: item?.quantity || 1,
        price: item?.unit_price ? (item?.unit_price / 100).toFixed(2) : null,
        tax_percentage: item?.tax_percentage || null,
        discount_percentage: item?.discount_percentage,
      });
    });

    return arr;
  };

  const getProductPrice = (item: OrderExternalItemType) => {
    if (!item.price) {
      return 0;
    }

    const tax_behaviour = "inclusive";

    let initialPrice =
      Number(item.price) * Number(item.quantity ? item.quantity : 1);
    let discountAmount = item.discount_percentage
      ? initialPrice * (Number(item.discount_percentage) / 100)
      : 0;
    let discountedPrice = initialPrice - discountAmount;

    let taxAmount = item.tax_percentage
      ? discountedPrice * (Number(item.tax_percentage) / 100)
      : 0;

    return discountedPrice + (tax_behaviour === "inclusive" ? 0 : taxAmount);
  };

  const getTotalAmount = (order: InvoiceType) => {
    let result = 0;
    [...getInternalInitialValues(order?.sales_details?.sale_items)]?.forEach(
      (item) => {
        result += +getProductPrice(item).toFixed(2);
      }
    );

    // return resultsales/settings
    return Number(result.toFixed(2));
  };

  return (
    <div className="card mt-7">
      <div className="card-header border-0 pt-8 d-flex flex-row justify-content-between">
        <div className="d-flex align-items-center">
          <div className="position-relative">
            {(tabActiveIndex === 7
              ? orderSearchText && orderSearchText?.length >= 3
              : searchText && searchText?.length >= 3) && (
              <div
                className="position-absolute"
                style={{
                  right: 8,
                  top: 10,
                  zIndex: 99,
                }}
                role={"button"}
              >
                <span
                  onClick={() => {
                    if (tabActiveIndex === 7) {
                      setSearchText("");
                      setInvoiceList(invoices);
                    } else {
                      handleSearch();
                    }
                  }}
                >
                  <SVGICON src={CloseIcon} className="svg-icon svg-icon-1 " />
                </span>
              </div>
            )}
            <input
              type="text"
              className="form-control form-control-solid w-250px position-relative"
              placeholder={intl.formatMessage({
                id: "INVOICES_SEARCH_PLACEHOLDER",
              })}
              value={searchText}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
                if (
                  invoicesSearchText?.length > 0 &&
                  e.target.value?.length === 0
                ) {
                  handleSearch();
                }
              }}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                paddingRight: "30px",
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (searchText && searchText?.length >= 3) {
                    if (tabActiveIndex === 7) {
                      setSearchText(searchText);
                      updateTrashOrdersList();
                    } else {
                      dispatch(invoicesActions.setInvoicesActivePageDetails(1));
                      dispatch(
                        invoicesActions.setInvoicesSearchText(searchText)
                      );
                      setSearchText(searchText);
                      getInvoicesListAPI(status, searchText, itemsPerPage, 1);
                    }
                  }
                }
              }}
            />
          </div>

          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              if (searchText && searchText.length >= 3) {
                if (tabActiveIndex === 7) {
                  setSearchText(searchText);
                  updateTrashOrdersList();
                } else {
                  dispatch(invoicesActions.setInvoicesActivePageDetails(1));
                  dispatch(invoicesActions.setInvoicesSearchText(searchText));
                  setSearchText(searchText);
                  getInvoicesListAPI(status, searchText, itemsPerPage, 1);
                }
              }
            }}
            style={{
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              padding: "11px",
            }}
          >
            <SVGICON src={SearchIcon} className="svg-icon svg-icon-2" />
          </button>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {checkedInvoices?.length > 0 &&
          (tabActiveIndex === 1 || tabActiveIndex === 7) ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4 d-none d-sm-block d-sm-none d-md-block">
                {checkedInvoices.length}{" "}
                {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn btn-danger me-2 d-flex align-items-center"
                onClick={() => {
                  if (tabActiveIndex === 1) {
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "INVOICES_DELETE_CONFIRM_MESSAGE",
                      }),
                      deleteSelectedItems,
                      intl.formatMessage({ id: "COMMON_YES_DELETE" })
                    );
                  }
                  if (tabActiveIndex === 7) {
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "INVOICES_DELETE_PERMANENT_CONFIRM_MESSAGE",
                      }),
                      forceDeleteSelectedItems,
                      intl.formatMessage({ id: "COMMON_YES_DELETE" })
                    );
                  }
                }}
              >
                <i className="bi bi-trash3-fill fs-6"></i>
                {intl.formatMessage({ id: "COMMON_DELETE" })}
              </button>
              {tabActiveIndex === 7 && (
                <button
                  className={clsx("btn", "btn-success ms-2")}
                  onClick={() => {
                    showDeleteConfirmModal(
                      intl.formatMessage({
                        id: "INVOICES_RESTORE_CONFIRM_MESSAGE",
                      }),
                      restoreSelectedItems,
                      intl.formatMessage({
                        id: "COMMON_YES_RESTORE",
                      })
                    );
                  }}
                >
                  {intl.formatMessage({ id: "COMMON_RESTORE_SELECTED" })}
                </button>
              )}
            </>
          ) : (
            tabActiveIndex === 0 && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  history.push(`/sales/invoices/edit`, {
                    order: {
                      id: null,
                      headerID: headerID,
                    },
                  });
                }}
              >
                <SVGICON src={PlusIcon} className="svg-icon-2" />
                <span className="align-middle">
                  {intl.formatMessage({
                    id: "INVOICES_CREATE_NEW_INVOICE",
                  })}
                </span>
              </button>
            )
          )}
        </div>
      </div>
      {searchText && searchText?.length > 0 && searchText?.length < 3 && (
        <div className="text-danger " style={{ padding: "0 2.25rem" }}>
          <span role="alert">
            {intl.formatMessage({
              id: "COURSES_LIST_SEARCH_VALIDATION_MESSAGE",
            })}
          </span>
        </div>
      )}
      <div className="card-body py-3">
        <div className="mt-2 mb-6">
          <Tabs
            tabs={[
              intl.formatMessage({ id: "INVOICES_PAYMENT_ALL_TAB_TITLE" }),
              intl.formatMessage({ id: "INVOICES_PAYMENT_DRAFT_TAB_TITLE" }),
              intl.formatMessage({ id: "INVOICES_PAYMENT_OPEN_TAB_TITLE" }),
              intl.formatMessage({ id: "INVOICES_PAYMENT_PAID_TAB_TITLE" }),
              intl.formatMessage({
                id: "INVOICES_PAYMENT_PARTIAL_PAID_TAB_TITLE",
              }),
              intl.formatMessage({ id: "INVOICES_PAYMENT_OVERDUE_TAB_TITLE" }),
              intl.formatMessage({ id: "INVOICES_PAYMENT_CANCEL_TAB_TITLE" }),
              intl.formatMessage({ id: "INVOICES_PAYMENT_TRASH_TAB_TITLE" }),
            ]}
            activeIndex={tabActiveIndex}
            onActiveIndexChange={(index) => {
              setCheckedInvoices([]);
              onTabChange(index);
            }}
          />
        </div>
        <div className="table-responsive ">
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                {(tabActiveIndex === 1 || tabActiveIndex === 7) && (
                  <th className="w-25px">
                    <Checkbox
                      onChange={onAllItemsCheckChange}
                      checked={checkAllItems}
                    />
                  </th>
                )}
                <th
                  className="min-w-125px text-start"
                  style={{ textAlign: "center", cursor: "pointer" }}
                >
                  {intl.formatMessage({
                    id: "INVOICES_STATUS_TITLE",
                  })}
                </th>

                <th className="min-w-125px" style={{ textAlign: "center" }}>
                  {intl.formatMessage({
                    id: "INVOICES_DATE_TITLE",
                  })}
                </th>
                <th className="min-w-125px" style={{ textAlign: "center" }}>
                  {intl.formatMessage({
                    id: "INVOICES_TO_DETAILS",
                  })}
                </th>
                {tabActiveIndex !== 1 && (
                  <th className="min-w-125px" style={{ textAlign: "center" }}>
                    {intl.formatMessage({
                      id: "INVOICES_NUMBER",
                    })}
                  </th>
                )}
                <th className="min-w-125px" style={{ textAlign: "center" }}>
                  {intl.formatMessage({
                    id: "INVOICES_FINAL_AMOUNT",
                  })}
                </th>

                {tabActiveIndex === 1 && <th></th>}
              </tr>
            </thead>
            <tbody>
              {paginatedItems
                ?.sort(
                  (a, b) => Number(b.invoice_number) - Number(a.invoice_number)
                )
                ?.map((order, i) => {
                  return (
                    <tr key={i} className="bg-hover-lighten">
                      {(tabActiveIndex === 1 || tabActiveIndex === 7) && (
                        <td
                          onClick={() => {
                            if (tabActiveIndex !== 7) {
                              history.push(`/sales/invoices/edit`, {
                                order,
                              });
                            }
                          }}
                          className="cursor-pointer"
                        >
                          <div
                            className="form-check form-check-sm form-check-custom form-check-solid me-2"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Checkbox
                              onChange={() => {
                                onCheckedChange(order);
                              }}
                              checked={isChecked(order)}
                            />
                          </div>
                        </td>
                      )}

                      {/* status column */}
                      <td
                        onClick={() => {
                          if (tabActiveIndex !== 7) {
                            history.push(`/sales/invoices/edit`, {
                              order,
                            });
                          }
                        }}
                        className="cursor-pointer"
                      >
                        <span
                          className={`${getClassName(
                            order?.status
                          )} badge text-uppercase`}
                        >
                          {order?.status &&
                            (order?.status === "draft"
                              ? intl.formatMessage({
                                  id: "INVOICES_PAYMENT_DRAFT_TAB_TITLE",
                                })
                              : order?.status === "open"
                                ? intl.formatMessage({
                                    id: "INVOICES_PAYMENT_OPEN_TAB_TITLE",
                                  })
                                : order?.status === "paid"
                                  ? intl.formatMessage({
                                      id: "INVOICES_PAYMENT_PAID_TAB_TITLE",
                                    })
                                  : order?.status === "partiallyPaid"
                                    ? intl.formatMessage({
                                        id: "INVOICES_PAYMENT_PARTIAL_PAID_TAB_TITLE",
                                      })
                                    : order?.status === "cancel"
                                      ? intl.formatMessage({
                                          id: "INVOICES_PAYMENT_CANCEL_TAB_TITLE",
                                        })
                                      : intl.formatMessage({
                                          id: "INVOICES_PAYMENT_OVERDUE_TAB_TITLE",
                                        }))}
                        </span>{" "}
                      </td>

                      {/* order date */}
                      <td
                        onClick={() => {
                          if (tabActiveIndex !== 7) {
                            history.push(`/sales/invoices/edit`, {
                              order,
                            });
                          }
                        }}
                        className="cursor-pointer"
                      >
                        {order.invoice_date ? (
                          <div className="d-flex flex-column flex-wrap align-items-center">
                            <span className="mb-2 text-gray-600 fw-bold">
                              {lang === "de"
                                ? moment(order.invoice_date).format(
                                    "DD.MM.yyyy"
                                  )
                                : moment(order.invoice_date).format(
                                    "YYYY-MM-DD"
                                  )}
                            </span>
                          </div>
                        ) : null}
                      </td>

                      {/* details*/}
                      <td
                        onClick={() => {
                          if (tabActiveIndex !== 7) {
                            history.push(`/sales/invoices/edit`, {
                              order,
                            });
                          }
                        }}
                        className="cursor-pointer"
                      >
                        <div className="d-flex  flex-column flex-wrap align-items-center">
                          <span
                            className={clsx("text-gray-800 fw-bold mb-1")}
                            style={{ wordBreak: "break-word" }}
                          >
                            {order?.sales_details?.to_firstname ||
                            order?.sales_details?.to_lastname
                              ? `${order?.sales_details?.to_firstname} ${order?.sales_details?.to_lastname}`
                              : "-"}
                          </span>
                          <span className="fw-bold text-gray-600 fs-7">
                            {order?.sales_details?.to_company_name
                              ? order?.sales_details?.to_company_name
                              : "-"}
                          </span>
                          <span className="fw-bold text-gray-600 fs-7">
                            {order?.sales_details?.to_address
                              ? order?.sales_details?.to_address +
                                (order?.sales_details?.to_city ? "," : ".")
                              : ""}
                          </span>
                          <span className="fw-bold text-gray-600 fs-7">
                            {order?.sales_details?.to_city
                              ? order?.sales_details?.to_city +
                                (order?.sales_details?.to_zipcode ? "," : ".")
                              : ""}
                            {order?.sales_details?.to_zipcode
                              ? order?.sales_details?.to_zipcode + "."
                              : ""}
                          </span>
                        </div>
                      </td>

                      {/* invoice number */}
                      {tabActiveIndex !== 1 && (
                        <td className="text-center">
                          <div className="d-flex flex-column gap-2 align-items-center">
                            <span
                              className="text-gray-600 fw-bold text-center"
                              onClick={() => {
                                let invoice_number: string | null = "";
                                if (tabActiveIndex === 6) {
                                  invoice_number =
                                    order?.cancelled_invoice_number;
                                } else {
                                  invoice_number = order?.final_invoice_number;
                                }
                                navigator.clipboard
                                  // @ts-ignore
                                  ?.writeText(invoice_number)
                                  .then(() => {
                                    successToast(
                                      intl.formatMessage({
                                        id: "FILEMANAGER_FILE_LINK_COPY_SUCCESS_MESSAGE",
                                      })
                                    );
                                  })
                                  .catch((error) =>
                                    console.error(
                                      error?.response?.data?.message
                                    )
                                  );
                              }}
                            >
                              {tabActiveIndex === 6
                                ? order?.cancelled_invoice_number
                                  ? order?.cancelled_invoice_number
                                  : "-"
                                : order?.final_invoice_number
                                  ? order?.final_invoice_number
                                  : "-"}
                            </span>
                          </div>
                        </td>
                      )}

                      {/* final Amount */}
                      <td
                        className="text-center cursor-pointer"
                        onClick={() => {
                          if (tabActiveIndex !== 7) {
                            history.push(`/sales/invoices/edit`, {
                              order,
                            });
                          }
                        }}
                      >
                        <span className="text-gray-600 fw-bold ">
                          {formatPriceLocale(
                            intl.locale,
                            getTotalAmount(order)
                          )}
                          <span className="ms-2">€</span>
                        </span>
                      </td>

                      {/* actions*/}
                      <td>
                        <div className="d-flex justify-content-center align-items-center">
                          {tabActiveIndex !== 1 && tabActiveIndex !== 7 && (
                            <>
                              {order?.status === "cancel" ? (
                                <div className="position-relative">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-icon me-2 dropdown-toggl"
                                    id="invoicedropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    <i className="bi bi-download fs-4"></i>
                                  </button>
                                  <div
                                    className="dropdown-menu menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4"
                                    aria-labelledby="invoicedropdown"
                                    id={`optiondropdown${order?.id}`}
                                  >
                                    <div
                                      className="menu-item px-3 "
                                      role="button"
                                      onClick={() => {
                                        if (!createdLoading) {
                                          generatePdfDocument(order, false);
                                        }
                                        const dropdownElement =
                                          document.getElementById(
                                            "invoicedropdown"
                                          );
                                        const optionDropdown =
                                          document.getElementById(
                                            `optiondropdown${order?.id}`
                                          );

                                        if (dropdownElement && optionDropdown) {
                                          dropdownElement.classList.remove(
                                            "show"
                                          );
                                          optionDropdown.classList.remove(
                                            "show"
                                          );
                                          dropdownElement.setAttribute(
                                            "aria-expanded",
                                            "false"
                                          );
                                        }
                                      }}
                                    >
                                      <span className="menu-link px-3">
                                        {intl.formatMessage({
                                          id: "ORIGINAL_TITLE",
                                        })}
                                      </span>
                                    </div>
                                    <div
                                      className="menu-item px-3"
                                      onClick={() => {
                                        if (!createdLoading) {
                                          generatePdfDocument(order, true);
                                        }

                                        const dropdownElement =
                                          document.getElementById(
                                            "invoicedropdown"
                                          );
                                        const optionDropdown =
                                          document.getElementById(
                                            `optiondropdown${order?.id}`
                                          );

                                        if (dropdownElement && optionDropdown) {
                                          dropdownElement.classList.remove(
                                            "show"
                                          );
                                          optionDropdown.classList.remove(
                                            "show"
                                          );
                                          dropdownElement.setAttribute(
                                            "aria-expanded",
                                            "false"
                                          );
                                        }
                                      }}
                                    >
                                      <span className="menu-link px-3">
                                        {intl.formatMessage({
                                          id: "CANCELLED_TITLE",
                                        })}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <button
                                  className={`btn btn-sm btn-icon mx-1 ${
                                    createdLoading === true && "pe-none"
                                  } `}
                                  type="button"
                                  onClick={() => {
                                    if (!createdLoading) {
                                      generatePdfDocument(order, false);
                                    }
                                  }}
                                  disabled={createdLoading === true}
                                >
                                  <i className="bi bi-download fs-4"></i>
                                </button>
                              )}
                            </>
                          )}
                          {
                            <InvoicesListActionsDropDown
                              order={order}
                              getOrdersListAPI={getInvoicesListAPI}
                              tabActiveIndex={tabActiveIndex}
                              status={status}
                              itemsPerPage={itemsPerPage}
                              activePage={activePage}
                              ordersSearchText={invoicesSearchText}
                              getOrdersTrashListAPI={getOrdersTrashListAPI}
                              handleRestoreOrder={handleRestoreOrder}
                              paginatedItems={paginatedItems}
                              fromInvoice
                              layoutFooterDetails={layoutFooterDetails}
                              getInvoicesListAPI={getInvoicesListAPI}
                              loading={loading}
                              setLoading={setLoading}
                            />
                          }
                        </div>
                      </td>
                    </tr>
                  );
                })}

              {/* loader */}
              {!getInvoiceListAPICompleted && paginatedItems?.length === 0 && (
                <tr>
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <SimpleLoader />
                  </td>
                </tr>
              )}

              {loading && <SimpleLoader />}

              {/* no data */}
              {getInvoiceListAPICompleted && paginatedItems?.length === 0 && (
                <NoItemsFound languageKey="NO_ITEMS_FOUND" />
              )}
            </tbody>
          </table>

          {(tabActiveIndex !== 7
            ? invoicesPagination?.number_of_pages
            : invoicesList?.length > 0) && (
            <Pagination
              totalPages={
                tabActiveIndex !== 7
                  ? invoicesPagination?.number_of_pages || 1
                  : Math.ceil(Number(invoicesList?.length) / itemsPerPage)
              }
              itemsPerPage={itemsPerPage}
              showItemsPerPage
              setItemsPerPage={(count) => {
                setActivePage(1);
                setItemsPerPage(count);
              }}
              activePage={activePage}
              onPageClick={onPageClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceOverVeiw;
