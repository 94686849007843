import React, { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

// custom
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import CustomersList from "./CustomersList";
import { PartnersContactDetailType } from "../../partnerManagement/types/getPartnersListResponseType";

interface Props {
  show: boolean;
  closeModal: () => void;
  onSelectCustomers: (customers: UserType[]) => void;
  isSelectionModal?: boolean;
  selectedContacts?: PartnersContactDetailType[] | undefined | any;
  selectMultiple?: boolean;
}

const SelectCustomerModal: FC<Props> = ({
  show,
  closeModal,
  onSelectCustomers,
  isSelectionModal,
  selectedContacts,
  selectMultiple,
}) => {
  const intl = useIntl();
  // state
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  return (
    <Modal
      show={show}
      centered
      dialogClassName="extra-large-size-modal"
      className={`${isFilterOpen ? "d-none" : "d-block"}`}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "SELECT_CUSTOMER_MODAL_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <div className="overflow-auto">
        <Modal.Body
          className={`${isSelectionModal ? "selections_modal" : ""} overflow-inherit`}
          style={{ padding: "0px" }}
        >
          <CustomersList
            type={"select"}
            // @ts-ignore
            onSelectCustomers={onSelectCustomers}
            isSelectionModal={isSelectionModal}
            selectMultiple={selectMultiple}
            selectedContacts={selectedContacts}
            setIsFilterOpen={setIsFilterOpen}
          />
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default SelectCustomerModal;
