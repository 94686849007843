import { useIntl } from "react-intl";
import { SVGICON } from "../../../../_metronic/helpers";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import { FC, useEffect, useState } from "react";
import AddContactTitlesModal from "./AddContactTitlesModal";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import { DealType } from "../types/DealResponseType";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import { deleteDealContactTitle } from "../redux/DealsAPI";

interface TitleProps {
  dealInformation: DealType;
  getDealPhaseAPI?: () => void;
}

const ContactTitlesForDeals: FC<TitleProps> = ({
  dealInformation,
  getDealPhaseAPI,
}) => {
  const intl = useIntl();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const [showAddTitlesModal, setShowAddTitlesModal] = useState({
    show: false,
  });
  const [groupedContacts, setGroupedContacts] = useState({});

  const deleteExistingTitle = (id: number) => {
    setDeleteModalLoading(true);
    deleteDealContactTitle(id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "CONTACT_TITLE_DELETE_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "CONTACT_TITLE_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getDealPhaseAPI?.();
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
      });
  };

  useEffect(() => {
    /* @ts-ignore */
    const grouped = dealInformation?.deals_contacts_titles_details?.reduce(
      /* @ts-ignore */
      (acc, contact) => {
        const title = contact.contact_title_name;
        if (!acc[title]) acc[title] = [];
        acc[title].push(contact);
        return acc;
      },
      {}
    );

    setGroupedContacts(grouped || {});
  }, [dealInformation]);
  /* @ts-ignore */
  //   const groupedContacts =
  //   /* @ts-ignore */
  //     dealInformation?.deals_contacts_titles_details?.reduce(
  //       (acc:any, contact: { contact_title_name: any }) => {
  //         const title = contact.contact_title_name;
  //         if (!acc[title]) {
  //           acc[title] = [];
  //         }
  //         acc[title].push(contact);
  //         return acc;
  //       } /* @ts-ignore */,
  //       {} as Record<string, any[]>
  //     );

  return (
    <div
      className="card mb-8 borderNone d-flex"
      style={{
        minHeight: "100px",
      }}
    >
      <div className="card-body align-items-center">
        <div className="d-flex justify-content-between align-items-center fs-5 fw-bolder pt-2">
          <h4 className="m-0">
            {intl.formatMessage({ id: "DEAL_CONTACT_TITLES" })}
          </h4>
          <div>
            <button
              type="button"
              className="btn btn-sm btn-icon btn-primary"
              onClick={() => {
                setShowAddTitlesModal((prev) => ({
                  ...prev,
                  show: true,
                  type: "new",
                }));
                //setShowAddUserModal(true);
              }}
            >
              <SVGICON src={PlusIcon} className="svg-icon-2" />
            </button>
          </div>
        </div>
        {Object.entries(groupedContacts).length > 0 && (
          <div className="d-flex flex-column  pt-2">
            {/* @ts-ignore */}
            {Object.entries(groupedContacts).map(([title, contacts]) => (
              <div key={title} className="mb-4">
                <h6>{title}</h6>
                {/* @ts-ignore */}
                {contacts?.map((contact: any) => (
              <>
              { contact?.firstname !== null &&  <div
                    key={contact.firstname + contact.lastname}
                    className="d-flex justify-content-between align-items-center"
                  >
                    {contact?.firstname !== null && (
                      <div className="d-flex flex-row">
                        <span
                          className="me-2 fs-7 text-gray-800 fw-bold text-truncate"
                          style={{ maxWidth: "100px" }}
                        >
                          {contact?.firstname} {contact?.lastname}
                        </span>
                        <span
                          className="fs-7 text-primary text-truncate"
                          style={{ maxWidth: "180px" }}
                        >
                          {", "}
                          {contact?.email}
                        </span>
                      </div>
                    )}
                    <div className="mb-3">
                      {/* <button
                      className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
                      onClick={() => {
                        setShowAddTitlesModal((prev) => ({
                          ...prev,
                          show: true,
                          type: "edit",
                          title: contacTitle,
                        }));
                      }}
                    >
                      <SVGICON src={EditIcon} className="svg-icon-3" />
                    </button> */}

                      <button
                        className="btn btn-icon btn-light btn-active-light-primary btn-sm"
                        onClick={() => {
                          showDeleteConfirmModal(
                            intl.formatMessage({
                              id: "CONTACT_TITLE_DELETE_CONFIRM_MESSAGE",
                            }),
                            () => {
                              deleteExistingTitle(
                                contact?.deals_contacts_titles_id
                              );
                            }
                          );
                        }}
                      >
                        <SVGICON src={DeleteIcon} className="svg-icon-3" />
                      </button>
                    </div>
                  </div>}
              </>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
      {showAddTitlesModal && (
        <AddContactTitlesModal
          modalProps={showAddTitlesModal}
          deal_Id={dealInformation?.id}
          getDealPhaseAPI={getDealPhaseAPI}
          closeModal={() => {
            setShowAddTitlesModal((prev) => ({
              ...prev,
              show: false,
            }));
          }}
          //getTasksListAPI={getTasksListAPI}
        />
      )}
    </div>
  );
};

export default ContactTitlesForDeals;
