import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import TextInput from "../../../../sharedComponents/TextInput/TextInput";

interface Props {
  show: boolean;
  closeModal: () => void;
  formik: any;
}

const UpdateOrderTaxModal: FC<Props> = ({ show, closeModal, formik }) => {
  const intl = useIntl();

  return (
    <>
      <Modal
        show={show}
        centered
        dialogClassName="large-size-modal"
        backdrop="static"
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({
              id: "ORDERS_EDIT_ADDITIONAL_INFO_MODAL_TITLE",
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 m-0">
          <div className="px-10 py-6">
            <div className="d-flex flex-column gap-6">
              {/* <div className="w-100 d-flex flex-column gap-3 py-3 px-5 fs-6 border border-dashed border-primary rounded bg-gray-100 position-relative">
                {formik.values.from_contact_person && (
                  <div className="text-gray-800">
                    <span className="fw-bolder ">
                      {intl.formatMessage({
                        id: "ORDER_CONTACT_PERSON_LABEL",
                      })}
                      {": "}
                    </span>
                    <span className="fw-bold">
                      {formik.values.from_contact_person}
                    </span>
                  </div>
                )}
                {formik.values.from_contact_person_email && (
                  <div className="text-gray-800">
                    <span className="fw-bolder ">
                      {intl.formatMessage({
                        id: "ORDER_ADDITIONAL_INFO_CONTACT_EMAIL",
                      })}
                      {": "}
                    </span>
                    <span className="fw-bold">
                      {formik.values.from_contact_person_email}
                    </span>
                  </div>
                )}
                {formik.values.from_contact_person_phone && (
                  <div className="text-gray-800">
                    <span className="fw-bolder ">
                      {intl.formatMessage({
                        id: "ORDER_ADDITIONAL_INFO_CONTACT_PHONE",
                      })}
                      {": "}
                    </span>
                    <span className="fw-bold">
                      {formik.values.from_contact_person_phone}
                    </span>
                  </div>
                )}
                {!formik.values.from_contact_person_phone &&
                  !formik.values.from_contact_person_email &&
                  !formik.values.from_contact_person && (
                    <span className="text-gray-700">
                      {intl.formatMessage({
                        id: "ORDER_NO_ADDITIONAL_INFO_MESSAGE",
                      })}
                    </span>
                  )}
                <div
                  className="position-absolute cursor-pointer"
                  style={{right: "10px", top: "10px"}}
                  onClick={() => {
                    setShowSelectUserModal(true)
                  }}
                >
                  <i className="bi bi-pencil-square"></i>
                </div>
              </div> */}
              <div className="w-100">
                <div className="mb-5">
                  <label className="form-label">
                    {intl.formatMessage({
                      id: "ORDER_CREDITOR_NUMBER_LABEL",
                    })}
                  </label>
                  <TextInput
                    value={formik.values.from_creditor_number || ""}
                    onChange={(value) => {
                      formik.setFieldValue("from_creditor_number", value);
                    }}
                  />
                </div>
                <div className="mb-5">
                  <label className="form-label">
                    {intl.formatMessage({
                      id: "ORDER_TAX_ID_LABEL",
                    })}
                  </label>
                  <TextInput
                    value={formik.values.from_tax_id || ""}
                    onChange={(value) => {
                      formik.setFieldValue("from_tax_id", value);
                    }}
                  />
                </div>
                <div className="mb-5">
                  <label className="form-label">
                    {intl.formatMessage({
                      id: "ORDER_TAX_NUMBER_LABEL",
                    })}
                  </label>
                  <TextInput
                    value={formik.values.from_tax_number || ""}
                    onChange={(value) => {
                      formik.setFieldValue("from_tax_number", value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center p-2">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {intl.formatMessage({ id: "ORDER_ADDRESS_UPDATE_BUTTON_TITLE" })}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateOrderTaxModal;
