import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

// Components
import { Button, CloseButton, Modal } from "react-bootstrap";

// Custom Components
import DropDown from "../../../sharedComponents/dropdown/Dropdown";

import { RootState } from "../../../../setup";

// Types
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { useDispatch } from "react-redux";
import { actions, getRolesList } from "../../RolesAndPermissions/redux";

export interface TaskFilterModalProps {
  show: boolean;
  role?: string;
}

interface Props {
  show: boolean;
  filters: any;
  setFilters: (filters: any) => void;
  closeModal: () => void;
}

export interface FieldOptionType {
  id: number;
  name: string;
  title: string;
}

const UsersFilterModal: FC<Props> = ({
  show,
  filters,
  setFilters,
  closeModal,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const allRoles = useSelector<RootState>(
    // @ts-ignore
    ({ rolesAndPermissionsManagement }) => rolesAndPermissionsManagement?.roles
  ) as RoleType[];

  // state
  const [tempFilters, setTempFilters] = useState<any>({ ...filters });

  // Field Options

  const ROLE_TYPES = allRoles?.map((item) => ({
    id: item.id,
    name: item.name,
  })) as FieldOptionType[];

  // effects
  useEffect(() => {
    if (allRoles.length === 0) {
      getRolesList()
        .then(({ data: { rolesDetails = [] } }) => {
          dispatch(actions.setRolesList(rolesDetails));
        })
        .catch((error) => console.error(error?.response?.data?.message));
    }
  }, []);

  // Handlers
  const handleSelectChange = (key: string, value: any) => {
    setTempFilters((prevFilters: any) => ({ ...prevFilters, [key]: value }));
  };

  const handleSubmit = () => {
    setFilters((prevFilters: any) => ({ ...prevFilters, ...tempFilters }));
    closeModal();
  };

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      backdrop="static"
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title>{intl.formatMessage({ id: "FILTER_BUTTON" })}</Modal.Title>
        <div className=" d-flex align-items-center">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setFilters({});
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "RESET_BUTTON" })}
          </button>
          <CloseButton className="ms-2" onClick={closeModal} />
        </div>
      </Modal.Header>
      <div className="overflow-auto">
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {/* User Role Filter */}
            <div className="mb-8">
              <label className="form-label">
                {intl.formatMessage({
                  id: "USER_MANAGEMENT_FILTER_ROLE_FIELD_LABEL",
                })}
              </label>
              <DropDown
                id="user_role"
                items={ROLE_TYPES || []}
                selectedItem={tempFilters?.role}
                onSelectItem={(item) => handleSelectChange("role", item.name)}
                displayValue={
                  ROLE_TYPES.filter(
                    (item) => item.name === tempFilters?.role
                  )[0]?.name
                }
                displayFunc={(item) => {
                  return (
                    <span className="d-flex align-items-center">
                      {/* @ts-ignore */}
                      {item?.name}
                    </span>
                  );
                }}
                hideSearch
              />
            </div>
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
        <Button className="btn btn-primary" onClick={handleSubmit}>
          {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UsersFilterModal;
