import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";

import ActionsDropdown from "../../../sharedComponents/ActionsDropdown/ActionsDropdown";
import { TeamType } from "../types/TeamsResponseType";
import { deleteTeam } from "../redux/TeamsAPI";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  team: TeamType;
  getTeamsListAPI: () => void;
  onEdit: (editObject: TeamModalProps) => void;
}

interface TeamModalProps {
  show: boolean;
  type?: string;
  team?: TeamType;
}

const TeamListActionsDropdown: FC<Props> = ({
  team,
  getTeamsListAPI,
  onEdit,
}) => {
  const intl = useIntl();
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
  //@ts-ignore
  const crudPermission: any = enablePermissionForCrud(userRoleDetails, "teams");

  const onEdituserClick = () => {
    onEdit({
      show: true,
      type: "edit",
      team: team,
    });
  };

  const deleteSelectedCustomer = () => {
    setDeleteModalLoading(true);
    deleteTeam(team.id)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "TEAMS_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "TEAMS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getTeamsListAPI?.();
      });
  };

  const dropdownOptions = [
    ...(crudPermission?.edit
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_EDIT" }),
            onClick: onEdituserClick,
          },
        ]
      : []),
    ...(crudPermission?.delete
      ? [
          {
            name: intl.formatMessage({ id: "COMMON_DELETE" }),
            onClick: () => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "TEAM_DELETE_CONFIRM_MESSAGE",
                }),
                () => {
                  deleteSelectedCustomer();
                }
              );
            },
            className: "text-danger",
          },
        ]
      : []),
  ];

  if (dropdownOptions.length > 0) {
    return (
      <div className="dropdown d-flex flex-row justify-content-end">
        <ActionsDropdown options={dropdownOptions} />
      </div>
    );
  } else {
    return null;
  }
};

export default TeamListActionsDropdown;
