import clsx from "clsx";
import { Formik, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";

// custom
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { AddressType, CustomerType } from "../../auth/types/LoginResponseTypes";
import { CountryType } from "../../masterdata/types/GetCountriesListResponseTypes";
// import {StateType} from '../../masterdata/types/GetStatesListResponseType'
import { updateCustomerPersonalAddress } from "../redux";
import { successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  modalProps: AddpersonalAddressModalProps;
  closeModal?: () => void;
  customerDetails: CustomerType;
  setCustomer: (customer: CustomerType) => void;
}

interface AddpersonalAddressModalProps {
  show: boolean;
  type?: string;
  address?: AddressType;
}

const AddAddressModal: FC<Props> = ({
  modalProps,
  closeModal,
  customerDetails,
  setCustomer,
}) => {
  const countries = useSelector<RootState>(
    ({ masterdata }) => masterdata.countriesList
  ) as CountryType[];
  const { show, type, address } = modalProps;
  const intl = useIntl();

  // state
  const [loading, setLoading] = useState<boolean>(false);
  // const [states, setStates] = useState<StateType[]>([])
  // const [statesLoading, setStatesLoading] = useState<boolean>(false)
  const [selectedCountry, setSelectedCountry] = useState<CountryType>();
  // const [selectedState, setSelectedState] = useState<StateType>()

  useEffect(() => {
    if (countries && countries.length > 0) {
      const germany = countries.filter((data) => data.id === 84)[0];
      setSelectedCountry(germany);
    }
  }, []);

  const initialValues = {
    address_1: type === "edit" ? address?.address_1 : "",
    address_2: type === "edit" ? address?.address_2 || "" : "",
    zip_code: type === "edit" ? address?.zip_code : undefined,
    city: type === "edit" ? address?.city : "",
    country_id: type === "edit" ? address?.country_id : 84,
    // state: type === 'edit' ? address?.state || '' : '',
  };

  const AddAddressSchema = Yup.object().shape({
    address_1: Yup.string().required(
      intl.formatMessage({ id: "CUSTOMER_ADDRESS_VALIDATION_MESSAGE" })
    ),
    zip_code: Yup.number()
      .max(
        9999999999,
        intl.formatMessage({
          id: "CUSTOMER_ADDRESS_ZIP_CODE_VALIDATION_MESSAGE",
        })
      )
      .required(
        intl.formatMessage({
          id: "CUSTOMER_ADDRESS_ZIP_CODE_VALIDATION_REQUIRED_MESSAGE",
        })
      ),
    city: Yup.string().required(
      intl.formatMessage({
        id: "CUSTOMER_ADDRESS_CITY_VALIDATION_REQUIRED_MESSAGE",
      })
    ),
    country_id: Yup.number().required(
      intl.formatMessage({
        id: "CUSTOMER_ADDRESS_COUNTRY_VALIDATION_REQUIRED_MESSAGE",
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: AddAddressSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (
        values.address_1 &&
        values.country_id &&
        values.city &&
        values.zip_code
      ) {
        setLoading(true);
        updateCustomerPersonalAddress(
          customerDetails?.personalInformation?.id,
          values.address_1,
          values.address_2,
          values.zip_code,
          values.city,
          values.country_id
          // values.state
        )
          .then(({ data: { personalAddress } }) => {
            setCustomer({
              ...customerDetails,
              personalAddress,
            });
            successToast(
              intl.formatMessage({ id: "CUSTOMER_PERSONAL_ADDRESS_SUCCESS_MESSAGE" })
            );
          })
          .catch(() => {
            setStatus(
              intl.formatMessage({
                id: "CUSTOMER_PERSONAL_ADDRESS_FAILURE_MESSAGE",
              })
            );
          })
          .finally(() => {
            setLoading(false);
            closeModal?.();
          });
      }
    },
  });

  const onCountrySelect = (item: CountryType, updateState?: boolean) => {
    setSelectedCountry(item);
  };

  useEffect(() => {
    if (type === "edit") {
      countries.forEach((country) => {
        if (
          country.name.toLocaleLowerCase() ===
          address?.country.toLocaleLowerCase()
        ) {
          formik.setFieldValue("country_id", country.id);
          setSelectedCountry(country);
          onCountrySelect(country, true);
        }
      });
    }
  }, []);

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      contentClassName={loading ? "pe-none" : ""}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({ id: "ADD_NEW_ADDRESS_MESSAGE" })}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form" className="overflow-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={AddAddressSchema}
          onSubmit={(values) => {}}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <>
              <Modal.Body className="overflow-inherit">
                <div className="p-4">
                  {/* form status */}
                  {formik.status && (
                    <div className="mb-10 alert alert-danger">
                      <div className="alert-text font-weight-bold">
                        {formik.status}
                      </div>
                    </div>
                  )}

                  {/* Country */}

                  {/* Address Line1 */}
                  <div className="fv-row mb-8">
                    <label className="required fw-bold fs-6 mb-2">
                      {intl.formatMessage({
                        id: "CUSTOMER_MANAGEMENT_ADDRESS_LINE_1",
                      })}
                    </label>
                    <input
                      className={clsx(
                        "form-control form-control-lg form-control-solid"
                      )}
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      {...formik.getFieldProps("address_1")}
                      value={formik.values.address_1}
                    />
                    {formik.touched.address_1 && formik.errors.address_1 && (
                      <div className="text-danger">
                        <span role="alert">{formik.errors.address_1}</span>
                      </div>
                    )}
                  </div>

                  {/* Address Line2 */}
                  <div className="fv-row mb-8">
                    <label className="fw-bold fs-6 mb-2">
                      {intl.formatMessage({
                        id: "CUSTOMER_MANAGEMENT_ADDRESS_LINE_2",
                      })}
                    </label>
                    <input
                      className={clsx(
                        "form-control form-control-lg form-control-solid"
                      )}
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      {...formik.getFieldProps("address_2")}
                      value={formik.values.address_2}
                    />
                    {formik.touched.address_2 && formik.errors.address_2 && (
                      <div className="text-danger">
                        <span role="alert">{formik.errors.address_2}</span>
                      </div>
                    )}
                  </div>

                  <div className="row fv-row  align-items-center ">
                    {/* city */}
                    <div className="col-xl-6 mb-8">
                      <label className="required fw-bold fs-6 mb-2">
                        {intl.formatMessage({
                          id: "CUSTOMER_MANAGEMENT_ADDRESS_CITY",
                        })}
                      </label>
                      <input
                        placeholder=""
                        type="text"
                        autoComplete="off"
                        {...formik.getFieldProps("city")}
                        className={clsx(
                          "form-control form-control-lg form-control-solid"
                        )}
                        value={formik.values.city}
                      />
                      {formik.touched.city && formik.errors.city && (
                        <div className="text-danger">
                          <span role="alert">{formik.errors.city}</span>
                        </div>
                      )}
                    </div>

                    {/*zip code */}
                    <div className="col-xl-6 mb-8">
                      <div className="fv-row">
                        <label className="required fw-bold fs-6 mb-2">
                          {intl.formatMessage({
                            id: "CUSTOMER_MANAGEMENT_ADDRESS_ZIP",
                          })}
                        </label>
                        <input
                          placeholder=""
                          type="number"
                          autoComplete="off"
                          {...formik.getFieldProps("zip_code")}
                          className={clsx(
                            "form-control form-control-lg form-control-solid"
                          )}
                          value={formik.values.zip_code}
                        />
                        {formik.touched.zip_code && formik.errors.zip_code && (
                          <div className="text-danger">
                            <span role="alert">{formik.errors.zip_code}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* State */}
                  {/* <div className='fv-row mb-8'>
                    <label className='fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADDRESS_STATE'})}
                    </label>
                    <input
                      placeholder=''
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('state')}
                      className={clsx('form-control form-control-lg form-control-solid')}
                      value={formik.values.state}
                    />
                    {formik.touched.state && formik.errors.state && (
                      <div className='text-danger'>
                        <span role='alert'>{formik.errors.state}</span>
                      </div>
                    )}
                  </div> */}

                  {/* country */}
                  <div className="fv-row">
                    <label className="required fw-bold fs-6 mb-2">
                      {intl.formatMessage({
                        id: "CUSTOMER_MANAGEMENT_ADDRESS_COUNTRY",
                      })}
                    </label>
                    <DropDown
                      id="countriesDropDown"
                      items={countries}
                      selectedItem={selectedCountry}
                      // @ts-ignore
                      onSelectItem={(item: CountryType) => {
                        formik.setFieldValue("country_id", item.id);
                        onCountrySelect(item);
                      }}
                      displayValue={selectedCountry?.name || ""}
                      // @ts-ignore
                      displayFunc={(item: CountryType) => {
                        return item.emoji + " " + item.name;
                      }}
                    />
                    {formik.touched.country_id && formik.errors.country_id && (
                      <div className="text-danger">
                        <span role="alert">{formik.errors.country_id}</span>
                      </div>
                    )}
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer className="justify-content-center">
                <Button variant="secondary" onClick={closeModal}>
                  {intl.formatMessage({
                    id: "CLOSE_BUTTON",
                  })}
                </Button>
                <Button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn  btn-primary"
                >
                  {!loading && (
                    <span className="indicator-label">
                      {intl.formatMessage({
                        id: "CUSTOMER_MANAGEMENT_ADDRESS_SUBMIT",
                      })}
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      {intl.formatMessage({
                        id: "CUSTOMER_MANAGEMENT_ADDRESS_LOADING_MESSAGE",
                      })}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </form>
    </Modal>
  );
};

export default AddAddressModal;
