import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";

import TextInput from "../../../../sharedComponents/TextInput/TextInput";
import DropDown from "../../../../sharedComponents/dropdown/Dropdown";
import { RootState } from "../../../../../setup";
import { CountryType } from "../types/GetCountriesListResponseTypes";

interface Props {
  show: boolean;
  closeModal: () => void;
  formik: any;
  selectedToCountry: any;
  setSelectedToCountry: any;
}

const EditAddressModal: FC<Props> = ({
  show,
  closeModal,
  formik,
  selectedToCountry,
  setSelectedToCountry,
}) => {
  const intl = useIntl();
  const countries = useSelector<RootState>(
    ({ masterdata }) => masterdata.countriesList
  ) as CountryType[];

  return (
    <Modal
      show={show}
      centered
      dialogClassName="large-size-modal"
      contentClassName={""}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "ORDERS_EDIT_ADDRESS_MODAL_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 m-0">
        <div className="px-10 py-6">
          <div className="mb-5">
            <label className="form-label required">
              {intl.formatMessage({
                id: "CONTACT_FORMS_LIST_FORM_PREVIEW_EMAIL_LABEL",
              })}
            </label>
            <TextInput
              value={formik.values.to_email || ""}
              onChange={(value) => {
                formik.setFieldValue("to_email", value);
              }}
            />
            {formik.touched.to_email && formik.errors.to_email && (
              <div className="text-danger">
                <span role="alert">{formik.errors.to_email}</span>
              </div>
            )}
          </div>
          <div className="mb-5">
            <label className="form-label">
              {intl.formatMessage({
                id: "PARTNERS_LIST_ADDRESS",
              })}
            </label>
            <TextInput
              value={formik.values.to_address || ""}
              onChange={(value) => {
                formik.setFieldValue("to_address", value);
              }}
            />
          </div>
          <div className="mb-5">
            <label className="form-label">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_CITY",
              })}
            </label>
            <TextInput
              value={formik.values.to_city || ""}
              onChange={(value) => {
                formik.setFieldValue("to_city", value);
              }}
            />
          </div>
          <div className="mb-5">
            <label className="form-label">
              {intl.formatMessage({
                id: "PARTNERS_GENERAL_DETAILS_FIELD_ZIPCODE",
              })}
            </label>
            <TextInput
              value={formik.values.to_zipcode || ""}
              onChange={(value) => {
                formik.setFieldValue("to_zipcode", value);
              }}
            />
          </div>
          <div className="mb-5">
            <label className="form-label">
              {intl.formatMessage({
                id: "CUSTOMER_MANAGEMENT_ADDRESS_COUNTRY",
              })}
            </label>
            <DropDown
              id="countriesDropDown"
              items={countries}
              selectedItem={selectedToCountry}
              // @ts-ignore
              onSelectItem={(item: CountryType) => {
                formik.setFieldValue("to_countries_id", item.id);
                setSelectedToCountry(item);
              }}
              displayValue={selectedToCountry?.name || ""}
              // @ts-ignore
              displayFunc={(item: CountryType) => {
                return item.emoji + " " + item.name;
              }}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-center p-2">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {intl.formatMessage({ id: "ORDER_ADDRESS_UPDATE_BUTTON_TITLE" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAddressModal;
