import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../../setup";

// images
import MenuIcon from "../../../../_metronic/assets/icons/menu.svg";

// custom
import { SVGICON } from "../../../../_metronic/helpers";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { deleteUser } from "../redux";
import { UserType } from "../types/getUsersListResponseType";
import { errorToast, successToast } from "../../../sharedComponents/toasts/Toasts";

interface Props {
  userDetails: UserType;
  getUsersListAPI: () => void;
}

const UsersListActionsDropdown: FC<Props> = ({
  userDetails,
  getUsersListAPI,
}) => {
  const intl = useIntl();
  const history = useHistory();

  // selectors
  const loggedUser = useSelector<RootState>(
    // @ts-ignore
    ({ auth }) => auth.user
  ) as UserType;

 const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;

  // contexts
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const onEdituserClick = () => {
    history.push(`/system/user/edituser`, { userDetails: userDetails });
  };

  const deleteSelectedUser = () => {
    setDeleteModalLoading(true);
    deleteUser(userDetails.id)
      .then(() => {
        successToast(
          intl.formatMessage({ id: "USER_MANAGEMENT_DELETE_USER_SUCCESS" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "USER_MANAGEMENT_DELETE_USER_FAILED" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getUsersListAPI?.();
      });
  };

  return (
    <div className="dropdown d-flex flex-row justify-content-end">
     {userData?.role?.id === 1  &&  <button
        type="button"
        className="btn btn-sm btn-icon btn-light btn-active-light-primary bgBrandLightBlueHover me-2 dropdown-toggl "
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <SVGICON src={MenuIcon} />
      </button>}
      <div
        className="dropdown-menu  menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4 userListActionsDropDownCont"
        aria-labelledby="dropdownMenuButton1"
      >
        <div
          className="menu-item px-3"
          onClick={() => {
            onEdituserClick();
          }}
        >
         {userData?.role.id === 1  &&  <span className="menu-link px-3 bgBrandLightBlueHover">
            {intl.formatMessage({ id: "EDIT_BUTTON" })}
          </span>}
        </div>
        {loggedUser?.id !== userDetails?.id && (
          <div
            className="menu-item px-3"
            onClick={() => {
              showDeleteConfirmModal(
                "Are you sure you want to delete this user?",
                deleteSelectedUser
              );
            }}
          >
            {userData?.role.id === 1 && <span className="menu-link px-3 text-danger bgBrandLightBlueHover">
              {intl.formatMessage({ id: "DELETE_BUTTON" })}
            </span>}
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersListActionsDropdown;
