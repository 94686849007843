import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import clsx from "clsx";

// Components
import { Button, Modal } from "react-bootstrap-v5";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import { SVGICON } from "../../../../_metronic/helpers";

// Assets
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import BlankAvatar from "../../../../_metronic/assets/icons/blankAvatar.svg";

// Types
import { RootState } from "../../../../setup";
import { Assignee } from "../types/TasksResponseType";
import { UserType } from "../../userManagement/types/getUsersListResponseType";

interface Props {
  show: boolean;
  closeModal: () => void;
  selectedUsers: Assignee[] | undefined;
  isSelectionModal?: boolean;
}

const TaskResponsibleUsersModal: FC<Props> = ({
  show,
  selectedUsers,
  closeModal,
  isSelectionModal,
}) => {
  const intl = useIntl();
  const history = useHistory();

  // selectors
  const usersList = useSelector<RootState>(
    // @ts-ignore
    ({ userManagement }) => userManagement.users
  ) as UserType[];

  // state
  const [checkedUser, setCheckedUser] = useState<Assignee[]>([]);
  const [responsiblePersons, setResponsiblePersons] = useState<Assignee[]>(
    selectedUsers || []
  );

  // effects
  useEffect(() => {
    if (selectedUsers) {
      setResponsiblePersons(selectedUsers);
    }
  }, [selectedUsers]);

  // handlers
  const toggleCheckedUser = (user: Assignee) => {
    setCheckedUser((prevCheckedUser) => {
      // Check if the user is already in the checkedUser list
      if (
        prevCheckedUser.some(
          (checkedCustomer) => checkedCustomer.id === user.id
        )
      ) {
        // Remove the user if already checked
        return prevCheckedUser.filter(
          (checkedCustomer) => checkedCustomer.id !== user.id
        );
      } else {
        // Add the user if not checked
        return [...prevCheckedUser, user];
      }
    });
  };

  const isChecked = (user: Assignee) =>
    checkedUser.some((checkedCustomer) => checkedCustomer.id === user.id);

  const goToUsersPage = (user_id: number) => {
    const contact = usersList.find((user) => user.id === user_id);
    if (contact) {
      history.push(`/system/user/edituser`, { userDetails: contact });
    }
  };

  return (
    <Modal
      show={show}
      centered
      dialogClassName="large-size-modal"
      backdrop="static"
      id="MailContactsModel"
    >
      <Modal.Header>
        <div>
          <h3>
            {intl.formatMessage({
              id: "TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_TITLE",
            })}
          </h3>
        </div>
        <div>
          {checkedUser.length > 0 && (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedUser.length} {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn btn-danger"
                // onClick={() => {
                //   deleteConfirmAlert(
                //     intl.formatMessage({
                //       id: "TEAM_USERS_DELETE_CONFIRM_MESSAGE",
                //     }),
                //     (data) => {
                //       if (data === "Yes") deleteUserHandle();
                //     }
                //   );
                //  showDeleteConfirmModal(
                //    intl.formatMessage({
                //      id: "TEAM_USERS_DELETE_CONFIRM_MESSAGE",
                //    }),
                //    () => {
                //      deleteUserHandle();
                //    }
                //  );
                // }}
              >
                <span className="indicator-label">
                  {intl.formatMessage({ id: "DELETE_BUTTON" })}
                </span>
              </button>
            </>
          )}
        </div>
      </Modal.Header>

      <div style={{ overflow: "auto" }}>
        <Modal.Body
          className={clsx("py-0 shubham", !isSelectionModal && "px-8")}
          style={{ overflow: "inherit" }}
        >
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
              <thead>
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  {isSelectionModal && (
                    <th className="w-25px">
                      {/* <Checkbox
                      onChange={onAllItemsCheckChange}
                      checked={checkAllItems}
                      disabled={false}
                    /> */}
                    </th>
                  )}
                  <th className="min-w-125px">
                    <div className={clsx(!isSelectionModal && "ms-15")}>
                      {intl.formatMessage({
                        id: "TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_NAME_COLUMN",
                      })}
                    </div>
                  </th>
                  <th className="min-w-125px">
                    <div>
                      {intl.formatMessage({
                        id: "TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_EMAIL_COLUMN",
                      })}
                    </div>
                  </th>
                  <th className="min-w-125px">
                    <div>
                      {intl.formatMessage({
                        id: "TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_PHONE_COLUMN",
                      })}
                    </div>
                  </th>
                  {isSelectionModal && (
                    <th className="min-w-40px text-end">
                      {intl.formatMessage({
                        id: "TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_ACTION_COLUMN",
                      })}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {responsiblePersons?.length === 0 ? (
                  <td valign="top" colSpan={7} className="dataTables_empty">
                    <div className="d-flex flex-column flex-center">
                      <div className="fs-1 fw-bolder text-dark mb-4">
                        {intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
                      </div>
                    </div>
                  </td>
                ) : (
                  responsiblePersons?.map((contact: Assignee, i) => (
                    <tr key={i}>
                      {isSelectionModal && (
                        <td className="py-2">
                          {!isSelectionModal ? null : (
                            <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
                              <Checkbox
                                onChange={() => toggleCheckedUser(contact)}
                                checked={isChecked(contact)}
                              />
                            </div>
                          )}
                        </td>
                      )}
                      <td className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                            {contact.profile_photo_url ? (
                              <img
                                placeholder={BlankAvatar}
                                src={contact.profile_photo_url}
                                alt={contact.firstname}
                                style={{ height: "40px", objectFit: "cover" }}
                                className="img-fluid rounded-circle"
                              />
                            ) : (
                              <div className="symbol-label fs-3 bg-light-danger text-danger text-uppercase cursor-pointer">
                                {contact?.firstname?.[0]}
                                {contact?.lastname?.[0]}
                              </div>
                            )}
                          </div>
                          <div
                            className="d-flex justify-content-start flex-column"
                            onClick={() => goToUsersPage(contact.id)}
                          >
                            <span
                              className="text-gray-800 fw-bold text-hover-primary text-capitalize mb-1"
                              role="button"
                            >
                              {contact?.firstname} {contact?.lastname}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="py-2">
                        <span className="text-gray-600 fw-bold">
                          {contact?.email}
                        </span>
                      </td>
                      <td className="py-2">
                        <span className="text-gray-600 fw-bold">
                          {contact?.telephone ? contact?.telephone : "-"}
                        </span>
                      </td>
                      {isSelectionModal && (
                        <td>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-icon btn-light btn-active-light-danger btn-sm"
                              // onClick={(e) => {
                              //   deleteConfirmAlert(
                              //     intl.formatMessage({
                              //       id: "TEAM_USERS_DELETE_CONFIRM_MESSAGE",
                              //     }),
                              //     (data) => {
                              //       if (data === "Yes")
                              //         deleteUser(contact?.id);
                              //     }
                              //   );
                              //   showDeleteConfirmModal(
                              //     intl.formatMessage({
                              //       id: "TEAM_USERS_DELETE_CONFIRM_MESSAGE",
                              //     }),
                              //     () => {
                              //        deleteUser(contact?.id);
                              //     }
                              //   );
                              // }}
                            >
                              <SVGICON
                                src={DeleteIcon}
                                className="svg-icon-3"
                              />
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </div>

      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={closeModal}>
          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TaskResponsibleUsersModal;
