import React, { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

// styles
import "../TwoFactorAuth.scss";

// images
import SettingsIcon from "../../../../_metronic/assets/icons/settings.svg";
import { SVGICON } from "../../../../_metronic/helpers";
// import {useStore} from 'react-redux'
import InformationText from "../../../sharedComponents/informationText/InformationText";

// import {enableTwoFactorAuthentication, getQRCode, getRecoveryCodes} from '../redux/TwoFactorAuthAPI'
import {
  enableTwoFactorAuthentication,
  getQRCode,
  getRecoveryCodes,
} from "../redux/TwoFactorAuthAPI";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import { confirmPassword } from "../../auth/redux/AuthCRUD";
// import {getUserDetails} from '../../userManagement/redux'
import { useIntl } from "react-intl";
import { errorToast } from "../../../sharedComponents/toasts/Toasts";
// import {info} from 'console'

interface Props {
  show: boolean;
  closeModal: () => void;
  userDetails: UserType;
  getUserDetailsAPI: () => void;
}

interface FormValuesType {
  password?: string | undefined;
}

const PasswordSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
});

const initialValues = {
  password: "",
};

const ChooseAuthMethodModal: FC<Props> = ({
  show,
  closeModal,
  userDetails,
  getUserDetailsAPI,
}) => {
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [confirmPasswordLoading, setConfirmPasswordLoading] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<string>("");
  const [passwordFormError, setPasswordFormError] = useState<string>("");
  const [QRCode, setQRCode] = useState<string>("");
  const [recovery_codes, setRecoveryCodes] = useState<string[]>([]);
  const intl = useIntl();
  // const getQRCodeAPI = () => {
  //   getQRCode(userDetails.id).then(({data: {svg}}) => {
  //     const formattedSVG = svg.replaceAll('\\', '')

  //     const buff = Buffer.from(formattedSVG)
  //     const base64data = buff.toString('base64')

  //     setQRCode(base64data)
  //   })
  // }
  // const getrecovery_codesAPI = () => {
  //   return getrecovery_codes(userDetails.id).then(({data: {'Recovery Codes': recovery_codes}}) => {
  //     setrecovery_codes(recovery_codes)
  //   })
  // }

  const enableTwoFactorAuthenticationAPI = async () => {
    setLoading(intl.formatMessage({ id: "USERS_AUTH_LOADING_MESSAGE" }));
    enableTwoFactorAuthentication(userDetails.id)
      .then(async () => {
        setLoading(intl.formatMessage({ id: "USERS_AUTH_LOADING_MESSAGE" }));
        try {
          const response = await Promise.all([
            getQRCode(userDetails.id),
            getRecoveryCodes(userDetails.id),
          ]);
          const {
            data: { svg },
          } = response[0];
          const {
            data: { recoveryCodes },
          } = response[1];
          // const formattedSVG = svg.replaceAll("\\", "");
          // const Buffer = require("buffer").Buffer;
          // const buff = Buffer.from(formattedSVG);
          // const base64data = buff.toString("base64");
          setRecoveryCodes(recoveryCodes);
          setQRCode(svg || "");
          setStep(3);
          setLoading("");
        } catch (e) {
          closeModal();
          errorToast(
            intl.formatMessage({ id: "USERS_QR_CODE_FAILURE_MESSAGE" })
          );
        }
      })
      .catch(() => {
        setLoading("");
      })
      .finally(() => {
        getUserDetailsAPI();
      });
  };

  const onFormSubmit = (
    values: FormValuesType,
    formikActions: FormikHelpers<FormValuesType>
  ) => {
    if (values.password) {
      setConfirmPasswordLoading(true);
      confirmPassword(userDetails.id, values.password)
        .then(() => {
          setLoading(
            intl.formatMessage({ id: "USERS_ENABLING_VERIFICATION_MESSAGE" })
          );
          enableTwoFactorAuthenticationAPI();
        })
        .catch((e) => {
          setPasswordFormError(
            intl.formatMessage({ id: "USERS_INCORRECT_PASSWORD_MESSAGE" })
          );
        })
        .finally(() => {
          setConfirmPasswordLoading(false);
        });
    }
  };
  // console.log(QRCode, "QRCode")
  const ChooseAuthMethod = () => {
    return (
      <div>
        <p className="text-muted fs-5 fw-bold mb-10">
          {intl.formatMessage({ id: "USERS_LOGIN_INFO" })}
        </p>
        <div>
          <label
            className="btn bg-light-primary border-primary border border-dashed p-7 d-flex align-items-center"
            htmlFor="kt_modal_two_factor_authentication_option_1"
          >
            <SVGICON
              src={SettingsIcon}
              className={"svg-icon-4x svg-icon-primary me-4"}
            />
            <span className="d-block fw-bold text-start">
              <span className="text-dark fw-bolder d-block fs-3">
                {intl.formatMessage({ id: "USERS_AUTHENTICATOR_APP_TITLE" })}
              </span>
              <span className="text-muted fw-bold fs-6">
                {intl.formatMessage({ id: "USERS_AUTHENTICATOR_APP_INFO" })}
              </span>
            </span>
          </label>
        </div>
      </div>
    );
  };

  let passwordHandleSubmit = () => {};
  const PasswordConfirmation = () => {
    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={PasswordSchema}
          onSubmit={(values, actions) => {
            setPasswordFormError("");
            // @ts-ignore
            onFormSubmit(values);
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            status,
            handleBlur,
            setFieldValue,
            handleSubmit,
            setStatus,
          }) => {
            passwordHandleSubmit = handleSubmit;
            return (
              <>
                {passwordFormError && (
                  <div className="mb-10 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      {passwordFormError}
                    </div>
                  </div>
                )}
                <p className="text-muted fs-5 fw-bold mb-10">
                  {intl.formatMessage({ id: "USERS_PASSWORD_FORM_ERROR" })}
                </p>
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="required">
                    {intl.formatMessage({ id: "USERS_PASSWORD_FIELD" })}
                  </span>
                </label>
                <input
                  type="password"
                  placeholder={intl.formatMessage({
                    id: "USERS_PASSWORD_PLACEHOLDER",
                  })}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  name="password"
                  autoComplete="off"
                  onChange={handleChange("password")}
                  value={values.password}
                />
                {touched.password && errors.password && (
                  <div className="text-danger">
                    <span role="alert">{errors.password}</span>
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    );
  };

  const DisplayQRCode = () => {
    // console.log(QRCode);
    return (
      <div>
        <h3 className="text-dark fw-bolder mb-8">
          {intl.formatMessage({ id: "USERS_AUTHENTICATOR_APP_TITLE" })}
        </h3>
        <div className="text-gray-500 fw-bold fs-6 mb-10">
          {intl.formatMessage({ id: "USERS_AUTHENTICATOR_QR_CODE_TEXT1" })}
          <a
            href="https://support.google.com/accounts/answer/1066447?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage({ id: "USERS_AUTHRNTICATOR_APP1" })}
          </a>
          ,
          <a
            href="https://www.microsoft.com/en-us/account/authenticator"
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage({ id: "USERS_AUTHRNTICATOR_APP2" })}
          </a>
          ,
          <a
            href="https://authy.com/download/"
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage({ id: "USERS_AUTHRNTICATOR_APP3" })}
          </a>
          , or
          <a
            href="https://support.1password.com/one-time-passwords/"
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage({ id: "USERS_AUTHRNTICATOR_APP4" })}
          </a>
          {intl.formatMessage({ id: "USERS_AUTHENTICATOR_QR_CODE_TEXT2" })}
          <div className="mt-8 text-center">
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(QRCode)}`}
              alt=""
              className="mw-150px"
            />
            {/* <img src={`${QRCode}`} alt="" className="mw-150px" /> */}
          </div>
        </div>
        <div className="d-flex flow-row  fw-bolder text-gray-800 mb-10">
          {(recovery_codes || []).join(`, `)}
        </div>
        <InformationText
          textComponent={
            <div className="fs-6 text-gray-700">
              {intl.formatMessage({ id: "USERS_AUTHENTICATOR_QR_CODE_TEXT3" })}
            </div>
          }
        />
      </div>
    );
  };

  return (
    <div className="cam-modal-container">
      <Modal
        show={show}
        centered
        dialogClassName="medium-size-modal"
        backdrop="static"
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({ id: "USERS_AUTH_MODAL_TITLE" })}
          </Modal.Title>
        </Modal.Header>
        <div className="overflow-auto">
          <Modal.Body className="pt-10 pb-10 px-lg-17 overflow-inherit">
            {loading && (
              <div className="d-flex flex-row justify-content-center">
                <h3 className="indicator-progress" style={{ display: "block" }}>
                  {`${loading} `}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </h3>
              </div>
            )}
            {!loading && (
              <>
                {step === 1 ? (
                  <ChooseAuthMethod />
                ) : step === 2 ? (
                  <PasswordConfirmation />
                ) : (
                  <DisplayQRCode />
                )}
              </>
            )}
          </Modal.Body>
        </div>
        <Modal.Footer className="justify-content-center px-lg-17">
          {step === 1 ? (
            <button
              className="btn btn-light-primary w-100"
              data-kt-element="options-select"
              onClick={() => {
                setStep(2);
                // enableTwoFactorAuthenticationAPI();
              }}
            >
              {intl.formatMessage({ id: "CONTINUE_BUTTON" })}
            </button>
          ) : step === 2 ? (
            <>
              <Button variant="secondary" onClick={closeModal}>
                {intl.formatMessage({ id: "CLOSE_BUTTON" })}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  passwordHandleSubmit();
                }}
              >
                {!confirmPasswordLoading &&
                  intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                {confirmPasswordLoading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </>
          ) : (
            <>
              <Button variant="primary w-100" onClick={closeModal}>
                {intl.formatMessage({ id: "OKAY_BUTTON" })}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChooseAuthMethodModal;
